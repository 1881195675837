import React, { useEffect, useState } from "react";
import DocumentUpload from "./document-upload";
import { Button, Card, CardContent, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, tableCellClasses } from "@mui/material";
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Stack } from "@mui/system";
import Iconify from "components/Iconify";
import { UserListHead, UserListToolbar } from "sections/@dashboard/user";
import Scrollbar from "components/Scrollbar";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import CreateTemplate from "./CreateTemplate";
import { getDocList } from "services/eSignService";
import { CommonListToolbar } from "sections/@dashboard/common";
import EsignListToolbar from "./common/EsignListToolbar";
import { EsignListHead, EsignMoreMenu } from "./common";
import { filter } from "lodash";


const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
        borderLeft: 0,
    },
    'td, th ': {
        border: 0,
        borderRight: "1px solid #0000000D !important"
    },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));




const TABLE_HEAD = [
    { id: 'document_name', label: 'Name', alignRight: false },
    { id: 'status', label: 'Status', alignRight: false },
    { id: 'updated_at', label: 'Last Updated', alignRight: false },
    { id: '', label: '', alignRight: false }
];


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_) => _.document_name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}




const Esign = () => {
    const theme = useTheme();
    const navigate = useNavigate()
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('asc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('name');
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [isCreate, setIsCreate] = useState(false)
    const [docList, setDocList] = useState([])

    useEffect(() => {
        handleFetchDocuList()
    }, [])


    const handleFetchDocuList = async () => {

        const res = await getDocList();
        if (res.status === 200) {
            setDocList(res.data.data)
        }
    }


    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - docList.length) : 0;

    const filteredDocList = applySortFilter(docList, getComparator(order, orderBy), filterName);

    const isUserNotFound = filteredDocList.length === 0;

    const handleViewDoc = (item) => {
        window.location.pathname = `dashboard/add-signature/${item._id.$oid}`
    }

    return (

        <>
            {!isCreate ?
                <>
                    <Stack direction="row" justifyContent="flex-end" mb={2}>
                        <Button variant="contained" className="custom_btn" onClick={() => setIsCreate(true)}>
                            <Iconify icon="eva:plus-fill" /> Upload
                        </Button>
                    </Stack>


                    <Card padding={5}>
                        <EsignListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

                        <Scrollbar>
                            <TableContainer sx={{ minWidth: 400 }}>
                                <Table className='time_table' sx={{ background: 'white', borderRadius: '4px' }} >
                                    <EsignListHead
                                        order={order}
                                        orderBy={orderBy}
                                        headLabel={TABLE_HEAD}
                                        rowCount={docList.length}
                                        onRequestSort={handleRequestSort}
                                    />

                                    <TableBody>

                                        {filteredDocList.reverse().slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, i) => {
                                            return (
                                                <StyledTableRow>
                                                    <StyledTableCell> {item.document_name.replace(".pdf", "")} </StyledTableCell>
                                                    <StyledTableCell width={'10%'}> {item.status ? item.status : '-'} </StyledTableCell>
                                                    <StyledTableCell width={'10%'}> {item.lastupdate ? item.lastupdate : '-'} </StyledTableCell>
                                                    <StyledTableCell width={'10%'}>
                                                        <EsignMoreMenu item={item} type={'user'} viewDoc={handleViewDoc} />
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                        </Scrollbar >
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25, 50, 100]}
                            component="div"
                            count={docList?.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Card>

                </>
                :

                <>
                    <CreateTemplate setIsCreate={setIsCreate} />
                </>
            }
        </>
    );
};

export default Esign;


