export const minutesArrayConst = [
  { title: '00', value: '00' },
  { title: '1', value: '01' },
  { title: '02', value: '02' },
  { title: '03', value: '03' },
  { title: '04', value: '04' },
  { title: '05', value: '05' },
  { title: '06', value: '06' },
  { title: '07', value: '07' },
  { title: '08', value: '08' },
  { title: '09', value: '09' },
  { title: '10', value: '10' },
  { title: '11', value: '11' },
  { title: '12', value: '12' },
  { title: '13', value: '13' },
  { title: '14', value: '14' },
  { title: '15', value: '15' },
  { title: '16', value: '16' },
  { title: '17', value: '17' },
  { title: '18', value: '18' },
  { title: '19', value: '19' },
  { title: '20', value: '20' },
  { title: '21', value: '21' },
  { title: '22', value: '22' },
  { title: '23', value: '23' },
  { title: '24', value: '24' },
  { title: '25', value: '25' },
  { title: '26', value: '26' },
  { title: '27', value: '27' },
  { title: '28', value: '28' },
  { title: '29', value: '29' },
  { title: '30', value: '30' },
  { title: '31', value: '31' },
  { title: '32', value: '32' },
  { title: '33', value: '33' },
  { title: '34', value: '34' },
  { title: '35', value: '35' },
  { title: '36', value: '36' },
  { title: '37', value: '37' },
  { title: '38', value: '38' },
  { title: '39', value: '39' },
  { title: '40', value: '40' },
  { title: '41', value: '41' },
  { title: '42', value: '42' },
  { title: '43', value: '43' },
  { title: '44', value: '44' },
  { title: '45', value: '45' },
  { title: '46', value: '46' },
  { title: '47', value: '47' },
  { title: '48', value: '48' },
  { title: '49', value: '49' },
  { title: '50', value: '50' },
  { title: '51', value: '51' },
  { title: '52', value: '52' },
  { title: '53', value: '53' },
  { title: '54', value: '54' },
  { title: '55', value: '55' },
  { title: '56', value: '56' },
  { title: '57', value: '57' },
  { title: '58', value: '58' },
  { title: '59', value: '59' }
];

export const allHoursConst = [
  { title: '00', value: '00' },
  { title: '01', value: '01' },
  { title: '02', value: '02' },
  { title: '03', value: '03' },
  { title: '04', value: '04' },
  { title: '05', value: '05' },
  { title: '06', value: '06' },
  { title: '07', value: '07' },
  { title: '08', value: '08' },
  { title: '09', value: '09' },
  { title: '10', value: '10' },
  { title: '11', value: '11' },
  { title: '12', value: '12' }
];

export const priorityLevelConst = {
  veryHigh: 'veryHigh',
  high: 'high',
  medium: 'medium',
  low: 'low'
};

export const getExpirtyModuleDateFormat = (value) => {
  return `${value.getFullYear()}-${value.getMonth() + 1}-${value.getDate()}`;
};

export function formatTimeDifference(dateString) {
  // Parse the input date string to a Date object
  const inputDate = new Date(dateString);

  // Get the current date/time
  const now = new Date();

  // Calculate the difference in milliseconds
  const diffMs = inputDate - now;

  // If the date has passed, return "0/Expired"
  if (diffMs < 0) {
    return 'Expired';
  }

  // Convert milliseconds to seconds
  const diffSec = Math.floor(diffMs / 1000);

  // Convert seconds to minutes, hours, and days
  const days = Math.floor(diffSec / (60 * 60 * 24));
  const hours = Math.floor((diffSec % (60 * 60 * 24)) / (60 * 60));
  const minutes = Math.floor((diffSec % (60 * 60)) / 60);

  // Format the result
  return `${days} days-${hours}hrs-${minutes}min`;
}
