import React from 'react';
import EsignCanvasAddText from './EsignCanvasAddText';

const EsignAddName = () => {
  return (
    <>
      <EsignCanvasAddText label={'Name'} />
    </>
  );
};

export default EsignAddName;
