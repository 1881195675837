import styled from '@emotion/styled';
import { Checkbox, FormControl, FormControlLabel, FormGroup, Paper, Stack, TextField, Button } from '@mui/material';
import { useAccount } from 'context/AccountProvider';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addRecipientList } from 'store/mainSlice';
import { generateRandomUniqueId } from 'utils';
import { getRandomLightColor } from 'utils/esign.helper';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary
}));

const defaultRecipientList = [generateRandomUniqueId()];
const AddSigners = ({ setRecipientDetails, recipientDetails }) => {
  const { userDetails } = useAccount();
  const dispatch = useDispatch();
  const [isSingleSigner, setIsSingleSigner] = useState(false);
  const [recipientList, setRecipientList] = useState(defaultRecipientList);
  const handleChange = (e, key) => {
    const { value, name } = e.target;
    setRecipientDetails({
      ...recipientDetails,
      [key]: { ...recipientDetails[key], [name]: value, color: getRandomLightColor(), id: key }
    });
  };

  const handleAddRecipient = (e) => {
    e.preventDefault();
    setRecipientList((state) => {
      return [...state, generateRandomUniqueId()];
    });
  };

  const handleCheckboxChecked = (e) => {
    setRecipientDetails({});
    setIsSingleSigner(e.target.checked);
  };

  useEffect(() => {
    console.log(userDetails, 'userDetails');
    if (!_.isEmpty(userDetails)) {
      setRecipientDetails({
        ...recipientDetails,
        ['currentUser']: {
          ...recipientDetails['currentUser'],
          email: userDetails?.email,
          name: `${userDetails?.first_name} ${userDetails?.last_name}`,
          color: '#ffd65b',
          id: generateRandomUniqueId()
        }
      });
    }
  }, [userDetails]);
  // console.log(recipientRowList, 'recipientRowList');
  return (
    <>
      <div className="esign_left_side"> </div>
      <Stack sx={{ maxWidth: 'calc(100% - 401px)', width: '100%' }}>
        <div className="add-recipient">
          <h1>Add Recipients</h1>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={isSingleSigner} onChange={handleCheckboxChecked} />}
              label="I am the only signer"
            />
          </FormGroup>

          <form
            className="mt-5"
            style={{
              overflow: 'auto',
              height: '300px'
            }}
          >
            <Item>
              <FormControl fullWidth>
                <TextField
                  disabled
                  size="small"
                  fullWidth
                  type="text"
                  label="name"
                  // onChange={(e) => handleChange(e, 'one')}
                  name="name"
                  value={`${userDetails?.first_name} ${userDetails?.last_name}`}
                />
              </FormControl>
            </Item>
            <Item>
              <FormControl fullWidth>
                <TextField
                  disabled
                  size="small"
                  fullWidth
                  type="email"
                  name="email"
                  label="email"
                  // onChange={(e) => handleChange(e, 'one')}
                  value={userDetails?.email}
                />
              </FormControl>
            </Item>

            {!isSingleSigner && (
              <>
                {recipientList.map((id) => {
                  return (
                    <Fragment key={id}>
                      <hr />

                      <Item>
                        <FormControl fullWidth>
                          <TextField size="small" fullWidth type="text" label="name" onChange={(e) => handleChange(e, id)} name="name" />
                        </FormControl>
                      </Item>
                      <Item>
                        <FormControl fullWidth>
                          <TextField size="small" fullWidth type="email" name="email" label="email" onChange={(e) => handleChange(e, id)} />
                        </FormControl>
                      </Item>
                    </Fragment>
                  );
                })}
              </>
            )}

            {/* <Stack direction="horizontal">
  <div className="p-2 ms-auto">
    {" "}
    <Button
      variant="primary"
      onClick={() => {
        localStorage.setItem(
          "recipientDetails",
          JSON.stringify(recipientDetails)
        );
        navigate(`/dashboard/docusign-container/${docId}`);
      }}
    >
      Next
    </Button>
  </div>
</Stack> */}
          </form>
          {!isSingleSigner && (
            <Button variant="outlined" onClick={handleAddRecipient}>
              Add Recipient
            </Button>
          )}
        </div>
      </Stack>
      <div className="esign_right_side"> </div>
    </>
  );
};

export default AddSigners;
