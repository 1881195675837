import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import EsignAvatarWithDescr from '../Avatar/EsignAvatarWithDescr';
import CrossIcon from 'app/assets/icons/CrossIcon';
import StatusColumnDropdown from '../Documents/DocumentList/InboxStatus/StatusColumnDropdown';
import classNames from 'classnames';
import { getNotifications } from 'services/eSignService';
import { useDispatch } from 'react-redux';
import { setEsignMainAppLoader } from 'store/esignMainSlice';
import { formatDateTime, getUserFullNameInitials, RecipientDropdownActionsConst } from 'utils/esign.helper';
import { useAccount } from 'context/AccountProvider';
const SignedText = () => {
  return <span className="text-[#2E844A] capitalize  font-[600] text-[12px]">{'Signed'} </span>;
};
const ApprovedText = () => {
  return <span className="text-[#3B82F6] capitalize font-[600] text-[12px]">{'Approved'} </span>;
};
const DocumentSentText = () => {
  return <span className="text-[#6B7280] capitalize font-[600] text-[12px]">{'Sent you a document.'} </span>;
};
const filterDataByDate = (items, email) => {
  // Get current date, today, and date 7 days ago
  const now = new Date();
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const sevenDaysAgo = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);

  // Initialize categories
  const result = {
    today: [],
    last7Days: [],
    older: []
  };

  // Iterate through items and classify them
  items.forEach((item) => {
    const itemDate = new Date(item.date?.$date);
    const newItem = {
      senderName:
        item?.role === 'organizer' || item?.status === RecipientDropdownActionsConst.DocumentSent
          ? item?.sender_email === email
            ? 'You'
            : item?.sender_name
          : item?.recipients?.[0]?.email === email
          ? 'You'
          : item?.recipients?.[0]?.name,
      documentName: item?.document_name.split('.')?.[0],
      notificationDate: formatDateTime(item.date?.$date)?.notificationTime,
      senderInitials: getUserFullNameInitials(item.sender_name),
      statusValue: item.status ? item.status : 'No Status',
      role: item.role
    };

    // Classify item based on date
    if (itemDate >= today) {
      // Today or future notifications
      result.today.push(newItem);
    } else if (itemDate >= sevenDaysAgo) {
      // Last 7 days but not today
      result.last7Days.push(newItem);
    } else {
      // Older notifications
      result.older.push(newItem);
    }
  });

  return result;
};

export default function NotifcationDrawer({ onClose }) {
  const dispatch = useDispatch();
  const [notificationData, setNotificationData] = React.useState([]);
  const { userDetails } = useAccount();
  const [isUnreadSection, setIsUnreadSection] = React.useState(false);
  // Static values
  const documentId = '12345'; // Example document ID
  const statusData = [
    { email: 'john.doe@example.com', name: 'John Doe', status_date: '2024-09-13T10:00:00Z' },
    { email: 'jane.smith@example.com', name: 'Jane Smith', status_date: '2024-09-12T15:00:00Z' }
  ]; // Example status data
  const senderData = { name: 'John Doe' }; // Example sender data
  const docCreatedDateNTime = { withYear: '2024-09-13', time: '10:00 AM' }; // Example document creation date and time

  const renderStatusText = (item) => {
    if (item?.statusValue === RecipientDropdownActionsConst.NeedsToSign) {
      return <SignedText />;
    } else if (item?.statusValue === RecipientDropdownActionsConst.Approved) {
      return <ApprovedText />;
    } else if (item?.statusValue === RecipientDropdownActionsConst.DocumentSent) {
      return <DocumentSentText />;
    }
    return null;
  };

  const getNotificationData = async (isUnread = false) => {
    try {
      const data = await getNotifications({ isUnread }, (value) => {
        dispatch(setEsignMainAppLoader(value));
      });
      console.log(data, 'notification data');
      const filteredData = filterDataByDate(data?.results, userDetails?.email);
      setNotificationData(filteredData);
    } catch (error) {}
  };

  const renderFilteredSections = (data, title) => {
    return (
      <div className="min-w-[400px] w-[400px] flex flex-col gap-[10px] items-center justify-start pb-[20px]">
        <h4 className="text-[12px] font-[500] text-[#9CA3AF] self-start pb-[6px] ">{title}</h4>
        {data?.map?.((item, i) => (
          <EsignAvatarWithDescr
            key={item.email}
            title={<span className="text-[#101010] text-[14px] font-[600]">{item?.senderName}</span>}
            initials={item?.senderInitials} // Example initials
            description={
              <h4 className="text-[#6B7280] text-[12px]">
                {renderStatusText(item)}
                {` ${item?.documentName}`}
              </h4>
            }
            bgColor={'#CEFFE7'}
            color={'#086036'}
            showArrow={false}
            date={item?.notificationDate}
          />
        ))}
      </div>
    );
  };

  React.useEffect(() => {
    if (userDetails?.email) {
      if (isUnreadSection) {
        getNotificationData(true);
      } else {
        getNotificationData();
      }
    }
  }, [userDetails?.email, isUnreadSection]);
  console.log(notificationData, 'notificationData');
  const list = (anchor) => (
    <Box sx={{ width: 'auto' }} role="presentation">
      <div className="w-full flex flex-col items-start justify-center p-[20px] divide-y-2">
        <div className="w-full h-[100%] flex items-center justify-between pt-[8px] pb-[20px] ">
          <h2 className="font-[700] text-[16px] text-[#101010]">Notification</h2>
          <button onClick={onClose}>
            <CrossIcon />
          </button>
        </div>
        <div className="w-full h-[52px] flex gap-[16px] items-center">
          <h4
            className={classNames('text-[14px] font-[500]', { 'text-[#030712]': !isUnreadSection, 'text-[#9CA3AF]': isUnreadSection })}
            onClick={() => {
              setIsUnreadSection(false);
            }}
          >
            All
          </h4>
          <h4
            className={classNames('text-[14px] font-[500]', {
              'text-[#9CA3AF] cursor-pointer': !isUnreadSection,
              'text-[#030712]': isUnreadSection
            })}
            onClick={() => {
              setIsUnreadSection(true);
            }}
          >
            Unread
          </h4>
        </div>
        <div className="flex flex-col justify-center w-full pt-[12px]">
          {renderFilteredSections(notificationData?.today, '')}
          {renderFilteredSections(notificationData?.last7Days, 'Last 7 days')}
          {renderFilteredSections(notificationData?.older, 'Older')}
        </div>
      </div>
    </Box>
  );

  const anchor = 'right';
  return (
    <div>
      <Drawer anchor={anchor} open={true} onClose={onClose}>
        {list('right')}
      </Drawer>
    </div>
  );
}
