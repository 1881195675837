import { useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';
import AccountProvider from 'context/AccountProvider';
import { ToastContainer } from 'react-toastify';
import ConfirmSendingEsignDoc from 'layout/MainLayoutNewUI/Popups/ConfirmSendingEsignDoc';
import EsignAppMainLoader from 'app/components/MainLoader';
import useLoadTailwindCss from 'hooks/useLoadTailwindCss';
import AuthenticationRoutes from 'routes/AuthenticationRoutes';
import { Navigate, useLocation } from 'react-router';

// ==============================|| APP ||============================== //

const App = () => {
  const customization = useSelector((state) => state.customization);
  const esignMainAppLoader = useSelector((state) => state.esignMain.esignMainAppLoader);
  // window.addEventListener('load', localStorage.clear());
  console.log(esignMainAppLoader, 'esignMainAppLoader');
  const location = useLocation();

  const publicRoutes = AuthenticationRoutes.map((item) => item.path);
  if (publicRoutes.includes(location.pathname)) {
    const token = localStorage.getItem('token');
    if (token) {
      return <Navigate to="/dashboard/home" />;
    }
  }
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes(customization)}>
        <CssBaseline />
        <ToastContainer />
        {esignMainAppLoader ? <EsignAppMainLoader /> : null}

        {/* <ConfirmSendingEsignDoc /> */}
        <NavigationScroll>
          <AccountProvider>
            <Routes />
          </AccountProvider>
        </NavigationScroll>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
export default App;
