import React from 'react';

const BellWhyChooseEsignIcon = () => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_6210_14468)">
        <path
          d="M9 18.625C9 19.4206 9.31607 20.1837 9.87868 20.7463C10.4413 21.3089 11.2044 21.625 12 21.625C12.7956 21.625 13.5587 21.3089 14.1213 20.7463C14.6839 20.1837 15 19.4206 15 18.625"
          stroke="black"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M5.24939 10.375C5.24939 8.58479 5.96055 6.8679 7.22642 5.60203C8.49229 4.33616 10.2092 3.625 11.9994 3.625C13.7896 3.625 15.5065 4.33616 16.7724 5.60203C18.0382 6.8679 18.7494 8.58479 18.7494 10.375C18.7494 13.7331 19.5275 16.4313 20.1463 17.5C20.212 17.6138 20.2466 17.7429 20.2468 17.8743C20.2469 18.0058 20.2124 18.1349 20.1469 18.2489C20.0814 18.3628 19.9871 18.4575 19.8735 18.5235C19.7598 18.5895 19.6308 18.6245 19.4994 18.625H4.49939C4.36813 18.6242 4.23936 18.589 4.12598 18.5228C4.01259 18.4567 3.91855 18.3619 3.85326 18.2481C3.78797 18.1342 3.75371 18.0051 3.75391 17.8739C3.75411 17.7426 3.78876 17.6137 3.85439 17.5C4.47221 16.4313 5.24939 13.7322 5.24939 10.375Z"
          stroke="black"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_6210_14468">
          <rect width="24" height="24" fill="white" transform="translate(0 0.625)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BellWhyChooseEsignIcon;
