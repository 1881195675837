import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { BsTypeBold, BsTypeItalic, BsTypeUnderline } from "react-icons/bs";
import DraggableBlocks from "../draggable-blocks";
import { resizeNode } from "components/CommonFunction";
import { fontColors, fontSizes } from "utils";
import { documentSignConfig, getDocumentById, getSignConfig } from "services/eSignService";
import { getDocumentByIdHandler, getSignConfigHandler, setNodes, setSelectedUser, setUploadedDocument } from "store/mainSlice";
import { Accordion, Button, Stack } from "@mui/material";
import SVGArea from "../svg-area";

const DocusignContainer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { documentId } = useParams();
  const { selectedUser, nodes } = useSelector((state) => state.main);
  const [draggedData, setDragData] = useState(null);
  const [selectedNode, setSelectedNode] = useState(null);
  const data = JSON.parse(localStorage.getItem("recipientDetails"));

  useEffect(() => {
    if (documentId) {
      getDocumentByIdHandler(documentId)
      getSignConfigHandler(documentId)
    }
  }, [documentId]);


  const getDocumentByIdHandler = async (id) => {
    
    try {
      const res = await getDocumentById(id);

      if (res) {
        const resData = res?.data?.data;
        console.log(resData, "resData >>>>");
        if (res.status === 200) {
          const _temp = resData.document_images.map((j) => {
            return {
              url: `data:image/png;base64,${j}`,
            };
          });
          console.log(_temp, "_temp");
          debugger
          dispatch(setUploadedDocument(_temp));
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };


  const getSignConfigHandler = async (id) => {
    try {
      const res = await getSignConfig(id);
      console.log(res, "resData");
      if (res.status === 200) {
        if (!isEmpty(res.data.data)) {
          dispatch(setNodes(res.data.data));
        } else {
          dispatch(setNodes([]));
        }
      }
    } catch (error) {
    }
  };






  var elements = document.getElementsByClassName("node");

  var myFunction = function () {
    var attribute = this.getAttribute("data-id");
    setSelectedNode(nodes.find((j) => j.id == attribute));
  };

  for (var i = 0; i < elements.length; i++) {
    elements[i].addEventListener("click", myFunction, false);
  }

  const onScale = (value) => {
    const _nodes = [...nodes];
    const _map = _nodes.map((item) => {
      console.log(resizeNode(item.height, item.width, value), "check");
      if (item.id === selectedNode.id) {
        return {
          ...item,
          height: resizeNode(item.height, item.width, value)[1],
          width: resizeNode(item.height, item.width, value)[0],
        };
      } else {
        return item;
      }
    });
    console.log(_map, "_map");
    dispatch(setNodes(_map));
  };

  const documentSignConfigHandler = async () => {
    const payload = {
      sign_configs: nodes,
    };
    const res = await documentSignConfig(documentId, payload);
    if (res.status === 200) {
      navigate(`/add-signature/${documentId}?email=${selectedUser}`);
    }
  };

  return (
    <div className="drag-main">
      <div className="drag-blocks">
        <select
          onChange={(e) => dispatch(setSelectedUser(e.target.value))}
          aria-label="Default select example"
        >
          <option value={0}>Select</option>
          <option value={data?.one?.email}>{data?.one?.name}</option>
          <option value={data?.two?.email}>{data?.two?.name}</option>
        </select>
        <DraggableBlocks setDragData={(dragData) => setDragData(dragData)} />
      </div>
      <div className="drag-area">
        <SVGArea draggedData={draggedData} />
        <Stack direction="horizontal">
          <div className="p-2 ms-auto">
            <Button
              variant="primary"
              onClick={() => {
                documentSignConfigHandler();
              }}
              style={{ minWidth: 120 }}
            >
              Send
            </Button>
          </div>
        </Stack>
      </div>
      <div className="format-area">
        <div className="head box">
          {selectedNode?.name === "Sign" && "Signature"}
          {selectedNode?.name === "Full Name" && "Name"}
        </div>
        <div className="recipient-box box">
          <select
            onChange={(e) => dispatch(setSelectedUser(e.target.value))}
            aria-label="Default select example"
          >
            <option value={0}>Select</option>
            <option value={data?.one?.email}>{data?.one?.name}</option>
            <option value={data?.two?.email}>{data?.two?.name}</option>
          </select>
        </div>
        <div className="formatting-box box">

          {/* <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Formatting</Accordion.Header>
              <Accordion.Body>
                {selectedNode?.name === "Sign" && (
                  <div className="input-box">
                    <Form.Control
                      onBlur={(e) => onScale(e.target.value)}
                      type="text"
                    />
                    <span>Scale %</span>
                  </div>
                )}
                {selectedNode?.name === "Full Name" && (
                  <div className="name-formating">
                    {" "}
                    <Form.Select
                      onChange={(e) =>
                        dispatch(setSelectedUser(e.target.value))
                      }
                      aria-label="Default select example"
                    >
                      <option value="Times New Roman">Times New Roman</option>
                      <option value="Arial">Arial</option>
                      <option value="fantasy">Fantasy</option>
                      <option value="cursive">cursive</option>
                    </Form.Select>
                    <div className="font-styles">
                      <Form.Select
                        onChange={(e) =>
                          dispatch(setSelectedUser(e.target.value))
                        }
                        aria-label="Default select example"
                      >
                        {fontSizes.map((item) => (
                          <option value={item}>{item}</option>
                        ))}
                      </Form.Select>
                      <ul>
                        <li>
                          <BsTypeBold />
                        </li>
                        <li>
                          <BsTypeItalic />
                        </li>
                        <li>
                          <BsTypeUnderline />
                        </li>
                      </ul>
                    </div>
                    <Form.Select
                      onChange={(e) =>
                        dispatch(setSelectedUser(e.target.value))
                      }
                      className="mt-3"
                      aria-label="Default select example"
                    >
                      {fontColors.map((item) => (
                        <option value={item.value}>{item.name}</option>
                      ))}
                    </Form.Select>
                  </div>
                )}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion> */}
        </div>
      </div>
    </div>
  );
};

export default DocusignContainer;
