import { Carousel } from 'flowbite-react';
import { useRef } from 'react';
const CarouselItem = ({ name }) => {
  return (
    <div className="bg-[white] border-[1px] border-[#D4D4D8] h-[100%] p-[20px] flex flex-col gap-[10px] rounded-[10px] min-w-[350px]">
      <div className="flex items-center gap-[10px]">
        <div className="w-[48px] h-[48px] rounded-full">
          <img
            src="https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcRSdMjGXLvTAEFop2cWQdEzdx1a9fMbj4EJF0w5vNGh9SzA2B_G"
            alt="avatar1"
            className="object-cover w-full h-full rounded-full"
          />
        </div>
        <div>
          <h5 className="font-[700] text-[16px] text-[#030712]">{name}</h5>
          <p className="text-[#030712] font-[500] text-[16px]">Indian chess grandmaster</p>
        </div>
      </div>
      <p className="w-[100%] text-[16px] font-[500] text-[#6B7280] overflow-hidden text-ellipsis line-clamp-4">
        Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget. At posuere vitae ac nisi orci facilisis sed imperdiet.
        Pellentesque mattis a commodo feugiat senectus tellus. Lacus ipsum tempor vulputate dui.
      </p>
    </div>
  );
};

const TestimonialsConst = [
  { title: 'Gukesh' },
  { title: 'Arjun Erigaisi' },
  { title: 'Pragg' },
  { title: 'Gukesh' },
  { title: 'Gukesh' },
  { title: 'Gukesh' },
  { title: 'Gukesh' },
  { title: 'Arjun Erigaisi' },
  { title: 'Pragg' },
  { title: 'Gukesh' },
  { title: 'Gukesh' },
  { title: 'Gukesh' },
  { title: 'Gukesh' },
  { title: 'Arjun Erigaisi' },
  { title: 'Pragg' },
  { title: 'Gukesh' },
  { title: 'Gukesh' },
  { title: 'Gukesh' }
];
const TestimonialsCarousel = ({ items }) => {
  const carouselRef = useRef(null);
  let isDragging = false;
  let startX, scrollLeft;

  const handleMouseDown = (e) => {
    isDragging = true;
    carouselRef.current.classList.add('cursor-grabbing');
    startX = e.pageX - carouselRef.current.offsetLeft;
    scrollLeft = carouselRef.current.scrollLeft;
  };

  const handleMouseLeave = () => {
    isDragging = false;
    carouselRef.current.classList.remove('cursor-grabbing');
  };

  const handleMouseUp = () => {
    isDragging = false;
    carouselRef.current.classList.remove('cursor-grabbing');
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - carouselRef.current.offsetLeft;
    const walk = (x - startX) * 2; // Adjust scroll speed
    carouselRef.current.scrollLeft = scrollLeft - walk;
  };

  return (
    <div
      ref={carouselRef}
      onMouseDown={handleMouseDown}
      onMouseLeave={handleMouseLeave}
      onMouseUp={handleMouseUp}
      onMouseMove={handleMouseMove}
      className="flex overflow-x-scroll gap-[20px] p-[10px] cursor-grab scrollbar-hide"
    >
      {TestimonialsConst.map((item, index) => (
        <CarouselItem key={index} name={item.name} />
      ))}
    </div>
  );
};
export default TestimonialsCarousel;
