import React from 'react';

const SendHowToDigiesign = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_6307_39853)">
        <path d="M13.332 18.6667L27.9987 4" stroke="#030712" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path
          d="M28.0001 4L19.3334 28C19.2749 28.1277 19.181 28.2358 19.0628 28.3117C18.9446 28.3875 18.8072 28.4278 18.6668 28.4278C18.5263 28.4278 18.3889 28.3875 18.2707 28.3117C18.1525 28.2358 18.0586 28.1277 18.0001 28L13.3334 18.6667L4.00009 14C3.87244 13.9415 3.76426 13.8476 3.68842 13.7294C3.61258 13.6112 3.57227 13.4738 3.57227 13.3333C3.57227 13.1929 3.61258 13.0554 3.68842 12.9373C3.76426 12.8191 3.87244 12.7252 4.00009 12.6667L28.0001 4Z"
          stroke="#030712"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_6307_39853">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SendHowToDigiesign;
