import MainLayout from 'layout/MainLayout';
import React, { useEffect, useState } from 'react';
import SignatureSavePopup from './Popups/SignatureSavePopup';

const MainLayoutNewUI = () => {
  const [isMainScssLoaded, setIsMainScssLoaded] = useState(false);
  useEffect(() => {
    // Dynamically import SCSS file when component mounts
    import('../../assets/scss/pages/newDashboard.scss')
      .then(() => {
        setIsMainScssLoaded(true);
      })
      .catch((error) => {
        console.error('Error loading Dashboard SCSS:', error);
      });
  }, []); // Run effect only once when component mounts
  return (
    <>
      {!isMainScssLoaded ? (
        <></>
      ) : (
        <>
          {' '}
          <MainLayout />
          {/* <SignatureSavePopup /> */}
        </>
      )}
    </>
  );
};

export default MainLayoutNewUI;
