import React from 'react';
import OverlayContainer from '../Overlay/OverlayContainer';
import EsignAppSecondaryButton from '../Buttons/EsignAppSecondaryButton';
import EsignAppPrimaryButton from '../Buttons/EsignAppPrimaryButton';
import { useNavigate } from 'react-router';
import { useAccount } from 'context/AccountProvider';

const ExitSignDocFlow = ({ onClose }) => {
  const navigate = useNavigate();
  const { userDetails } = useAccount();
  const handleExit = () => {
    if (userDetails?.email) {
      window.location.href = '/dashboard/home';
    } else {
      window.location.href = '/register';
      // window.history.back();
    }

    // navigate('/dashboard/home');
  };
  console.log(userDetails, 'useDetailsNeDebug');
  return (
    <>
      <OverlayContainer>
        <div className="absolute top-[30%] left-[40%] z-30 bg-[white] p-[20px] rounded-[10px] esignConfirmSendingDocPopup">
          <h4 className="text-[16px] font-[700] text-[#101010]">{'Exit Document '}</h4>
          <p className="text-[14px] font-[500] text-[#6B7280] mt-[10px]">{'Are you sure you want to exit this document?'}</p>
          <div className="flex justify-end items-center gap-[10px] mt-[30px]">
            <EsignAppSecondaryButton title={'Exit Anyway'} onClick={handleExit} />
            <EsignAppPrimaryButton title={'Cancel'} onClick={onClose} />
          </div>
        </div>
      </OverlayContainer>
    </>
  );
};

export default ExitSignDocFlow;
