import React from 'react';

const FileTextWhyChooseEsignIcon = () => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_6210_14429)">
        <path
          d="M18.75 21.625H5.25C5.05109 21.625 4.86032 21.546 4.71967 21.4053C4.57902 21.2647 4.5 21.0739 4.5 20.875V4.375C4.5 4.17609 4.57902 3.98532 4.71967 3.84467C4.86032 3.70402 5.05109 3.625 5.25 3.625H14.25L19.5 8.875V20.875C19.5 21.0739 19.421 21.2647 19.2803 21.4053C19.1397 21.546 18.9489 21.625 18.75 21.625Z"
          stroke="black"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path d="M14.25 3.625V8.875H19.5" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M9 13.375H15" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M9 16.375H15" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_6210_14429">
          <rect width="24" height="24" fill="white" transform="translate(0 0.625)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FileTextWhyChooseEsignIcon;
