import React from 'react';

const MenuDropdownIcon = () => {
  return (
    <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 1.5L7 7.5L13 1.5" stroke="#6B7280" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};

export default MenuDropdownIcon;
