import EsignAppSecondaryButton from 'app/components/Buttons/EsignAppSecondaryButton';
import { useAccount } from 'context/AccountProvider';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { changeIsNextButtonDisabled, selectUsersNodes } from 'store/esignMainSlice';
import { deleteSignConfigsNodes, removePlaceHolderValueWithId } from 'store/mainSlice';
import { selectReplaceCanvasAction } from 'store/replaceCanvasReducer';
import { addSignRectBox } from 'utils/esign.helper';

const RemovePlaceholderValue = () => {
  const canvas = useSelector(selectReplaceCanvasAction);
  const activeObject = useSelector((state) => state.esignMain.selectedActiveObject);
  const { signConfigsNodes } = useSelector((state) => state.main);
  const usersNodes = useSelector(selectUsersNodes);
  const dispatch = useDispatch();
  const { userDetails } = useAccount();
  console.log(activeObject, usersNodes, 'usersNodesdebug');
  const updateNextButtonState = () => {
    let isCanvasEmpty = true;
    Object.keys(signConfigsNodes).forEach((key) => {
      signConfigsNodes[key]?.map((item) => {
        if (item?.id !== activeObject?.id) {
          if (!signConfigsNodes[key]?.length) {
            isCanvasEmpty = true;
          } else {
            isCanvasEmpty = false;
          }
        }
      });
    });

    if (isCanvasEmpty) {
      dispatch(changeIsNextButtonDisabled(true));
    }
  };
  const handleDeletePlaceholder = () => {
    if (activeObject) {
      dispatch(
        deleteSignConfigsNodes({
          id: activeObject?.id,
          containerEmailKey: activeObject?.email
        })
      );
      canvas.remove(activeObject);
      canvas.renderAll();
      //   updateNextButtonState();
    }
  };
  const handResetVaue = () => {
    if (activeObject) {
      canvas.remove(canvas.getActiveObject());
      const selectedObject = usersNodes?.find((item) => item?.id === activeObject?.id);
      console.log(selectedObject, usersNodes, activeObject, 'selectedObject');
      if (selectedObject?.id) {
        addSignRectBox(canvas, selectedObject, userDetails?.email);

        dispatch(removePlaceHolderValueWithId(selectedObject?.id));
        canvas.renderAll();
      }
    } else {
      alert('Nothing is selected');
    }
  };
  return (
    <>
      <div className="w-full mt-[20px] removePlaceholderValue">
        <EsignAppSecondaryButton title={'Remove Value'} onClick={handResetVaue} />
      </div>
    </>
  );
};

export default RemovePlaceholderValue;
