import React from 'react';

const HamBurgerMenuIcon = ({ width = 32, height = 32 }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.33398 8H26.6673M5.33398 16H26.6673M5.33398 24H26.6673"
        stroke="black"
        stroke-width="2.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default HamBurgerMenuIcon;
