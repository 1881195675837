import React, { useRef, useEffect, useState } from 'react';
import { fabric } from 'fabric';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { AllCanvasTypes } from 'utils';
import { clearCanvas, initializeCanvas, selectReplaceCanvasAction } from 'store/replaceCanvasReducer';
import { loadPdfOnCanvas, loadPdfOnCanvasForPlaceholderCanvas, loadPdfOnCanvasForSignCanvas } from 'utils/esign.helper';
import { selectUploadedDocsImages } from 'store/esignMainSlice';

const CreateNewCanvasWithPdfLoad = ({ canvasRdxAction, initializeCanvasRdx, clearCanvasRdx, id }) => {
  const canvasRef = useRef(null);
  const dispatch = useDispatch();
  const canvas = useSelector(canvasRdxAction);
  const uploadDocument = useSelector(selectUploadedDocsImages);
  useEffect(() => {
    const canvasInstance = new fabric.Canvas(canvasRef.current);
    dispatch(initializeCanvasRdx({ canvas: canvasInstance }));
    console.log(canvasInstance, 'canvasInstance');
    return () => {
      console.log(canvasInstance, 'canvasInstance --unmount');
      // Cleanup code (if needed)
      // canvasInstance.dispose();
      // clearCanvasRdx();
    };
  }, []);

  useEffect(() => {
    if (canvas !== null) {
      canvas.on('selection:created', (e) => {
        console.log(e?.selected[0], canvas?.getObjects(), 'selectedObj');
      });
    }
    return () => {
      if (canvas !== null) {
        canvas.clear();
        canvas.off('all'); // Remove all event listeners
      }
    };
  }, [canvas]);

  useEffect(() => {
    console.log(uploadDocument, 'uploadDocumentTetsing');
    if (Array.isArray(uploadDocument) && uploadDocument?.length && canvas !== null) {
      console.log(uploadDocument, 'uploadDocumentTetsing --2');
      // Function to resize canvas to fill the container
      function resizeCanvas() {
        let canvasEl = canvasRef.current;
        let containerWidth = canvasEl.parentElement.clientWidth;
        let containerWidth2 = document.querySelector('.drag-area').clientWidth;
        console.log(canvasEl.parentElement, containerWidth, containerWidth2, 'containerWidth');
        // Set canvas dimensions
        canvas.setDimensions(
          {
            width: containerWidth2 - 5
          },
          {
            renderOnResize: true
          }
        );
        if (id === AllCanvasTypes.placeHolderCanvas) {
          loadPdfOnCanvasForPlaceholderCanvas(uploadDocument, canvas, dispatch);
        } else if (id === AllCanvasTypes.addSignCanvas) {
          loadPdfOnCanvasForSignCanvas(uploadDocument, canvas, dispatch);
        }
        // loadPdfOnCanvas(uploadDocument, canvas, dispatch);
      }

      // Call resizeCanvas initially and on window resize

      const timeout = setTimeout(() => {
        resizeCanvas();
      }, 10);
      if (window) {
        // window.addEventListener('resize', resizeCanvas);
      }

      return () => {
        // window.removeEventListener('resize', resizeCanvas);
        clearTimeout(timeout);
      };
    }
  }, [canvas, uploadDocument]);
  console.log(canvas?.getObjects(), 'canvasAllData');
  return (
    <>
      <canvas
        id={id}
        ref={canvasRef}
        // style={{
        //   border: '2px solid red'
        // }}
      />
    </>
  );
};

export default CreateNewCanvasWithPdfLoad;
