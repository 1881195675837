import React from 'react';
const randomId = () => '_' + Math.random().toString(36).slice(2, 11);
const CheckBoxWithTitle = ({ title, onChange, inputProps = {} }) => {
  return (
    <div class="flex items-center space-x-2 checkboxTitle">
      <input
        type="checkbox"
        id={randomId}
        class="form-checkbox h-5 w-5 text-blue-600 rounded border-gray-300 focus:ring-blue-500"
        onChange={onChange}
        {...inputProps}
      />
      <label for={randomId} class="text-[#6B7280] font-weight-[500] text-[14px]">
        {title}
      </label>
    </div>
  );
};

export default CheckBoxWithTitle;
