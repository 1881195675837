import { useEffect } from 'react';

const useLoadTailwindCss = () => {
  useEffect(() => {
    // Dynamically import SCSS file when component mounts
    import('../assets/scss/pages/newDashboard.scss')
      .then(() => {
        setIsMainScssLoaded(true);
      })
      .catch((error) => {
        console.error('Error loading Dashboard SCSS:', error);
      })
      .finally(() => {});
  }, []); // Run effect only once when component mounts
};
export default useLoadTailwindCss;
