import Joyride, { TooltipRenderProps } from 'react-joyride';

function CustomJoyrideTooltip(props) {
  const { backProps, closeProps, continuous, index, primaryProps, skipProps, step, tooltipProps } = props;

  return (
    <div className="bg-[#3B82F6] rounded-[6px] p-[10px] max-w-[228px] " {...tooltipProps}>
      {/* <button className="tooltip__close" {...closeProps}>
        &times;
      </button> */}
      {step.title && <h4 className="tooltip__title">{step.title}</h4>}
      <div className="text-[14px] font-[500] text-[white]">{step.content}</div>
      <div className="flex gap-[10px] items-center justify-end mt-[12px]">
        <button className="bg-[#3B82F6] border-[1px] text-[14px] font-[600] text-[white] rounded-[6px] py-[8px] px-[20px]" {...skipProps}>
          {skipProps.title}
        </button>
        <div className="">
          {/* {index > 0 && (
            <button
              className="bg-[#3B82F6] border-[1px] text-[14px] font-[600] text-[white] rounded-[6px] py-[8px] px-[20px]"
              {...backProps}
            >
              {backProps.title}
            </button>
          )} */}
          {continuous && (
            <button
              className="bg-[white] border-[1px] text-[14px] font-[600] text-[#3B82F6] rounded-[6px] py-[8px] px-[20px]"
              {...primaryProps}
            >
              {primaryProps.title}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default CustomJoyrideTooltip;
