import React from 'react';

const DateIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g clip-path="url(#clip0_1708_62053)">
        <path
          d="M4.5 2L6 2L6 0.499998C6 0.219998 6.22 -1.55438e-06 6.5 -1.5299e-06C6.78 -1.50542e-06 7 0.219999 7 0.499999L7 2L17 2L17 0.499999C17 0.219999 17.22 -5.92726e-07 17.5 -5.68248e-07C17.78 -5.4377e-07 18 0.219999 18 0.499999L18 2L19.5 2C21.98 2 24 4.02 24 6.5L24 19.5C24 21.98 21.98 24 19.5 24L4.5 24C2.02 24 -1.92155e-06 21.98 -1.70474e-06 19.5L-5.68248e-07 6.5C-3.5144e-07 4.02 2.02 2 4.5 2ZM12.5 9L12.5 13L17 13L17 9L12.5 9ZM7 9L7 13L11.5 13L11.5 9L7 9ZM0.999999 9L0.999999 13L6 13L6 9L0.999999 9ZM18 13L23 13L23 9L18 9L18 13ZM23 14L18 14L18 18L23 18L23 14ZM17 14L12.5 14L12.5 18L17 18L17 14ZM12.5 19L12.5 23L17 23L17 19L12.5 19ZM11.5 19L7 19L7 23L11.5 23L11.5 19ZM11.5 18L11.5 14L7 14L7 18L11.5 18ZM6 14L0.999999 14L0.999998 18L6 18L6 14ZM19.5 3L4.5 3C2.57 3 1 4.57 0.999999 6.5L0.999999 8L23 8L23 6.5C23 4.57 21.43 3 19.5 3ZM23 19.5L23 19L18 19L18 23L19.5 23C21.43 23 23 21.43 23 19.5ZM4.5 23L6 23L6 19L0.999998 19L0.999998 19.5C0.999998 21.43 2.57 23 4.5 23Z"
          fill="#606060"
        />
      </g>
      <defs>
        <clipPath id="clip0_1708_62053">
          <rect width="24" height="24" fill="white" transform="matrix(-1 -8.74228e-08 -8.74228e-08 1 24 0)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DateIcon;
