import React from 'react';

const EsignInputWithLabel = ({ label, ...props }) => {
  return (
    <>
      <label className="text-[14px] font-[600] text-[#030712] mb-[5px]">{label}</label>
      <input type="text" className="max-w-full border-[1px] border-[#D1D5DB] py-[8px] px-[16px] rounded-[6px]" {...props} />
    </>
  );
};

export default EsignInputWithLabel;
