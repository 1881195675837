import React from 'react';

const ZoomInIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.5 7.5C0.5 7.27 0.686667 7.08333 0.916667 7.08333H7.58333V0.416667C7.58333 0.186667 7.77 0 8 0C8.23 0 8.41667 0.186667 8.41667 0.416667V7.08333H15.0833C15.3133 7.08333 15.5 7.27 15.5 7.5C15.5 7.73 15.3133 7.91667 15.0833 7.91667H8.41667V14.5833C8.41667 14.8133 8.23 15 8 15C7.77 15 7.58333 14.8133 7.58333 14.5833V7.91667H0.916667C0.686667 7.91667 0.5 7.73 0.5 7.5Z"
        fill="#303030"
      />
    </svg>
  );
};

export default ZoomInIcon;
