import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentStep: 1,
  completedTill: 0, // Track the highest validated step
  validationErrors: {} // Errors for each step
};

const stepperSlice = createSlice({
  name: 'stepper',
  initialState,
  reducers: {
    setCurrentStep: (state, action) => {
      state.currentStep = action.payload;
    },
    setCompletedTill: (state, action) => {
      state.completedTill = Math.max(state.completedTill, action.payload);
    },
    setValidationErrors: (state, action) => {
      state.validationErrors = { ...state.validationErrors, ...action.payload };
    }
  }
});

export const { setCurrentStep, setCompletedTill, setValidationErrors } = stepperSlice.actions;
export default stepperSlice.reducer;
