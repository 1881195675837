import CrossIcon from 'app/assets/icons/CrossIcon';
import React, { useState } from 'react';
import EsignAppPrimaryButton from '../Buttons/EsignAppPrimaryButton';
import EsignAppSecondaryButton from '../Buttons/EsignAppSecondaryButton';
import OverlayContainer from '../Overlay/OverlayContainer';
import CheckBoxWithTitle from '../Checkboxes/CheckBoxWithTitle';

const SignConsentPopup = ({ onSignClick, onCancel }) => {
  const [isSignBtnDisabled, setIsSignBtnDisabled] = useState(true);
  return (
    <>
      <OverlayContainer>
        <div
          className="absolute w-[100vw] flex flex-col items-center justify-center top-[-65px] left-[0px] signConsentPopup"
          style={{
            height: ' calc(100vh + 65px)'
          }}
        >
          <div className="max-w-[600px] w-full max-h-[230px] h-full bg-[white] z-30 rounded-[10px] p-[20px] flex flex-col justify-between">
            <div>
              <div className="flex items-center justify-between">
                <h4 className="font-[700] text-[16px] text-[#101010]">Consent</h4>
                <button onClick={onCancel} className="w-auto">
                  <CrossIcon />
                </button>
              </div>

              {/* <CheckBoxWithTitle
                title={`  `}
                onChange={(e) => {
                  if (e?.target?.checked) {
                    setIsSignBtnDisabled(false);
                  } else {
                    setIsSignBtnDisabled(true);
                  }
                }}
              /> */}
              <h4 className="mt-[10px] leading-[21px] text-[#6B7280] text-[14px] font-[500]">
                {' '}
                By checking this box and selecting the "Sign" button, I consent to using this mark as the digital equivalent of my signature
                on this electronic document. I acknowledge that any recipients of this electronically signed document will view my
                signature, date stamp, DigiEsign Id, email ID and IP address.
              </h4>
              <CheckBoxWithTitle
                onChange={(e) => {
                  if (e?.target?.checked) {
                    setIsSignBtnDisabled(false);
                  } else {
                    setIsSignBtnDisabled(true);
                  }
                }}
                title={<span className="text-[14px] foont-[500] text-[#030712] mt-[8px]">I agree with the above information</span>}
              ></CheckBoxWithTitle>
            </div>

            <div className="flex justify-end gap-[10px]">
              <EsignAppSecondaryButton title={'Cancel'} onClick={onCancel} />
              <EsignAppPrimaryButton title={'Sign'} onClick={onSignClick} disabled={isSignBtnDisabled} />
            </div>
          </div>
        </div>
      </OverlayContainer>
    </>
  );
};

export default SignConsentPopup;
