import { useAccount } from 'context/AccountProvider';
import { parse } from 'flatted';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getEsignPdfImageScaleValue,
  getIsPlaceHolderCanvasVisitedBefore,
  selectUploadedDocsImages,
  setEsignCurrentDocSelectedPage,
  setIsPlaceHolderCanvasVisitedBefore
} from 'store/esignMainSlice';
import { replaceAllSignConfigsNodes, setSignConfigsNodes, updateSignConfigsNodes } from 'store/mainSlice';
import { selectCanvasAction } from 'store/placeHolderCanvasReducer';
import { generateRandomUniqueId } from 'utils';
import { addPlaceHolderAgainUsingExitingValue, addPlaceholderBox, RecipientDropdownActionsConst } from 'utils/esign.helper';
import { constrainMovement, isTouching } from 'utils/fabircCanvas.helper';

const usePlaceholderDropOnCanvas = () => {
  const { signConfigsNodes, selectedRecipient, recipientList } = useSelector((state) => state.main);
  const canvas = useSelector(selectCanvasAction);
  const dispatch = useDispatch();
  const { userDetails } = useAccount();
  const isCanvasVisitedBefore = useSelector(getIsPlaceHolderCanvasVisitedBefore);
  const currentPageNo = useSelector((state) => state.esignMain.esignCurrentDocSelectedPage);
  const uploadDocument = useSelector(selectUploadedDocsImages);
  const scaleFactor = useSelector(getEsignPdfImageScaleValue);

  // Helper function to get the original coordinates
  const getOriginalCoordsPayload = (left, top, canvasHeight) => ({
    originalX: left / scaleFactor,
    originalY: currentPageNo + 1 === 1 ? top / scaleFactor : (top - (canvasHeight / uploadDocument.length) * currentPageNo) / scaleFactor
  });

  // Calculate the current page based on the top position
  const calculatePageNumber = (top) => {
    const paddingBottom = 20;
    const totalPages = uploadDocument.length;
    const totalPadding = (totalPages - 1) * paddingBottom;
    const pageHeight = Math.floor((canvas.getHeight() - totalPadding) / totalPages);
    const canvasOnePageHeight = pageHeight + paddingBottom;

    const pageNoWithDecimal = top / canvasOnePageHeight;
    const currentPage = Math.floor(pageNoWithDecimal);
    const offset = top % canvasOnePageHeight;

    return { currentPage, offset, canvasOnePageHeight, pageHeight };
  };

  // const handleDrop = (event) => {
  //   const uniqueId = generateRandomUniqueId();
  //   const containerEmailKey = selectedRecipient?.email || userDetails?.email;

  //   // Define padding and calculate page dimensions
  //   const paddingBottom = 20;
  //   const totalPages = uploadDocument.length;
  //   const totalPadding = (totalPages - 1) * paddingBottom;
  //   const pageHeight = Math.floor((canvas.getHeight() - totalPadding) / totalPages);
  //   const canvasOnePageHeight = pageHeight + paddingBottom;

  //   // Add placeholder to the canvas
  //   const addedItem = addPlaceholderBox(canvas, event.e, uniqueId, selectedRecipient?.color, containerEmailKey);
  //   const dropItem = parse(event.e.dataTransfer.getData('customData'));

  //   // Calculate page number and offset
  //   const { currentPage, offset, canvasOnePageHeight: cOnePageHeight } = calculatePageNumber(addedItem.top);

  //   // Snapping threshold (10% of the page height)
  //   const snappingThreshold = 0.1 * pageHeight;

  //   // Snap logic, similar to the handleObjectModified function
  //   let snappedPage = currentPage;
  //   const addedItemBottom = addedItem.top + addedItem.height;

  //   // Snap to the next page if close to the bottom of the current page
  //   if (addedItemBottom > (currentPage + 1) * canvasOnePageHeight - paddingBottom - snappingThreshold) {
  //     if (currentPage < totalPages - 1 && addedItemBottom > (currentPage + 1) * canvasOnePageHeight - paddingBottom) {
  //       snappedPage = currentPage + 1;
  //     }
  //   }

  //   // Get boundaries for the snapped page
  //   const pageTopBoundary = snappedPage * canvasOnePageHeight;
  //   const pageBottomBoundary = pageTopBoundary + pageHeight;

  //   // Adjust top position to ensure it snaps to the top or bottom of the page
  //   if (addedItem.top < pageTopBoundary) {
  //     addedItem.top = pageTopBoundary; // Snap to the top of the current page
  //   }
  //   if (addedItem.top + addedItem.height > pageBottomBoundary) {
  //     addedItem.top = pageBottomBoundary - addedItem.height; // Snap to the bottom of the current page
  //   }

  //   // Ensure the item stays within the horizontal canvas boundaries
  //   addedItem.left = Math.max(0, Math.min(addedItem.left, canvas.getWidth() - addedItem.width));
  //   // Update the page number in Redux if it changes
  //   if (snappedPage !== currentPageNo) {
  //     dispatch(setEsignCurrentDocSelectedPage(snappedPage));
  //   }

  //   // Dispatch the placeholder details to Redux
  //   if (dropItem?.selected) {
  //     const schema = {
  //       id: uniqueId,
  //       name: dropItem.selected.name,
  //       color: selectedRecipient?.color || '#ffd65b',
  //       x: addedItem.left,
  //       y: addedItem.top,
  //       height: dropItem.selected.height * addedItem.scaleY,
  //       width: dropItem.selected.width * addedItem.scaleX,
  //       email: containerEmailKey,
  //       scaleX: addedItem.scaleY,
  //       scaleY: addedItem.scaleX,
  //       type: dropItem.selected.type,
  //       page: snappedPage + 1, // 1-indexed page number
  //       userFullName: selectedRecipient?.name,
  //       pdfImgWidth: canvas?.getWidth(),
  //       pdfImgHeight: pageHeight,
  //       OriginalPdfImgWidth: canvas?.getObjects()?.[0]?.width,
  //       OriginalPdfImgHeight: canvas?.getObjects()?.[0]?.height,
  //       ...getOriginalCoordsPayload(addedItem.left, addedItem.top, canvas?.getHeight())
  //     };

  //     dispatch(setSignConfigsNodes({ newItem: schema, containerEmailKey }));
  //   }

  //   addedItem.setCoords();
  //   canvas.renderAll();
  // };

  const handleDrop = (event) => {
    const uniqueId = generateRandomUniqueId();
    const containerEmailKey = selectedRecipient?.email || userDetails?.email;

    // Define padding and calculate page dimensions
    const paddingBottom = 20;
    const totalPages = uploadDocument.length;
    const totalPadding = (totalPages - 1) * paddingBottom;
    const pageHeight = Math.floor((canvas.getHeight() - totalPadding) / totalPages);
    const canvasOnePageHeight = pageHeight + paddingBottom;

    // Add placeholder to the canvas
    const addedItem = addPlaceholderBox(canvas, event.e, uniqueId, selectedRecipient?.color, containerEmailKey);
    const dropItem = parse(event.e.dataTransfer.getData('customData'));

    // Calculate page number and offset
    const { currentPage, offset, canvasOnePageHeight: cOnePageHeight } = calculatePageNumber(addedItem.top);

    // Snapping threshold (10% of the page height)
    const snappingThreshold = 0.1 * pageHeight;

    // Snap logic, similar to the handleObjectModified function
    let snappedPage = currentPage;
    const addedItemBottom = addedItem.top + addedItem.height;

    // Snap to the next page if close to the bottom of the current page
    if (addedItemBottom > (currentPage + 1) * canvasOnePageHeight - paddingBottom - snappingThreshold) {
      if (currentPage < totalPages - 1 && addedItemBottom > (currentPage + 1) * canvasOnePageHeight - paddingBottom) {
        snappedPage = currentPage + 1;
      }
    }

    // Get boundaries for the snapped page
    const pageTopBoundary = snappedPage * canvasOnePageHeight;
    const pageBottomBoundary = pageTopBoundary + pageHeight;

    // Adjust top position to ensure it snaps to the top or bottom of the page
    if (addedItem.top < pageTopBoundary) {
      addedItem.top = pageTopBoundary; // Snap to the top of the current page
    }
    if (addedItem.top + addedItem.height > pageBottomBoundary) {
      addedItem.top = pageBottomBoundary - addedItem.height; // Snap to the bottom of the current page
    }

    // Ensure the item stays within the horizontal canvas boundaries
    addedItem.left = Math.max(0, Math.min(addedItem.left, canvas.getWidth() - addedItem.width));

    // Adjust position if it overlaps with any existing items
    const objects = canvas.getObjects('group'); // or any other type you are using for your objects
    objects.forEach((otherObj) => {
      if (addedItem !== otherObj && isTouching(addedItem, otherObj, 3)) {
        // Calculate a non-overlapping position
        const overlapBuffer = 3; // Buffer space before touching

        if (addedItem.left < otherObj.left && addedItem.left + addedItem.width > otherObj.left) {
          // Move to the left of the other object
          addedItem.left = otherObj.left - addedItem.width - overlapBuffer;
        } else if (addedItem.left > otherObj.left && addedItem.left < otherObj.left + otherObj.width) {
          // Move to the right of the other object
          addedItem.left = otherObj.left + otherObj.width + overlapBuffer;
        }

        if (addedItem.top < otherObj.top && addedItem.top + addedItem.height > otherObj.top) {
          // Move above the other object
          addedItem.top = otherObj.top - addedItem.height - overlapBuffer;
        } else if (addedItem.top > otherObj.top && addedItem.top < otherObj.top + otherObj.height) {
          // Move below the other object
          addedItem.top = otherObj.top + otherObj.height + overlapBuffer;
        }
      }
    });

    // Keep the rectangle within canvas bounds
    addedItem.left = Math.max(0, Math.min(addedItem.left, canvas.getWidth() - addedItem.width));
    addedItem.top = Math.max(0, Math.min(addedItem.top, canvas.getHeight() - addedItem.height));

    // Update the page number in Redux if it changes
    if (snappedPage !== currentPageNo) {
      dispatch(setEsignCurrentDocSelectedPage(snappedPage));
    }

    // Dispatch the placeholder details to Redux
    if (dropItem?.selected) {
      const schema = {
        id: uniqueId,
        name: dropItem.selected.name,
        color: selectedRecipient?.color || '#ffd65b',
        x: addedItem.left,
        y: addedItem.top,
        height: dropItem.selected.height * addedItem.scaleY,
        width: dropItem.selected.width * addedItem.scaleX,
        email: containerEmailKey,
        scaleX: addedItem.scaleY,
        scaleY: addedItem.scaleX,
        type: dropItem.selected.type,
        page: snappedPage + 1, // 1-indexed page number
        userFullName: selectedRecipient?.name,
        pdfImgWidth: canvas?.getWidth(),
        pdfImgHeight: pageHeight,
        OriginalPdfImgWidth: canvas?.getObjects()?.[0]?.width,
        OriginalPdfImgHeight: canvas?.getObjects()?.[0]?.height,
        ...getOriginalCoordsPayload(addedItem.left, addedItem.top, canvas?.getHeight())
      };

      dispatch(setSignConfigsNodes({ newItem: schema, containerEmailKey }));
    }

    addedItem.setCoords();
    canvas.renderAll();
  };

  const handleObjectModified = ({ target }) => {
    const containerEmailKey = selectedRecipient?.email || userDetails?.email;

    const paddingBottom = 20;
    const totalPages = uploadDocument.length;
    const totalPadding = (totalPages - 1) * paddingBottom;
    const pageHeight = (canvas.getHeight() - totalPadding) / totalPages;
    const canvasOnePageHeight = pageHeight + paddingBottom;

    const { currentPage, offset } = calculatePageNumber(target.top);
    const snappingThreshold = 0.1 * pageHeight;

    let snappedPage = currentPage;
    const targetBottom = target.top + target.height;

    // Snap to next page if dragging near bottom of current page
    if (targetBottom > (currentPage + 1) * canvasOnePageHeight - paddingBottom - snappingThreshold) {
      if (currentPage < totalPages - 1 && targetBottom > (currentPage + 1) * canvasOnePageHeight - paddingBottom) {
        snappedPage = currentPage + 1;
      }
    }

    const pageTopBoundary = snappedPage * canvasOnePageHeight;
    const pageBottomBoundary = pageTopBoundary + pageHeight;

    // Snap to top or bottom of the current page
    if (target.top < pageTopBoundary) {
      target.top = pageTopBoundary; // Snap to top
    }
    if (target.top + target.height > pageBottomBoundary) {
      target.top = pageBottomBoundary - target.height; // Snap to bottom
    }

    // Ensure horizontal boundaries are respected
    target.left = Math.max(0, Math.min(target.left, canvas.getWidth() - target.width));

    // Update Redux if the page has changed
    if (snappedPage !== currentPageNo) {
      dispatch(setEsignCurrentDocSelectedPage(snappedPage));
    }

    // Update position in state
    dispatch(
      updateSignConfigsNodes({
        id: target?.id,
        updateValue: {
          x: target.left,
          y: target.top,
          scaleX: target.scaleX,
          scaleY: target.scaleY,
          height: target.height * target.scaleY,
          width: target.width * target.scaleX,
          page: snappedPage + 1, // 1-indexed page number
          ...getOriginalCoordsPayload(target.left, target.top, canvas?.getHeight())
        },
        containerEmailKey
      })
    );
    console.log(
      target,
      { OriginalPdfImgWidth: canvas?.getObjects()?.[0]?.width, OriginalPdfImgHeight: canvas?.getObjects()?.[0]?.height },
      'groupDatadebug'
    );
    target.setCoords();
    canvas.renderAll();
  };

  useEffect(() => {
    if (canvas && selectedRecipient) {
      canvas.on('drop', handleDrop);
      canvas.on('object:modified', handleObjectModified);
      return () => {
        canvas.off('drop', handleDrop);
        canvas.off('object:modified', handleObjectModified);
      };
    }
  }, [canvas, selectedRecipient, currentPageNo, scaleFactor]);
  console.log(signConfigsNodes, 'debug90872');
  return null;
};

export default usePlaceholderDropOnCanvas;
