import classNames from 'classnames';
import React from 'react';

const StatusTextWithIcon = ({ title, Icon, textColor }) => {
  return (
    <div className="flex gap-[10px] items-center">
      <Icon />
      <span className={classNames(`text-[14px] font-[500] text-[${textColor}]`)}>{title}</span>
    </div>
  );
};

export default StatusTextWithIcon;
