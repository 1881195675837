// assets
import { GoChecklist } from 'react-icons/go';
import { HiUser } from 'react-icons/hi';
import { MdOutlineAccountBalanceWallet, MdOutlineCampaign } from 'react-icons/md';
import DomainOutlinedIcon from '@mui/icons-material/DomainOutlined';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import { FaFileSignature } from 'react-icons/fa6';
import DigiEsignIcon from '../assets/images/MainLayoutNewUI/Sidebar/digi_esign.svg';
import OrgSubscriptionIcon from '../assets/images/MainLayoutNewUI/Sidebar/org_subscription_icon.svg';
import OrganizationIcon from '../assets/images/MainLayoutNewUI/Sidebar/organization_icon.svg';
// constant
import {
  IconSettings,
  IconHome,
  IconShoppingCart,
  IconClock,
  IconBookmark,
  IconHelp,
  IconClipboardList,
  IconUsers,
  IconChecklist,
  IconBusinessplan,
  IconWorld,
  IconBuilding,
  IconTemplate,
  IconDiscount2,
  IconInfoCircle,
  IconCreditCard,
  IconReceipt,
  IconSignature
} from '@tabler/icons';
import { RxPieChart } from 'react-icons/rx';
import { RiUser2Line, RiMegaphoneLine, RiFileList2Line, RiFileList3Line } from 'react-icons/ri';
import { LiaIndustrySolid } from 'react-icons/lia';
import { AiOutlinePartition } from 'react-icons/ai';
import { MdOutlineGroups } from 'react-icons/md';

// constant
const icons = {
  IconSettings,
  IconHome,
  IconShoppingCart,
  IconClock,
  IconBookmark,
  IconHelp,
  IconClipboardList,
  IconUsers,
  IconChecklist,
  IconBusinessplan,
  IconWorld,
  IconBuilding,
  IconTemplate,
  IconDiscount2,
  IconInfoCircle,
  IconCreditCard,
  IconReceipt,
  IconSignature
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
  id: 'dashboard',
  type: 'group',
  children: [
    {
      id: 'default',
      title: 'Dashboard',
      type: 'item',
      url: '/dashboard/default',
      icon: icons.IconHome
    },
    {
      id: 'my-organization',
      title: 'Org Subscription',
      type: 'item',
      url: 'dashboard/my-organization',
      icon: OrgSubscriptionIcon
    },
    {
      id: 'my-organization',
      title: 'Organization',
      type: 'item',
      url: 'dashboard/my-organization',
      icon: OrganizationIcon
    },
    {
      id: 'esign',
      title: 'Digi-Esign',
      type: 'collapse',
      url: 'dashboard/esign',
      icon: DigiEsignIcon,
      children: [
        // {
        //   id: 'esign',
        //   title: 'esign old',
        //   type: 'item',
        //   url: 'dashboard/esign',
        //   icon: IconSignature,
        //   breadcrumbs: false
        // },
        {
          id: 'esign',
          title: 'Esign',
          type: 'item',
          url: 'dashboard/digi-esign',
          // icon: IconSignature,
          breadcrumbs: false
        }
      ]
    }

    // {
    //   id: 'e-sign',
    //   title: 'New E Sign',
    //   type: 'item',
    //   url: 'dashboard/e-sign',
    //   icon: DomainOutlinedIcon
    // }
  ]
};

export default dashboard;
