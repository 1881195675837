import React from 'react';

const PenNibIcon = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_6307_39838)">
        <path
          d="M15.5 19C16.8807 19 18 17.8807 18 16.5C18 15.1193 16.8807 14 15.5 14C14.1193 14 13 15.1193 13 16.5C13 17.8807 14.1193 19 15.5 19Z"
          stroke="black"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path d="M5.00195 26.9981L13.7332 18.2656" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path
          d="M5 27L22.4312 24.095C22.6042 24.0661 22.7665 23.9922 22.9019 23.8807C23.0372 23.7693 23.1409 23.6242 23.2025 23.46L26 16L16 6L8.54 8.7975C8.37583 8.8591 8.23074 8.96278 8.11929 9.09815C8.00784 9.23351 7.93394 9.39581 7.905 9.56875L5 27Z"
          stroke="black"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M26 16.0002L29.7075 12.2927C29.8949 12.1051 30.0002 11.8509 30.0002 11.5858C30.0002 11.3207 29.8949 11.0664 29.7075 10.8789L21.125 2.29266C20.9375 2.10527 20.6832 2 20.4181 2C20.153 2 19.8988 2.10527 19.7113 2.29266L16 6.00016"
          stroke="black"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_6307_39838">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PenNibIcon;
