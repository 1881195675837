import React from 'react';

const DocumentCompletedIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_3700_25958)">
        <path d="M7 12L12 17L22 7" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M2 12L7 17M12 12L17 7" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_3700_25958">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DocumentCompletedIcon;
