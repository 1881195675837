import React from 'react';

const CreateDocumentIcon = () => {
  return (
    <svg width="169" height="150" viewBox="0 0 169 150" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_3700_34047)">
        <path
          d="M86.5281 0.323329L81.0889 1.72572L14.0765 19.0036L8.63724 20.406C5.99711 21.0898 3.7361 22.7929 2.35027 25.1414C0.964439 27.4899 0.566957 30.2921 1.24503 32.9333L24.8307 124.376C25.5147 127.015 27.2181 129.276 29.567 130.661C31.916 132.047 34.7188 132.444 37.3604 131.767L37.3745 131.763L115.237 111.688L115.251 111.684C117.891 111 120.152 109.297 121.538 106.949C122.924 104.6 123.322 101.798 122.644 99.1566L99.0578 7.71417C98.3738 5.07453 96.6705 2.81396 94.3215 1.42838C91.9725 0.0428074 89.1698 -0.354607 86.5281 0.323329Z"
          fill="#F2F2F2"
        />
        <path
          d="M87.2678 3.19117L80.8254 4.85221L15.8191 21.6128L9.37682 23.2738C7.49671 23.7608 5.88659 24.9736 4.8997 26.646C3.91281 28.3184 3.62975 30.314 4.11262 32.1948L27.6983 123.637C28.1854 125.517 29.3984 127.127 31.0712 128.113C32.7439 129.1 34.7398 129.383 36.621 128.9L36.6351 128.897L114.498 108.821L114.512 108.818C116.392 108.331 118.002 107.118 118.989 105.446C119.976 103.773 120.259 101.778 119.776 99.8968L96.1904 8.45436C95.7033 6.57464 94.4903 4.96484 92.8176 3.97814C91.1448 2.99144 89.1489 2.70842 87.2678 3.19117Z"
          fill="white"
        />
        <path
          d="M82.4032 32.8083L43.0143 42.9639C42.5744 43.0774 42.1074 43.0114 41.7161 42.7806C41.3248 42.5498 41.0413 42.173 40.9278 41.7332C40.8144 41.2933 40.8803 40.8264 41.1112 40.4352C41.342 40.044 41.7189 39.7605 42.1588 39.647L81.5477 29.4914C81.9875 29.3784 82.4541 29.4446 82.8451 29.6755C83.236 29.9063 83.5194 30.2829 83.6327 30.7225C83.7461 31.1621 83.6803 31.6287 83.4498 32.0198C83.2192 32.4109 82.8428 32.6945 82.4032 32.8083Z"
          fill="#F2F2F2"
        />
        <path
          d="M90.6893 36.6368L44.4579 48.5566C44.018 48.6701 43.551 48.6041 43.1597 48.3733C42.7684 48.1425 42.4849 47.7657 42.3714 47.3259C42.258 46.886 42.3239 46.4191 42.5548 46.0279C42.7856 45.6367 43.1625 45.3532 43.6024 45.2397L89.8337 33.3199C90.2737 33.2065 90.7407 33.2724 91.132 33.5032C91.5233 33.734 91.8068 34.1108 91.9203 34.5507C92.0337 34.9905 91.9678 35.4574 91.7369 35.8486C91.506 36.2398 91.1292 36.5234 90.6893 36.6368Z"
          fill="#F2F2F2"
        />
        <path
          d="M88.8732 57.8868L49.4843 68.0424C49.2665 68.0986 49.0397 68.1113 48.817 68.0799C48.5942 68.0484 48.3798 67.9734 48.1861 67.8591C47.9923 67.7448 47.823 67.5935 47.6877 67.4138C47.5525 67.2341 47.4539 67.0295 47.3978 66.8117C47.3416 66.5939 47.3289 66.3672 47.3603 66.1445C47.3918 65.9217 47.4668 65.7074 47.5811 65.5137C47.6955 65.32 47.8468 65.1507 48.0266 65.0154C48.2063 64.8802 48.4109 64.7817 48.6288 64.7255L88.0176 54.5699C88.2355 54.5137 88.4622 54.501 88.685 54.5325C88.9077 54.5639 89.1221 54.6389 89.3159 54.7532C89.5096 54.8675 89.679 55.0188 89.8142 55.1985C89.9495 55.3783 90.048 55.5829 90.1042 55.8007C90.1604 56.0184 90.1731 56.2452 90.1416 56.4679C90.1102 56.6906 90.0351 56.9049 89.9208 57.0986C89.8065 57.2923 89.6552 57.4616 89.4754 57.5969C89.2956 57.7321 89.091 57.8306 88.8732 57.8868Z"
          fill="#F2F2F2"
        />
        <path
          d="M97.1592 61.7227L50.9279 73.6426C50.7101 73.6987 50.4833 73.7114 50.2606 73.68C50.0378 73.6485 49.8234 73.5735 49.6297 73.4592C49.4359 73.3449 49.2666 73.1936 49.1313 73.0139C48.9961 72.8342 48.8976 72.6296 48.8414 72.4118C48.7852 72.194 48.7725 71.9673 48.8039 71.7446C48.8354 71.5219 48.9104 71.3075 49.0247 71.1138C49.1391 70.9201 49.2904 70.7508 49.4702 70.6156C49.6499 70.4803 49.8545 70.3818 50.0724 70.3257L96.3037 58.4058C96.7436 58.2924 97.2106 58.3583 97.6019 58.5892C97.9932 58.82 98.2768 59.1967 98.3902 59.6366C98.5037 60.0764 98.4377 60.5433 98.2069 60.9346C97.976 61.3258 97.5992 61.6093 97.1592 61.7227Z"
          fill="#F2F2F2"
        />
        <path
          d="M95.343 82.9727L55.9541 93.1284C55.5143 93.2415 55.0476 93.1753 54.6565 92.9445C54.2655 92.7136 53.9821 92.337 53.8687 91.8973C53.7553 91.4577 53.8212 90.991 54.0518 90.5999C54.2824 90.2087 54.6589 89.9252 55.0986 89.8115L94.4875 79.6558C94.9274 79.5424 95.3944 79.6083 95.7857 79.8392C96.177 80.07 96.4605 80.4467 96.574 80.8866C96.6874 81.3264 96.6215 81.7933 96.3906 82.1846C96.1598 82.5758 95.7829 82.8593 95.343 82.9727Z"
          fill="#F2F2F2"
        />
        <path
          d="M103.629 86.8087L57.3979 98.7285C57.18 98.7849 56.9531 98.7978 56.7302 98.7665C56.5073 98.7352 56.2928 98.6602 56.0989 98.5459C55.9049 98.4317 55.7355 98.2803 55.6001 98.1005C55.4647 97.9207 55.3661 97.716 55.3099 97.4981C55.2537 97.2802 55.241 97.0533 55.2725 96.8305C55.304 96.6077 55.3792 96.3933 55.4936 96.1995C55.6081 96.0057 55.7596 95.8364 55.9396 95.7012C56.1195 95.566 56.3244 95.4676 56.5424 95.4116L102.774 83.4918C103.214 83.3783 103.681 83.4443 104.072 83.6751C104.463 83.9059 104.747 84.2827 104.86 84.7225C104.974 85.1624 104.908 85.6293 104.677 86.0205C104.446 86.4117 104.069 86.6952 103.629 86.8087Z"
          fill="#F2F2F2"
        />
        <path
          d="M36.2899 53.3734L22.3506 56.9674C22.1397 57.0216 21.9159 56.9898 21.7283 56.8792C21.5407 56.7685 21.4047 56.588 21.3501 56.3773L18.1503 43.9717C18.0962 43.7608 18.1279 43.537 18.2386 43.3495C18.3492 43.162 18.5298 43.026 18.7406 42.9714L32.68 39.3774C32.8909 39.3232 33.1147 39.355 33.3023 39.4656C33.4898 39.5763 33.6258 39.7568 33.6804 39.9675L36.8802 52.3731C36.9344 52.584 36.9026 52.8078 36.792 52.9953C36.6813 53.1828 36.5008 53.3188 36.2899 53.3734Z"
          fill="#E6E6E6"
        />
        <path
          d="M42.7599 78.4593L28.8206 82.0533C28.6096 82.1075 28.3858 82.0758 28.1983 81.9651C28.0107 81.8545 27.8747 81.674 27.8201 81.4632L24.6203 69.0576C24.5662 68.8467 24.5979 68.623 24.7086 68.4354C24.8192 68.2479 24.9998 68.1119 25.2106 68.0573L39.1499 64.4633C39.3609 64.4092 39.5847 64.4409 39.7722 64.5516C39.9598 64.6622 40.0958 64.8427 40.1504 65.0535L43.3502 77.4591C43.4043 77.67 43.3726 77.8937 43.2619 78.0812C43.1513 78.2688 42.9707 78.4047 42.7599 78.4593Z"
          fill="#E6E6E6"
        />
        <path
          d="M49.2299 103.545L35.2905 107.139C35.0796 107.193 34.8558 107.162 34.6682 107.051C34.4807 106.94 34.3447 106.76 34.29 106.549L31.0903 94.1436C31.0361 93.9327 31.0679 93.7089 31.1785 93.5214C31.2892 93.3338 31.4697 93.1979 31.6805 93.1433L45.6199 89.5493C45.8308 89.4951 46.0546 89.5269 46.2422 89.6375C46.4298 89.7481 46.5658 89.9286 46.6204 90.1394L49.8202 102.545C49.8743 102.756 49.8426 102.98 49.7319 103.167C49.6212 103.355 49.4407 103.491 49.2299 103.545Z"
          fill="#E6E6E6"
        />
        <path
          d="M140.853 23.5078H60.4141C57.6867 23.5109 55.072 24.5955 53.1435 26.5236C51.215 28.4517 50.1303 31.0659 50.1272 33.7927V128.227C50.1303 130.954 51.215 133.568 53.1435 135.496C55.072 137.424 57.6867 138.509 60.4141 138.512H140.853C143.58 138.509 146.195 137.424 148.124 135.496C150.052 133.568 151.137 130.954 151.14 128.227V33.7927C151.137 31.0659 150.052 28.4517 148.124 26.5236C146.195 24.5955 143.58 23.5109 140.853 23.5078Z"
          fill="#E6E6E6"
        />
        <path
          d="M140.853 26.4688H60.4139C58.4717 26.4709 56.6097 27.2433 55.2364 28.6163C53.8631 29.9894 53.0906 31.851 53.0884 33.7929V128.227C53.0906 130.169 53.8631 132.03 55.2364 133.403C56.6097 134.776 58.4717 135.549 60.4139 135.551H140.853C142.795 135.549 144.657 134.776 146.031 133.403C147.404 132.03 148.176 130.169 148.179 128.227V33.7929C148.176 31.8511 147.404 29.9894 146.031 28.6164C144.657 27.2433 142.795 26.4709 140.853 26.4688Z"
          fill="white"
        />
        <path
          d="M149.304 150.002C159.675 150.002 168.082 141.597 168.082 131.228C168.082 120.859 159.675 112.453 149.304 112.453C138.933 112.453 130.526 120.859 130.526 131.228C130.526 141.597 138.933 150.002 149.304 150.002Z"
          fill="#3B82F6"
        />
        <path
          d="M158.285 128.776H151.753V122.246C151.753 121.596 151.495 120.973 151.036 120.514C150.576 120.055 149.953 119.797 149.304 119.797C148.654 119.797 148.031 120.055 147.572 120.514C147.113 120.973 146.854 121.596 146.854 122.246V128.776H140.323C139.673 128.776 139.05 129.034 138.591 129.493C138.132 129.953 137.874 130.575 137.874 131.225C137.874 131.874 138.132 132.497 138.591 132.957C139.05 133.416 139.673 133.674 140.323 133.674H146.854V140.204C146.854 140.854 147.113 141.476 147.572 141.936C148.031 142.395 148.654 142.653 149.304 142.653C149.953 142.653 150.576 142.395 151.036 141.936C151.495 141.476 151.753 140.854 151.753 140.204V133.674H158.285C158.934 133.674 159.557 133.416 160.017 132.957C160.476 132.497 160.734 131.874 160.734 131.225C160.734 130.575 160.476 129.953 160.017 129.493C159.557 129.034 158.934 128.776 158.285 128.776Z"
          fill="white"
        />
        <path
          d="M128.747 78.3551H88.0694C87.8443 78.3554 87.6214 78.3112 87.4133 78.2253C87.2053 78.1393 87.0162 78.0132 86.8569 77.8541C86.6976 77.6951 86.5713 77.5062 86.4851 77.2983C86.3989 77.0903 86.3545 76.8675 86.3545 76.6424C86.3545 76.4173 86.3989 76.1944 86.4851 75.9865C86.5713 75.7786 86.6976 75.5897 86.8569 75.4307C87.0162 75.2716 87.2053 75.1455 87.4133 75.0595C87.6214 74.9735 87.8443 74.9294 88.0694 74.9297H128.747C129.201 74.9302 129.636 75.1109 129.957 75.432C130.278 75.7531 130.458 76.1885 130.458 76.6424C130.458 77.0963 130.278 77.5317 129.957 77.8528C129.636 78.1739 129.201 78.3546 128.747 78.3551Z"
          fill="#E6E6E6"
        />
        <path
          d="M135.813 84.1364H88.0694C87.8443 84.1366 87.6214 84.0925 87.4133 84.0065C87.2053 83.9206 87.0162 83.7945 86.8569 83.6354C86.6976 83.4763 86.5713 83.2874 86.4851 83.0795C86.3989 82.8716 86.3545 82.6487 86.3545 82.4236C86.3545 82.1986 86.3989 81.9757 86.4851 81.7678C86.5713 81.5599 86.6976 81.371 86.8569 81.2119C87.0162 81.0528 87.2053 80.9267 87.4133 80.8408C87.6214 80.7548 87.8443 80.7107 88.0694 80.7109H135.813C136.268 80.7109 136.703 80.8914 137.025 81.2126C137.346 81.5338 137.526 81.9694 137.526 82.4236C137.526 82.8779 137.346 83.3135 137.025 83.6347C136.703 83.9559 136.268 84.1364 135.813 84.1364Z"
          fill="#E6E6E6"
        />
        <path
          d="M128.747 104.261H88.0694C87.8443 104.262 87.6214 104.217 87.4133 104.132C87.2053 104.046 87.0162 103.919 86.8569 103.76C86.6976 103.601 86.5713 103.412 86.4851 103.205C86.3989 102.997 86.3545 102.774 86.3545 102.549C86.3545 102.324 86.3989 102.101 86.4851 101.893C86.5713 101.685 86.6976 101.496 86.8569 101.337C87.0162 101.178 87.2053 101.052 87.4133 100.966C87.6214 100.88 87.8443 100.836 88.0694 100.836H128.747C129.201 100.836 129.637 101.016 129.958 101.338C130.28 101.659 130.46 102.094 130.46 102.549C130.46 103.003 130.28 103.439 129.958 103.76C129.637 104.081 129.201 104.261 128.747 104.261Z"
          fill="#E6E6E6"
        />
        <path
          d="M135.813 110.043H88.0694C87.8443 110.043 87.6214 109.999 87.4133 109.913C87.2053 109.827 87.0162 109.701 86.8569 109.542C86.6976 109.383 86.5713 109.194 86.4851 108.986C86.3989 108.778 86.3545 108.555 86.3545 108.33C86.3545 108.105 86.3989 107.882 86.4851 107.674C86.5713 107.466 86.6976 107.277 86.8569 107.118C87.0162 106.959 87.2053 106.833 87.4133 106.747C87.6214 106.661 87.8443 106.617 88.0694 106.617H135.813C136.038 106.617 136.261 106.661 136.469 106.747C136.677 106.833 136.867 106.959 137.026 107.118C137.185 107.277 137.311 107.466 137.398 107.674C137.484 107.882 137.528 108.105 137.528 108.33C137.528 108.555 137.484 108.778 137.398 108.986C137.311 109.194 137.185 109.383 137.026 109.542C136.867 109.701 136.677 109.827 136.469 109.913C136.261 109.999 136.038 110.043 135.813 110.043Z"
          fill="#E6E6E6"
        />
        <path
          d="M78.9575 86.7664H64.5621C64.3443 86.7662 64.1356 86.6796 63.9816 86.5256C63.8276 86.3717 63.741 86.1629 63.7407 85.9452V73.1337C63.741 72.916 63.8276 72.7073 63.9816 72.5533C64.1356 72.3993 64.3443 72.3127 64.5621 72.3125H78.9575C79.1753 72.3127 79.3841 72.3993 79.5381 72.5533C79.6921 72.7073 79.7787 72.916 79.7789 73.1337V85.9452C79.7787 86.1629 79.6921 86.3717 79.5381 86.5256C79.3841 86.6796 79.1753 86.7662 78.9575 86.7664Z"
          fill="#E6E6E6"
        />
        <path
          d="M78.9575 112.665H64.5621C64.3443 112.665 64.1356 112.578 63.9816 112.424C63.8276 112.27 63.741 112.061 63.7407 111.844V99.0322C63.741 98.8144 63.8276 98.6057 63.9816 98.4517C64.1356 98.2978 64.3443 98.2112 64.5621 98.2109H78.9575C79.1753 98.2112 79.3841 98.2978 79.5381 98.4517C79.6921 98.6057 79.7787 98.8144 79.7789 99.0322V111.844C79.7787 112.061 79.6921 112.27 79.5381 112.424C79.3841 112.578 79.1753 112.665 78.9575 112.665Z"
          fill="#E6E6E6"
        />
        <path
          d="M128.792 49.5192H98.8024C98.3481 49.5192 97.9124 49.3387 97.5911 49.0175C97.2698 48.6963 97.0894 48.2607 97.0894 47.8065C97.0894 47.3522 97.2698 46.9166 97.5911 46.5954C97.9124 46.2742 98.3481 46.0938 98.8024 46.0938H128.792C129.247 46.0938 129.682 46.2742 130.004 46.5954C130.325 46.9166 130.505 47.3522 130.505 47.8065C130.505 48.2607 130.325 48.6963 130.004 49.0175C129.682 49.3387 129.247 49.5192 128.792 49.5192Z"
          fill="#CCCCCC"
        />
        <path
          d="M135.859 55.3004H98.8024C98.5774 55.3004 98.3547 55.2561 98.1468 55.17C97.939 55.084 97.7502 54.9578 97.5911 54.7988C97.432 54.6397 97.3058 54.4509 97.2198 54.2431C97.1337 54.0353 97.0894 53.8126 97.0894 53.5877C97.0894 53.3628 97.1337 53.1401 97.2198 52.9323C97.3058 52.7245 97.432 52.5357 97.5911 52.3766C97.7502 52.2176 97.939 52.0914 98.1468 52.0054C98.3547 51.9193 98.5774 51.875 98.8024 51.875H135.859C136.313 51.875 136.749 52.0554 137.07 52.3766C137.391 52.6978 137.572 53.1335 137.572 53.5877C137.572 54.0419 137.391 54.4776 137.07 54.7988C136.749 55.12 136.313 55.3004 135.859 55.3004Z"
          fill="#CCCCCC"
        />
        <path
          d="M92.165 62.3099H64.517C64.2992 62.3096 64.0904 62.223 63.9364 62.0691C63.7824 61.9151 63.6958 61.7063 63.6956 61.4886V39.915C63.6958 39.6973 63.7824 39.4885 63.9364 39.3345C64.0904 39.1806 64.2992 39.094 64.517 39.0938H92.165C92.3828 39.094 92.5916 39.1806 92.7456 39.3345C92.8996 39.4885 92.9862 39.6973 92.9864 39.915V61.4886C92.9862 61.7063 92.8996 61.9151 92.7456 62.0691C92.5916 62.223 92.3828 62.3096 92.165 62.3099Z"
          fill="#3B82F6"
        />
      </g>
      <defs>
        <clipPath id="clip0_3700_34047">
          <rect width="167.164" height="150" fill="white" transform="translate(0.917969)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CreateDocumentIcon;
