import React from 'react';

const SignatureIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_3700_34471)">
        <path d="M2.25 16.5H21.75" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path
          d="M2.25 21C2.25 21 13.08 3 7.30406 3C3.00656 3 2.96063 21.1041 12 9.76781C12 9.76781 12.7603 13.4653 14.5528 13.5C15.2766 13.5141 16.17 12.9084 17.25 11.25C17.25 11.25 17.25 13.5 21.75 13.5"
          stroke="black"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3700_34471">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SignatureIcon;
