import HomePublic from 'app/pages/public/HomePublic';
import AddSignOnCanvasForUnAuthUser from 'app/pages/RoutesPages/AddSignOnCanvas/AddSignOnCanvasForUnAuthUser';
import MainLayout from 'layout/MainLayout';
import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import Loader from 'ui-component/Loader';
import AddDigiSign from 'views/digiEsign/addDigiSign';
import AddDigiSignRecipient from 'views/digiEsign/addDigiSignRecipient';
// import MinimalLayout from 'layout/MinimalLayout';

// login option 3 routing
const AuthLogin = Loadable(lazy(() => import('views/pages/authentication/authentication/Login')));
const AuthRegisterSign = Loadable(lazy(() => import('../views/pages/authentication/authentication/RegisterSign')));
const AuthRegister = Loadable(lazy(() => import('views/pages/authentication/authentication/Register')));
const ContractLib = Loadable(lazy(() => import('views/pages/authentication/contractLibrary/contractLibrary')));
const Library = Loadable(lazy(() => import('views/pages/authentication/Library/library')));
const ChangePassword = Loadable(lazy(() => import('views/pages/authentication/ChangePassword/changePassword')));
const ForgetPassword = Loadable(lazy(() => import('../views/pages/authentication/authentication/ForgetPassword')));
const ResetPassword = Loadable(lazy(() => import('../views/pages/authentication/authentication/ResetPassword')));
const PasswordSuccess = Loadable(lazy(() => import('../views/pages/authentication/authentication/PasswordSuccess')));
const UserLoginPage = Loadable(lazy(() => import('app/pages/public/Auth/UserLoginPage')));
const UserForgotPasswordPage = Loadable(lazy(() => import('app/pages/public/Auth/UserForgotPasswordPage')));
const UserSignupPage = Loadable(lazy(() => import('app/pages/public/Auth/SignUp/UserSignupPage')));
// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = [
  {
    path: '/',
    element: <HomePublic />,
    type: 'public'
  },
  {
    path: '/login',
    // element: <AuthLogin />
    element: <UserLoginPage />,
    type: 'public'
  },

  {
    path: '/register-signup',
    element: <AuthRegisterSign />,
    type: 'public'
  },
  {
    path: '/register',
    // element: <AuthRegister />
    element: <UserSignupPage />,
    type: 'public'
  },
  {
    path: '/library',
    element: <Library />,
    type: 'public'
  },
  {
    path: '/ChangePassword',
    element: <ChangePassword />,
    type: 'public'
  },
  {
    path: '/forgetPassword',
    // element: <ForgetPassword />
    element: <UserForgotPasswordPage />,
    type: 'public'
  },
  {
    path: '/resetPassword',
    element: <ResetPassword />,
    type: 'public'
  },
  {
    path: '/passwordSuccess',
    element: <PasswordSuccess />,
    type: 'public'
  }
  // {
  //   path: '/add-signature-recipient/:documentId',
  //   // element: <AddDigiSignRecipient />
  //   element: <AddSignOnCanvasForUnAuthUser />
  // }
];

export default AuthenticationRoutes;
