import SuccessSignedDocIcon from 'app/assets/icons/SuccessSignedDocIcon';
import React from 'react';
import EsignAppSecondaryButton from '../Buttons/EsignAppSecondaryButton';

const DocumentSignedSuccessScreen = ({ heading, btnTitle, btnOnClick }) => {
  return (
    <div className="w-screen h-screen">
      <div className="h-full w-full flex flex-col items-center justify-center gap-[30px] ">
        <SuccessSignedDocIcon />
        <h2 className="text-[40px] font-[600] #101010 text-center max-w-[440px]">{heading}</h2>
        <div className="flex items-center gap-2 ">
          <EsignAppSecondaryButton title={btnTitle} onClick={btnOnClick} />
        </div>
      </div>
    </div>
  );
};

export default DocumentSignedSuccessScreen;
