import FirstBannerLeftIcon from 'app/assets/icons/public_home/FirstBannerLeftIcon';
import FirstBannerRightIcon from 'app/assets/icons/public_home/FirstBannerRightIcon';
import { useScreenDetector } from 'app/hooks/useScreenDetector';
import React from 'react';

const TopBanner = () => {
  const { isMobile, isTablet, isDesktop } = useScreenDetector();
  return (
    <div className="mt-[8%] sm:mt-[70px] w-full">
      <div className="flex items-baseline sm:items-center  justify-evenly w-[100%] sm:w-[90%] mx-auto">
        <FirstBannerLeftIcon {...(isMobile ? { height: 'auto', width: '30%' } : {})} />
        <div className="flex flex-col items-center justify-center w-[100%] sm:w-[50%] pb-[5%]">
          <h4 className="text-[#030712] font-[700] text-[34px]  sm:text-[56px] text-center">eSignature solution for</h4>
          <h3 className="text-[#3B82F6] text-[34px] sm:text-[48px] font-[600]">Contracts</h3>
          <h5 className="text-[18px] sm:text-[30px] text-[#6B7280] font-[500] hidden sm:block">Secure, Smart & Swift</h5>
          <div className="hidden sm:flex items-center gap-[10px] mt-[20px]">
            <button className="bg-[#3B82F6] border-[2px] border-[#3B82F64D] rounded-[10px] h-[48px] px-[20px] text-[14px] font-[600] text-[white] w-fit mt-[20px]">
              Start Free Trial
            </button>
            <button className="bg-[white] border-[2px] border-[#D1D5DB] rounded-[10px] h-[48px] px-[20px] text-[14px] font-[600] text-[black] w-fit mt-[20px]">
              Get a Demo
            </button>
          </div>
        </div>

        <FirstBannerRightIcon {...(isMobile ? { height: 'auto', width: '40%' } : {})} />
      </div>
      <h5 className="text-[24px] sm:text-[30px] text-[#6B7280] font-[500] text-center sm:hidden">Secure, Smart & Swift</h5>
      <div className="flex flex-col sm:hidden items-center gap-[8px] mb-[10%]">
        <button className="bg-[#3B82F6] border-[2px] border-[#3B82F64D] rounded-[10px] h-[48px] px-[20px] text-[14px] font-[600] text-[white] w-full mt-[20px]">
          Start Free Trial
        </button>
        <button className="bg-[white] border-[2px] border-[#D1D5DB] rounded-[10px] h-[48px] px-[20px] text-[14px] font-[600] text-[black] w-full">
          Get a Demo
        </button>
      </div>
    </div>
  );
};

export default TopBanner;
