import React from 'react';

const DeleteBinIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="22" viewBox="0 0 21 22" fill="none">
      <path d="M3 3.5H18V18C18 19.933 16.433 21.5 14.5 21.5H6.5C4.567 21.5 3 19.933 3 18V3.5Z" stroke="white" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.5 3.5C0.5 3.22386 0.713198 3 0.97619 3H20.0238C20.2868 3 20.5 3.22386 20.5 3.5C20.5 3.77614 20.2868 4 20.0238 4H0.97619C0.713198 4 0.5 3.77614 0.5 3.5Z"
        fill="white"
      />
      <path d="M8 2C8 1.17157 8.67157 0.5 9.5 0.5H11.5C12.3284 0.5 13 1.17157 13 2V3.5H8V2Z" stroke="white" />
      <path d="M10.5 18V6" stroke="white" stroke-linecap="round" />
      <path d="M14.5 18V6" stroke="white" stroke-linecap="round" />
      <path d="M6.5 18V6" stroke="white" stroke-linecap="round" />
    </svg>
  );
};

export default DeleteBinIcon;
