import { MenuButton } from '@headlessui/react';
import ArrowDownIcon from 'app/assets/icons/ArrowDownIcon';
import DocumentArchiveIcon from 'app/assets/icons/DocumentArchiveIcon';
import React from 'react';

const StatusColumnDropdownButton = ({ title, onButtonClick }) => {
  return (
    <MenuButton className="flex items-center justify-between h-[100%] rounded-[6px] bg-[white] border-[1px] border-[#E2E2E2] w-full max-w-[140px]">
      <span
        className="text-[14px] font-[500] text-[#101010] border-r py-[8px] w-[70%] text-center"
        onClick={(e) => {
          e.stopPropagation();
          onButtonClick(e);
        }}
      >
        {title}
      </span>
      <button className="w-[30%] flex items-center justify-center cursor-pointer">
        <ArrowDownIcon />
      </button>
    </MenuButton>
  );
};

export default StatusColumnDropdownButton;
