import React from 'react';
import EsignCanvasAddText from './EsignCanvasAddText';

const EsignAddDesignation = () => {
  return (
    <>
      <EsignCanvasAddText label={'Designation'} />
    </>
  );
};

export default EsignAddDesignation;
