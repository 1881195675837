// import { Link } from 'react-router-dom';

// // material-ui
// import { ButtonBase } from '@mui/material';

// project imports
// import config from 'config';

import Logo from 'ui-component/Logo';
import Logo2 from '../../../assets/images/Dashboard/dashboardlogo.png';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => <Logo />;
export default LogoSection;
