import React from 'react';

const InitialsIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.056 23.743C7.965 23.907 7.794 24 7.618 24C7.536 24 7.452 23.979 7.376 23.938C2.688 21.333 0 16.981 0 12C0 5.383 5.383 0 12 0C13.245 0 14.474 0.19 15.652 0.565C15.915 0.649 16.06 0.93 15.977 1.193C15.894 1.457 15.614 1.6 15.349 1.518C14.269 1.174 13.143 0.999 12.001 0.999C5.935 1 1 5.935 1 12C1 16.607 3.501 20.64 7.862 23.062C8.104 23.197 8.191 23.5 8.056 23.743ZM23.433 8.347C23.349 8.084 23.069 7.941 22.805 8.023C22.542 8.107 22.397 8.388 22.481 8.652C22.825 9.729 23 10.856 23 12C23 15.859 19.86 19 16 19C12.14 19 9 15.859 9 12V11.5C9 11.224 8.776 11 8.5 11C8.224 11 8 11.224 8 11.5V12C8 16.411 11.589 20 16 20C20.411 20 24 16.411 24 12C24 10.753 23.81 9.523 23.434 8.348L23.433 8.347ZM13.025 7.439L19.732 0.732C20.675 -0.213 22.324 -0.213 23.267 0.732C24.242 1.707 24.242 3.293 23.267 4.267L16.56 10.974C15.909 11.625 15.006 11.999 14.085 11.999H12.499C12.223 11.999 11.999 11.775 11.999 11.499V9.913C11.999 8.977 12.363 8.099 13.024 7.438L13.025 7.439ZM13.732 8.146C13.26 8.618 13 9.246 13 9.914V11H14.086C14.744 11 15.388 10.733 15.854 10.268L22.561 3.561C23.146 2.976 23.146 2.025 22.561 1.44C21.995 0.874 21.006 0.874 20.44 1.44L13.733 8.147L13.732 8.146Z"
        fill="#303030"
      />
    </svg>
  );
};

export default InitialsIcon;
