import React, { useRef, useEffect, useState } from 'react';
import { fabric } from 'fabric';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { AllCanvasTypes } from 'utils';
import { clearCanvas, initializeCanvas, selectReplaceCanvasAction } from 'store/replaceCanvasReducer';
import { loadPdfOnCanvas } from 'utils/esign.helper';

const ReplaceFabricCanvas = ({ uploadDocument, selectedPdfPageIndex, documentData }) => {
  const canvasRef = useRef(null);
  const dispatch = useDispatch();
  const canvas = useSelector(selectReplaceCanvasAction);
  const [zoomLevel, setZoomLevel] = useState(1.0);

  useEffect(() => {
    const canvasInstance = new fabric.Canvas(canvasRef.current);
    dispatch(initializeCanvas({ canvas: canvasInstance }));
    return () => {
      // Cleanup code (if needed)
      canvasInstance.dispose();
      // dispatch(clearCanvas());
      // canvas.dispose();
    };
  }, []);

  useEffect(() => {
    if (canvas !== null) {
      // Add Fabric.js code here to manipulate the canvas
      // addSignInRect(canvas);

      canvas.on('selection:created', (e) => {
        console.log(e?.selected[0], canvas?.getObjects(), 'selectedObj');
      });

      // canvas.on('drop', (event) => {
      //   addSignInRect(canvas, event.e);

      //   console.log(event, 'eDragData');
      // });
    }
  }, [canvas]);

  useEffect(() => {
    // if (canvas !== null && Array.isArray(uploadDocument)) {
    //   const loadImage = () => {
    //     let canvasWidth = 0;
    //     let canvasHeight = 0;
    //     let top = 30;
    //     //'https://craftmypdf.com/wp-content/uploads/2021/10/mp_06.png'
    //     uploadDocument.map((item) => {
    //       fabric.Image.fromURL(item?.url, function (img) {
    //         const scale = 0.81;
    //         img.set({
    //           width: img.width,
    //           height: img.height,
    //           top,
    //           selectable: false,
    //           evented: false,
    //           left: 0,
    //           scaleX: scale,
    //           scaleY: scale
    //         });
    //         top += img.height * scale;
    //         canvasWidth = img.width * scale;
    //         canvasHeight += img.height * scale;
    //         canvas.setDimensions({ width: canvasWidth, height: canvasHeight });
    //         canvas.add(img);
    //         canvas.renderAll();
    //       });
    //     });
    //   };

    //   // canvas?.renderAll();
    //   // loadImage();

    // }
    loadPdfOnCanvas(uploadDocument, canvas);
  }, [canvas, uploadDocument, selectedPdfPageIndex]);

  useEffect(() => {
    if (canvas !== null) {
      // Function to resize canvas to fill the container
      function resizeCanvas() {
        var canvasEl = canvasRef.current;
        var containerWidth = canvasEl.parentElement.clientWidth;
        var containerHeight = canvasEl.parentElement.clientHeight;

        // Set canvas dimensions
        canvas.setDimensions(
          {
            width: containerWidth
            // height: containerHeight
          },
          {
            // Trigger a render on resize to update canvas content
            renderOnResize: true
          }
        );
      }

      // Call resizeCanvas initially and on window resize
      resizeCanvas();
      if (window) {
        window.addEventListener('resize', resizeCanvas);
      }

      return () => {
        window.removeEventListener('resize', resizeCanvas);
      };
    }
  }, [canvas]);

  // Function to handle zoom in
  const zoomIn = () => {
    setZoomLevel((prevZoom) => prevZoom + 0.1);
  };

  // Function to handle zoom out
  const zoomOut = () => {
    if (zoomLevel > 0.2) {
      // Limit zoom out level
      setZoomLevel((prevZoom) => prevZoom - 0.1);
    }
  };

  // Apply zoom to the canvas and its objects
  React.useEffect(() => {
    if (canvas !== null) {
      canvas.setZoom(zoomLevel);
      canvas.renderAll();
    }
  }, [canvas, zoomLevel]);

  return (
    <>
      <canvas id={AllCanvasTypes.addSignCanvas} ref={canvasRef} />
    </>
  );
};

export default ReplaceFabricCanvas;
