import { Button, Stack } from '@mui/material';
import UploadSignIcon from 'app/assets/icons/UploadSignIcon';
import EsignValueRemoveSignBtn from 'app/components/Buttons/EsignValueRemoveSignBtn';
import ReviewDocumentForRecipient from 'app/components/Consent/ReviewDocumentForRecipient';
import SignConsentPopup from 'app/components/Consent/SignConsentPopup';
import PlaceHolderValueContainer from 'app/components/FabricCanvas/PlaceHolderValueContainer';
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setEsignSelectedSignType } from 'store/esignMainSlice';
import { setPlaceHolderValue } from 'store/mainSlice';
import { selectReplaceCanvasAction } from 'store/replaceCanvasReducer';
import {
  createCustomRect,
  PlaceHolderTypes,
  replacePlaceholderwithImage,
  replaceSignPlaceholderValue,
  uploadSignHelper
} from 'utils/esign.helper';
import CustomSignTemplate from './CustomSignTemplate';

const EsignUploadSign = () => {
  const [previewImage, setPreviewImage] = useState(null);
  const [fileSelected, setFileSelected] = useState(false);
  const canvas = useSelector(selectReplaceCanvasAction);
  const fileInputRef = useRef(null);
  const dispatch = useDispatch();
  const [previewImageWithContainer, setPreviewImageWithContainer] = useState(null);
  const handleImageUpload = (event) => {
    // event.stopPropagation();
    // const file = event.target.files[0];
    // if (file) {
    //   const reader = new FileReader();
    //   reader.onload = () => {
    //     setPreviewImage(reader.result);
    //   };
    //   reader.readAsDataURL(file);
    //   setFileSelected(true); // Set the flag to indicate that a file has been selected
    // }
    uploadSignHelper(event, (result) => {
      setPreviewImage(result);
      setFileSelected(true); // Set the flag to indicate that a file has been selected
    });
  };

  const handleClearPreview = () => {
    setPreviewImage(null);
    setFileSelected(false); // Reset the flag when clearing the preview
  };
  const handleSave = () => {
    replacePlaceholderwithImage(previewImageWithContainer, canvas, (img, enhancedImageResolution) => {
      dispatch(setPlaceHolderValue({ value: { id: img?.id, addedValue: enhancedImageResolution, actionType: PlaceHolderTypes.sign } }));
    });
    // replacePlaceholderwithImage(previewImage, canvas, (img) => {
    //   dispatch(setPlaceHolderValue({ value: { id: img?.id, addedValue: img.toDataURL(), actionType: PlaceHolderTypes.sign } }));
    // });

    // replaceSignPlaceholderValue({ img: previewImage, canvas }, (img) => {
    //   dispatch(setPlaceHolderValue({ value: { id: img?.id, addedValue: img.toDataURL(), actionType: PlaceHolderTypes.sign } }));
    // });
    dispatch(setEsignSelectedSignType(''));
  };
  console.log(previewImageWithContainer, 'previewImageWithContainer --2');
  const placeholderWidth = canvas?.getActiveObject()?.width;
  const placeholderHeight = canvas?.getActiveObject()?.height;
  return (
    <>
      {/* <SignConsentPopup /> */}
      <div className="w-full h-[140px] border-[3px] border-dashed rounded-[10px] flex flex-col items-center justify-center gap-[12px] cursor-pointer">
        {!fileSelected ? (
          <label htmlFor="file-upload" className="flex flex-col items-center justify-center gap-[12px]">
            <UploadSignIcon />
            <h4 className="text-[14px] font-[500] text-[#6B7280]">Upload your Signature</h4>
            <input
              id="file-upload"
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              ref={fileInputRef}
              style={{ display: 'none' }}
            />
          </label>
        ) : (
          <>
            {/* <img src={previewImage} alt="Preview" style={{ width: '100%', height: '100%', objectFit: 'cover' }} /> */}

            <CustomSignTemplate
              width={placeholderWidth}
              height={placeholderHeight}
              imageUrl={previewImage}
              cb={(imageurl) => {
                setPreviewImageWithContainer(imageurl);
              }}
            />
          </>

          // <PlaceHolderValueContainer
          //   imgUrl={previewImage}
          //   geFinalImageForCanvas={(image) => {
          //     setPreviewImageWithContainer(image);
          //   }}
          // />
        )}
      </div>
      <div className="mt-[10px] w-full">
        <EsignValueRemoveSignBtn
          onRemoveOnClick={handleClearPreview}
          onSignOnClick={handleSave}
          isSignBtnDisabled={!previewImage}
          isRemoveBtnDisabled={!previewImage}
        />
      </div>
    </>
  );
};

export default EsignUploadSign;
