import React from 'react';

const SignPenIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23.8543 7.46523C23.6593 7.27023 23.3423 7.27023 23.1473 7.46523L20.3643 10.2482L13.7533 3.63723L16.5363 0.854227C16.7313 0.659227 16.7313 0.342227 16.5363 0.147227C16.3413 -0.0477734 16.0243 -0.0477734 15.8293 0.147227L12.7703 3.20623L6.53031 5.51523C5.13631 6.03123 4.06031 7.19223 3.65231 8.62123L0.0953071 21.0872C-0.142693 21.8972 0.0713071 22.7362 0.667307 23.3332C1.10431 23.7702 1.67031 24.0012 2.25931 24.0012C2.47431 24.0012 2.69331 23.9702 2.90831 23.9062L15.3333 20.3762C16.7573 19.9712 17.9173 18.9022 18.4373 17.5172L20.7943 11.2312L23.8533 8.17223C24.0483 7.97723 24.0493 7.66023 23.8543 7.46523ZM1.10231 22.2242C1.10231 22.2242 1.10131 22.2212 1.10031 22.2202C1.10331 22.2202 1.10431 22.2202 1.10231 22.2242ZM1.77631 22.8982C1.77631 22.8982 1.77931 22.8982 1.77831 22.8982H1.77631ZM17.5003 17.1652C17.0913 18.2552 16.1793 19.0962 15.0593 19.4142L2.63031 22.9462C2.34831 23.0282 2.05831 23.0122 1.79931 22.9072L8.12231 16.5842C8.51731 16.8462 8.99131 16.9992 9.50031 16.9992C10.8783 16.9992 12.0003 15.8772 12.0003 14.4992C12.0003 13.1212 10.8783 11.9992 9.50031 11.9992C8.12231 11.9992 7.00031 13.1212 7.00031 14.4992C7.00031 15.0082 7.15331 15.4812 7.41531 15.8772L1.09231 22.2002C0.987307 21.9412 0.971307 21.6502 1.05531 21.3652L4.61331 8.89523C4.93431 7.77023 5.78031 6.85723 6.87631 6.45223L12.9193 4.21623L19.7833 11.0802L17.5003 17.1652ZM8.00031 14.4992C8.00031 13.6722 8.67331 12.9992 9.50031 12.9992C10.3273 12.9992 11.0003 13.6722 11.0003 14.4992C11.0003 15.3262 10.3273 15.9992 9.50031 15.9992C8.67331 15.9992 8.00031 15.3262 8.00031 14.4992Z"
        fill="#303030"
      />
    </svg>
  );
};

export default SignPenIcon;
