import React from 'react';

const EsignAppPrimaryButton = ({ title, onClick, ...props }) => {
  return (
    <button
      className="bg-[#3B82F6] primaryButton text-white rounded-[6px] width-full py-[10px] px-[20px] border-0 disabled:opacity-50"
      onClick={onClick}
      {...props}
    >
      {title}
    </button>
  );
};

export default EsignAppPrimaryButton;
