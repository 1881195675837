import React from 'react';

const InsertSignatureIcon = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_6307_39846)">
        <path
          d="M20 9.33333V4H4V20.0133H9.33333"
          stroke="#030712"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-dasharray="0 4"
        />
        <path
          d="M25.332 14.6693V12.0026C25.332 11.2954 25.0511 10.6171 24.551 10.117C24.0509 9.61689 23.3726 9.33594 22.6654 9.33594H11.9987C11.2915 9.33594 10.6132 9.61689 10.1131 10.117C9.61298 10.6171 9.33203 11.2954 9.33203 12.0026V22.6693C9.33203 23.3765 9.61298 24.0548 10.1131 24.5549C10.6132 25.055 11.2915 25.3359 11.9987 25.3359H14.6654"
          stroke="#030712"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M17.332 17.3359L29.332 21.3359L23.9987 24.0026L21.332 29.3359L17.332 17.3359Z"
          stroke="#030712"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_6307_39846">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default InsertSignatureIcon;
