import React, { useEffect, useState } from "react";
import Draggable from "../draggable";
import { useSelector } from "react-redux";
import { BsPencilSquare, BsFillPersonFill } from "react-icons/bs";

const DraggableBlocks = ({ setDragData }) => {
  const { selectedUser } = useSelector((state) => state.main);
  const [blocks, setBlocks] = useState([
    {
      name: "Sign",
      height: 35,
      width: 84,
      color: "#ffd65b",
      icon: <BsPencilSquare />,
    },
    {
      name: "Full Name",
      height: 35,
      width: 100,
      color: "#ffd65b",
      icon: <BsFillPersonFill />,
    },
  ]);
  const onDragStart = (dragData) => {
    console.log(dragData);
    setDragData(dragData);
  };

  const onDragEnd = () => { };

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("recipientDetails"));
    const _temp = [...blocks];
    if (selectedUser) {
      for (const key in data) {
        if (data[key].email === selectedUser) {
          _temp.forEach((el) => {
            if (key === "one") {
              el.color = "#ffd65b";
            } else {
              el.color = "red";
            }
          });
        }
      }
      setBlocks(_temp);
    }
  }, [selectedUser]);

  return (
    <div className="dragging-blocks">
      {blocks.map((b) => (
        <div className="drag-main-box">
          {/* <div className={"icon-box"} style={{ backgroundColor: b.color }}>
            {b.icon}
          </div> */}
          <Draggable
            key={b.name}
            dragObject={b}
            onDragStart={(dragData) => onDragStart(dragData)}
            onDragEnd={() => onDragEnd()}
          >
            <div className={`block`}>
              <span>{b.name}</span>
            </div>
          </Draggable>
        </div>
      ))}
    </div>
  );
};

export default DraggableBlocks;
