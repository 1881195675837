export const fontSizes = [7, 8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 36, 48, 72];

export const fontColors = [
  { name: 'Black', value: 'black' },
  { name: 'White', value: 'white' },
  { name: 'Navy Blue', value: 'navyblue' },
  { name: 'Bright Blue', value: 'brightblue' },
  { name: 'Purple', value: 'purple' },
  { name: 'Dark Red', value: 'darkred' }
];

export const AllCanvasTypes = {
  placeHolderCanvas: 'placeHolderCanvas',
  addSignCanvas: 'addSignCanvas'
};

export const generateRandomUniqueId = () => {
  const minLength = 10000000; // Minimum 8-digit integer
  const maxLength = 9999999999; // Maximum 10-digit integer

  // Generate a random integer within the specified range
  const uniqueId = Math.floor(Math.random() * (maxLength - minLength + 1)) + minLength;

  return uniqueId;
};
