/* eslint-disable react/no-unknown-property */

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, CssBaseline, Toolbar, useMediaQuery } from '@mui/material';
// project imports
import Breadcrumbs from 'ui-component/extended/Breadcrumbs';
import Header from './Header';
import Sidebar from './Sidebar';
import navigation from 'menu-items';
import { drawerWidth } from 'store/constant';
import { SET_MENU, USER_ACCESS, USER_DATA } from 'store/actions';
// assets
import { IconChevronRight } from '@tabler/icons';
import { useAccount } from 'context/AccountProvider';
import { getMyUserDetails } from 'services/UserService';
import { getUserAccessScreenWise } from 'services/UserAccessMasterService';
import AppHeader from 'layout/MainLayoutNewUI/Header';
import EsignAppHeader from 'app/components/EsignAppHeader';
// styles

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  ...theme.typography.mainContent,
  ...(!open && {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    [theme.breakpoints.up('md')]: {
      marginLeft: -(drawerWidth - 20),
      width: `calc(100% - ${drawerWidth}px)`
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: '20px',
      width: `calc(100% - ${drawerWidth}px)`,
      padding: '16px'
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '10px',
      width: `calc(100% - ${drawerWidth}px)`,
      padding: '16px',
      marginRight: '10px'
    }
  }),
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    width: `calc(100% - ${drawerWidth}px)`,
    [theme.breakpoints.down('md')]: {
      marginLeft: '20px'
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '10px'
    }
  })
}));

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));
  const { setUserDetails } = useAccount();
  const [isLoading, setLoading] = useState(true);

  // Handle left drawer
  const leftDrawerOpened = useSelector((state) => state.customization.opened);
  const dispatch = useDispatch();
  const handleLeftDrawerToggle = () => {
    dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
  };
  useEffect(() => {
    dispatch({ type: SET_MENU, opened: !matchDownMd });
  }, [matchDownMd]);

  useEffect(() => {
    setLoading(false);
    getMyUserDetailsHandler();
  }, []);

  const getMyUserDetailsHandler = async () => {
    const res = await getMyUserDetails();

    if (res) {
      if (res.id) {
        getUserAccessOfUser(res.id);
      }
      dispatch({ type: USER_DATA, payload: res });
      console.log(res, 'resData');
      setUserDetails(res);
    }
  };

  const getUserAccessOfUser = async (id) => {
    // const res = await getUserAccessScreenWise(id);
    // dispatch({ type: USER_ACCESS, payload: res });
    // console.log(res, 'USER_ACCESS');
  };

  return (
    <Box sx={{ display: 'flex', background: '#F9F9F9', padding: '0px' }}>
      <CssBaseline />
      <AppBar
        enableColorOnDark
        position="fixed"
        color="inherit"
        elevation={9}
        sx={{
          bgcolor: theme.palette.background.default,
          transition: leftDrawerOpened ? theme.transitions.create('width') : 'none',
          // padding: '10px',
          zIndex: 9,
          boxShadow: 'none'
        }}
        className="app_bar"
      >
        {/* <Toolbar> */}
        {/* <Header handleLeftDrawerToggle={handleLeftDrawerToggle} /> */}
        {/* <AppHeader /> */}
        <EsignAppHeader />

        {/* </Toolbar> */}
      </AppBar>

      <Main theme={theme} open={leftDrawerOpened}>
        <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
        <Outlet />
      </Main>
    </Box>
    // <ContractLibrary />
  );
};

export default MainLayout;
