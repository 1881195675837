import { createSlice } from '@reduxjs/toolkit';
import isEmpty from 'is-empty';
import { getDocumentById, getSignConfig } from 'services/eSignService';

const initialState = {
  isLoading: false,
  selectedUser: null,
  uploadedDocument: [],
  nodes: [],
  nodesObj: {},
  docId: null,
  signConfigs: null,
  signConfigsNodes: {},
  placeHolderValues: [],
  recipientList: [],
  selectedRecipient: null,
  publicRecipientDetails: null
};

const mainSlice = createSlice({
  name: 'main',
  initialState,
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setSelectedUser(state, action) {
      state.selectedUser = action.payload;
    },
    setUploadedDocument(state, action) {
      state.uploadedDocument = action.payload;
    },
    setNodes(state, action) {
      state.nodes = action.payload;
    },
    setNodesObj(state, action) {
      state.nodesObj = action.payload;
      // return {
      //   ...state,
      //   nodesObj: {
      //     ...state.nodesObj,
      //     [action.payload.email]: [...(state.nodesObj[action.payload.email] || []), action.payload],
      //   },
      // };
    },
    setDocId(state, action) {
      state.docId = action.payload;
    },
    setSignConfigs(state, action) {
      state.signConfigs = action.payload;
    },
    setSignConfigsNodes(state, { payload: { newItem, containerEmailKey } }) {
      let updatedList = [];
      if (Array.isArray(state.signConfigsNodes[containerEmailKey])) {
        updatedList = [...state.signConfigsNodes[containerEmailKey], newItem];
      } else {
        updatedList.push(newItem);
      }
      state.signConfigsNodes = { ...state.signConfigsNodes, [containerEmailKey]: updatedList };
    },
    updateSignConfigsNodes(state, { payload: { id, updateValue, containerEmailKey } }) {
      if (Array.isArray(state.signConfigsNodes[containerEmailKey])) {
        const allList = state.signConfigsNodes[containerEmailKey];
        const updatedList = allList.map((item) => {
          if (item.id === id) {
            return { ...item, ...updateValue };
          }
          return item;
        });
        state.signConfigsNodes = { ...state.signConfigsNodes, [containerEmailKey]: updatedList };
      }
    },
    deleteSignConfigsNodes(state, { payload: { id, containerEmailKey } }) {
      if (Array.isArray(state.signConfigsNodes[containerEmailKey])) {
        const allList = state.signConfigsNodes[containerEmailKey];

        if (allList.length <= 1) {
          const newSignConfigNodes = state.signConfigsNodes;
          delete newSignConfigNodes[containerEmailKey];
          state.signConfigsNodes = { ...newSignConfigNodes };
        } else {
          const updatedList = allList.filter((item) => item.id !== id);
          state.signConfigsNodes = { ...state.signConfigsNodes, [containerEmailKey]: updatedList };
        }
      }
    },
    replaceAllSignConfigsNodes(state, { payload: { list } }) {
      state.signConfigsNodes = list;
    },
    setPlaceHolderValue(state, { payload: { value, isReset } }) {
      if (isReset) {
        state.placeHolderValues = value;
      } else {
        state.placeHolderValues = [...state.placeHolderValues, value];
      }
    },
    removePlaceHolderValueWithId(state, { payload }) {
      const filteredList = state.placeHolderValues.filter((item) => item.id !== payload);
      state.placeHolderValues = filteredList;
    },
    addRecipientList(state, { payload }) {
      state.recipientList = payload;
    },
    updateRecipientList(state, { payload }) {
      state.recipientList = [...state.recipientList, payload];
    },
    setSelectedRecipient(state, { payload }) {
      state.selectedRecipient = payload;
    },
    setPublicRecipientDetails(state, { payload }) {
      state.publicRecipientDetails = payload;
    }
  }
});

export const {
  setIsLoading,
  setSelectedUser,
  setUploadedDocument,
  setNodes,
  setNodesObj,
  setDocId,
  setSignConfigs,
  setSignConfigsNodes,
  updateSignConfigsNodes,
  setPlaceHolderValue,
  removePlaceHolderValueWithId,
  addRecipientList,
  setSelectedRecipient,
  setPublicRecipientDetails,
  deleteSignConfigsNodes,
  updateRecipientList,
  replaceAllSignConfigsNodes
} = mainSlice.actions;

export default mainSlice.reducer;

export const getDocumentByIdHandler = (id) => async (dispatch) => {
  try {
    const res = await getDocumentById(id);
    if (res) {
      const resData = res?.data?.data;
      console.log(resData, 'resData >>>>');
      if (res.status === 200) {
        const _temp = resData.document_images.map((j) => {
          return {
            url: `data:image/png;base64,${j}`
          };
        });
        console.log(_temp, '_temp');
        // resData.document_images.forEach((el) => {
        //   el.url = `data:image/png;base64,${el}`;
        // });
        // console.log(resData.document_images, "resData.document_images");
        dispatch(setUploadedDocument(_temp));
      }
    }
  } catch (error) {
    console.log(error.message);
    dispatch(setIsLoading(false));
  }
};

export const getSignConfigHandler = (id) => async (dispatch) => {
  try {
    dispatch(setIsLoading(false));
    const res = await getSignConfig(id);
    console.log(res, 'resData');
    if (res.status === 200) {
      if (!isEmpty(res.data.data)) {
        dispatch(setNodes(res.data.data));
      } else {
        dispatch(setNodes([]));
      }
    }
  } catch (error) {
    dispatch(setIsLoading(false));
  }
};
