import React from 'react';

const FirstBannerRightIcon = ({ width = 223, height = 218 }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 223 218" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_6210_14263)">
        <path d="M146.307 131.609L36.2434 162.007L0 30.398L110.064 0L146.307 131.609Z" fill="white" />
        <path
          d="M146.307 131.609L36.2434 162.007L0 30.398L110.064 0L146.307 131.609ZM37.6496 159.526L143.83 130.201L108.657 2.48077L2.47719 31.8062L37.6496 159.526Z"
          fill="#F1F1F1"
        />
        <path
          d="M86.1113 58.9592L58.5218 66.5791C58.1326 66.6861 57.7169 66.6341 57.3659 66.4346C57.0149 66.235 56.7573 65.9042 56.6495 65.5147L49.0407 37.8852C48.9339 37.4954 48.9857 37.0791 49.185 36.7276C49.3842 36.3761 49.7146 36.1181 50.1035 36.0102L77.693 28.3904C78.0822 28.2834 78.4979 28.3354 78.8489 28.5349C79.1999 28.7344 79.4575 29.0652 79.5653 29.4548L87.1741 57.0843C87.281 57.474 87.2291 57.8903 87.0298 58.2418C86.8306 58.5933 86.5002 58.8513 86.1113 58.9592ZM50.2654 36.5981C50.032 36.6629 49.8338 36.8177 49.7143 37.0286C49.5947 37.2395 49.5636 37.4892 49.6277 37.7231L57.2365 65.3526C57.3012 65.5863 57.4557 65.7848 57.6663 65.9045C57.8769 66.0243 58.1263 66.0554 58.3599 65.9912L85.9494 58.3714C86.1828 58.3066 86.381 58.1518 86.5005 57.9409C86.62 57.73 86.6512 57.4803 86.5871 57.2464L78.9783 29.6169C78.9136 29.3832 78.759 29.1847 78.5484 29.065C78.3378 28.9453 78.0884 28.9141 77.8549 28.9783L50.2654 36.5981Z"
          fill="#E5E5E5"
        />
        <path
          d="M79.1179 64.5348L51.5284 72.1547C51.1781 72.251 50.804 72.2042 50.4881 72.0247C50.1722 71.8451 49.9403 71.5473 49.8433 71.1968L42.2345 43.5673C42.1383 43.2165 42.185 42.8418 42.3643 42.5255C42.5437 42.2091 42.841 41.9769 43.191 41.8798L70.7805 34.26C71.1308 34.1636 71.5049 34.2104 71.8208 34.39C72.1367 34.5696 72.3686 34.8673 72.4656 35.2179L80.0744 62.8474C80.1705 63.1982 80.1239 63.5728 79.9445 63.8892C79.7652 64.2055 79.4679 64.4377 79.1179 64.5348Z"
          fill="#3B82F6"
        />
        <path d="M111.078 68.2985L34.0859 89.5625L34.9065 92.542L111.898 71.2781L111.078 68.2985Z" fill="#F1F1F1" />
        <path d="M113.216 76.0642L36.2246 97.3281L37.0451 100.308L114.037 79.0437L113.216 76.0642Z" fill="#F1F1F1" />
        <path d="M115.359 83.8376L38.3672 105.102L39.1877 108.081L116.179 86.8171L115.359 83.8376Z" fill="#F1F1F1" />
        <path d="M119.124 97.5095L42.1328 118.773L42.9533 121.753L119.945 100.489L119.124 97.5095Z" fill="#F1F1F1" />
        <path d="M121.263 105.283L44.2715 126.547L45.092 129.526L122.084 108.262L121.263 105.283Z" fill="#F1F1F1" />
        <path d="M123.402 113.049L46.4102 134.312L47.2307 137.292L124.222 116.028L123.402 113.049Z" fill="#F1F1F1" />
        <path d="M181.54 161.569H67.3672V25.0469H181.54V161.569Z" fill="white" />
        <path d="M181.54 161.569H67.3672V25.0469H181.54V161.569ZM69.3814 159.552H179.526V27.064H69.3814V159.552Z" fill="#E5E5E5" />
        <path d="M160.752 83.7344H95.4746V86.8251H160.752V83.7344Z" fill="#3B82F6" />
        <path d="M160.752 91.7656H95.4746V94.8564H160.752V91.7656Z" fill="#3B82F6" />
        <path d="M160.752 99.8047H95.4746V102.895H160.752V99.8047Z" fill="#3B82F6" />
        <path
          d="M90.1685 87.7451C91.2809 87.7451 92.1827 86.8421 92.1827 85.728C92.1827 84.614 91.2809 83.7109 90.1685 83.7109C89.0561 83.7109 88.1543 84.614 88.1543 85.728C88.1543 86.8421 89.0561 87.7451 90.1685 87.7451Z"
          fill="#3B82F6"
        />
        <path d="M160.752 49.1094H95.4746V52.2001H160.752V49.1094Z" fill="#E5E5E5" />
        <path d="M160.752 57.1406H95.4746V60.2314H160.752V57.1406Z" fill="#E5E5E5" />
        <path d="M160.752 65.1797H95.4746V68.2705H160.752V65.1797Z" fill="#E5E5E5" />
        <path
          d="M90.1685 52.7842C91.2809 52.7842 92.1827 51.8811 92.1827 50.7671C92.1827 49.6531 91.2809 48.75 90.1685 48.75C89.0561 48.75 88.1543 49.6531 88.1543 50.7671C88.1543 51.8811 89.0561 52.7842 90.1685 52.7842Z"
          fill="#E5E5E5"
        />
        <path d="M160.752 118.695H95.4746V121.786H160.752V118.695Z" fill="#E5E5E5" />
        <path d="M160.752 126.734H95.4746V129.825H160.752V126.734Z" fill="#E5E5E5" />
        <path d="M160.752 134.766H95.4746V137.856H160.752V134.766Z" fill="#E5E5E5" />
        <path
          d="M90.1685 122.37C91.2809 122.37 92.1827 121.467 92.1827 120.353C92.1827 119.239 91.2809 118.336 90.1685 118.336C89.0561 118.336 88.1543 119.239 88.1543 120.353C88.1543 121.467 89.0561 122.37 90.1685 122.37Z"
          fill="#E5E5E5"
        />
        <path
          d="M176.209 173.326C189.097 173.326 199.545 162.863 199.545 149.956C199.545 137.049 189.097 126.586 176.209 126.586C163.321 126.586 152.873 137.049 152.873 149.956C152.873 162.863 163.321 173.326 176.209 173.326Z"
          fill="#3B82F6"
        />
        <path
          d="M182.675 148.459V143.987C182.675 143.137 182.508 142.294 182.184 141.508C181.859 140.722 181.383 140.008 180.783 139.406C180.182 138.804 179.469 138.327 178.684 138.001C177.9 137.675 177.059 137.508 176.209 137.508C175.36 137.508 174.519 137.675 173.734 138.001C172.949 138.327 172.236 138.804 171.636 139.406C171.035 140.008 170.559 140.722 170.235 141.508C169.91 142.294 169.743 143.137 169.744 143.987V148.459C168.879 148.498 168.063 148.869 167.466 149.495C166.868 150.121 166.534 150.954 166.533 151.82V162.387H185.885V151.82C185.885 150.954 185.551 150.121 184.953 149.495C184.355 148.869 183.539 148.498 182.675 148.459ZM176.209 139.792C177.32 139.794 178.384 140.236 179.17 141.022C179.955 141.809 180.397 142.875 180.398 143.987V148.451H172.02V143.987C172.022 142.875 172.463 141.809 173.249 141.022C174.034 140.236 175.099 139.794 176.209 139.792Z"
          fill="white"
        />
        <path
          d="M177.95 153.68C177.95 153.38 177.872 153.084 177.725 152.823C177.577 152.561 177.365 152.342 177.108 152.187C176.851 152.032 176.559 151.947 176.259 151.938C175.959 151.93 175.662 151.999 175.397 152.14C175.132 152.28 174.907 152.487 174.746 152.74C174.584 152.993 174.49 153.283 174.473 153.583C174.457 153.883 174.518 154.182 174.651 154.451C174.784 154.72 174.984 154.951 175.232 155.12V158.906H177.188V155.12C177.423 154.96 177.615 154.746 177.748 154.495C177.88 154.244 177.95 153.964 177.95 153.68Z"
          fill="#3B82F6"
        />
      </g>
      <defs>
        <clipPath id="clip0_6210_14263">
          <rect width="223" height="218" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FirstBannerRightIcon;
