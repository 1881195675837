import React from 'react';
import SecondaryButton from '../Form/Button/SecondaryButton';
import { useDispatch, useSelector } from 'react-redux';
import { selectReplaceCanvasAction } from 'store/replaceCanvasReducer';
import { addSignRectBox } from 'utils/esign.helper';
import { useAccount } from 'context/AccountProvider';
import { changeIsNextButtonDisabled, selectUsersNodes } from 'store/esignMainSlice';
import { deleteSignConfigsNodes, removePlaceHolderValueWithId, setPlaceHolderValue } from 'store/mainSlice';

const RemoveSelectedCanvasObject = ({ canvasRdxSelector = selectReplaceCanvasAction, isSignCanvas = true }) => {
  const canvas = useSelector(canvasRdxSelector);
  const activeObject = useSelector((state) => state.esignMain.selectedActiveObject);
  const usersNodes = useSelector(selectUsersNodes);
  const { selectedUser, signConfigsNodes, selectedRecipient } = useSelector((state) => state.main);
  const { userDetails } = useAccount();
  const dispatch = useDispatch();
  const handResetVaue = () => {
    if (activeObject) {
      canvas.remove(canvas.getActiveObject());
      const selectedObject = usersNodes?.find((item) => item?.id === activeObject?.id);
      console.log(selectedObject, usersNodes, activeObject, 'selectedObject');
      if (selectedObject?.id) {
        addSignRectBox(canvas, selectedObject, userDetails?.email);

        dispatch(removePlaceHolderValueWithId(selectedObject?.id));
        canvas.renderAll();
      }
    } else {
      alert('Nothing is selected');
    }
  };
  const updateNextButtonState = () => {
    let isCanvasEmpty = true;
    Object.keys(signConfigsNodes).forEach((key) => {
      signConfigsNodes[key]?.map((item) => {
        if (item?.id !== activeObject?.id) {
          if (!signConfigsNodes[key]?.length) {
            isCanvasEmpty = true;
          } else {
            isCanvasEmpty = false;
          }
        }
      });
    });

    if (isCanvasEmpty) {
      dispatch(changeIsNextButtonDisabled(true));
    }
  };
  const handleDeletePlaceholder = () => {
    if (activeObject) {
      // const containerEmailKey = selectedRecipient?.email ? selectedRecipient?.email : userDetails?.email;
      console.log(activeObject, signConfigsNodes, 'denbug 91');
      dispatch(
        deleteSignConfigsNodes({
          id: activeObject?.id,
          containerEmailKey: activeObject?.email
        })
      );
      canvas.remove(activeObject);
      canvas.renderAll();
      updateNextButtonState();
    }
  };

  return (
    <>
      {isSignCanvas ? (
        <SecondaryButton
          title={'Reset Value'}
          className={'w-full border-[#101010]  mt-4 font-bold rounded-[10px]'}
          style={{
            color: '#101010',
            fontWeight: 'bold'
          }}
          onClick={handResetVaue}
        />
      ) : (
        <SecondaryButton
          title={'Remove Placeholder'}
          className={'w-full border-[#101010]  mt-4 font-bold rounded-[10px]'}
          style={{
            color: '#101010',
            fontWeight: 'bold'
          }}
          onClick={handleDeletePlaceholder}
        />
      )}
    </>
  );
};

export default RemoveSelectedCanvasObject;
