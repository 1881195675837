import TripleDotIcon from 'app/assets/icons/TripleDotIcon';
import { Dropdown } from 'flowbite-react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { updateDocumentStatus } from 'services/eSignService';
import { setEsignMainAppLoader } from 'store/esignMainSlice';
import { RecipientDropdownActionsConst } from 'utils/esign.helper';
import { DeclineDocumentPopup } from '../Popups/DeclineDocumentPopup';
import { useState } from 'react';
import { useAccount } from 'context/AccountProvider';
import { useSelector } from 'react-redux';
const DropdownItem = ({ title, onClick }) => {
  return (
    <>
      <div
        className="w-full border-b-[1px] border-[#E9E9E9] text-[14px] font-[400] text-[#444444] h-[36px] flex items-center pl-[20px] cursor-pointer hover:bg-[#3B82F6] hover:text-[white] hover:font-[600] hover:border-[#3B82F6] hover:shadow-[0px_2px_4px_rgba(0,0,0,0.15)]"
        onClick={onClick}
      >
        {title}
      </div>
    </>
  );
};

const PrimaryButton = ({ title, onClick, bgColor = '#3B82F6' }) => {
  return (
    <button
      className={`text-[14px] font-[600]  h-full rounded-[6px] text-[white] flex items-center justify-center w-[85%] rounded-r-[0px] cursor-pointer`}
      style={{
        backgroundColor: bgColor
      }}
      onClick={onClick}
    >
      {title}
    </button>
  );
};
export function FinishDropdown({ setShowExitPopup, handleFinish, handleApprove }) {
  const dispatch = useDispatch();
  const { documentId } = useParams();
  const [openDeclinePopup, setOpenDeclinePopup] = useState(false);
  const [openActionPanel, setOpenActionPanel] = useState(false);
  const { userDetails } = useAccount();
  const uploadedDocumentAllData = useSelector((state) => state.esignMain.uploadedDocumentAllData);
  console.log(openActionPanel, 'openActionPanel');

  const recipientConfig = userDetails?.email
    ? uploadedDocumentAllData?.recipient_configs?.filter?.((item) => item?.email === userDetails?.email)?.[0] || {}
    : { type: uploadedDocumentAllData?.type, status: '' };
  console.log(recipientConfig, 'recipientConfig');
  const renderPrimaryButton = () => {
    if (recipientConfig?.status === RecipientDropdownActionsConst.Decline) {
      return <PrimaryButton title={'You declined'} bgColor="#BA0517" />;
    } else if (recipientConfig?.type === RecipientDropdownActionsConst.NeedsToSign) {
      if (recipientConfig?.status === RecipientDropdownActionsConst.NeedsToSign) {
        return <PrimaryButton title={'You Signed'} bgColor="#2E844A" />;
      }
      return <PrimaryButton title={'Finish'} onClick={handleFinish} />;
    } else if (recipientConfig?.type === RecipientDropdownActionsConst.NeedsToApprove) {
      if (recipientConfig?.status === RecipientDropdownActionsConst.NeedsToApprove) {
        return <PrimaryButton title={'You Approved'} bgColor="#1A95FF" />;
      }
      return <PrimaryButton title={'Approve'} onClick={handleApprove} />;
    }
  };
  console.log(uploadedDocumentAllData, 'uploadedDocumentAllData123');
  return (
    <>
      <DeclineDocumentPopup
        open={openDeclinePopup}
        onClose={() => setOpenDeclinePopup(false)}
        isFromCanvas={true}
        documentId={documentId}
      />

      <div className="w-full  h-[40px] rounded-[6px]  flex justify-between items-center relative">
        {openActionPanel ? (
          <div className="absolute w-full h-auto bg-[white] bottom-[45px] border-[1px] border-[#E9E9E9] rounded-[6px]">
            {!recipientConfig?.status ? <DropdownItem title={'Decline'} onClick={() => setOpenDeclinePopup(true)} /> : null}

            <DropdownItem title={'Exit'} onClick={() => setShowExitPopup(true)} />
          </div>
        ) : null}
        {renderPrimaryButton()}

        <div
          className="w-[15%] flex items-center justify-center cursor-pointer border-[1px] border-[#3B82F6] h-full rounded-r-[6px]"
          onClick={() => setOpenActionPanel((state) => !state)}
        >
          <TripleDotIcon />
        </div>
      </div>
    </>
  );
}
