import React from 'react';

const UploadIcon = ({ fill = '#216DDE' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
      <g clip-path="url(#clip0_1708_55577)">
        <path
          d="M20.096 6.18587L16.611 2.70088C15.383 1.47287 13.751 0.796875 12.015 0.796875H6.5C4.019 0.796875 2 2.81588 2 5.29688V20.2969C2 22.7779 4.019 24.7969 6.5 24.7969H17.5C19.981 24.7969 22 22.7779 22 20.2969V10.7819C22 9.04587 21.324 7.41288 20.096 6.18587ZM19.389 6.89288C19.94 7.44388 20.359 8.09088 20.632 8.79688H15.5C14.673 8.79688 14 8.12388 14 7.29688V2.16488C14.706 2.43788 15.353 2.85687 15.904 3.40788L19.389 6.89288ZM21 20.2969C21 22.2269 19.43 23.7969 17.5 23.7969H6.5C4.57 23.7969 3 22.2269 3 20.2969V5.29688C3 3.36688 4.57 1.79688 6.5 1.79688H12.015C12.349 1.79688 12.678 1.82688 13 1.88488V7.29688C13 8.67488 14.122 9.79688 15.5 9.79688H20.912C20.97 10.1189 21 10.4479 21 10.7819V20.2969ZM15.913 14.9469C16.106 15.1439 16.102 15.4609 15.905 15.6539C15.808 15.7489 15.681 15.7969 15.555 15.7969C15.425 15.7969 15.296 15.7469 15.198 15.6469L12.683 13.0769C12.627 13.0209 12.566 12.9739 12.5 12.9349V20.2979C12.5 20.5739 12.276 20.7979 12 20.7979C11.724 20.7979 11.5 20.5739 11.5 20.2979V12.9359C11.434 12.9759 11.371 13.0239 11.313 13.0819L8.802 15.6479C8.609 15.8459 8.293 15.8499 8.095 15.6559C7.898 15.4629 7.894 15.1459 8.087 14.9489L10.602 12.3789C10.986 11.9949 11.488 11.8019 11.99 11.7989C11.993 11.7989 11.996 11.7989 11.999 11.7989C12.002 11.7989 12.005 11.7989 12.008 11.7989C12.51 11.8009 13.011 11.9929 13.392 12.3749L15.911 14.9489L15.913 14.9469Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_1708_55577">
          <rect width="24" height="24" fill="white" transform="translate(0 0.796875)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default UploadIcon;
