import moment from "moment";

export const initialState = {
    name: '',
    description: '',
    client_matter_id: '',
    provider_matter_id: '',
    billing_matter_name: '',
    format_engagement_name: '',
    add_service: [],
    role_in_matter: [],
    engagement_service_status: '',
    engagement_start_date: null,
    billing_type: '',
    billing_contact_name: '',
    billing_contact_email_id: '',
    billing_phone_number: '',
    billing_currency: '',
    billing_address: '',
    billing_country: '',
    billing_state: '',
    billing_city: '',
    // billing_postal: '',
    billing_pan: '',
    billing_gstin: '',
    responsible_attorney: '',
};


export const matterBillingState = {
    name: '',
    description: '',
    client_matter_id: '',
    provider_matter_id: '',
    billing_matter_name: '',
    format_engagement_name: '',
    add_service: [],
    role_in_matter: [],
    engagement_service_status: '',
    engagement_start_date: null,
    billing_type: '',
    billing_contact_name: '',
    billing_contact_email_id: '',
    billing_phone_number: '',
    billing_currency: '',
    billing_address: '',
    billing_country: '',
    billing_state: '',
    billing_city: '',
    // billing_postal: '',
    billing_pan: '',
    billing_gstin: '',
    responsible_attorney: '',
};


const clientMatterReducer = (state = matterBillingState, action) => {
    console.log("reducer :::::::", state, action)
    switch (action.type) {
        case 'UPDATE_STATE':
            return action.billingAtt;
        case 'UPDATE_FIELD':
            return { ...state, [action.name]: action.value };
        case 'RESET':
            return matterBillingState; // Assuming you have an initial state defined.
        default:
            return state;
    }
};

export default clientMatterReducer;

