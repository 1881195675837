import EsignValueRemoveSignBtn from 'app/components/Buttons/EsignValueRemoveSignBtn';
import EsignInputWithLabel from 'app/components/Input/EsignInputWithLabel';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { selectUsersNodes } from 'store/esignMainSlice';
import { setPlaceHolderValue } from 'store/mainSlice';
import { selectReplaceCanvasAction } from 'store/replaceCanvasReducer';
import { PlaceHolderTypes, replaceRectWithText } from 'utils/esign.helper';

const EsignCanvasAddText = ({ label }) => {
  const canvas = useSelector(selectReplaceCanvasAction);
  const activeObject = useSelector((state) => state.esignMain.selectedActiveObject);
  const usersNodes = useSelector(selectUsersNodes);
  const dispatch = useDispatch();
  const [initialsInputs, setInitialsInputs] = useState('');
  const handleCancel = () => {
    setInitialsInputs('');
  };
  const handleAddInitialsOnCanvas = () => {
    if (canvas !== null) {
      console.log({ canvas, usersNodes, activeObject, text: initialsInputs }, 'activeObjectdebug');
      const textbox = replaceRectWithText({ canvas, usersNodes, activeObject, text: initialsInputs });
      console.log(textbox, 'textboxNewDebug');
      dispatch(
        setPlaceHolderValue({ value: { id: activeObject?.id, addedValue: textbox.toDataURL(), actionType: PlaceHolderTypes.initials } })
      );
    }
  };

  return (
    <div className="w-full max-w-full mt-[16px] flex flex-col items-start justify-start">
      <EsignInputWithLabel
        placeholder={`Enter ${label}`}
        value={initialsInputs}
        onChange={(e) => {
          setInitialsInputs(e?.target?.value);
        }}
        label={label}
      />
      <div className="mt-[10px] w-full">
        <EsignValueRemoveSignBtn
          onRemoveOnClick={handleCancel}
          onSignOnClick={handleAddInitialsOnCanvas}
          isSignBtnDisabled={!initialsInputs}
          isRemoveBtnDisabled={!initialsInputs}
          primaryBtnLabel={`Add ${label}`}
          isShowConsent={false}
        />
      </div>
    </div>
  );
};

export default EsignCanvasAddText;
