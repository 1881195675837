import DateSignedIcon from './DateSignedIcon';
import DropdownIcon from './DropdownIcon';
import DropdownStrokeIcon from './DropdownIconStroke';
import InitialsIcon from './InitialsIcon';
import SignPenIcon from './SignPenIcon';
import UploadIcon from './UploadIcon';

export const EsignNewIcon = {
  UploadIcon,
  DropdownIcon,
  SignPenIcon,
  DropdownStrokeIcon,
  DateSignedIcon,
  InitialsIcon
};
