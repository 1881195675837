import LogoIcon from 'app/assets/icons/LogoIcon';
import HomePageSearchIcon from 'app/assets/icons/public_home/HomePageSearchIcon';
import MenuDropdownIcon from 'app/assets/icons/public_home/MenuDropdownIcon';
import HamBurgerMenuIcon from 'app/assets/icons/public_home/HamBurgerMenuIcon';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import CrossIcon from 'app/assets/icons/CrossIcon';
const Menu = [
  { title: 'Products', isChild: true },
  { title: 'Resources', isChild: true },
  { title: 'Support', isChild: true },
  { title: 'Templates', isChild: false },
  { title: 'Workflows', isChild: false },
  { title: 'Pricing', isChild: false }
];
const PublicHeader = () => {
  const navigate = useNavigate();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  return (
    <div className="flex w-full h-[68px] items-center justify-between bg-[#FFFFFF] border-[1px] border-[#E5E7EB] px-[7%]  headerSection">
      <div className="items-center  hidden sm:flex">
        <button
          onClick={() => {
            navigate('/');
          }}
        >
          <LogoIcon width={150} height={27} />
        </button>
        <ul className="flex gap-[20px] ml-[20px]">
          {Menu.map((item) => {
            return (
              <li key={item} className="flex items-center gap-[10px] text-[#6B7280] text-[16px] font-[500]">
                {item?.title}
                {item?.isChild && (
                  <span>
                    <MenuDropdownIcon />{' '}
                  </span>
                )}
              </li>
            );
          })}
        </ul>
      </div>
      {/* Mobile menu */}
      <div className="flex sm:hidden w-full items-center justify-between">
        <button onClick={() => navigate('/')}>
          <LogoIcon width={150} height={31} />
        </button>
        <div className="relative">
          <button onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}>
            {isMobileMenuOpen ? <CrossIcon width={30} height={30} stroke="black" /> : <HamBurgerMenuIcon />}
          </button>
          {isMobileMenuOpen && (
            <div
              className={`absolute  right-[-26px]  h-fit w-[100vw] top-[40px]   right- bg-[white] p-[20px] pt-[0px] overflow-y-scroll border-t-[1px]`}
            >
              <div className="h-full flex flex-col justify-between ">
                <div className="flex flex-col ">
                  {Menu.map((item) => {
                    return (
                      <li key={item} className="flex items-center gap-[10px] text-[black] text-[16px] font-[500] justify-between h-[48px]">
                        {item?.title}
                        {item?.isChild && (
                          <span>
                            <MenuDropdownIcon />{' '}
                          </span>
                        )}
                      </li>
                    );
                  })}
                </div>
                <div className="flex items-center gap-[20px] py-[10px] border-t-[1px] ">
                  <button
                    className=" bg-[#030712] rounded-[6px] h-[40px] flex items-center justify-center text-[white] font-[500] text-[14px] px-[20px]"
                    onClick={() => {
                      navigate('/login');
                    }}
                  >
                    Login
                  </button>
                  <button
                    className="border-[1px] border-[#D1D5DB] bg-[white] rounded-[6px] h-[40px] flex items-center justify-center text-[#101010] font-[500] text-[14px] px-[20px]"
                    onClick={() => {
                      navigate('/register');
                    }}
                  >
                    Signup
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* end mobile menu */}
      <div className=" items-center gap-[8px] hidden sm:flex">
        <HomePageSearchIcon />
        <button
          className="border-[1px] border-[#D1D5DB] bg-[white] rounded-[6px] h-[40px] flex items-center justify-center text-[#101010] font-[500] text-[14px] px-[20px]"
          onClick={() => {
            navigate('/register');
          }}
        >
          Signup
        </button>
        <button
          className=" bg-[#030712] rounded-[6px] h-[40px] flex items-center justify-center text-[white] font-[500] text-[14px] px-[20px]"
          onClick={() => {
            navigate('/login');
          }}
        >
          Login
        </button>
      </div>
    </div>
  );
};

export default PublicHeader;
