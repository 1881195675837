import DateIcon from 'assets/images/eSign/DateIcon';
import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import TextButton from '../Button/TextButton';
import SecondaryButton from '../Button/SecondaryButton';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateCalendar } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { replaceRectWithText } from 'utils/esign.helper';
import { useSelector } from 'react-redux';
import { selectReplaceCanvasAction } from 'store/replaceCanvasReducer';
import CustomCalender from 'app/components/Calender/CustomCalender';
// import 'react-datepicker/dist/react-datepicker.css';
const DateInput = ({ value, onChange }) => {
  const [showPicker, setShowPicker] = useState(false);
  const dateInputRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dateInputRef.current && !dateInputRef.current.contains(event.target)) {
        setShowPicker(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  return (
    <div className="mt-3" ref={dateInputRef}>
      <div
        className="flex w-full  border-1 border-[#D1D9E0] rounded-[10px] p-[10px] h-[40px] justify-between items-center mt-3 cursor-pointer"
        onClick={() => {
          setShowPicker((prevState) => !prevState);
        }}
      >
        <div>{value}</div>
        <div>
          <DateIcon />
        </div>
      </div>
      {showPicker && (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <div className="w-full shadow border-1 border-[#D1D9E0] rounded-xl mt-2">
            {/* <DateCalendar className="w-full" onChange={onChange} /> */}
            <div className="absolute right-[10px]">
              <CustomCalender handleUpdateFilter={onChange} value={value} />
            </div>
          </div>
        </LocalizationProvider>
      )}
    </div>
  );
};

export default DateInput;
