import EsignAppAssets from 'app/assets';
import BlackLogo from 'app/assets/icons/BlackLogo';
import LogoIcon from 'app/assets/icons/LogoIcon';
import BellWhyChooseEsignIcon from 'app/assets/icons/public_home/BellWhyChooseEsignIcon';
import BuildingWhyChooseEsignIcon from 'app/assets/icons/public_home/BuildingWhyChooseEsignIcon';
import ArticleImageIcon from 'app/assets/icons/public_home/explore_section/ArticleImageIcon';
import BlogImageIcon from 'app/assets/icons/public_home/explore_section/BlogImageIcon';
import FAQImageIcon from 'app/assets/icons/public_home/explore_section/FAQImageIcon';
import HomePageGuides from 'app/assets/icons/public_home/explore_section/HomePageGuides';
import FileTextWhyChooseEsignIcon from 'app/assets/icons/public_home/FileTextWhyChooseEsignIcon';
import FileWhyChooseEsignIcon from 'app/assets/icons/public_home/FileWhyChooseEsignIcon';
import FirstBannerLeftIcon from 'app/assets/icons/public_home/FirstBannerLeftIcon';
import FirstBannerRightIcon from 'app/assets/icons/public_home/FirstBannerRightIcon';
import GearWhyChooseEsignIcon from 'app/assets/icons/public_home/GearWhyChooseEsignIcon';
import HamBurgerMenuIcon from 'app/assets/icons/public_home/HamBurgerMenuIcon';
import HomeFirstBannerImage from 'app/assets/icons/public_home/HomeFirstBannerImage';
import HomePageSearchIcon from 'app/assets/icons/public_home/HomePageSearchIcon';
import BellHowToDigiesign from 'app/assets/icons/public_home/howToDigiesign/BellHowToDigiesign';
import HardDriveSecureSignatureIcon from 'app/assets/icons/public_home/howToDigiesign/HardDriveSecureSignatureIcon';
import InsertSignatureIcon from 'app/assets/icons/public_home/howToDigiesign/InsertSignatureIcon';
import PenNibIcon from 'app/assets/icons/public_home/howToDigiesign/PenNibIcon';
import SendHowToDigiesign from 'app/assets/icons/public_home/howToDigiesign/SendHowToDigiesign';
import CustomizableSolutionsIcon from 'app/assets/icons/public_home/letsGrowTogether/CustomizableSolutionsIcon';
import DigiEsignIntegrationIcon from 'app/assets/icons/public_home/letsGrowTogether/DigiEsignIntegrationIcon';
import ExpertSupportIcoon from 'app/assets/icons/public_home/letsGrowTogether/ExpertSupportIcoon';
import ImpCustSatisfactionIcon from 'app/assets/icons/public_home/letsGrowTogether/ImpCustSatisfactionIcon';
import LockWhyChooseEsignIcon from 'app/assets/icons/public_home/LockWhyChooseEsignIcon';
import MenuDropdownIcon from 'app/assets/icons/public_home/MenuDropdownIcon';
import PathWhyChooseEsignIcon from 'app/assets/icons/public_home/PathWhyChooseEsignIcon';
import ShieldWhyChooseEsignIcon from 'app/assets/icons/public_home/ShieldWhyChooseEsignIcon';
import SignWhyChooseIcon from 'app/assets/icons/public_home/SignWhyChooseIcon';
import BankingInsuIcon from 'app/assets/icons/public_home/streamline_pics/BankingInsuIcon';
import ContractsImage from 'app/assets/icons/public_home/streamline_pics/ContractsImage';
import GovRegulatoryComplIcon from 'app/assets/icons/public_home/streamline_pics/GovRegulatoryComplIcon';
import HumanResourcesIcon from 'app/assets/icons/public_home/streamline_pics/HumanResourcesIcon';
import KnowMoreIcon from 'app/assets/icons/public_home/streamline_pics/KnowMoreIcon';
import PublicHeader from 'app/components/public/home/PublicHeader';
import TopBanner from 'app/components/public/home/TopBanner';
import WhyChooseDigieSignSection from 'app/components/public/home/WhyChooseDigieSignSection';
import TestimonialsCarousel from 'app/components/public/TestimonialsCarousel';
import useCheckIsAuthenticated from 'app/hooks/useCheckIsAuthenticated';
import classNames from 'classnames';
import useLoadTailwindCss from 'hooks/useLoadTailwindCss';
import React, { useEffect, useState } from 'react';

const HowToDigiEsignConst = [
  { title: 'Upload Document', Icon: FileTextWhyChooseEsignIcon },
  { title: 'Add recipients & set signing order', Icon: PenNibIcon },
  { title: 'Insert signature fields & placeholders', Icon: InsertSignatureIcon },
  { title: 'Confirm & Send', Icon: SendHowToDigiesign },
  { title: 'Notify Signatories', Icon: BellHowToDigiesign },
  { title: 'Secure Storage', Icon: HardDriveSecureSignatureIcon }
];

const LetsGrowTogetherConst = [
  { title: 'DigieSign Integration', descr: 'Join over 100+ satisfied Partners.', Icon: DigiEsignIntegrationIcon },
  {
    title: 'Customizable Solutions',
    descr: 'Configure and Personalise DigieSign to suit your offerings.',
    Icon: CustomizableSolutionsIcon
  },
  { title: 'Improve Customer Satisfaction', descr: 'Provide secure and legally compliant e-signatures', Icon: ImpCustSatisfactionIcon },
  { title: 'Expert Support', descr: 'Get dedicated support and resources to ensure your success as a partner.', Icon: ExpertSupportIcoon }
];

const FooterCompany = ['Terms Of Use', 'Privacy Policy', 'About US'];

const FooterSupport = ['Pricing', 'Tech Support', 'Sales Desk', 'FAQs'];
const FooterSolutions = ['Contracts', 'Practice Management', 'Document Automation', 'Legal Research', 'Analytics', 'Marketplace'];
const FooterResources = ['Contract Library', 'Clause Library', 'Case Studies', 'Whitepapers', 'Blogs'];

const HowToDigiEsignLeftStep = ({ active, title, Icon, onClick }) => {
  return (
    <div
      className={classNames(
        'howToDigiEsignLeftStepCard cursor-pointer',
        { 'border-b-[4px] border-[#3B82F6] active': active },
        { 'text-[24px] font-[700] text-[#030712]': !active }
      )}
      onClick={onClick}
    >
      <div className="mb-[16px] flex gap-[10px]">
        <div className="text-[32px]">
          <Icon />
        </div>
        <div>
          <h4
            className={classNames('flex gap-[10px] items-center text-[20px] sm:text-[24px]', {
              'text-[#3B82F6] font-[700] text-[20px] sm:text-[24px] mb-[8px]': active
            })}
          >
            {title}
          </h4>
          {active && (
            <p className="text-[16px] font-[500] text-[#6B7280] ">
              Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget. At posuere vitae ac nisi orci facilisis sed
              imperdiet. Pellentesque mattis a commodo feugiat senectus tellus. Lacus ipsum tempor vulputate dui.
            </p>
          )}
        </div>
      </div>
    </div>
  );
};
const StreamlineCard = ({ title, descr, className, Icon2 = () => null }) => {
  return (
    <div
      className={classNames(
        `border-[1px] border-[#96B5FF] rounded-[10px] px-[20px] py-[20px] sm:py-[20px] h-[auto] sm:h-[250px] flex items-center justify-between gap-[24px] flex-col sm:flex-row relative ${className}`
      )}
    >
      <Icon2 />

      <div className="">
        <h4 className="text-[#0034AE] font-[700] text-[24px]">{title}</h4>
        <p className="text-[16px] font-[500] text-[#030712]">{descr}</p>
      </div>
      <button className="text-[#0034AE] text-[16px] font-[500] sm:absolute bottom-[10px] right-[24px] flex items-center gap-[10px] self-baseline sm:self-auto">
        Know More
        <KnowMoreIcon />
      </button>
    </div>
  );
};

const ExploreCardItem = ({ title, descr, bgColor, Image }) => {
  return (
    <div className="h-[100%] bg-[white] rounded-[10px] w-full shadow-md">
      <div
        className="h-[300px] bg-[#FFE9E6] rounded-tl-[10px] rounded-tr-[10px] w-[100%] flex items-center justify-center"
        style={{
          backgroundColor: bgColor
        }}
      >
        <Image />
      </div>
      <div className="p-[20px] ">
        <h4 className="text-[#030712] text-[20px] font-[700] mb-[8px]">{title}</h4>
        <p className="text-[#6B7280] font-[500] text-[16px]">{descr}</p>
        <button className="font-[600] text-[14px] text-[#030712] w-full h-[40px] border-[1px] border-[#030712] rounded-[6px] mt-[12px]">
          View Guides
        </button>
      </div>
    </div>
  );
};

const HomePublic = () => {
  const [howToDigiEsignActiveIndex, setHowToDigiEsignActiveIndex] = useState(0);
  useLoadTailwindCss();
  const { getMyUserDetailsHandler } = useCheckIsAuthenticated();
  // useEffect(() => {
  //   getMyUserDetailsHandler();
  // }, []);

  return (
    <section className="m-auto homePageLandingContainer flex flex-col justify-center items-center relative">
      <PublicHeader />
      <div className="absolute w-[40%] h-[10%] bg-[#3B82F699] top-[5%] rounded-full opacity-60 z-[-1] blur-[150px]"></div>

      <div className="w-[90%] sm:w-[80%] flex flex-col justify-center ">
        <TopBanner />
        <div className="flex items-center justify-center mb-[8%] sm:mb-[70px] shadow-lg rounded-[2px] sm:rounded-[10px]">
          <img
            src={EsignAppAssets.FirstBannerImage2}
            alt="banner1"
            className="  rounded-[2px] sm:rounded-[10px] w-full h-full object-contain"
          />
        </div>
        <WhyChooseDigieSignSection />
        <div className="mt-[8%] sm:mt-[70px]">
          <h2 className="text-[24px] leading-8 sm:text-[44px] font-[700] text-[#030712] text-center mb-[20px]">
            Streamline your Business with DigieSign
          </h2>
          <div className="w-full sm:grid grid-cols-2 gap-[16px] flex flex-col streamlineContainer">
            <StreamlineCard
              title={'Contracts'}
              descr={'Accelerate sales contracts, purchase orders, and vendor agreements with vendors & suppliers.'}
              className={'streamlineCard1'}
              Icon2={ContractsImage}
            />
            <StreamlineCard
              title={'Human Resources'}
              descr={'Simplify employee offer letters, contracts and HR onboarding forms.'}
              bgColor={'#FFDEE4'}
              className={'streamlineCard2'}
              Icon2={HumanResourcesIcon}
            />
            <StreamlineCard
              title={'Government, Regulatory & Compliance'}
              descr={'Simplify and secure regulatory compliance & stamp; application processing with secure digital signatures.'}
              bgColor={'#DFF7E8'}
              className={'streamlineCard3'}
              Icon2={GovRegulatoryComplIcon}
            />
            <StreamlineCard
              title={'Banking, insurance & Real Estate'}
              descr={
                'Facilitate applications, letter, claim submissions, transfer agreements, and mortgage documents with privacy protection and enhanced efficiency.'
              }
              bgColor={'#FEF2D5'}
              className={'streamlineCard4'}
              Icon2={BankingInsuIcon}
            />
          </div>
        </div>
        <div className="mt-[70px] howToDigiesignContainer">
          <h2 className="text-[24px] sm:text-[44px] font-[700] text-[#030712] text-center mb-[30px]">How to DigieSign</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 w-full">
            <div className="w-full sm:w-[80%] h-[auto]  flex flex-col gap-[40px] ">
              {HowToDigiEsignConst.map(({ title, Icon }, index) => {
                return (
                  <HowToDigiEsignLeftStep
                    active={howToDigiEsignActiveIndex === index}
                    title={title}
                    Icon={Icon}
                    onClick={() => setHowToDigiEsignActiveIndex(index)}
                  />
                );
              })}
            </div>

            <div className=" bg-[#FFFFFF] border-[1px] border-[#D4D4D8] rounded-[10px] max-h-[350px] sm:h-[600px] flex items-center justify-center">
              <div className="border-[3px] border-[#C9C9C9] border-dashed h-[80%] w-[80%] rounded-[10px] flex items-center justify-center p-[20px]">
                <img src={EsignAppAssets.WhyChooseDigiEsignImage} className="w-full h-full object-contain" />
              </div>
            </div>
          </div>
        </div>
        <div className="mt-[50px] testimonialsSection">
          <h2 className="text-[24px] sm:text-[44px] font-[700] text-[#030712] text-center mb-[20px]">Testimonials</h2>
          <TestimonialsCarousel />
        </div>
        <div className=" mt-[70px] mb-[10px] bg-[#FFF2D9] w-full  rounded-[10px] flex flex-col items-center justify-center sm:py-[0px] sm:px-[0px] py-[24px] px-[16px] letsGrowTogetherSection">
          <div className="grid sm:grid-cols-2 sm:w-[90%] grid-cols-1 w-full">
            <div className="w-[80%]">
              <h2 className="text-[24px] sm:text-[44px] font-[700] text-[#030712] pb-[50px]">Let’s grow together</h2>
              <ul className="flex flex-col gap-[24px] align-middle">
                {LetsGrowTogetherConst?.map(({ title, descr, Icon }) => {
                  return (
                    <li className="flex gap-[12px]">
                      <Icon />
                      <div>
                        <h4 className="text-[#030712] font-[700] text-[16px] mb-[6px]">{title}</h4>
                        <p className="text-[16px] font-[400] text-[#505050] ">{descr}</p>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div>
              <h3 className="text-[20px] text-[#030712] font-[600] mb-[30px] sm:mt-[0px] mt-[26px]">
                Want to partner with us? Enter your deatils and we shall get back to you.
              </h3>
              <div className="flex flex-col gap-[24px]">
                <label className="w-full">
                  <span className="text-[#030712] font-[600] text-[14px] ">Name</span>
                  <input className="w-full rounded-[6px] h-[36px] pl-[20px] mt-[12px]" placeholder="Enter Name" />
                </label>
                <label className="w-full">
                  <span className="text-[#030712] font-[600] text-[14px] ">Email</span>
                  <input className="w-full rounded-[6px] h-[36px] pl-[20px] mt-[12px]" placeholder="Enter Email" />
                </label>
                <label className="w-full">
                  <span className="text-[#030712] font-[600] text-[14px] ">Company</span>
                  <input className="w-full rounded-[6px] h-[36px] pl-[20px] mt-[12px]" placeholder="Enter Company Name" />
                </label>
                <label className="w-full">
                  <span className="text-[#030712] font-[600] text-[14px] ">Message</span>
                  <textarea className="w-full rounded-[6px] h-[65px] pl-[20px] mt-[12px] border-none" placeholder="Enter Message" />
                </label>

                <button className="w-full bg-[#3B82F6] rounded-[6px] text-[white] h-[40px]">Submit</button>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-[70px]  sm:block">
          <h2 className="text-[24px] sm:text-[44px] font-[700] text-[#030712] text-center mb-[20px] ">Explore</h2>
          <div className="w-full grid grid-cols-1 sm:grid-cols-4 gap-[17px]">
            <ExploreCardItem
              title={'Guides'}
              descr={'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.'}
              Image={HomePageGuides}
            />
            <ExploreCardItem
              title={'Articles'}
              descr={'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.'}
              Image={ArticleImageIcon}
              bgColor={'#FFF0D3'}
            />
            <ExploreCardItem
              title={'Blogs'}
              descr={'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.'}
              Image={BlogImageIcon}
              bgColor={'#F9D8FF'}
            />
            <ExploreCardItem
              title={'FAQs'}
              descr={'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.'}
              Image={FAQImageIcon}
              bgColor={'#DEF7E5'}
            />
          </div>
        </div>
        <div className="mt-[70px]   sm:block">
          <div
            className="w-full rounded-[10px] h-[300px] sm:pl-[5%] px-[16px] py-[20px] sm:py-[0px] flex flex-col justify-between sm:justify-center gap-[8px]"
            style={{
              backgroundImage: `url(${EsignAppAssets.FooterBannerImage})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat'
            }}
          >
            <div>
              <h4 className="uppercase font-[700] text-[white] text-[12px]">BE READY TO GET MORE</h4>
              <h2 className="font-[800] text-[white] text-[24px] sm:text-[44px] w-[80%] sm:w-[400px] sm:mt-[0px] mt-[6px]  ">
                Get your eSignatures now!
              </h2>
            </div>
            <button className="bg-[#3B82F6] border-[2px] border-[#3B82F64D] rounded-[10px] h-[48px] px-[20px] text-[14px] font-[600] text-[white] w-full sm:w-fit mt-[20px]">
              Start Free Trial
            </button>
          </div>
        </div>
      </div>
      <div className="mt-[70px]  bg-[white] w-full  flex-col items-center  sm:flex sm:px-[0px] sm:py-[0px] px-[16px] py-[24px]">
        <div className="sm:w-[80%] w-full flex flex-col justify-center items-center pt-[30px] ">
          <div className="w-full flex items-start justify-around flex-wrap ">
            <div className="w-full sm:w-[30%]">
              <BlackLogo />
              <h4 className="text-[#000000] font-[700] text-[14px] mt-[13px] mb-[12px]">Digiebooks Private Limited</h4>
              <p className="text-[#6B7280] font-[400] text-[14px] w-[300px]">
                Clarity gives you the blocks and components you need to create a truly professional website.
              </p>
            </div>
            <div className="w-full sm:w-[55%] flex justify-between sm:mt-[0px] mt-[24px] flex-wrap sm:gap-[0px] h-auto">
              <div className="flex flex-col items-start  gap-[10px] sm:w-fit w-[50%] ">
                <h3 className="font-[700] text-[14px] text-[#030712]">Company</h3>
                {FooterCompany.map((item) => {
                  return (
                    <h3 key={item} className="font-[400] text-[14px] text-[#6B7280]">
                      {item}
                    </h3>
                  );
                })}
              </div>
              <div className="flex flex-col items-start justify-start gap-[10px] sm:w-fit w-[32%]">
                <h3 className="font-[700] text-[14px] text-[#030712]">Support</h3>
                {FooterSupport.map((item) => {
                  return (
                    <h3 key={item} className="font-[400] text-[14px] text-[#6B7280]">
                      {item}
                    </h3>
                  );
                })}
              </div>
              <div className="flex flex-col items-start justify-start gap-[10px] sm:w-fit w-[50%] mt-[25px]">
                <h3 className="font-[700] text-[14px] text-[#030712]">Solutions</h3>
                {FooterSolutions.map((item) => {
                  return (
                    <h3 key={item} className="font-[400] text-[14px] text-[#6B7280]">
                      {item}
                    </h3>
                  );
                })}
              </div>
              <div className="flex flex-col items-start justify-start gap-[10px] sm:w-fit w-auto mt-[25px]">
                <h3 className="font-[700] text-[14px] text-[#030712] w-full ">Resources</h3>
                {FooterResources?.map((item) => {
                  return (
                    <h3 key={item} className="font-[400] text-[14px] text-[#6B7280] w-full ">
                      {item}
                    </h3>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="border-t-[1px] border-[#7C7C7C] mt-[32px] w-full flex items-center justify-center pt-[20px]">
            <p className="text-[14px] font-[400] text-[#71717A] pb-[20px]">© Copyright 2024, All Rights Reserved by Kronicle</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomePublic;
