/* eslint-disable spaced-comment */
/* eslint-disable vars-on-top */
/* eslint-disable no-var */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable func-names */
/* eslint-disable no-restricted-syntax */
/* eslint-disable prefer-const */
/* eslint-disable no-return-assign */
/* eslint-disable no-cond-assign */
/* eslint-disable no-unused-vars */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/no-unescaped-entities */

/* eslint-disable react/no-unknown-property */
import PropTypes from 'prop-types';
import { useState } from 'react';

// material-ui
import { useTheme, styled } from '@mui/material/styles';
import {
    AppBar,
    Avatar,
    Box,
    ButtonBase,
    Card,
    Grid,
    InputAdornment,
    Link,
    OutlinedInput,
    Popper,
    Tab,
    Tabs,
    Toolbar,
    Typography
} from '@mui/material';

// third-party
import PopupState, { bindPopper, bindToggle } from 'material-ui-popup-state';

// project imports
import Transitions from 'ui-component/extended/Transitions';

// assets
import { IconAdjustmentsHorizontal, IconSearch, IconX } from '@tabler/icons';
import { Stack, shouldForwardProp, style } from '@mui/system';
import Sidebar from '../../Sidebar';
import { SET_MENU } from 'store/actions';
import Calender from '../../../../assets/images/Dashboard/calendar_month (1).png';
import App from '../../../../assets/images/Dashboard/apps.png';
import Help from '../../../../assets/images/Dashboard/help.png';
import Settings from '../../../../assets/images/Dashboard/settings.png';
import DasboardImage from '../../../../assets/images/Dashboard/image 19.png';
import { useDispatch, useSelector } from 'react-redux';
import NotificationSection from '../NotificationSection';
import { useAccount } from 'context/AccountProvider';

// styles
const PopperStyle = styled(Popper, { shouldForwardProp })(({ theme }) => ({
    zIndex: 1100,
    width: '99%',
    top: '-55px !important',
    padding: '0 12px',
    [theme.breakpoints.down('sm')]: {
        padding: '0 10px'
    }
}));

const OutlineInputStyle = styled(OutlinedInput, { shouldForwardProp })(({ theme }) => ({
    width: 434,
    marginLeft: 16,
    paddingLeft: 16,
    paddingRight: 16,
    '& input': {
        background: 'transparent !important',
        paddingLeft: '4px !important'
    },
    [theme.breakpoints.down('lg')]: {
        width: 250
    },
    [theme.breakpoints.down('md')]: {
        width: '100%',
        marginLeft: 4,
        background: '#fff'
    }
}));

const HeaderAvatarStyle = styled(Avatar, { shouldForwardProp })(({ theme }) => ({
    ...theme.typography.commonAvatar,
    ...theme.typography.mediumAvatar,
    background: theme.palette.secondary.light,
    color: theme.palette.secondary.dark,
    '&:hover': {
        background: theme.palette.secondary.dark,
        color: theme.palette.secondary.light
    }
}));

// ==============================|| SEARCH INPUT - MOBILE||============================== //

const MobileSearch = ({ value, setValue, popupState }) => {
    const theme = useTheme();

    return (
        <></>
        // <OutlineInputStyle
        //     id="input-search-header"
        //     value={value}
        //     onChange={(e) => setValue(e.target.value)}
        //     placeholder="Search"
        //     startAdornment={
        //         <InputAdornment position="start">
        //             <IconSearch stroke={1.5} size="1rem" color={theme.palette.grey[500]} />
        //         </InputAdornment>
        //     }
        //     endAdornment={
        //         <InputAdornment position="end">
        //             <ButtonBase sx={{ borderRadius: '12px' }}>
        //                 <HeaderAvatarStyle variant="rounded">
        //                     <IconAdjustmentsHorizontal stroke={1.5} size="1.3rem" />
        //                 </HeaderAvatarStyle>
        //             </ButtonBase>
        //             <Box sx={{ ml: 2 }}>
        //                 <ButtonBase sx={{ borderRadius: '12px' }}>
        //                     <Avatar
        //                         variant="rounded"
        //                         sx={{
        //                             ...theme.typography.commonAvatar,
        //                             ...theme.typography.mediumAvatar,
        //                             background: theme.palette.orange.light,
        //                             color: theme.palette.orange.dark,
        //                             '&:hover': {
        //                                 background: theme.palette.orange.dark,
        //                                 color: theme.palette.orange.light
        //                             }
        //                         }}
        //                         {...bindToggle(popupState)}
        //                     >
        //                         <IconX stroke={1.5} size="1.3rem" />
        //                     </Avatar>
        //                 </ButtonBase>
        //             </Box>
        //         </InputAdornment>
        //     }
        //     aria-describedby="search-helper-text"
        //     inputProps={{ 'aria-label': 'weight' }}
        // />
    );
};

MobileSearch.propTypes = {
    value: PropTypes.string,
    setValue: PropTypes.func,
    popupState: PopupState
};

// ==============================|| SEARCH INPUT ||============================== //

const SearchSection = () => {
    const theme = useTheme();
    const { userDetails } = useAccount();
    const [value, setValue] = useState('');
    const [activeTab, setActiveTab] = useState('Overview');
    const leftDrawerOpened = useSelector((state) => state.customization.opened);
    const drawerOpen = useSelector((state) => state.customization.isOpen[0]);
    const dispatch = useDispatch();
    const handleLeftDrawerToggle = () => {
        dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
    };
    const handleChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    console.log(SET_MENU, drawerOpen);
    console.log(userDetails, "userDetails")
    return (
        <>


            {/* <Grid container> */}
            {/* <Grid item xs={3}>
                            <Sidebar drawerOpen={leftDrawerOpened} />   
                        </Grid> */}

            {/*                         
                <Grid item xs={7} sx={{ p: 2 }}>
                    <Grid container sx={{ background: 'white', paddingLeft: '300PX' }}> */}
            {/* <AppBar sx={{ background: 'white', paddingLeft: '300PX' }}> */}
            <Box sx={{ display: 'flex', alignItems: "center" }}>
                {/* {drawerOpen !== 'plans' && (
                        <Toolbar>
                            <Tabs
                                sx={{ background: activeTab === 'Overview' ? '#E3F5FD' : 'white' }}
                                onChange={handleChange}
                            >
                                <Tab
                                    label="Overview"
                                    value="Overview"
                                    sx={{
                                        color: activeTab === 'Overview' ? theme.palette.secondary.main : '#707070'
                                    }}
                                    component={Link}
                                    to="/dashboard/my-subscriptions"
                                />
                            </Tabs>
                        </Toolbar>
                    )}
                    {drawerOpen !== 'plans' && (
                        <Toolbar>
                            <Tabs
                                sx={{ background: activeTab === 'Users' ? '#E3F5FD' : 'white' }}
                                onChange={handleChange}
                                textColor="secondary"
                                indicatorColor="secondary"
                                aria-label="secondary tabs example"
                            >
                                <Tab
                                    label="Users"
                                    value="Users"
                                    sx={{
                                        color: activeTab === 'Users' ? theme.palette.secondary.main : '#707070'
                                    }}
                                    component={Link}
                                    to="/dashboard/user"
                                />
                            </Tabs>
                        </Toolbar>
                    )} */}

                <Box>
                    <OutlinedInput
                        sx={{
                            ...theme.typography.customInput,
                            width: '260px',
                            color: '#005287',
                            fontSize: '14px',
                            fontWeight: 500,
                            '& input::placeholder': { fontSize: '14px', fontWeight: 500 }
                        }}
                        id="input-search-header"
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                        placeholder="Search"
                        startAdornment={
                            <InputAdornment position="start">
                                <IconSearch stroke={1.5} sx={{ width: '24px', height: '24px' }} color="#005287" />
                            </InputAdornment>
                        }
                        aria-describedby="search-helper-text"
                        inputProps={{ 'aria-label': 'weight' }}
                    />
                </Box>
                {userDetails.email === "demokronicle@gmail.com" ?
                    <Typography sx={{ padding: '0 10px', cursor: 'pointer' }}>
                        <a href='https://apjslglegal.sharepoint.com/sites/KRPLCOMPLIANCE/SitePages/Compliance-Caleder.aspx' target='_blank'>
                            <img src={Calender} alt="Calender" />
                        </a>
                    </Typography>
                    :
                    <Typography sx={{ padding: '0 10px', cursor: 'pointer' }}>
                        <img src={Calender} alt="Calender" />
                    </Typography>
                }


                <Typography sx={{ padding: '0 10px', cursor: 'pointer' }}>
                    <a href='https://www.kronicle.in/faqs' target='_blank'>
                        <img src={Help} alt="Help" />
                    </a>
                </Typography>
                <Typography sx={{ padding: '0 10px', cursor: 'pointer' }}>
                    <img src={Settings} alt="Settings" />
                </Typography>

                {/* <Stack
                    alignItems={'center'}
                    direction={"row"}
                    sx={{ color: '#303030', padding: '0 15px', fontSize: '15px', fontWeight: 500 }}
                >
                    <Avatar alt="DasboardImage" src={DasboardImage} />
                    <NotificationSection />
                </Stack> */}
                <NotificationSection />
            </Box>
            {/* </AppBar> */}
            {/* </Grid>
                </Grid> */}
            {/* </Grid> */}
        </>
    );
};

export default SearchSection;
