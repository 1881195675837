import React, { useRef, useEffect, useState } from 'react';
import { fabric } from 'fabric';
import { clearCanvas, initializeCanvas, selectCanvasAction } from 'store/placeHolderCanvasReducer';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { loadPdfOnCanvas } from 'utils/esign.helper';
import CreateNewCanvasWithPdfLoad from './CreateNewCanvasWithPdfLoad';
import { AllCanvasTypes } from 'utils';
import CanvasCard from '../components/canvas/CanvasCard';

const PlaceholderCanvas = ({ uploadDocument }) => {
  const canvas = useSelector(selectCanvasAction);
  useEffect(() => {
    if (canvas !== null) {
      // window.addEventListener('keydown', function (e) {
      //   // Check if the delete key is pressed (key code 46)
      //   if (e.keyCode === 46) {
      //     // Get the active object
      //     var activeObject = canvas.getActiveObject();
      //     // Check if there is an active object
      //     if (activeObject) {
      //       // Remove the active object from the canvas
      //       canvas.remove(activeObject);
      //       // Refresh the canvas
      //       canvas.renderAll();
      //     }
      //   }
      // });
    }
  }, [canvas]);

  return (
    <CanvasCard canvasRdxAction={selectCanvasAction}>
      <CreateNewCanvasWithPdfLoad
        uploadDocument={uploadDocument}
        canvasRdxAction={selectCanvasAction}
        id={AllCanvasTypes.placeHolderCanvas}
        initializeCanvasRdx={initializeCanvas}
        clearCanvasRdx={clearCanvas}
      />
    </CanvasCard>
  );
};

export default PlaceholderCanvas;
