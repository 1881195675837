import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { BsX, BsXLg } from "react-icons/bs";
import AddRecipient from "../add-recipient";
import { uploadDocument } from "services/eSignService";
import { convertBase64, getFileExtension } from "components/CommonFunction";
import { Button } from "@mui/material";

const DocumentUpload = () => {
  const dispatch = useDispatch();
  const [file, setFile] = useState(null);
  const hiddenFileInput = useRef(null);
  const [docId, setDocId] = useState()

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  const handleFileChange = async (event) => {
    const __file = event.target.files[0];
    setFile(__file);
    const _file = await convertBase64(__file);
    pdfToImageConvertHandler(
      _file.replace("data:application/pdf;base64,", ""),
      __file.name,
      __file.type
    );
  };

  const pdfToImageConvertHandler = async (file, name, type) => {
    const payload = {
      document: file,
      document_name: name,
      document_type: getFileExtension(name),
    };
    const res = await uploadDocument(payload);
    if (res.status === 200) {
      // res.data.forEach((el) => {
      //   images.push({ url: `data:image/png;base64,${el}` });
      // });
      debugger
      setDocId(res.data.data)
      // dispatch(setDocId(res.data.data));
    }
  };

  console.log(file, "file");

  return (
    <>

      <AddRecipient docId={docId} />
    </>

  );
};

export default DocumentUpload;
