import React from 'react';

const LockWhyChooseEsignIcon = () => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_6210_14449)">
        <path
          d="M19.5 8.875H4.5C4.08579 8.875 3.75 9.21079 3.75 9.625V20.125C3.75 20.5392 4.08579 20.875 4.5 20.875H19.5C19.9142 20.875 20.25 20.5392 20.25 20.125V9.625C20.25 9.21079 19.9142 8.875 19.5 8.875Z"
          stroke="black"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12 16C12.6213 16 13.125 15.4963 13.125 14.875C13.125 14.2537 12.6213 13.75 12 13.75C11.3787 13.75 10.875 14.2537 10.875 14.875C10.875 15.4963 11.3787 16 12 16Z"
          fill="black"
        />
        <path
          d="M8.25 8.875V5.875C8.25 4.88044 8.64509 3.92661 9.34835 3.22335C10.0516 2.52009 11.0054 2.125 12 2.125C12.9946 2.125 13.9484 2.52009 14.6517 3.22335C15.3549 3.92661 15.75 4.88044 15.75 5.875V8.875"
          stroke="black"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_6210_14449">
          <rect width="24" height="24" fill="white" transform="translate(0 0.625)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LockWhyChooseEsignIcon;
