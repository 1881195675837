import DropdownStrokeIcon from 'assets/images/eSign/DropdownIconStroke';
import React, { useEffect, useState } from 'react';
import DropdownWithIcon from './DropdownWithIcon';
import { useAccount } from 'context/AccountProvider';
import { useDispatch, useSelector } from 'react-redux';
import { generateRandomUniqueId } from 'utils';
import { getRandomLightColor, isValidEmail, RecipientDropdownActionsConst } from 'utils/esign.helper';
import classNames from 'classnames';
import NeedToSignIcon from 'assets/images/eSign/NeedToSignIcon';
import DeleteReceipientIcon from 'assets/images/eSign/DeleteReceipientIcon';
import { addRecipientList, deleteSignConfigsNodes, replaceAllSignConfigsNodes, updateRecipientList } from 'store/mainSlice';
import { toast } from 'react-toastify';
import { changeIsNextButtonDisabled, getIsPlaceHolderCanvasVisitedBefore } from 'store/esignMainSlice';
import { RemoveCurrentUserError } from 'utils/toastMessages';
import { clearCanvas } from 'store/placeHolderCanvasReducer';

const addCurrentUserReceipient = (userDetails) => {
  return {
    email: userDetails?.email,
    name: `${userDetails?.first_name} ${userDetails?.last_name}`,
    color: '#ffd65b',
    id: generateRandomUniqueId(),
    isCurrentUser: true,
    errors: { email: false, name: false },
    actionType: RecipientDropdownActionsConst.NeedsToSign
  };
};

const CheckBoxWithText = ({ checked, onChange }) => {
  return (
    <div className="space-y-5 mt-[10px]">
      <div className="relative flex items-start">
        <div className="flex h-6 items-center">
          <input
            type="checkbox"
            className="h-[20px] w-[20px] rounded border-[#303030] text-indigo-600 focus:ring-indigo-600"
            checked={checked}
            onChange={onChange}
          />
        </div>
        <div className="ml-2 text-sm leading-6">
          <p id="comments-description" className="text-[#101010] text-[13px] font-medium]">
            I am the only signer
          </p>
        </div>
      </div>
    </div>
  );
};

export const InputWithLabel = ({
  title,
  value,
  onChange,
  name,
  inputProps = {},
  disabled,
  inputInfoMsg,
  isError,
  classNamForContainer
}) => {
  return (
    <div
      className={classNames(
        'rounded-xl border-1  h-[40px] w-full relative flex items-center px-[12px]',
        {
          // 'bg-[#A0A0A0]': disabled,
          'bg-white': !disabled
        },
        { 'border-[red]': isError },
        { ' border-[#D1D9E0]': !isError },
        classNamForContainer
      )}
    >
      <div className="w-full">
        <label
          className={classNames('text-[12px] absolute block bg-white top-[-8px] left-[10px]  text-[#606060] px-1 font-medium z-10', {
            'text-[red]': isError
          })}
        >
          {title}
        </label>
        <div className="w-full flex justify-between text-[14px] text-[#216DDE] relative">
          <input
            type="text"
            value={value}
            className={classNames('w-full h-[35px] focus:outline-none font-normal disabled:text-[#A0A0A0] disabled:bg-[transparent]', {
              'text-[red]': isError
            })}
            onChange={onChange}
            name={name}
            disabled={disabled}
            {...inputProps}
          />
          {inputInfoMsg && <p className="block absolute top-[36px] text-[9px] text-[#606060]">{inputInfoMsg}</p>}
        </div>
      </div>
    </div>
  );
};

const RecipientDetailsCard = ({
  handleNameChange,
  nameValue,
  handleEmailChange,
  emailValue,
  colorCode,
  isActive,
  count,
  handleDelete,
  handleSelect,
  handleActionType,
  errors,
  actionType,
  ...props
}) => {
  const recipientAction = [
    { id: RecipientDropdownActionsConst.NeedsToSign, name: 'Needs to Sign', Icon: () => <NeedToSignIcon /> },
    { id: RecipientDropdownActionsConst.NeedsToApprove, name: 'Needs to Approve', Icon: () => <NeedToSignIcon /> }
  ];

  const { userDetails } = useAccount();

  const selectedActionType = recipientAction.filter((item) => item?.id === actionType)?.[0];

  return (
    <div
      className={classNames(' w-full flex gap-1 items-center  p-[10px]  py-3', { 'rounded-xl shadow-sm': isActive })}
      onClick={handleSelect}
      {...props}
    >
      {count && (
        <div className="w-[30px] h-[30px] rounded-[10px] border-1 flex items-center justify-center border-[#606060] mr-1 text-[14px]">
          {count}
        </div>
      )}

      <div
        className={classNames('w-[6px] h-[35px] rounded-xl')}
        style={{
          backgroundColor: colorCode
        }}
      ></div>
      <div className="w-full flex items-center justify-between ">
        <div className="flex items-center w-[60%] gap-2 ">
          <InputWithLabel
            title={userDetails?.email === emailValue ? 'Your Name' : 'Name'}
            value={nameValue}
            onChange={handleNameChange}
            name="name"
            isError={errors?.['name']}
          />

          <InputWithLabel
            title={userDetails?.email === emailValue ? 'Your Email' : 'Email'}
            value={emailValue}
            onChange={handleEmailChange}
            name="email"
            disabled={errors?.['email'] ? false : userDetails?.email === emailValue}
            isError={errors?.['email']}
            // inputInfoMsg={userDetails?.email === emailValue ? 'This email address is yours and cannot be changed.' : ''}
          />
        </div>
        <div className={classNames('w-[40%] flex justify-end gap-3 items-center')}>
          <div className="w-[160px">
            <DropdownWithIcon
              list={recipientAction}
              selected={selectedActionType}
              setSelected={(value) => {
                handleActionType({ target: { name: 'actionType', value: value?.id } });
                // setSelected(value);
              }}
            />
          </div>
          {isActive ? (
            <button onClick={handleDelete}>
              <DeleteReceipientIcon />
            </button>
          ) : (
            <div className="invisible w-[25px]"></div>
          )}
        </div>
      </div>
    </div>
  );
};
const defaultRecipientList = [generateRandomUniqueId()];
const PdfAddRecepient = () => {
  const { userDetails } = useAccount();
  const dispatch = useDispatch();
  const [isSingleSigner, setIsSingleSigner] = useState(false);
  const [selectedRecepient, setSelectedRecepient] = useState(null);
  const recipientListNew = useSelector((state) => state?.main?.recipientList);
  const isPlaceHolderCanvasVisitedBefore = useSelector(getIsPlaceHolderCanvasVisitedBefore);
  const signConfigsNodes = useSelector((state) => state.main.signConfigsNodes);
  const setRecipientListNew = (value) => {
    console.log(value, 'debug190 set');
    dispatch(addRecipientList(value));
  };

  const handleCheckboxChecked = (e) => {
    setIsSingleSigner(e.target.checked);
    setRecipientListNew([addCurrentUserReceipient(userDetails)]);
    if (e.target.checked === true) {
      dispatch(changeIsNextButtonDisabled(false));
    }
  };

  const handleAddUser = (e) => {
    e.preventDefault();
    dispatch(
      updateRecipientList({
        email: '',
        name: '',
        color: getRandomLightColor(),
        id: generateRandomUniqueId(),
        actionType: RecipientDropdownActionsConst.NeedsToSign,
        errors: { email: true, name: true }
      })
    );
  };

  const handleRemoveUser = (index, isCurrentUser) => {
    if (isSingleSigner && isCurrentUser) {
      toast.error(RemoveCurrentUserError);
    } else {
      const newUsers = JSON.parse(JSON.stringify([...recipientListNew]));
      const removedUser = newUsers[index];
      const removedUserEmail = removedUser?.email;
      if (removedUserEmail) {
        const newSignConfigsNodes = JSON.parse(JSON.stringify(signConfigsNodes));
        if (newSignConfigsNodes?.[removedUserEmail]) {
          delete newSignConfigsNodes[removedUserEmail];
          dispatch(replaceAllSignConfigsNodes({ list: newSignConfigsNodes }));
          console.log(removedUser, newSignConfigsNodes, 'currentUserdebug');
        }
        newUsers.splice(index, 1);
        setRecipientListNew(newUsers);
        console.log(newUsers, 'newUsers');
        toast.success(`${removedUser?.name} removed successfully!`);
      } else {
        toast.error('Reciepient not deleted something went wrong!');
      }

      if (newUsers.length === 0) {
        dispatch(changeIsNextButtonDisabled(true));
      }
    }
  };

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const newUsers = JSON.parse(JSON.stringify([...recipientListNew]));
    console.log(newUsers, name, value, index, isValidEmail(value), 'newUsers');
    newUsers[index][name] = value;
    if (name === 'name') {
      if (value?.length) {
        newUsers[index]['errors'] = { ...(newUsers[index]['errors'] ? newUsers[index]['errors'] : {}), [name]: false };
      } else {
        newUsers[index]['errors'] = { ...(newUsers[index]['errors'] ? newUsers[index]['errors'] : {}), [name]: true };
      }
    } else if (name === 'email') {
      if (isValidEmail(value)) {
        newUsers[index]['errors'] = { ...(newUsers[index]['errors'] ? newUsers[index]['errors'] : {}), [name]: false };
      } else {
        newUsers[index]['errors'] = { ...(newUsers[index]['errors'] ? newUsers[index]['errors'] : {}), [name]: true };
      }
    }
    setRecipientListNew(newUsers);
  };

  useEffect(() => {
    if (!recipientListNew?.length) {
      setRecipientListNew([addCurrentUserReceipient(userDetails)]);
      dispatch(changeIsNextButtonDisabled(false));
    }
    // else if (recipientListNew?.length) {
    //   dispatch(changeIsNextButtonDisabled(false));
    // }
    return () => {
      if (!isPlaceHolderCanvasVisitedBefore) {
        // dispatch(clearCanvas());
        dispatch(changeIsNextButtonDisabled(true));
      }
    };
  }, [userDetails, isPlaceHolderCanvasVisitedBefore]);

  useEffect(() => {
    let isAnyArray = false;
    const updateNextButton = () => {
      const newUsers = JSON.parse(JSON.stringify([...recipientListNew]));
      if (newUsers) {
        for (let index = 0; index < newUsers.length; index++) {
          const item = newUsers[index];
          if (item?.errors?.email) {
            isAnyArray = true;
            return;
          } else if (item.errors?.name) {
            isAnyArray = true;
            return;
          }
        }
      }
    };
    console.log(isAnyArray, 'testing2New');
    if (recipientListNew?.length) {
      updateNextButton();
      if (isAnyArray === false) {
        dispatch(changeIsNextButtonDisabled(false));
      } else if (isAnyArray === true) {
        dispatch(changeIsNextButtonDisabled(true));
      }
    }
  }, [recipientListNew]);

  console.log(recipientListNew, 'recipientList');
  return (
    <>
      <div className=" bg-white px-3 py-[10px]  w-[65%] rounded-xl max-w-[900px]  mt-[20px]">
        <h3 className="text-[16px] font-bold text-[#303030]">Add Recipients</h3>
        <CheckBoxWithText checked={isSingleSigner} onChange={handleCheckboxChecked} />
        <div className="flex justify-start items-center mt-[20px]">
          <button
            className={classNames(
              'w-[180px] h-[40px] bg-[#E9F0FC] border-1 border-[#216DDE] rounded-[10px] text-[#216DDE] font-bold  disabled:opacity-[0.5]',
              {}
            )}
            onClick={handleAddUser}
            disabled={isSingleSigner}
          >
            Add Recipients
          </button>

          {/* <button className="w-[180px] h-[40px] bg-white border-1 border-[#606060] rounded-xl text-[#404040] font-medium">
            Advanced Options
          </button> */}
        </div>
        <div className="w-[100%]  h-[270px] text-[18px] flex flex-col items-center justify-start mt-4 overflow-auto">
          {/* <RecipientDetailsCard
            nameValue={recipientDetails?.currentUser?.name}
            emailValue={recipientDetails?.currentUser?.email}
            colorCode={recipientDetails?.currentUser?.color}
            handleSelect={() => setSelectedRecepient(0)}
            {...(0 === selectedRecepient ? { isActive: true } : {})}
          /> */}
          {recipientListNew &&
            recipientListNew.map((receipientItem, i) => {
              console.log(receipientItem, 'receipientItem');
              const { id, name, email, color, errors, actionType } = receipientItem;
              return (
                <RecipientDetailsCard
                  key={id}
                  handleNameChange={(e) => handleInputChange(i, e)}
                  handleEmailChange={(e) => handleInputChange(i, e)}
                  nameValue={name}
                  emailValue={email}
                  colorCode={color}
                  count={i + 1}
                  // handleSelect={() => setSelectedRecepient(id)}
                  {...(id === selectedRecepient ? { isActive: true } : {})}
                  handleDelete={() => handleRemoveUser(i, userDetails?.email === email)}
                  onMouseOver={() => {
                    setSelectedRecepient(id);
                  }}
                  onMouseOut={() => setSelectedRecepient(null)}
                  errors={errors}
                  handleActionType={(e) => handleInputChange(i, e)}
                  actionType={actionType}
                />
              );
            })}
          {/* {recipientList &&
            recipientList.map((id, i) => {
              return (
                <RecipientDetailsCard
                  key={id}
                  handleNameChange={(e) => handleChange(e, id)}
                  handleEmailChange={(e) => handleChange(e, id)}
                  nameValue={recipientDetails?.[id]?.name}
                  emailValue={recipientDetails?.[id]?.email}
                  colorCode={recipientDetails?.[id]?.color}
                  count={i + 1}
                  handleSelect={() => setSelectedRecepient(id)}
                  {...(id === selectedRecepient ? { isActive: true } : {})}
                />
              );
            })} */}
        </div>
      </div>
    </>
  );
};

export default PdfAddRecepient;
