import React, { useState } from 'react';
import OverlayContainer from '../Overlay/OverlayContainer';
import CrossIcon from 'app/assets/icons/CrossIcon';
import EsignAppLogo from '../EsignAppLogo';
import EsignAvatar from '../Avatar/EsignAvatar';
import CheckBoxWithTitle from '../Checkboxes/CheckBoxWithTitle';
import CommonDropdown from '../Dropdowns/CommonDropdown';
import EsignAppPrimaryButton from '../Buttons/EsignAppPrimaryButton';

const ReviewDocumentForRecipient = ({ userInitials, fullName, userColor, onClose }) => {
  const [isBtnDisabled, setIsBtnDisabled] = useState(true);
  return (
    <OverlayContainer>
      <div
        className="absolute w-[100vw] flex flex-col items-center justify-center top-[-65px] left-[0px] reviewDocRecipientPopup"
        style={{
          height: ' calc(100vh + 65px)'
        }}
      >
        <div className="max-w-[600px] w-full max-h-[260px] h-full bg-[white] z-30 rounded-[10px] p-[20px] flex flex-col gap-[20px]">
          <div className="flex items-center justify-between">
            <EsignAppLogo />
            <button onClick={onClose}>
              <CrossIcon />
            </button>
          </div>
          <p className="text-[16px] font-[700] text-[#101010]">Please Review & Act on these Documents</p>
          <EsignAvatar initials={userInitials} fullName={fullName} bgColor={userColor} />
          <CheckBoxWithTitle
            title={'I agree to use electronic records and signatures.'}
            onChange={(e) => {
              if (e?.target?.checked) {
                setIsBtnDisabled(false);
              } else {
                setIsBtnDisabled(true);
              }
            }}
          />
          <div className="w-full  flex items-center justify-between gap-[12px] reviewDocForRecipientConsent">
            <CommonDropdown
              items={[{ title: 'Decline' }, { title: 'Finish Later' }]}
              buttonLabel="Other Actions"
              className="otherActionsDropdown"
              onSelect={() => {}}
            />
            <EsignAppPrimaryButton title={'Continue'} onClick={onClose} disabled={isBtnDisabled} />
          </div>
        </div>
      </div>
    </OverlayContainer>
  );
};

export default ReviewDocumentForRecipient;
