import React, { useState } from 'react';
import ReplacePlaceholderWithValueCanvas from 'views/digiEsign/canvas/ReplacePlaceholderWithValueCanvas';
import DocumentThumbnail from '../../DashboardCreateDocument/PlaceFields/DocumentThumbnail';
import RecipientDropdownList from '../../DashboardCreateDocument/PlaceFields/RecipientDropdownList';
import useRecipientAddSign from 'app/hooks/useRecipientAddSign';
import RightSideSignNPlaceholderValueAdd from '../RightSideSignNPlaceholderValueAdd';
import { selectReplaceCanvasAction } from 'store/replaceCanvasReducer';
import { useSelector } from 'react-redux';
import EsignAppSecondaryButton from 'app/components/Buttons/EsignAppSecondaryButton';
import EsignAppPrimaryButton from 'app/components/Buttons/EsignAppPrimaryButton';
import { useAccount } from 'context/AccountProvider';
import { selectCombinedSignAndUnSignedNode, selectUsersNodes, setCombinedSignAndUnSignedNodeRdx } from 'store/esignMainSlice';
import { PlaceholderPayloadKeys } from 'utils/esign.helper';
import { useParams } from 'react-router';
import { createSignConfig } from 'services/eSignService';
import EsignReciepientFooterFinish from '../FinishButtons';
import DocumentSignedSuccessScreen from 'app/components/EsignSuccessScreen/DocumentSignedSuccessScree';

const AddSignOnCanvasForAuthUser = () => {
  const [isSignedSubmitted, setIsSignedSubmitted] = useState(false);
  useRecipientAddSign();
  console.log(isSignedSubmitted, 'isSignedSubmitted');
  return (
    <>
      {isSignedSubmitted ? (
        <DocumentSignedSuccessScreen
          heading={'You have successfully signed the document'}
          btnTitle={'Go to Inbox'}
          btnOnClick={() => {
            window.location.href = '/dashboard/documents/inbox';
          }}
        />
      ) : (
        <div className="h-full w-full flex flex-col items-center justify-start esignAddPlaceFields">
          <div className="w-full h-full flex">
            <div
              className="bg-[#D1D5DB]  p-[14px] overflow-auto min-h-full "
              style={{
                maxHeight: 'calc(100vh - 160px)'
              }}
            >
              <DocumentThumbnail canvasRdxFunction={selectReplaceCanvasAction} />
            </div>
            <div className="h-full w-[100%]">
              <ReplacePlaceholderWithValueCanvas isSignCanvas={true} />
            </div>
            <div
              className="h-full w-[100%] max-w-[250px] p-[10px] overflow-auto max-w-[100%] overflow-auto border-l finishBtnContainer"
              style={{}}
            >
              <RightSideSignNPlaceholderValueAdd />
              <EsignReciepientFooterFinish setIsSignedSubmitted={setIsSignedSubmitted} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AddSignOnCanvasForAuthUser;
