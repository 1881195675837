import React from 'react';

const DeclineIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 8.99975V10.9998M12 14.9998H12.01M5.07199 18.9998H18.928C20.468 18.9998 21.43 17.3328 20.66 15.9998L13.732 3.99975C12.962 2.66675 11.038 2.66675 10.268 3.99975L3.33999 15.9998C2.56999 17.3328 3.53199 18.9998 5.07199 18.9998Z"
        stroke="#EA001E"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default DeclineIcon;
