import React from 'react';

const DropdownStrokeIcon = ({ width = '20', height = '20' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 14 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.00003 6.00003C6.08403 6.00003 5.22403 5.64403 4.57603 4.99603L0.158028 0.865027C-0.0429724 0.677027 -0.0539721 0.360028 0.135028 0.158028C0.323028 -0.0429724 0.640028 -0.0539721 0.842028 0.135028L5.27203 4.27803C6.21303 5.21703 7.79903 5.20603 8.71703 4.29003L13.158 0.136027C13.36 -0.0519727 13.677 -0.041972 13.865 0.159028C14.053 0.361028 14.043 0.678028 13.842 0.866028L9.41203 5.00903C8.77603 5.64503 7.91603 6.00103 7.00003 6.00103V6.00003Z"
        fill="#606060"
      />
    </svg>
  );
};

export default DropdownStrokeIcon;
