import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './customizationReducer';
import clientMatterReducer from './clientMatterReducer';
import fabricCanvasReducer from './placeHolderCanvasReducer';
import replaceCanvasReducer from './replaceCanvasReducer';
import mainSlice from './mainSlice';
import esignMainSlice from './esignMainSlice';
import stepperSlice from './esignFlowStepperSlice';
import { AllCanvasTypes } from 'utils';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
  customization: customizationReducer,
  clientMatter: clientMatterReducer,
  [AllCanvasTypes.placeHolderCanvas]: fabricCanvasReducer,
  [AllCanvasTypes.addSignCanvas]: replaceCanvasReducer,
  main: mainSlice,
  esignMain: esignMainSlice,
  stepper: stepperSlice
});

export default reducer;
