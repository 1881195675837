import { useEffect, useState } from 'react';

function useEsignCanvasZoom(canvas) {
  const [zoomLevel, setZoomLevel] = useState(1.0);

  useEffect(() => {
    if (canvas !== null) {
      // updateCanvasSize(zoomLevel);
      canvas.setZoom(zoomLevel);

      const originalWidth = parseInt(document.querySelector('.drag-area').dataset.dynamicWidth, 10);
      const originalHeight = parseInt(document.querySelector('.drag-area').dataset.dynamicHeight, 10);
      canvas.setWidth(originalWidth * canvas.getZoom());
      canvas.setHeight(originalHeight * canvas.getZoom());
      // centerCanvasContent();
      canvas.renderAll();
      console.log(canvas?.getObjects()?.[0], 'allObjects');
    }
  }, [canvas, zoomLevel]);

  const updateCanvasSize = (zoomLevel) => {
    const containerWidth = parseInt(document.querySelector('.drag-area').dataset.dynamicWidth, 10);
    const containerHeight = parseInt(document.querySelector('.drag-area').dataset.dynamicHeight, 10);
    canvas.setWidth(containerWidth * zoomLevel);
    canvas.setHeight(containerHeight * zoomLevel);
  };

  const centerCanvasContent = () => {
    const canvasWidth = canvas.getWidth();
    const objects = canvas.getObjects();

    if (objects.length > 0) {
      const boundingBox = getBoundingBox(objects);
      const offsetX = (canvasWidth - boundingBox.width * zoomLevel) / 2 - boundingBox.left * zoomLevel;

      const viewportTransform = canvas.viewportTransform;
      if (viewportTransform) {
        viewportTransform[4] = offsetX;
        canvas.setViewportTransform(viewportTransform);
      }
    }
  };

  const getBoundingBox = (objects) => {
    let minX, maxX;

    objects.forEach((obj) => {
      const boundingRect = obj.getBoundingRect();
      if (minX === undefined || boundingRect.left < minX) {
        minX = boundingRect.left;
      }
      if (maxX === undefined || boundingRect.left + boundingRect.width > maxX) {
        maxX = boundingRect.left + boundingRect.width;
      }
    });

    return {
      left: minX,
      width: maxX - minX
    };
  };

  const zoomIn = () => {
    setZoomLevel((prevZoom) => prevZoom + 0.1);
  };

  const zoomOut = () => {
    if (zoomLevel > 0.2) {
      setZoomLevel((prevZoom) => prevZoom - 0.1);
    }
  };

  return [zoomIn, zoomOut, Math.round(zoomLevel * 100)];
}

export default useEsignCanvasZoom;
