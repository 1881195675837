import React from 'react';

const GearWhyChooseEsignIcon = () => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_6210_14409)">
        <path
          d="M12 16.375C14.0711 16.375 15.75 14.6961 15.75 12.625C15.75 10.5539 14.0711 8.875 12 8.875C9.92893 8.875 8.25 10.5539 8.25 12.625C8.25 14.6961 9.92893 16.375 12 16.375Z"
          stroke="black"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M3.88352 17.3184C3.46912 16.6047 3.15164 15.8389 2.93945 15.0413L4.51258 13.0725C4.49477 12.7715 4.49477 12.4697 4.51258 12.1688L2.94039 10.2C3.15221 9.40226 3.46904 8.63619 3.88258 7.92188L6.38664 7.64062C6.5866 7.41534 6.79979 7.20215 7.02508 7.00219L7.30633 4.49906C8.01961 4.0875 8.78443 3.77255 9.5807 3.5625L11.5495 5.13562C11.8504 5.11781 12.1522 5.11781 12.4532 5.13562L14.422 3.56344C15.2197 3.77526 15.9858 4.09209 16.7001 4.50562L16.9813 7.00969C17.2066 7.20965 17.4198 7.42284 17.6198 7.64813L20.1229 7.92938C20.5373 8.64315 20.8548 9.40895 21.067 10.2066L19.4938 12.1753C19.5116 12.4763 19.5116 12.7781 19.4938 13.0791L21.066 15.0478C20.8557 15.8453 20.5404 16.6114 20.1285 17.3259L17.6245 17.6072C17.4245 17.8325 17.2113 18.0457 16.986 18.2456L16.7048 20.7487C15.991 21.1631 15.2252 21.4806 14.4276 21.6928L12.4588 20.1197C12.1578 20.1375 11.8561 20.1375 11.5551 20.1197L9.58633 21.6919C8.78882 21.4815 8.02276 21.1663 7.3082 20.7544L7.02695 18.2503C6.80167 18.0504 6.58848 17.8372 6.38852 17.6119L3.88352 17.3184Z"
          stroke="black"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_6210_14409">
          <rect width="24" height="24" fill="white" transform="translate(0 0.625)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GearWhyChooseEsignIcon;
