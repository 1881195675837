import { Datepicker, Flowbite } from 'flowbite-react';
import React from 'react';
const customTheme = {
  root: {
    base: 'relative'
  },
  popup: {
    root: {
      base: 'absolute top-10 z-50 block ',
      inline: 'relative top-0 z-auto',
      inner: 'inline-block rounded-lg bg-white p-4 shadow-lg dark:bg-gray-700 w-full'
    },
    header: {
      base: 'w-full',
      title: 'px-2 py-3 text-center font-semibold text-gray-900 dark:text-white',
      selectors: {
        base: 'mb-2 flex justify-between',
        button: {
          base: 'rounded-lg bg-white text-sm font-semibold text-gray-900 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:bg-gray-700 dark:text-white dark:hover:bg-gray-600',
          prev: '',
          next: '',
          view: ''
        }
      }
    },
    view: {
      base: 'p-1'
    },
    footer: {
      base: 'mt-2 flex gap-[10px]',
      button: {
        base: 'w-[50%] rounded-lg px-5 py-2 text-center text-sm font-medium focus:ring-4 focus:ring-cyan-300',
        today: 'bg-[#3B82F6] text-white hover:bg-[#3B82F6] dark:bg-[#3B82F6] dark:hover:bg-[#3B82F6]',
        clear:
          'border border-gray-300 bg-white text-gray-900 hover:bg-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:hover:bg-gray-600'
      }
    }
  },
  views: {
    days: {
      header: {
        base: 'mb-1 grid grid-cols-7',
        title: 'h-6 text-center text-sm font-medium leading-6 text-gray-500 dark:text-gray-400'
      },
      items: {
        base: 'grid w-full grid-cols-7',
        item: {
          base: 'disabled:opacity-40 block flex-1 cursor-pointer rounded-lg border-0 text-center text-sm font-semibold leading-9 text-gray-900 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-600',
          selected: 'bg-[#3B82F6] text-white hover:bg-[#3B82F6]',
          disabled: 'text-gray-500 '
        }
      }
    },
    months: {
      items: {
        base: 'grid w-ful grid-cols-4',
        item: {
          base: 'block flex-1 cursor-pointer rounded-lg border-0 text-center text-sm font-semibold leading-9 text-gray-900 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-600',
          selected: 'bg-[#3B82F6] text-white hover:bg-[#3B82F6]',
          disabled: 'text-gray-500'
        }
      }
    },
    years: {
      items: {
        base: 'grid w-full grid-cols-4',
        item: {
          base: 'block flex-1 cursor-pointer rounded-lg border-0 text-center text-sm font-semibold leading-9 text-gray-900 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-600',
          selected: 'bg-[#3B82F6] text-white hover:bg-[#3B82F6]',
          disabled: 'text-gray-500'
        }
      }
    },
    decades: {
      items: {
        base: 'grid w-full grid-cols-4',
        item: {
          base: 'block flex-1 cursor-pointer rounded-lg border-0 text-center text-sm font-semibold leading-9 text-gray-900 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-600',
          selected: 'bg-[#3B82F6] text-white hover:bg-[#3B82F6]',
          disabled: 'text-gray-500'
        }
      }
    }
  }
};
const CustomCalender = ({ handleUpdateFilter, item, selected, value, ...props }) => {
  return (
    <>
      <Datepicker
        value={value}
        theme={customTheme}
        inline
        onSelectedDateChanged={(e) => {
          handleUpdateFilter(selected, e);
          // console.log(, 'calenderselect');
        }}
        fi
        {...props}
      />
    </>
  );
};

export default CustomCalender;
