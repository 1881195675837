import React from 'react';

const KnowMoreIcon = () => {
  return (
    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 1L19 8M19 8L12 15M19 8H1" stroke="#0034AE" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};

export default KnowMoreIcon;
