import React from 'react';

const EsignAppInput = ({ placeholder, onChange, value }) => {
  return (
    <input
      placeholder={placeholder}
      className="h-[36px] px-[16px] rounded-[6px] outline-none border-[1px] border-[#D1D5DB] borderesignAppInput"
      onChange={onChange}
      {...(value ? { value: value } : {})}
    />
  );
};

export default EsignAppInput;
