import React from 'react';
import SVG from 'react-inlinesvg';
import toImg from 'd3-svg-to-png';
import { formatDateTime } from 'utils/esign.helper';
import { useAccount } from 'context/AccountProvider';

const CustomSignTemplate = ({ imageUrl, width = 353, height = 140, cb }) => {
  const dateData = formatDateTime(new Date());
  const finalDate = `${dateData.withYearWithSlash} at ${dateData.time}`;
  const { userDetails } = useAccount();
  console.log(userDetails, 'userDetails');
  const svg = `<svg width="${width}" height="${height}" viewBox="0 0 353 141" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<rect width="353" height="141" fill="trasnparent"/>
<rect x="142" y="26" width="104" height="85" fill="url(#pattern0_1_6)"/>
<text fill="black" xml:space="preserve" style="white-space: pre" font-family="sans-serif" font-size="15.7773" font-weight="bold" letter-spacing="0em"><tspan x="28.1555" y="139.533">${userDetails?.username}</tspan></text>
<text fill="black" xml:space="preserve" style="white-space: pre" font-family="sans-serif" font-size="15.7773" font-weight="bold" letter-spacing="0em"><tspan x="28.3727" y="11.5332">eSigned</tspan></text>
<text fill="black" xml:space="preserve" style="white-space: pre" font-family="sans-serif" font-size="15.7773" font-weight="bold" letter-spacing="0em"><tspan x="91.311" y="11.5332">on ${finalDate}</tspan></text>
<path fill-rule="evenodd" clip-rule="evenodd" d="M-1.71054e-07 102.954L1.50285e-08 106.518C2.34497e-08 106.711 0.156177 106.867 0.348832 106.867L14.6512 106.867C14.8438 106.867 15 106.711 15 106.518L15 102.561C14.7311 102.875 14.4317 103.152 14.1018 103.391C13.2843 103.98 12.3153 104.305 11.195 104.367L11.195 101.486C11.8672 101.43 12.4274 101.207 12.8755 100.816C13.3176 100.42 13.6476 99.9146 13.8656 99.3011C14.0776 98.6814 14.1836 98.0028 14.1836 97.2653C14.1836 96.4535 14.0594 95.7315 13.8111 95.0994C13.6561 94.7104 13.4651 94.372 13.2381 94.0843C13.0542 93.8821 12.8582 93.6911 12.6512 93.5123C12.2212 93.2008 11.7267 93.0451 11.1678 93.0451C10.6167 93.0451 10.1655 93.2062 9.81428 93.5284C9.46304 93.8445 9.17236 94.2752 8.94224 94.8205C8.71212 95.3597 8.50925 95.9701 8.33363 96.6518L7.73411 98.9014C7.32837 100.426 6.73187 101.634 5.94461 102.527C5.15736 103.413 4.11575 103.856 2.81981 103.856C1.74793 103.856 0.812306 103.559 0.0129378 102.964C0.00861994 102.96 0.00430728 102.957 -1.71054e-07 102.954ZM0.00701193 98.3304C0.0673121 98.6429 0.154069 98.9324 0.267282 99.1988C0.503459 99.769 0.830474 100.212 1.24833 100.528C1.66012 100.838 2.12945 100.993 2.6563 100.993C3.09838 100.993 3.47989 100.888 3.80085 100.677C4.12181 100.46 4.39129 100.178 4.6093 99.8309C4.82126 99.4777 4.9999 99.0997 5.14524 98.6969C5.28453 98.294 5.39959 97.9067 5.49043 97.5349L5.96278 95.6757C6.10812 95.0684 6.30493 94.4456 6.55322 93.8073C6.80151 93.169 7.12852 92.5772 7.53426 92.0318C7.57592 91.9758 7.6186 91.921 7.6623 91.8672L6.77419 91.8672C3.13714 91.8672 0.169547 94.7335 0.00701193 98.3304Z" fill="black"/>
<text transform="translate(1 88) rotate(-90)" fill="black" xml:space="preserve" style="white-space: pre" font-family="Inter" font-size="10.6745" font-weight="bold" letter-spacing="0.04em"><tspan x="22.3299" y="10.3816">eSign</tspan></text>
<text transform="translate(1 88) rotate(-90)" fill="black" xml:space="preserve" style="white-space: pre" font-family="Inter" font-size="10.6745" font-weight="bold" letter-spacing="0.04em"><tspan x="0.284191" y="10.3816">Digi</tspan></text>
<path d="M5 15C5 9.47715 9.47715 5 15 5H23V10H15C12.2386 10 10 12.2386 10 15V29H5V15Z" fill="black"/>
<path d="M5 126C5 131.523 9.47715 136 15 136H23V131H15C12.2386 131 10 128.761 10 126V112H5V126Z" fill="black"/>
<defs>
<pattern id="pattern0_1_6" patternContentUnits="objectBoundingBox" width="1" height="1">
<use xlink:href="#image0_1_6" transform="scale(0.00961538 0.0117647)"/>
</pattern>
<image id="image0_1_6" width="104" height="85" xlink:href="${imageUrl}"/>
</defs>
</svg>
`;

  return (
    <>
      <SVG
        src={svg}
        width={width}
        height={height}
        id="signSvgPreview"
        style={{
          // visibility: "hidden",
          // background: "transparent",
          fontFamily: 'Inter'
        }}
        onLoad={(src, isCached) => {
          toImg('#signSvgPreview', 'name', {
            scale: 10,
            format: 'png',
            quality: 10,
            download: false
          }).then((fileData) => {
            console.log(fileData, 'fileData');
            cb(fileData);
            //do something with the data
          });
        }}
      />
    </>
  );
};

export default CustomSignTemplate;
