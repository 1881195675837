import React from 'react';

const DateSignedIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.5 2H18V0.5C18 0.224 17.776 0 17.5 0C17.224 0 17 0.224 17 0.5V2H7V0.5C7 0.224 6.776 0 6.5 0C6.224 0 6 0.224 6 0.5V2H4.5C2.019 2 0 4.019 0 6.5V19.5C0 21.981 2.019 24 4.5 24H19.5C21.981 24 24 21.981 24 19.5V6.5C24 4.019 21.981 2 19.5 2ZM4.5 3H19.5C21.43 3 23 4.57 23 6.5V8H1V6.5C1 4.57 2.57 3 4.5 3ZM19.5 23H4.5C2.57 23 1 21.43 1 19.5V9H23V19.5C23 21.43 21.43 23 19.5 23ZM19 13.5C19 13.776 18.776 14 18.5 14H5.5C5.224 14 5 13.776 5 13.5C5 13.224 5.224 13 5.5 13H18.5C18.776 13 19 13.224 19 13.5ZM12 18.5C12 18.776 11.776 19 11.5 19H5.5C5.224 19 5 18.776 5 18.5C5 18.224 5.224 18 5.5 18H11.5C11.776 18 12 18.224 12 18.5Z"
        fill="#303030"
      />
    </svg>
  );
};

export default DateSignedIcon;
