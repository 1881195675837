export function isTouching(rect1, rect2, buffer = 3) {
  return !(
    (
      rect1.left + rect1.width + buffer < rect2.left || // rect1 is too far left
      rect2.left + rect2.width + buffer < rect1.left || // rect2 is too far left
      rect1.top + rect1.height + buffer < rect2.top || // rect1 is too high
      rect2.top + rect2.height + buffer < rect1.top
    ) // rect2 is too high
  );
}

// Function to constrain movement to avoid touching other rectangles
export function constrainMovement(rect1, canvas) {
  const buffer = 3; // Buffer space before touching (2-3 pixels)
  const objects = canvas.getObjects('group'); // Get all other rectangles
  console.log(objects, 'vkdebug890');
  for (let obj of objects) {
    if (obj !== rect1 && isTouching(rect1, obj, buffer)) {
      // Check horizontal direction
      if (rect1.left < obj.left && rect1.left + rect1.width + buffer >= obj.left) {
        // Moving right, stop before hitting the left edge of obj
        rect1.set({ left: obj.left - rect1.width - buffer });
      } else if (rect1.left > obj.left && rect1.left <= obj.left + obj.width + buffer) {
        // Moving left, stop before hitting the right edge of obj
        rect1.set({ left: obj.left + obj.width + buffer });
      }

      // Check vertical direction
      if (rect1.top < obj.top && rect1.top + rect1.height + buffer >= obj.top) {
        // Moving down, stop before hitting the top edge of obj
        rect1.set({ top: obj.top - rect1.height - buffer });
      } else if (rect1.top > obj.top && rect1.top <= obj.top + obj.height + buffer) {
        // Moving up, stop before hitting the bottom edge of obj
        rect1.set({ top: obj.top + obj.height + buffer });
      }
    }
  }

  // Keep the rectangle within canvas bounds
  if (rect1.left < 0) rect1.set({ left: 0 });
  if (rect1.top < 0) rect1.set({ top: 0 });
  if (rect1.left + rect1.width > canvas.width) rect1.set({ left: canvas.width - rect1.width });
  if (rect1.top + rect1.height > canvas.height) rect1.set({ top: canvas.height - rect1.height });
}

export const restrictPlaceholderGoOutsideCanvasOnMoveEvent = (canvas, options) => {
  const { e } = options;
  const obj = options.transform?.target; // Get the object being moved

  // Ensure the object exists
  if (!obj) return;

  // Get mouse pointer position relative to the canvas
  const mouse = canvas.getPointer(e);

  // Calculate new left and top positions based on mouse movement
  obj.set({
    left: mouse.x - (obj.originalMouseX || 0),
    top: mouse.y - (obj.originalMouseY || 0)
  });

  // Restrict the object within the canvas boundaries
  const objWidth = obj.width * obj.scaleX;
  const objHeight = obj.height * obj.scaleY;

  // Restrict left and top positions within canvas
  if (obj.left < 0) obj.left = 0;
  if (obj.top < 0) obj.top = 0;

  // Restrict right and bottom positions within canvas
  if (obj.left + objWidth > canvas.width) {
    obj.left = canvas.width - objWidth;
  }
  if (obj.top + objHeight > canvas.height) {
    obj.top = canvas.height - objHeight;
  }

  // Handle page boundaries
  const objTop = obj.top;
  const objBottom = objTop + objHeight;

  // Check if the object overlaps any page boundaries
  const overlappingBoundary = pageBoundaries.find((boundary) => objTop < boundary.bottom && objBottom > boundary.top);

  // If the object is between pages or inside the padding, reset it to the last valid position
  if (overlappingBoundary || isInPaddingArea(obj, pageBoundaries, paddingBottom)) {
    const boundary = overlappingBoundary || pageBoundaries.find((b) => objTop >= b.top && objTop <= b.bottom);

    if (boundary) {
      // Restrict movement within the current page's boundary
      obj.set({
        top: Math.min(Math.max(objTop, boundary.top), boundary.bottom - objHeight)
      });
    }
  }

  // Ensure the changes are rendered
  obj.setCoords();
  canvas.renderAll();
};

export function constrainAndRestrictMovement(rect1, canvas, options, pageBoundaries, paddingBottom) {
  const buffer = 3; // Buffer space before touching (2-3 pixels)
  const objects = canvas.getObjects('group'); // Get all rectangles on the canvas

  const { e } = options;
  const obj = options.transform?.target; // Get the object being moved

  // Ensure the object exists
  if (!obj) return;

  // Get mouse pointer position relative to the canvas
  const mouse = canvas.getPointer(e);

  // Calculate new left and top positions based on mouse movement
  obj.set({
    left: mouse.x - (obj.originalMouseX || 0),
    top: mouse.y - (obj.originalMouseY || 0)
  });

  // Prevent overlap with other rectangles
  objects.forEach((otherObj) => {
    if (otherObj !== rect1 && isTouching(rect1, otherObj, buffer)) {
      // Check horizontal direction
      if (rect1.left < otherObj.left && rect1.left + rect1.width + buffer >= otherObj.left) {
        rect1.set({ left: otherObj.left - rect1.width - buffer }); // Stop before hitting the left edge
      } else if (rect1.left > otherObj.left && rect1.left <= otherObj.left + otherObj.width + buffer) {
        rect1.set({ left: otherObj.left + otherObj.width + buffer }); // Stop before hitting the right edge
      }

      // Check vertical direction
      if (rect1.top < otherObj.top && rect1.top + rect1.height + buffer >= otherObj.top) {
        rect1.set({ top: otherObj.top - rect1.height - buffer }); // Stop before hitting the top edge
      } else if (rect1.top > otherObj.top && rect1.top <= otherObj.top + otherObj.height + buffer) {
        rect1.set({ top: otherObj.top + otherObj.height + buffer }); // Stop before hitting the bottom edge
      }
    }
  });

  // Canvas Boundary Restriction
  const objWidth = obj.width * obj.scaleX;
  const objHeight = obj.height * obj.scaleY;

  // Restrict left and top positions within canvas
  if (obj.left < 0) obj.left = 0;
  if (obj.top < 0) obj.top = 0;

  // Restrict right and bottom positions within canvas
  if (obj.left + objWidth > canvas.width) {
    obj.left = canvas.width - objWidth;
  }
  if (obj.top + objHeight > canvas.height) {
    obj.top = canvas.height - objHeight;
  }

  // Handle page boundaries
  const objTop = obj.top;
  const objBottom = objTop + objHeight;

  // Check if the object overlaps any page boundaries
  const overlappingBoundary = pageBoundaries.find((boundary) => objTop < boundary.bottom && objBottom > boundary.top);

  if (overlappingBoundary || isInPaddingArea(obj, pageBoundaries, paddingBottom)) {
    const boundary = overlappingBoundary || pageBoundaries.find((b) => objTop >= b.top && objTop <= b.bottom);

    if (boundary) {
      obj.set({
        top: Math.min(Math.max(objTop, boundary.top), boundary.bottom - objHeight)
      });
    }
  }

  // Ensure the changes are rendered
  obj.setCoords();
  canvas.renderAll();
}

export const adjustPosition = (item, canvas) => {
  const objects = canvas.getObjects('rect'); // Get all existing rectangles
  let adjustmentMade = false; // Flag to track adjustments

  objects.forEach((otherObj) => {
    if (item !== otherObj && isTouching(item, otherObj, 0)) {
      adjustmentMade = true; // Set adjustment flag

      // Calculate the overlap distance
      const overlapX = item.left + item.width - otherObj.left;
      const overlapY = item.top + item.height - otherObj.top;

      if (Math.abs(overlapX) < Math.abs(overlapY)) {
        // Move horizontally
        if (overlapX > 0) {
          // Move item to the right
          item.left = otherObj.left + otherObj.width + 3; // Buffer space
        } else {
          // Move item to the left
          item.left = otherObj.left - item.width - 3; // Buffer space
        }
      } else {
        // Move vertically
        if (overlapY > 0) {
          // Move item down
          item.top = otherObj.top + otherObj.height + 3; // Buffer space
        } else {
          // Move item up
          item.top = otherObj.top - item.height - 3; // Buffer space
        }
      }
    }
  });

  // Keep the item within the canvas bounds
  if (item.left < 0) item.left = 0;
  if (item.top < 0) item.top = 0;
  if (item.left + item.width > canvas.width) item.left = canvas.width - item.width;
  if (item.top + item.height > canvas.height) item.top = canvas.height - item.height;

  return adjustmentMade; // Return whether any adjustment was made
};
