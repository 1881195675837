import React from 'react';

const EsignAppSecondaryButton = ({ title, onClick, ...props }) => {
  return (
    <button
      className="bg-[white] text-[14px] text-[#101010] font-[600] border border-[#D1D5DB] primaryButton  rounded-[6px] width-full py-[10px] px-[20px] secondyAppButton"
      onClick={onClick}
      {...props}
    >
      {title}
    </button>
  );
};

export default EsignAppSecondaryButton;
