import classNames from 'classnames';
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectUploadedDocsImages, setEsignCurrentDocSelectedPage, setIsProgrammaticScroll } from 'store/esignMainSlice';
import { selectReplaceCanvasAction } from 'store/replaceCanvasReducer';

const DocumentThumbnailItem = ({ imgUrl, isActive, index, onClick }) => {
  return (
    <div
      className={classNames('w-[70px] h-[90px] rounded-[4px] p-[4px]  relative cursor-pointer ', {
        'border-[3px] border-[#3B82F6] bg-[black]': isActive
      })}
      onClick={onClick}
    >
      <div className="absolute w-[20px] h-[20px] bg-[#000000BF] flex items-center justify-center rounded-full text-white text-[12px] font-[600] top-[5px] left-[5px] ">
        {index + 1}
      </div>
      <img src={imgUrl} className="w-full h-full object-cover" alt="img" />
    </div>
  );
};

const DocumentThumbnail = ({ canvasRdxFunction }) => {
  const canvas = useSelector(canvasRdxFunction);
  const uploadDocument = useSelector(selectUploadedDocsImages);
  const dispatch = useDispatch();
  const currentPageNo = useSelector((state) => state.esignMain.esignCurrentDocSelectedPage);
  const [totalPagesWithCoords, setTotalPagesWithCoords] = useState(null);
  const [pageFromTopCoords, setPageFromTopCoords] = useState({});
  const isProgrammaticScrollRef = useRef(false); // Using ref to track programmatic scroll state

  const onPageClick = (pageNo) => {
    isProgrammaticScrollRef.current = true; // Set the flag for programmatic scroll
    const container = document.getElementById('esign_document_canvas_container');
    const top = totalPagesWithCoords[pageNo]?.top;

    if (top !== undefined) {
      container.scrollTo({
        top: top,
        behavior: 'smooth'
      });

      dispatch(setEsignCurrentDocSelectedPage(pageNo));

      // Wait until the scroll is done to re-enable scroll tracking
      const checkScrollEnd = setInterval(() => {
        if (Math.abs(container.scrollTop - top) <= 1) {
          // Allow some margin
          clearInterval(checkScrollEnd);
          isProgrammaticScrollRef.current = false; // Reset the flag
        }
      }, 50);
    }
  };

  const handleScroll = (e, container) => {
    if (isProgrammaticScrollRef.current) return; // Ignore scroll during programmatic scroll

    const scrollPosition = container?.scrollTop;
    const pageKeys = Object.keys(pageFromTopCoords).map(Number); // Convert keys to numbers

    let currentPage = 0;

    // Determine the current page based on scroll position
    for (let i = 0; i < pageKeys.length; i++) {
      const topValue = pageKeys[i];
      const nextTopValue = pageKeys[i + 1] || Infinity;

      if (scrollPosition >= topValue && scrollPosition < nextTopValue) {
        currentPage = pageFromTopCoords[topValue].page;
        break;
      }
    }

    if (currentPage !== currentPageNo) {
      dispatch(setEsignCurrentDocSelectedPage(currentPage));
    }
  };

  useEffect(() => {
    const container = document.getElementById('esign_document_canvas_container');
    if (container && totalPagesWithCoords !== null) {
      const scrollListener = (e) => handleScroll(e, container);
      container.addEventListener('scroll', scrollListener);

      return () => {
        container.removeEventListener('scroll', scrollListener);
      };
    }
  }, [pageFromTopCoords, currentPageNo]);

  useEffect(() => {
    if (canvas !== null) {
      const onAfterRender = () => {
        const placeholders = canvas?.getObjects()?.filter((item) => item?.id === undefined);

        if (placeholders?.length) {
          let withTop = {};
          const sortedData = placeholders.sort((a, b) => a.top - b.top);

          const placeholderList = sortedData.map((item, index) => {
            withTop = { ...withTop, [item?.top]: { page: index, top: item?.top } };
            return { top: item?.top, count: index + 1 };
          });

          setTotalPagesWithCoords(placeholderList);
          setPageFromTopCoords(withTop);
        }
      };

      canvas.on('after:render', onAfterRender);

      return () => {
        canvas.off('after:render', onAfterRender);
      };
    }
  }, [canvas]);

  return (
    <div className="flex flex-col gap-[10px] items-center">
      {uploadDocument?.map?.((img, i) => (
        <DocumentThumbnailItem key={i} index={i} isActive={currentPageNo === i} imgUrl={img.url} onClick={() => onPageClick(i)} />
      ))}
    </div>
  );
};

export default DocumentThumbnail;
