import React from 'react';
import EsignAppPrimaryButton from '../Buttons/EsignAppPrimaryButton';
import EsignAppSecondaryButton from '../Buttons/EsignAppSecondaryButton';
import OverlayContainer from '../Overlay/OverlayContainer';

const PrimaryPopupForConfirmation = ({ title, description, onSubmit, onCancel, primaryBtnTitle = 'Send' }) => {
  return (
    <>
      <OverlayContainer>
        <div className="absolute top-[30%] left-[40%] z-30 bg-[white] p-[20px] rounded-[10px] esignConfirmSendingDocPopup">
          <h4 className="text-[16px] font-[700] text-[#101010]">{title}</h4>
          <p className="text-[14px] font-[500] text-[#6B7280] mt-[10px]">{description}</p>
          <div className="flex justify-end items-center gap-[10px] mt-[30px] primaryPopupBtnContainer">
            <EsignAppSecondaryButton title={'Cancel'} onClick={onCancel} />
            <EsignAppPrimaryButton title={primaryBtnTitle} onClick={onSubmit} />
          </div>
        </div>
      </OverlayContainer>
    </>
  );
};

export default PrimaryPopupForConfirmation;
