import React from 'react';

const UserStatusArrowIcon = () => {
  return (
    <svg width="10" height="20" viewBox="0 0 10 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 15L5 19M5 19L1 15M5 19V1" stroke="#D9D9D9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};

export default UserStatusArrowIcon;
