import React from 'react';

import MenuIcon from '../../../assets/images/MainLayoutNewUI/Header/menu_icon.svg';
import IndiaLawOnlineLogo from '../../../assets/images/MainLayoutNewUI/indiaLawOnlineLogo.svg';
import SearchIcon from '../../../assets/images/MainLayoutNewUI/Header/search_icon.svg';
import CalendarIcon from '../../../assets/images/MainLayoutNewUI/Header/calendar_icon.svg';
import QuestionIcon from '../../../assets/images/MainLayoutNewUI/Header/question_icon.svg';
import GearIcon from '../../../assets/images/MainLayoutNewUI/Header/gear_icon.svg';
import UserSvg from '../../../assets/images/MainLayoutNewUI/Header/user_img.svg';
import HeaderUserMenu from '../Dropdown';
const RightSideAction = [
  { name: 'Search', src: SearchIcon },
  { name: 'Calendar', src: CalendarIcon },
  { name: 'Question', src: QuestionIcon },
  { name: 'Setting', src: GearIcon }
];

const AppHeader = () => {
  return (
    <div className="h-14 bg-white rounded-lg w-full flex items-center px-4 justify-between border border-[#D1D9E0]">
      {/* Logo Section */}
      <section className="w-40 flex items-center">
        <button className="flex-none w-10">
          <img src={MenuIcon} alt="menu icon" />
        </button>
        <button className="flex-none w-[130px] relative top-[5px]">
          <img src={IndiaLawOnlineLogo} alt="menu icon" />
        </button>
      </section>

      {/* Menus list Section*/}
      <section className="bg-black"></section>
      {/* Right side section */}

      <section className="flex items-center gap-2">
        {RightSideAction.map((item) => {
          return (
            <button key={item.name} className="flex-none w-10">
              <img src={item.src} alt={item.name} />
            </button>
          );
        })}

        <HeaderUserMenu />
      </section>
    </div>
  );
};

export default AppHeader;
