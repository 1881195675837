import React from 'react';

const ArticleImageIcon = () => {
  return (
    <svg width="181" height="183" viewBox="0 0 181 183" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M152.559 156.383H168.047V151.454H152.559V156.383Z" fill="#EBEBEB" />
      <path d="M143.193 48.5938H127.705V53.523H143.193V48.5938Z" fill="#EBEBEB" />
      <path
        d="M161.195 162.595C163.768 162.555 170.362 162.445 172.846 162.414H176.683L176.481 162.612V157.683L176.701 157.903C174.128 157.903 167.569 157.873 165.085 157.837L161.213 157.789L161.318 157.683L161.213 162.612L161.195 162.595ZM161.195 162.595L161.094 157.666V157.564H161.199C163.133 157.538 167.014 157.494 168.944 157.476L176.688 157.445H176.908V157.666V162.595V162.797H176.71H172.802C170.336 162.771 163.772 162.656 161.221 162.612L161.195 162.595Z"
        fill="#EBEBEB"
      />
      <path
        d="M91.161 102.696C88.584 102.74 82.0778 102.851 79.5801 102.877H75.6772H75.4746V102.674V97.7451V97.5469H75.6993L83.4389 97.5777C85.3683 97.5777 89.258 97.635 91.183 97.6614H91.2931V97.7627L91.1874 102.692L91.161 102.696ZM91.161 102.696L91.0597 97.7671L91.161 97.8729C89.2316 97.8993 85.3507 97.9433 83.4169 97.9565L75.6772 97.9874L75.8931 97.7671V102.696L75.6949 102.498H79.5317C81.9853 102.525 88.6413 102.635 91.1786 102.679L91.161 102.696Z"
        fill="#EBEBEB"
      />
      <path
        d="M82.5229 108.915C79.9415 108.959 73.4352 109.069 70.942 109.096L67.0347 109.118H66.8365V108.915L66.8145 103.986V103.766H67.0347L74.7788 103.796C76.7082 103.796 80.5934 103.854 82.5229 103.88H82.6286V103.981L82.5229 108.911V108.915ZM82.5229 108.915L82.4171 103.986L82.5229 104.092C80.5934 104.118 76.7126 104.158 74.7788 104.175L67.0347 104.206L67.255 103.986V108.915L67.0523 108.717H70.8891C73.3471 108.743 80.0032 108.853 82.5405 108.898L82.5229 108.915Z"
        fill="#EBEBEB"
      />
      <path d="M73.9178 97.7656H58.4297V102.695H73.9178V97.7656Z" fill="#F8E0B2" />
      <path d="M3.26576 162.602H18.7539V157.672H3.26576V162.602Z" fill="#EBEBEB" />
      <path d="M11.9044 10.3828L27.3926 10.3828V5.45356L11.9044 5.45356V10.3828Z" fill="#F8E0B2" />
      <path
        d="M3.26588 16.589C5.83843 16.5449 12.4328 16.4348 14.9172 16.4084H18.754L18.5514 16.6066V11.6774L18.7716 11.8976C16.1991 11.8976 9.63998 11.8668 7.15554 11.8315L3.2835 11.7831L3.3716 11.6421L3.26588 16.5714V16.589ZM3.26588 16.589L3.16016 11.6421V11.5408H3.26588C5.19969 11.5144 9.07614 11.4703 11.01 11.4527L18.754 11.4219H18.9743V11.6421V16.5714V16.774H18.7761H14.8688C12.4019 16.7432 5.83402 16.633 3.2879 16.589H3.26588Z"
        fill="#EBEBEB"
      />
      <path
        d="M152.559 10.3761C155.131 10.332 161.726 10.2263 164.21 10.1955H168.047L167.844 10.3937V5.46444L168.065 5.68469L160.321 5.65385C158.391 5.65385 154.506 5.59659 152.576 5.57016L152.682 5.46444L152.576 10.3937L152.559 10.3761ZM152.559 10.3761L152.453 5.44681V5.3455H152.559C154.493 5.31907 158.374 5.27502 160.303 5.2574L168.047 5.22656H168.267V5.44681L168.245 10.3761V10.5787H168.047H164.14C161.673 10.5479 155.109 10.4377 152.559 10.3937V10.3761Z"
        fill="#EBEBEB"
      />
      <path d="M154.421 53.8396H79.3418V3.64844H154.421V53.8396ZM79.7823 53.3991H153.981V4.08894H79.7823V53.3991Z" fill="#EBEBEB" />
      <path
        d="M83.0066 5.99645C83.0066 6.14717 82.9619 6.29451 82.8781 6.41983C82.7944 6.54516 82.6754 6.64283 82.5361 6.70051C82.3969 6.75819 82.2437 6.77328 82.0958 6.74388C81.948 6.71448 81.8122 6.64189 81.7056 6.53532C81.5991 6.42874 81.5265 6.29295 81.4971 6.14512C81.4677 5.99729 81.4828 5.84407 81.5404 5.70482C81.5981 5.56557 81.6958 5.44655 81.8211 5.36281C81.9464 5.27907 82.0938 5.23438 82.2445 5.23438C82.4463 5.23553 82.6394 5.31619 82.7821 5.45886C82.9248 5.60153 83.0054 5.79469 83.0066 5.99645Z"
        fill="#A6A6A6"
      />
      <path
        d="M86.1159 5.99645C86.1159 6.14717 86.0712 6.29451 85.9875 6.41983C85.9038 6.54516 85.7848 6.64283 85.6455 6.70051C85.5063 6.75819 85.353 6.77328 85.2052 6.74388C85.0574 6.71448 84.9216 6.64189 84.815 6.53532C84.7084 6.42874 84.6358 6.29295 84.6064 6.14512C84.577 5.99729 84.5921 5.84407 84.6498 5.70482C84.7075 5.56557 84.8052 5.44655 84.9305 5.36281C85.0558 5.27907 85.2031 5.23438 85.3539 5.23438C85.5556 5.23553 85.7488 5.31619 85.8915 5.45886C86.0341 5.60153 86.1148 5.79469 86.1159 5.99645Z"
        fill="#EBEBEB"
      />
      <path
        d="M89.2253 5.99645C89.2253 6.14717 89.1806 6.29451 89.0969 6.41983C89.0131 6.54516 88.8941 6.64283 88.7549 6.70051C88.6156 6.75819 88.4624 6.77328 88.3146 6.74388C88.1667 6.71448 88.031 6.64189 87.9244 6.53532C87.8178 6.42874 87.7452 6.29295 87.7158 6.14512C87.6864 5.99729 87.7015 5.84407 87.7592 5.70482C87.8169 5.56557 87.9145 5.44655 88.0399 5.36281C88.1652 5.27907 88.3125 5.23438 88.4632 5.23438C88.6654 5.23438 88.8592 5.31467 89.0021 5.45758C89.145 5.6005 89.2253 5.79433 89.2253 5.99645Z"
        fill="#FFC727"
      />
      <path d="M154.202 8.0625H79.5625V8.503H154.202V8.0625Z" fill="#EBEBEB" />
      <path d="M33.5296 18.6172H3.75586V54.7342H33.5296V18.6172Z" fill="#F8E0B2" />
      <path d="M30.1305 21.9219H7.1582V51.4445H30.1305V21.9219Z" fill="white" />
      <path d="M8.05243 21.9219H7.1582V51.4445H8.05243V21.9219Z" fill="#C7C7C7" />
      <path d="M34.4274 18.6172H33.5332V54.7342H34.4274V18.6172Z" fill="#C7C7C7" />
      <path d="M19.156 44.875H24.5742L24.5742 28.7393H19.156V44.875Z" fill="#EBEBEB" />
      <path d="M27.1581 34.1118H12.2734V25.7422H27.1581V34.1118ZM12.7139 33.6713H26.7176V26.1827H12.7139V33.6713Z" fill="#EBEBEB" />
      <path d="M51.1487 107.633H21.375V143.75H51.1487V107.633Z" fill="#F8E0B2" />
      <path d="M47.7516 110.922H24.7793V140.445H47.7516V110.922Z" fill="white" />
      <path d="M25.6735 110.922H24.7793V140.445H25.6735V110.922Z" fill="#C7C7C7" />
      <path d="M52.0466 107.633H51.1523V143.75H52.0466V107.633Z" fill="#C7C7C7" />
      <path d="M37.8948 117.75H32.4766V133.886H37.8948V117.75Z" fill="#EBEBEB" />
      <path d="M44.7792 123.121H29.8945V114.773H44.7792V123.121ZM30.335 122.681H44.3387V115.214H30.335V122.681Z" fill="#EBEBEB" />
      <path d="M42.3382 63.2891H12.5645V99.4061H42.3382V63.2891Z" fill="#F8E0B2" />
      <path d="M38.9411 66.5781H15.9688V96.1008H38.9411V66.5781Z" fill="white" />
      <path d="M16.863 66.5781H15.9688V96.1008H16.863V66.5781Z" fill="#C7C7C7" />
      <path d="M43.236 63.2891H42.3418V99.4061H43.236V63.2891Z" fill="#C7C7C7" />
      <path d="M29.867 74.2109H25.2285V85.9416H29.867V74.2109Z" fill="#EBEBEB" />
      <path d="M34.9771 89.6532H22.0703V81.125H34.9771V89.6532ZM22.5108 89.2127H34.5366V81.5655H22.5108V89.2127Z" fill="#DBDBDB" />
      <path d="M0 181.603C57.6445 181.33 122.302 181.33 179.946 181.603C122.302 181.872 57.6445 181.872 0 181.603Z" fill="#263238" />
      <path
        d="M156.87 139.936C163.751 146.672 164.905 157.094 163.456 166.226C163.416 166.433 163.099 166.375 163.099 166.182C163.099 164.102 163.16 162.041 163.156 159.988C163.116 159.896 163.086 159.807 163.046 159.715C161.253 158.393 159.601 156.931 158.579 154.909C157.429 152.649 156.976 150.099 156.099 147.742C156.015 147.513 156.258 147.579 156.416 147.698C160.601 150.781 161.936 154.107 163.134 158.548C163.11 156.225 162.867 153.909 162.407 151.632C161.504 147.42 159.711 143.262 156.681 140.117C156.575 139.998 156.751 139.839 156.87 139.936Z"
        fill="#263238"
      />
      <path
        d="M155.576 140.705C154.391 140.744 153.651 142.639 152.73 143.189C152.634 143.272 152.521 143.333 152.399 143.368C152.276 143.403 152.148 143.411 152.023 143.392C151.897 143.372 151.777 143.326 151.671 143.256C151.565 143.186 151.476 143.094 151.409 142.987C150.492 141.709 151.748 140.132 152.928 139.256C152.082 139.599 151.386 140.211 150.475 140.383C150.358 140.422 150.235 140.435 150.113 140.421C149.991 140.408 149.874 140.368 149.769 140.305C149.664 140.242 149.574 140.156 149.505 140.055C149.436 139.954 149.389 139.839 149.369 139.718C148.968 137.978 151.131 137.207 152.646 137.168C151.92 136.665 149.84 135.295 150.946 134.617C152.052 133.939 154.505 135.903 155.303 136.551C156.474 137.498 157.866 138.916 157.919 140.401C158.003 142.136 156.492 141.07 155.576 140.705Z"
        fill="#FFC727"
      />
      <path
        opacity="0.1"
        d="M155.576 140.705C154.391 140.744 153.651 142.639 152.73 143.189C152.634 143.272 152.521 143.333 152.399 143.368C152.276 143.403 152.148 143.411 152.023 143.392C151.897 143.372 151.777 143.326 151.671 143.256C151.565 143.186 151.476 143.094 151.409 142.987C150.492 141.709 151.748 140.132 152.928 139.256C152.082 139.599 151.386 140.211 150.475 140.383C150.358 140.422 150.235 140.435 150.113 140.421C149.991 140.408 149.874 140.368 149.769 140.305C149.664 140.242 149.574 140.156 149.505 140.055C149.436 139.954 149.389 139.839 149.369 139.718C148.968 137.978 151.131 137.207 152.646 137.168C151.92 136.665 149.84 135.295 150.946 134.617C152.052 133.939 154.505 135.903 155.303 136.551C156.474 137.498 157.866 138.916 157.919 140.401C158.003 142.136 156.492 141.07 155.576 140.705Z"
        fill="black"
      />
      <path
        d="M155.918 141.007C154.403 140.32 151.513 141.311 151.249 140.739C150.747 139.571 153.073 138.774 154.333 138.62C153.174 138.307 150.963 137.805 151.029 136.695C151.112 135.334 154.425 136.501 156.103 137.787C158.213 139.408 158.2 140.968 157.865 141.281C157.372 141.712 156.169 141.122 155.918 141.007Z"
        fill="#FFC727"
      />
      <path
        d="M172.54 139.257C165.408 145.724 163.858 156.098 164.959 165.274C164.99 165.481 165.312 165.437 165.32 165.243C165.4 163.168 165.413 161.106 165.501 159.054L165.615 158.785C167.461 157.547 169.166 156.142 170.267 154.155C171.501 151.953 172.051 149.407 173.016 147.107C173.108 146.882 172.866 146.94 172.703 147.05C168.404 149.975 166.941 153.243 165.571 157.622C165.687 155.303 166.018 152.999 166.563 150.741C167.629 146.565 169.576 142.464 172.73 139.451C172.742 139.439 172.753 139.424 172.76 139.408C172.767 139.391 172.77 139.374 172.77 139.356C172.771 139.338 172.767 139.32 172.761 139.304C172.754 139.287 172.744 139.272 172.732 139.26C172.719 139.247 172.705 139.237 172.688 139.23C172.672 139.223 172.654 139.219 172.636 139.219C172.619 139.219 172.601 139.222 172.585 139.228C172.568 139.235 172.553 139.245 172.54 139.257Z"
        fill="#263238"
      />
      <path
        d="M173.803 140.102C174.984 140.186 175.653 142.106 176.556 142.692C176.65 142.777 176.76 142.841 176.88 142.88C177 142.919 177.127 142.931 177.252 142.916C177.378 142.902 177.498 142.86 177.606 142.795C177.714 142.73 177.807 142.642 177.878 142.538C178.838 141.296 177.644 139.675 176.499 138.75C177.332 139.129 178.006 139.767 178.909 139.974C179.024 140.012 179.145 140.027 179.266 140.016C179.387 140.005 179.504 139.969 179.61 139.911C179.716 139.852 179.809 139.773 179.884 139.677C179.958 139.581 180.011 139.471 180.041 139.353C180.508 137.626 178.358 136.772 176.86 136.679C177.605 136.204 179.737 134.917 178.658 134.195C177.578 133.472 175.05 135.34 174.23 135.957C173.023 136.86 171.587 138.225 171.468 139.71C171.323 141.437 172.878 140.428 173.803 140.102Z"
        fill="#FFC727"
      />
      <path
        opacity="0.1"
        d="M173.803 140.102C174.984 140.186 175.653 142.106 176.556 142.692C176.65 142.777 176.76 142.841 176.88 142.88C177 142.919 177.127 142.931 177.252 142.916C177.378 142.902 177.498 142.86 177.606 142.795C177.714 142.73 177.807 142.642 177.878 142.538C178.838 141.296 177.644 139.675 176.499 138.75C177.332 139.129 178.006 139.767 178.909 139.974C179.024 140.012 179.145 140.027 179.266 140.016C179.387 140.005 179.504 139.969 179.61 139.911C179.716 139.852 179.809 139.773 179.884 139.677C179.958 139.581 180.011 139.471 180.041 139.353C180.508 137.626 178.358 136.772 176.86 136.679C177.605 136.204 179.737 134.917 178.658 134.195C177.578 133.472 175.05 135.34 174.23 135.957C173.023 136.86 171.587 138.225 171.468 139.71C171.323 141.437 172.878 140.428 173.803 140.102Z"
        fill="black"
      />
      <path
        d="M173.452 140.371C174.989 139.741 177.857 140.842 178.125 140.279C178.672 139.133 176.363 138.243 175.134 138.041C176.297 137.772 178.531 137.358 178.504 136.244C178.473 134.882 175.117 135.926 173.394 137.147C171.223 138.684 171.192 140.23 171.513 140.569C171.976 141.019 173.196 140.477 173.452 140.371Z"
        fill="#FFC727"
      />
      <path
        d="M165.249 156.351C164.77 159.811 164.034 163.229 163.047 166.58C163.028 166.64 162.987 166.69 162.933 166.722C162.878 166.754 162.814 166.765 162.752 166.752C162.691 166.74 162.636 166.705 162.598 166.654C162.561 166.604 162.543 166.541 162.549 166.478C163.016 163.329 163.87 160.232 164.337 157.082C164.84 153.891 165.015 150.657 164.862 147.431C164.562 142.118 163.1 136.467 159.65 132.308C159.576 132.211 159.734 132.07 159.813 132.163C166.756 140.255 165.65 150.175 165.646 151.065C166.628 147.717 167.641 143.876 171.372 140.466C171.535 140.317 171.659 140.387 171.632 140.638C170.967 146.849 169.24 150.096 165.637 153.413C165.534 154.403 165.405 155.382 165.249 156.351Z"
        fill="#263238"
      />
      <path
        d="M159.602 133.228C158.351 132.673 156.628 134.263 155.395 134.369C155.254 134.402 155.108 134.405 154.966 134.378C154.824 134.35 154.689 134.293 154.571 134.21C154.453 134.127 154.354 134.019 154.28 133.895C154.206 133.77 154.16 133.632 154.144 133.488C153.836 131.726 155.932 130.704 157.598 130.404C156.549 130.343 155.514 130.62 154.483 130.343C154.343 130.323 154.209 130.274 154.09 130.199C153.971 130.123 153.869 130.023 153.792 129.905C153.714 129.787 153.663 129.653 153.642 129.514C153.621 129.374 153.63 129.232 153.668 129.096C154.131 127.092 156.783 127.387 158.355 128.1C157.857 127.219 156.386 124.744 157.875 124.598C159.364 124.453 160.923 127.722 161.421 128.796C162.161 130.369 162.892 132.545 162.201 134.109C161.399 135.95 160.364 134.078 159.602 133.228Z"
        fill="#FFC727"
      />
      <path
        opacity="0.1"
        d="M159.602 133.228C158.351 132.673 156.628 134.263 155.395 134.369C155.254 134.402 155.108 134.405 154.966 134.378C154.824 134.35 154.689 134.293 154.571 134.21C154.453 134.127 154.354 134.019 154.28 133.895C154.206 133.77 154.16 133.632 154.144 133.488C153.836 131.726 155.932 130.704 157.598 130.404C156.549 130.343 155.514 130.62 154.483 130.343C154.343 130.323 154.209 130.274 154.09 130.199C153.971 130.123 153.869 130.023 153.792 129.905C153.714 129.787 153.663 129.653 153.642 129.514C153.621 129.374 153.63 129.232 153.668 129.096C154.131 127.092 156.783 127.387 158.355 128.1C157.857 127.219 156.386 124.744 157.875 124.598C159.364 124.453 160.923 127.722 161.421 128.796C162.161 130.369 162.892 132.545 162.201 134.109C161.399 135.95 160.364 134.078 159.602 133.228Z"
        fill="black"
      />
      <path
        d="M159.811 133.721C158.586 132.245 155.071 131.809 155.102 131.078C155.172 129.615 157.983 129.954 159.361 130.426C158.322 129.518 156.278 127.888 156.908 126.77C157.679 125.4 160.524 128.281 161.617 130.457C162.996 133.201 162.194 134.813 161.674 134.963C160.96 135.179 160.018 133.967 159.811 133.721Z"
        fill="#FFC727"
      />
      <path d="M169.815 158.222L172.876 181.613H154.252C154.635 179.538 158.102 158.195 158.102 158.195L169.815 158.222Z" fill="#FFC727" />
      <path d="M166.977 125.758H86.6777V11.8125H175.695V117.031L166.977 125.758Z" fill="white" />
      <path
        d="M166.978 125.759C166.938 125.759 86.6697 125.794 86.63 125.807C86.5904 125.82 86.5904 11.8353 86.5684 11.8133V11.7031H86.6785C86.7225 11.7031 175.713 11.7648 175.757 11.7516C175.801 11.7384 175.678 117.032 175.7 117.032C175.722 117.032 166.978 125.754 166.978 125.759ZM166.978 125.759L175.687 117.032L175.634 11.8133C176.022 11.9454 86.5948 11.8925 86.6785 11.9234L86.7886 11.8133L86.7269 125.759C86.4274 125.644 167.044 125.789 166.978 125.759Z"
        fill="#263238"
      />
      <path d="M175.695 11.8125H86.6777V14.9885H175.695V11.8125Z" fill="#FFC727" />
      <path
        d="M89.0104 13.3968C89.0104 13.5031 88.9789 13.607 88.9198 13.6954C88.8608 13.7837 88.7768 13.8526 88.6786 13.8933C88.5804 13.934 88.4724 13.9446 88.3681 13.9239C88.2639 13.9031 88.1681 13.852 88.0929 13.7768C88.0178 13.7016 87.9666 13.6059 87.9459 13.5016C87.9251 13.3974 87.9358 13.2893 87.9765 13.1911C88.0171 13.0929 88.086 13.009 88.1744 12.9499C88.2628 12.8909 88.3667 12.8594 88.473 12.8594C88.6155 12.8594 88.7522 12.916 88.853 13.0168C88.9538 13.1176 89.0104 13.2543 89.0104 13.3968Z"
        fill="#C7C7C7"
      />
      <path
        d="M91.2018 13.3968C91.2018 13.5031 91.1703 13.607 91.1112 13.6954C91.0522 13.7838 90.9682 13.8526 90.87 13.8933C90.7718 13.934 90.6638 13.9446 90.5595 13.9239C90.4553 13.9032 90.3595 13.852 90.2844 13.7768C90.2092 13.7017 90.158 13.6059 90.1373 13.5017C90.1165 13.3974 90.1272 13.2893 90.1679 13.1911C90.2085 13.0929 90.2774 13.009 90.3658 12.95C90.4542 12.8909 90.5581 12.8594 90.6644 12.8594C90.7351 12.8588 90.8053 12.8723 90.8707 12.8991C90.9362 12.9259 90.9957 12.9655 91.0457 13.0155C91.0957 13.0655 91.1353 13.125 91.1621 13.1905C91.1889 13.2559 91.2024 13.3261 91.2018 13.3968Z"
        fill="white"
      />
      <path
        d="M93.3932 13.3968C93.3932 13.5031 93.3617 13.607 93.3026 13.6954C93.2436 13.7837 93.1596 13.8526 93.0614 13.8933C92.9632 13.934 92.8552 13.9446 92.7509 13.9239C92.6467 13.9031 92.5509 13.852 92.4758 13.7768C92.4006 13.7016 92.3494 13.6059 92.3287 13.5016C92.3079 13.3974 92.3186 13.2893 92.3593 13.1911C92.3999 13.0929 92.4688 13.009 92.5572 12.9499C92.6456 12.8909 92.7495 12.8594 92.8558 12.8594C92.9264 12.8594 92.9962 12.8733 93.0614 12.9003C93.1266 12.9273 93.1859 12.9669 93.2358 13.0168C93.2857 13.0667 93.3253 13.1259 93.3523 13.1911C93.3793 13.2563 93.3932 13.3262 93.3932 13.3968Z"
        fill="#455A64"
      />
      <path
        d="M121.634 24.4696H120.194L119.903 25.2404C119.869 25.3232 119.804 25.3893 119.722 25.4247C119.639 25.4601 119.546 25.4619 119.463 25.4299C119.421 25.4143 119.382 25.3904 119.349 25.3596C119.317 25.3288 119.291 25.2918 119.272 25.2507C119.254 25.2096 119.245 25.1653 119.244 25.1204C119.244 25.0755 119.252 25.0309 119.269 24.9894L120.59 21.4962C120.617 21.4344 120.662 21.3818 120.718 21.345C120.774 21.3082 120.84 21.2888 120.908 21.2891C120.975 21.2879 121.04 21.307 121.096 21.344C121.152 21.3809 121.195 21.434 121.22 21.4962L122.542 24.9894C122.558 25.0313 122.566 25.076 122.565 25.1209C122.563 25.1658 122.553 25.2101 122.535 25.2511C122.517 25.2921 122.49 25.329 122.457 25.3597C122.424 25.3904 122.386 25.4142 122.344 25.4299C122.307 25.4373 122.27 25.4373 122.234 25.4299C122.168 25.4312 122.103 25.412 122.049 25.3749C121.994 25.3378 121.952 25.2846 121.93 25.2228L121.634 24.4696ZM121.392 23.8176L120.912 22.5446L120.432 23.8176H121.392Z"
        fill="#263238"
      />
      <path
        d="M126.099 24.9575C126.125 25.0399 126.118 25.1294 126.08 25.2069C126.041 25.2843 125.973 25.3434 125.892 25.3716C125.848 25.3765 125.803 25.3765 125.759 25.3716C125.677 25.3718 125.596 25.3489 125.526 25.3055C125.448 25.2521 125.383 25.1812 125.336 25.0985C125.246 24.8796 125.205 24.6434 125.218 24.4069C125.217 24.3183 125.193 24.2315 125.147 24.1558C125.102 24.0834 125.04 24.023 124.966 23.9796C124.864 23.9171 124.752 23.8696 124.636 23.8386H123.936V25.1249C123.936 25.1681 123.927 25.2108 123.91 25.2507C123.894 25.2905 123.869 25.3266 123.839 25.357C123.808 25.3873 123.771 25.4112 123.731 25.4273C123.691 25.4434 123.648 25.4514 123.605 25.4509C123.562 25.4515 123.52 25.4434 123.48 25.4273C123.44 25.4111 123.404 25.3871 123.373 25.3567C123.343 25.3263 123.319 25.2902 123.303 25.2503C123.287 25.2105 123.279 25.1679 123.279 25.1249V21.6229C123.279 21.5799 123.287 21.5373 123.303 21.4974C123.319 21.4576 123.343 21.4214 123.373 21.3911C123.404 21.3607 123.44 21.3367 123.48 21.3205C123.52 21.3043 123.562 21.2963 123.605 21.2969H124.667C124.834 21.2969 124.999 21.3299 125.153 21.3939C125.308 21.4579 125.448 21.5518 125.566 21.67C125.683 21.7883 125.777 21.9287 125.84 22.0831C125.904 22.2375 125.936 22.403 125.936 22.57C125.932 22.7542 125.888 22.9354 125.807 23.1006C125.725 23.2659 125.608 23.4111 125.464 23.5259C125.544 23.5883 125.614 23.6625 125.671 23.7461C125.801 23.934 125.87 24.1567 125.869 24.3848C125.854 24.5018 125.854 24.6203 125.869 24.7372C125.923 24.7514 125.973 24.779 126.013 24.8176C126.053 24.8561 126.082 24.9043 126.099 24.9575ZM125.266 22.57C125.267 22.4894 125.252 22.4094 125.222 22.3346C125.192 22.2599 125.148 22.1918 125.091 22.1344C125.035 22.077 124.967 22.0315 124.893 22.0004C124.818 21.9693 124.739 21.9532 124.658 21.9533H123.927V23.1779H124.658C124.819 23.1779 124.974 23.1138 125.088 22.9998C125.202 22.8858 125.266 22.7312 125.266 22.57Z"
        fill="#263238"
      />
      <path
        d="M129.482 21.9533H128.539V25.1205C128.539 25.2069 128.505 25.2899 128.444 25.351C128.383 25.4121 128.3 25.4465 128.213 25.4465C128.127 25.4465 128.044 25.4121 127.983 25.351C127.922 25.2899 127.887 25.2069 127.887 25.1205V21.9533H126.949C126.862 21.9521 126.779 21.9168 126.718 21.8549C126.657 21.7931 126.623 21.7097 126.623 21.6229C126.622 21.5799 126.631 21.5373 126.647 21.4974C126.663 21.4576 126.687 21.4214 126.717 21.3911C126.748 21.3607 126.784 21.3367 126.824 21.3205C126.863 21.3043 126.906 21.2963 126.949 21.2969H129.482C129.568 21.2969 129.651 21.3312 129.712 21.3924C129.774 21.4535 129.808 21.5364 129.808 21.6229C129.807 21.7094 129.772 21.7921 129.711 21.8537C129.651 21.9153 129.568 21.951 129.482 21.9533Z"
        fill="#263238"
      />
      <path
        d="M130.828 21.6229C130.828 21.5799 130.836 21.5373 130.852 21.4974C130.868 21.4576 130.892 21.4214 130.922 21.3911C130.953 21.3607 130.989 21.3367 131.029 21.3205C131.069 21.3043 131.111 21.2963 131.154 21.2969C131.241 21.2969 131.324 21.3311 131.386 21.3921C131.448 21.4531 131.483 21.536 131.485 21.6229V25.1205C131.483 25.2073 131.448 25.2902 131.386 25.3513C131.324 25.4123 131.241 25.4465 131.154 25.4465C131.111 25.4471 131.069 25.439 131.029 25.4229C130.989 25.4067 130.953 25.3827 130.922 25.3523C130.892 25.3219 130.868 25.2857 130.852 25.2459C130.836 25.2061 130.828 25.1635 130.828 25.1205V21.6229Z"
        fill="#263238"
      />
      <path
        d="M132.414 23.3167C132.423 22.7461 132.657 22.202 133.064 21.8022C133.471 21.4024 134.019 21.179 134.59 21.1802C135.083 21.1695 135.566 21.3246 135.96 21.6207C136.023 21.679 136.062 21.7588 136.07 21.8445C136.077 21.9302 136.052 22.0154 135.999 22.0835C135.947 22.1515 135.871 22.1974 135.786 22.212C135.701 22.2267 135.614 22.209 135.542 22.1625C135.274 21.9432 134.936 21.8261 134.59 21.8322C134.191 21.8389 133.808 21.9962 133.52 22.2727C133.242 22.546 133.084 22.9182 133.079 23.3078C133.081 23.5003 133.12 23.6906 133.196 23.8675C133.271 24.0445 133.382 24.2047 133.52 24.3386C133.808 24.6151 134.191 24.7724 134.59 24.7791C134.935 24.7838 135.272 24.6686 135.542 24.4532C135.615 24.403 135.704 24.3819 135.792 24.3942C135.88 24.4064 135.961 24.451 136.017 24.5192C136.069 24.5904 136.092 24.6787 136.082 24.7662C136.071 24.8537 136.028 24.9339 135.96 24.9906C135.566 25.2867 135.083 25.4418 134.59 25.4311C134.023 25.4347 133.476 25.215 133.069 24.8195C132.662 24.4239 132.427 23.8841 132.414 23.3167Z"
        fill="#263238"
      />
      <path
        d="M137.172 25.1205V21.6228C137.172 21.5364 137.206 21.4535 137.267 21.3923C137.329 21.3312 137.411 21.2969 137.498 21.2969C137.585 21.2969 137.668 21.3311 137.73 21.3921C137.792 21.4531 137.827 21.536 137.828 21.6228V24.7901H139.436C139.523 24.7923 139.605 24.828 139.666 24.8896C139.726 24.9512 139.761 25.0339 139.762 25.1205C139.761 25.2066 139.726 25.2888 139.665 25.3497C139.604 25.4106 139.522 25.4453 139.436 25.4464H137.498C137.455 25.447 137.412 25.439 137.372 25.4228C137.333 25.4067 137.296 25.3827 137.266 25.3523C137.236 25.3219 137.212 25.2857 137.195 25.2459C137.179 25.2061 137.171 25.1634 137.172 25.1205Z"
        fill="#263238"
      />
      <path
        d="M140.512 25.1205V21.6229C140.511 21.5799 140.519 21.5373 140.535 21.4974C140.552 21.4576 140.576 21.4214 140.606 21.3911C140.636 21.3607 140.672 21.3367 140.712 21.3205C140.752 21.3043 140.795 21.2963 140.838 21.2969H142.78C142.867 21.2969 142.95 21.3312 143.011 21.3924C143.072 21.4535 143.106 21.5364 143.106 21.6229C143.106 21.7097 143.072 21.7931 143.011 21.8549C142.95 21.9168 142.867 21.9521 142.78 21.9533H141.173V23.0413H142.551C142.637 23.0424 142.72 23.0772 142.781 23.138C142.841 23.1989 142.876 23.2812 142.877 23.3673C142.878 23.4105 142.87 23.4533 142.854 23.4934C142.838 23.5334 142.814 23.5699 142.783 23.6006C142.753 23.6314 142.717 23.6558 142.677 23.6724C142.637 23.6891 142.594 23.6977 142.551 23.6977H141.173V24.7901H142.78C142.867 24.7924 142.949 24.828 143.01 24.8896C143.071 24.9512 143.105 25.034 143.106 25.1205C143.105 25.2066 143.07 25.2888 143.01 25.3497C142.949 25.4106 142.866 25.4453 142.78 25.4465H140.855C140.811 25.4489 140.767 25.4422 140.725 25.4269C140.684 25.4116 140.646 25.388 140.613 25.3574C140.581 25.3269 140.556 25.2902 140.538 25.2494C140.521 25.2087 140.512 25.1648 140.512 25.1205Z"
        fill="#263238"
      />
      <path
        d="M146.803 23.3776C154.379 23.0825 162.009 23.0825 169.581 23.3776C162.009 23.6727 154.379 23.6727 146.803 23.3776Z"
        fill="#263238"
      />
      <path
        d="M92.7832 23.3776C100.355 23.0825 107.985 23.0825 115.562 23.3776C107.985 23.6727 100.355 23.6727 92.7832 23.3776Z"
        fill="#263238"
      />
      <path d="M169.594 27.7656H92.7832V32.5011H169.594V27.7656Z" fill="#FFC727" />
      <path
        d="M157.555 37.2106C161.436 37.0652 165.708 37.0652 169.589 37.2106C165.708 37.356 161.436 37.356 157.555 37.2106Z"
        fill="#263238"
      />
      <path
        d="M143.105 37.2139C146.588 37.0641 150.074 37.0641 153.563 37.2139C150.077 37.3637 146.591 37.3637 143.105 37.2139Z"
        fill="#263238"
      />
      <path
        d="M124.596 37.2106C129.49 37.0652 134.873 37.0652 139.762 37.2106C134.873 37.356 129.49 37.356 124.596 37.2106Z"
        fill="#263238"
      />
      <path
        d="M162.363 40.6654C164.769 40.5178 167.182 40.5178 169.588 40.6654C167.182 40.8151 164.769 40.8151 162.363 40.6654Z"
        fill="#263238"
      />
      <path
        d="M147.633 40.6621C151.818 40.5211 156.443 40.5167 160.606 40.6621C156.425 40.8074 151.818 40.8074 147.633 40.6621Z"
        fill="#263238"
      />
      <path
        d="M124.596 40.6621C131.45 40.5211 138.991 40.5167 145.846 40.6621C138.991 40.8074 131.45 40.8074 124.596 40.6621Z"
        fill="#263238"
      />
      <path
        d="M147.092 44.1012C154.351 43.9558 162.333 43.9558 169.588 44.1012C162.333 44.2466 154.351 44.2466 147.092 44.1012Z"
        fill="#263238"
      />
      <path
        d="M124.596 44.1012C130.763 43.9558 137.533 43.9558 143.692 44.1012C137.525 44.2466 130.754 44.2466 124.596 44.1012Z"
        fill="#263238"
      />
      <path
        d="M154.699 47.5449C159.501 47.4039 164.787 47.3995 169.588 47.5449C164.787 47.6902 159.501 47.6902 154.699 47.5449Z"
        fill="#263238"
      />
      <path
        d="M138.051 47.5449C142.601 47.4039 147.614 47.3995 152.147 47.5449C147.597 47.6902 142.584 47.6902 138.051 47.5449Z"
        fill="#263238"
      />
      <path
        d="M124.596 47.5449C128.243 47.4039 132.256 47.3995 135.903 47.5449C132.256 47.6902 128.243 47.6902 124.596 47.5449Z"
        fill="#263238"
      />
      <path
        d="M124.596 50.984C134.379 50.8387 145.141 50.8387 154.924 50.984C145.141 51.1294 134.379 51.125 124.596 50.984Z"
        fill="#263238"
      />
      <path
        d="M160.221 57.8858C163.345 57.736 166.469 57.736 169.59 57.8858C166.466 58.0355 163.342 58.0355 160.221 57.8858Z"
        fill="#263238"
      />
      <path
        d="M141.908 57.8825C146.82 57.7371 152.229 57.7371 157.141 57.8825C152.229 58.0278 146.82 58.0234 141.908 57.8825Z"
        fill="#263238"
      />
      <path
        d="M124.596 57.8825C129.3 57.7371 134.472 57.7371 139.172 57.8825C134.472 58.0278 129.3 58.0234 124.596 57.8825Z"
        fill="#263238"
      />
      <path
        d="M149.525 61.3121C156.001 61.1668 163.119 61.1668 169.59 61.3121C163.119 61.4575 156.001 61.4575 149.525 61.3121Z"
        fill="#263238"
      />
      <path
        d="M134.141 61.3121C138.321 61.1668 142.915 61.1668 147.091 61.3121C142.915 61.4575 138.321 61.4575 134.141 61.3121Z"
        fill="#263238"
      />
      <path
        d="M124.596 61.3138C127.256 61.1662 129.922 61.1662 132.582 61.3138C129.922 61.4636 127.256 61.4636 124.596 61.3138Z"
        fill="#263238"
      />
      <path
        d="M159.049 64.753C162.573 64.6032 166.087 64.6032 169.59 64.753C166.066 64.9027 162.552 64.9027 159.049 64.753Z"
        fill="#263238"
      />
      <path
        d="M143.691 64.7496C147.793 64.6043 152.308 64.6043 156.409 64.7496C152.308 64.895 147.793 64.8906 143.691 64.7496Z"
        fill="#263238"
      />
      <path
        d="M124.596 64.7496C130.102 64.6043 136.159 64.6043 141.665 64.7496C136.159 64.895 130.102 64.8906 124.596 64.7496Z"
        fill="#263238"
      />
      <path
        d="M152.846 68.2028C158.246 68.0574 164.189 68.0574 169.585 68.2028C164.184 68.3481 158.242 68.3481 152.846 68.2028Z"
        fill="#263238"
      />
      <path
        d="M138.635 68.2028C142.463 68.0574 146.674 68.0574 150.502 68.2028C146.674 68.3481 142.463 68.3481 138.635 68.2028Z"
        fill="#263238"
      />
      <path
        d="M120.568 68.2028C125.925 68.0574 131.814 68.0574 137.171 68.2028C131.814 68.3481 125.925 68.3481 120.568 68.2028Z"
        fill="#263238"
      />
      <path
        d="M108.652 68.2061C111.809 68.0563 114.968 68.0563 118.128 68.2061C114.968 68.3559 111.809 68.3559 108.652 68.2061Z"
        fill="#263238"
      />
      <path
        d="M92.8555 68.2028C97.353 68.0574 102.3 68.0574 106.797 68.2028C102.3 68.3481 97.353 68.3481 92.8555 68.2028Z"
        fill="#263238"
      />
      <path
        d="M130.941 71.6403C137.73 71.4949 145.196 71.4949 151.984 71.6403C145.196 71.7856 137.73 71.7812 130.941 71.6403Z"
        fill="#263238"
      />
      <path
        d="M115.934 71.6403C120.392 71.4949 125.294 71.4949 129.752 71.6403C125.294 71.7856 120.392 71.7812 115.934 71.6403Z"
        fill="#263238"
      />
      <path
        d="M104.311 71.6436C107.791 71.4938 111.273 71.4938 114.759 71.6436C111.276 71.7904 107.793 71.7904 104.311 71.6436Z"
        fill="#263238"
      />
      <path
        d="M92.8555 71.6436C96.1504 71.4938 99.4469 71.4938 102.745 71.6436C99.4498 71.7904 96.1534 71.7904 92.8555 71.6436Z"
        fill="#263238"
      />
      <path
        d="M159.049 78.5264C162.573 78.3766 166.087 78.3766 169.59 78.5264C166.066 78.6703 162.552 78.6703 159.049 78.5264Z"
        fill="#263238"
      />
      <path
        d="M145.846 78.5231C149.621 78.3777 153.775 78.3777 157.554 78.5231C153.779 78.6685 149.625 78.664 145.846 78.5231Z"
        fill="#263238"
      />
      <path
        d="M131.885 78.5231C135.691 78.3777 139.88 78.3777 143.69 78.5231C139.88 78.6685 135.691 78.664 131.885 78.5231Z"
        fill="#263238"
      />
      <path
        d="M123.402 78.5219C125.515 78.3677 127.637 78.3677 129.75 78.5219C127.637 78.6717 125.516 78.6717 123.402 78.5219Z"
        fill="#263238"
      />
      <path
        d="M109.246 78.5231C113.127 78.3777 117.4 78.3777 121.281 78.5231C117.4 78.6685 113.127 78.664 109.246 78.5231Z"
        fill="#263238"
      />
      <path
        d="M92.8555 78.5231C97.5821 78.3777 102.78 78.3777 107.507 78.5231C102.78 78.6685 97.5821 78.664 92.8555 78.5231Z"
        fill="#263238"
      />
      <path
        d="M152.846 81.9762C158.246 81.8308 164.189 81.8308 169.585 81.9762C164.184 82.1216 158.242 82.1216 152.846 81.9762Z"
        fill="#263238"
      />
      <path
        d="M140.635 81.9639C144.129 81.8141 147.62 81.8141 151.106 81.9639C147.617 82.1137 144.127 82.1137 140.635 81.9639Z"
        fill="#263238"
      />
      <path
        d="M127.65 81.9639C131.315 81.8141 134.977 81.8141 138.637 81.9639C134.977 82.1137 131.315 82.1137 127.65 81.9639Z"
        fill="#263238"
      />
      <path
        d="M104.799 81.9762C111.459 81.8308 118.789 81.8308 125.45 81.9762C118.789 82.1216 111.459 82.1216 104.799 81.9762Z"
        fill="#263238"
      />
      <path
        d="M92.8555 81.9639C96.1504 81.8141 99.4469 81.8141 102.745 81.9639C99.4498 82.1137 96.1534 82.1137 92.8555 81.9639Z"
        fill="#263238"
      />
      <path
        d="M118.641 85.4121C124.618 85.2711 131.195 85.2667 137.173 85.4121C131.195 85.5574 124.618 85.5574 118.641 85.4121Z"
        fill="#263238"
      />
      <path
        d="M108.652 85.4059C111.393 85.2605 114.14 85.2605 116.881 85.4059C114.14 85.5557 111.393 85.5557 108.652 85.4059Z"
        fill="#263238"
      />
      <path
        d="M92.8555 85.4121C97.2605 85.2711 102.106 85.2667 106.48 85.4121C102.075 85.5574 97.2297 85.5574 92.8555 85.4121Z"
        fill="#263238"
      />
      <path
        d="M116.223 88.8512C120.214 88.7058 124.592 88.7058 128.592 88.8512C124.601 88.9966 120.222 88.9966 116.223 88.8512Z"
        fill="#263238"
      />
      <path
        d="M103.553 88.8545C107.191 88.7047 110.828 88.7047 114.464 88.8545C110.831 89.0043 107.194 89.0043 103.553 88.8545Z"
        fill="#263238"
      />
      <path
        d="M92.8555 88.8545C95.9096 88.7047 98.9623 88.7047 102.014 88.8545C98.9594 89.0043 95.9067 89.0043 92.8555 88.8545Z"
        fill="#263238"
      />
      <path
        d="M121.279 95.734C126.402 95.5887 132.045 95.5887 137.173 95.734C132.045 95.8794 126.402 95.8794 121.279 95.734Z"
        fill="#263238"
      />
      <path
        d="M107.799 95.734C111.556 95.5887 115.688 95.5887 119.441 95.734C115.688 95.8794 111.556 95.8794 107.799 95.734Z"
        fill="#263238"
      />
      <path
        d="M92.8555 95.734C97.0623 95.5887 101.666 95.5887 105.894 95.734C101.688 95.8794 97.0623 95.8794 92.8555 95.734Z"
        fill="#263238"
      />
      <path
        d="M127.902 99.1804C130.995 99.0336 134.078 99.0336 137.153 99.1804C134.066 99.3302 130.983 99.3302 127.902 99.1804Z"
        fill="#263238"
      />
      <path
        d="M114.76 99.1777C118.323 99.0367 122.248 99.0323 125.821 99.1777C122.253 99.3231 118.332 99.3231 114.76 99.1777Z"
        fill="#263238"
      />
      <path
        d="M102.016 99.1804C105.595 99.0336 109.18 99.0336 112.768 99.1804C109.183 99.3302 105.598 99.3302 102.016 99.1804Z"
        fill="#263238"
      />
      <path
        d="M92.8555 99.181C95.2951 99.0334 97.7414 99.0334 100.181 99.181C97.7415 99.3308 95.295 99.3308 92.8555 99.181Z"
        fill="#263238"
      />
      <path
        d="M119.441 102.625C125.168 102.479 131.454 102.479 137.172 102.625C131.445 102.77 125.159 102.77 119.441 102.625Z"
        fill="#263238"
      />
      <path d="M107.391 102.62C110.753 102.47 114.113 102.47 117.469 102.62C114.113 102.77 110.753 102.77 107.391 102.62Z" fill="#263238" />
      <path
        d="M92.8555 102.625C96.9434 102.479 101.441 102.479 105.529 102.625C101.441 102.77 96.9434 102.77 92.8555 102.625Z"
        fill="#263238"
      />
      <path
        d="M92.8555 106.061C100.604 105.92 109.128 105.915 116.881 106.061C109.132 106.206 100.604 106.206 92.8555 106.061Z"
        fill="#263238"
      />
      <path
        d="M146.547 112.943C153.983 112.802 162.158 112.798 169.59 112.943C162.158 113.089 153.983 113.089 146.547 112.943Z"
        fill="#263238"
      />
      <path
        d="M132.582 112.943C136.547 112.802 140.903 112.798 144.863 112.943C140.899 113.089 136.542 113.089 132.582 112.943Z"
        fill="#263238"
      />
      <path
        d="M124.596 112.949C126.709 112.798 128.83 112.798 130.943 112.949C128.838 113.101 126.724 113.101 124.618 112.949H124.596Z"
        fill="#263238"
      />
      <path
        d="M107.799 112.943C112.644 112.802 117.988 112.798 122.838 112.943C117.992 113.089 112.649 113.089 107.799 112.943Z"
        fill="#263238"
      />
      <path
        d="M92.8555 112.943C97.0623 112.802 101.666 112.798 105.894 112.943C101.688 113.089 97.0623 113.089 92.8555 112.943Z"
        fill="#263238"
      />
      <path
        d="M153.283 116.398C158.543 116.253 164.327 116.253 169.582 116.398C164.322 116.543 158.538 116.539 153.283 116.398Z"
        fill="#263238"
      />
      <path
        d="M140.01 116.398C143.582 116.253 147.498 116.253 151.088 116.398C147.516 116.543 143.6 116.539 140.01 116.398Z"
        fill="#263238"
      />
      <path
        d="M128.301 116.394C131.646 116.244 134.991 116.244 138.335 116.394C134.988 116.543 131.643 116.543 128.301 116.394Z"
        fill="#263238"
      />
      <path
        d="M112.768 116.398C117.221 116.253 122.124 116.253 126.573 116.398C122.124 116.543 117.221 116.539 112.768 116.398Z"
        fill="#263238"
      />
      <path
        d="M101.697 116.394C104.837 116.244 107.977 116.244 111.12 116.394C107.98 116.543 104.84 116.543 101.697 116.394Z"
        fill="#263238"
      />
      <path
        d="M92.8555 116.394C95.176 116.244 97.5037 116.244 99.8242 116.394C97.5037 116.541 95.1761 116.541 92.8555 116.394Z"
        fill="#263238"
      />
      <path
        d="M131.885 119.836C135.973 119.69 140.479 119.69 144.567 119.836C140.479 119.981 135.973 119.981 131.885 119.836Z"
        fill="#263238"
      />
      <path
        d="M120.287 119.837C123.441 119.69 126.595 119.69 129.749 119.837C126.595 119.986 123.441 119.986 120.287 119.837Z"
        fill="#263238"
      />
      <path
        d="M107.391 119.836C111.02 119.69 115.016 119.69 118.641 119.836C115.016 119.981 111.02 119.981 107.391 119.836Z"
        fill="#263238"
      />
      <path
        d="M92.8555 119.836C96.82 119.69 101.194 119.69 105.163 119.836C101.199 119.981 96.8244 119.981 92.8555 119.836Z"
        fill="#263238"
      />
      <path d="M92.7807 64.7578H120.814V36.7241H92.7807V64.7578Z" fill="#EBEBEB" />
      <path d="M94.1559 63.375H119.445V38.0856H94.1559V63.375Z" fill="#FFC727" />
      <path
        opacity="0.1"
        d="M98.2262 44.1296C98.1255 44.6321 98.1892 45.1536 98.4079 45.6171C98.6266 46.0806 98.9885 46.4615 99.4403 46.7033C99.8922 46.9452 100.41 47.0353 100.917 46.9603C101.424 46.8853 101.893 46.6491 102.255 46.2867C102.618 45.9243 102.854 45.455 102.929 44.948C103.004 44.4411 102.914 43.9234 102.672 43.4716C102.43 43.0198 102.049 42.6578 101.586 42.4392C101.122 42.2205 100.601 42.1568 100.098 42.2574C99.6364 42.35 99.2121 42.5771 98.879 42.9102C98.5458 43.2434 98.3187 43.6676 98.2262 44.1296Z"
        fill="black"
      />
      <path opacity="0.1" d="M101.166 56.8165H119.443L110.306 46.5703L101.166 56.8165Z" fill="black" />
      <path d="M94.1523 56.8221H109.244L101.698 50.1484L94.1523 56.8221Z" fill="#FFC727" />
      <g opacity="0.2">
        <path d="M94.1523 56.8143H109.244L101.698 50.1406L94.1523 56.8143Z" fill="black" />
      </g>
      <path d="M119.442 56.8203H94.1523V63.3838H119.442V56.8203Z" fill="#FFC727" />
      <path opacity="0.2" d="M119.442 56.8203H94.1523V63.3838H119.442V56.8203Z" fill="black" />
      <path d="M140.004 106.188H169.84V85.1622H140.004V106.188Z" fill="#EBEBEB" />
      <path d="M141.041 105.148H168.814V86.1847H141.041V105.148Z" fill="#455A64" />
      <path
        d="M144.093 90.713C144.018 91.0893 144.066 91.4797 144.23 91.8266C144.394 92.1734 144.666 92.4583 145.004 92.6392C145.342 92.82 145.73 92.8873 146.109 92.831C146.489 92.7747 146.84 92.5978 147.112 92.3265C147.383 92.0552 147.56 91.7038 147.616 91.3243C147.672 90.9448 147.605 90.5572 147.424 90.2188C147.243 89.8805 146.959 89.6092 146.612 89.4452C146.265 89.2811 145.874 89.233 145.498 89.3078C145.151 89.3773 144.833 89.5479 144.583 89.7979C144.333 90.0479 144.163 90.3663 144.093 90.713Z"
        fill="#37474F"
      />
      <path d="M146.297 100.234H168.815L157.556 92.5469L146.297 100.234Z" fill="#37474F" />
      <path d="M141.035 100.231H156.409L148.722 95.2266L141.035 100.231Z" fill="#263238" />
      <path d="M168.809 100.227H141.035V105.147H168.809V100.227Z" fill="#263238" />
      <path
        d="M86.6771 73.0418C89.0273 73.0418 90.9324 71.1367 90.9324 68.7865C90.9324 66.4364 89.0273 64.5312 86.6771 64.5312C84.327 64.5312 82.4219 66.4364 82.4219 68.7865C82.4219 71.1367 84.327 73.0418 86.6771 73.0418Z"
        fill="#455A64"
      />
      <path
        d="M87.3331 70.0408C87.3801 70.0874 87.4123 70.1468 87.4255 70.2117C87.4388 70.2765 87.4325 70.3438 87.4075 70.4051C87.3825 70.4663 87.34 70.5188 87.2852 70.5559C87.2304 70.593 87.1658 70.613 87.0996 70.6135C87.0097 70.6121 86.9234 70.5776 86.8573 70.5165L85.3728 69.01C85.3661 69.0077 85.36 69.0037 85.3554 68.9982C85.3507 68.9928 85.3476 68.9862 85.3464 68.9792C85.3429 68.9647 85.3429 68.9496 85.3464 68.9351C85.3443 68.9279 85.3443 68.9203 85.3464 68.9131C85.3464 68.9131 85.3464 68.8867 85.3464 68.8823C85.3464 68.8779 85.3464 68.8602 85.3464 68.8558V68.8206C85.3464 68.8206 85.3464 68.8206 85.3464 68.7854C85.3464 68.7501 85.3464 68.7854 85.3464 68.7589V68.7237C85.3464 68.7237 85.3464 68.6973 85.3464 68.684C85.3535 68.667 85.3624 68.6508 85.3728 68.6356C85.3805 68.6218 85.391 68.6098 85.4037 68.6004L86.8926 67.041C86.9566 66.9796 87.0418 66.9453 87.1304 66.9453C87.2191 66.9453 87.3043 66.9796 87.3683 67.041C87.3998 67.0721 87.4247 67.1091 87.4417 67.15C87.4588 67.1908 87.4675 67.2346 87.4675 67.2788C87.4675 67.3231 87.4588 67.3669 87.4417 67.4077C87.4247 67.4485 87.3998 67.4856 87.3683 67.5167L86.1173 68.7766L87.3331 70.0408Z"
        fill="white"
      />
      <path
        d="M175.695 73.0418C178.045 73.0418 179.95 71.1367 179.95 68.7865C179.95 66.4364 178.045 64.5312 175.695 64.5312C173.345 64.5312 171.439 66.4364 171.439 68.7865C171.439 71.1367 173.345 73.0418 175.695 73.0418Z"
        fill="#455A64"
      />
      <path
        d="M175.025 67.518C174.978 67.4714 174.945 67.412 174.932 67.3471C174.919 67.2823 174.925 67.215 174.95 67.1537C174.975 67.0924 175.018 67.0399 175.073 67.0028C175.127 66.9657 175.192 66.9457 175.258 66.9453C175.348 66.945 175.435 66.9797 175.5 67.0422L176.985 68.5488C176.985 68.5488 177.007 68.5487 177.011 68.5796C177.015 68.5941 177.015 68.6092 177.011 68.6236C177.014 68.6307 177.014 68.6386 177.011 68.6457C177.011 68.6457 177.011 68.6721 177.011 68.6765C177.011 68.6809 177.011 68.6985 177.011 68.7029V68.7382C177.011 68.7382 177.011 68.7382 177.011 68.769C177.011 68.7998 177.011 68.769 177.011 68.7954V68.8351C177.011 68.8351 177.011 68.8351 177.011 68.8571C177.011 68.8791 177.011 68.8835 177.011 68.8967C177.011 68.8967 177.011 68.932 176.985 68.9408C176.979 68.9555 176.968 68.9679 176.954 68.976L175.469 70.4826C175.406 70.5439 175.32 70.5782 175.232 70.5782C175.143 70.5782 175.058 70.5439 174.994 70.4826C174.962 70.4514 174.937 70.4144 174.92 70.3736C174.903 70.3327 174.895 70.2889 174.895 70.2447C174.895 70.2005 174.903 70.1567 174.92 70.1158C174.937 70.075 174.962 70.038 174.994 70.0068L176.245 68.7426L175.025 67.518Z"
        fill="white"
      />
      <path d="M166.975 125.765L175.701 117.039H166.975V125.765Z" fill="#EBEBEB" />
      <path
        d="M49.9566 107.661C53.8727 129.734 62.9383 156.416 67.1363 160.882C72.4664 166.534 107.072 176.432 108.909 176.741C113.464 177.432 125.754 149.086 120.918 148.134C113.552 146.694 87.1396 141.527 85.6067 139.791C84.0165 137.972 73.3474 123.052 62.4493 106.229C54.9475 94.5865 47.525 93.9566 49.9566 107.661Z"
        fill="#D3766A"
      />
      <path
        d="M106.444 176.117C106.933 176.315 113.897 179.064 118.122 179.887C121.276 180.5 130.399 182.024 131.416 178.619C132.117 176.297 126.857 174.773 123.161 173.61C121.214 172.998 118.725 171.24 119.17 169.985C119.95 167.782 124.967 169.469 128.311 171.029C134.773 174.046 143.534 179.733 147.006 176.28C148.327 174.958 146.477 172.976 143.878 171.091C148.01 173.187 152.358 174.826 153.873 173.43C154.754 172.637 154.697 171.668 154.076 170.593C155.318 170.879 156.38 170.703 157.054 169.853C158.203 168.408 157.406 166.5 155.838 164.615C156.075 164.565 156.298 164.459 156.486 164.306C156.675 164.152 156.824 163.956 156.921 163.734C159.243 159.386 144.715 148.823 139.958 148.14C125.958 146.118 111.576 148.026 111.576 148.026C106.581 152.827 106.431 176.112 106.444 176.117Z"
        fill="#D3766A"
      />
      <path
        d="M125.458 161.688C128.763 161.903 131.984 162.821 134.907 164.379C138.589 166.361 141.135 168.837 144.294 171.278C144.373 171.339 144.294 171.471 144.197 171.41C142.69 170.247 136.501 165.939 134.915 164.974C132.045 163.191 128.806 162.085 125.445 161.741C125.414 161.741 125.423 161.683 125.458 161.688Z"
        fill="#263238"
      />
      <path
        d="M127.487 155.641C131.179 156.301 134.976 156.962 138.451 158.367C142.279 159.905 145.416 162.684 148.495 165.354C150.345 166.957 152.157 168.602 153.931 170.287C154.006 170.358 153.931 170.49 153.834 170.42C150.706 167.659 147.496 164.991 144.204 162.416C141.166 160.01 137.683 158.229 133.954 157.174C131.822 156.575 129.65 156.138 127.478 155.707C127.472 155.703 127.467 155.698 127.463 155.692C127.46 155.685 127.458 155.678 127.459 155.671C127.46 155.663 127.463 155.656 127.469 155.651C127.474 155.646 127.48 155.642 127.487 155.641Z"
        fill="#263238"
      />
      <path
        d="M130.873 150.195C134.437 150.601 137.547 150.94 140.815 152.534C145.141 154.649 149.476 158.472 151 159.834C152.682 161.331 154.312 162.886 155.92 164.468C155.986 164.529 155.876 164.644 155.806 164.582C152.141 161.132 148.258 157.922 144.181 154.97C142.259 153.538 140.129 152.41 137.864 151.627C135.6 150.887 133.217 150.565 130.873 150.222V150.195Z"
        fill="#263238"
      />
      <path
        d="M115.245 143.675L102.03 179.215C102.03 179.215 71.6353 168.859 66.2788 162.476C60.9223 156.093 52.1122 124.187 49.8127 108.813C47.5133 93.4397 55.1913 94.2018 61.7813 104.153C68.3712 114.104 84.8197 138.243 85.78 138.799C86.7403 139.354 115.245 143.675 115.245 143.675Z"
        fill="#FFC727"
      />
      <path
        d="M99.3537 162.564C100.847 158.599 102.371 154.635 103.715 150.617C104.481 148.331 105.257 146.045 106.001 143.754C106.027 143.666 105.882 143.604 105.847 143.688C104.19 147.609 102.539 151.547 101.063 155.546C99.5872 159.546 98.0895 163.476 96.6579 167.484C95.8503 169.757 95.1161 172.052 94.4553 174.369C94.4543 174.379 94.4564 174.389 94.4615 174.397C94.4665 174.405 94.4741 174.412 94.4832 174.415C94.4922 174.419 94.5021 174.419 94.5114 174.416C94.5207 174.414 94.5289 174.408 94.5346 174.4C96.3363 170.532 97.8516 166.559 99.3537 162.564Z"
        fill="#263238"
      />
      <path
        opacity="0.1"
        d="M86.7233 173.466L85.3798 172.92C82.8557 171.871 80.2832 170.757 77.8648 169.62C77.552 169.479 77.2437 169.334 76.9441 169.18C76.4552 168.951 75.9706 168.713 75.5037 168.48C75.2658 168.365 75.0368 168.242 74.8033 168.127L74.5566 168.004L73.8298 167.625C72.8739 167.123 71.9664 166.625 71.1251 166.127C70.9313 166.017 70.7286 165.898 70.5392 165.779C69.2222 165.013 67.9894 164.111 66.861 163.088C66.7553 162.982 66.6539 162.881 66.5614 162.779C66.4689 162.678 66.372 162.577 66.2883 162.48C60.9538 156.115 52.1173 124.191 49.8179 108.818C49.7958 108.637 49.7694 108.461 49.743 108.285C49.7408 108.246 49.7408 108.208 49.743 108.17C49.5932 107.003 49.4831 105.562 49.4126 104.007C49.3641 96.4129 53.0115 95.3557 57.2756 98.9062C67.1561 123.16 82.1069 163.158 86.7233 173.466Z"
        fill="black"
      />
      <path
        d="M72.3529 181.473H0C3.0571 167.84 15.7348 116.719 38.1653 96.2489L38.6631 95.8084C41.9184 92.9187 49.2661 93.5663 54.0808 95.9494C54.4151 96.1486 54.713 96.4034 54.9618 96.7026C56.8824 98.8259 60.3712 104.737 62.3094 110.799C62.6499 111.848 62.9366 112.914 63.1684 113.992C63.2168 114.199 63.2653 114.433 63.3138 114.728C63.3622 114.94 63.4019 115.169 63.4503 115.437C63.512 115.803 63.5781 116.204 63.6529 116.644C65.5956 128.388 70.5512 166.721 72.3529 181.473Z"
        fill="#FFC727"
      />
      <path d="M49.6973 114.152L53.1156 108.945L56.6705 113.117L55.7718 117.068L51.7456 117.725L49.6973 114.152Z" fill="#263238" />
      <path d="M51.7446 117.734L51.3438 163.247L57.2377 177.401L62.581 162.085L55.7708 117.078L51.7446 117.734Z" fill="#263238" />
      <path
        d="M30.0374 108.462C18.9015 132.725 11.3468 172.961 18.2451 179.079C25.9275 185.89 40.4025 157.327 39.777 155.9C39.3012 154.821 39.6492 128.862 40.1734 109.885C40.6976 90.9079 32.7906 102.462 30.0374 108.462Z"
        fill="#D3766A"
      />
      <path
        d="M39.9493 155.853C39.8128 153.144 40.6365 118.78 40.5396 108.045C40.4427 97.31 36.2579 95.4951 29.8309 107.693C23.4039 119.89 13.9375 150.607 14.7524 167.804C15.5365 184.367 23.2454 183.195 30.8661 172.65C34.6179 167.474 37.6719 161.826 39.9493 155.853Z"
        fill="#FFC727"
      />
      <path
        d="M39.8649 160.29C39.4508 147.445 39.6417 134.598 40.4376 121.75C40.8546 134.595 40.6637 147.442 39.8649 160.29Z"
        fill="#263238"
      />
      <path
        d="M40.4609 129.211C40.6707 127.532 41.0485 125.878 41.5886 124.273C41.4904 125.971 41.1092 127.64 40.4609 129.211Z"
        fill="#263238"
      />
      <path
        d="M20.1884 179.415C26.4656 183.771 39.6191 181.388 69.525 176.543C73.6613 175.869 74.811 144.681 70.3487 145.17C64.7367 145.786 43.0947 149.39 41.5485 149.857C28.4699 153.764 15.9287 176.459 20.1884 179.415Z"
        fill="#D3766A"
      />
      <path
        d="M75.9609 143.333C84.6565 141.667 87.1454 142.575 93.7353 137.276C98.0655 133.796 102.145 135.073 99.8407 140.359C96.0788 149.024 85.304 153.526 81.1281 154.015L75.9609 143.333Z"
        fill="#D3766A"
      />
      <path
        d="M56.8633 150.763C56.8633 150.763 62.6868 146.957 71.7788 144.266C78.0648 142.407 92.1962 139.883 106.513 153.226C109.909 156.389 112.503 163.309 105.081 160.155C99.7949 157.913 97.1518 154.9 93.8348 154.155C93.8348 154.155 96.0946 163.349 92.7909 166.626C89.9008 169.493 86.8094 172.151 83.5403 174.577C82.9586 174.082 82.4097 173.549 81.8972 172.982C81.8972 172.982 78.8797 176.432 68.9463 176.639L56.8633 150.763Z"
        fill="#D3766A"
      />
      <path
        d="M78.0795 143.461C81.2027 144.893 87.7706 148.038 92.255 152.482C97.2283 157.421 105.47 168.266 101.801 171.904C99.1004 174.569 95.5852 170.702 95.5852 170.702C95.5852 170.702 98.052 175.909 95.0874 177.781C92.3871 179.49 89.8454 176.336 89.8454 176.336C89.8454 176.336 90.7969 179.75 87.8808 180.895C84.3567 182.269 82.9471 174.891 78.1897 173.208C77.0972 172.856 78.0795 143.461 78.0795 143.461Z"
        fill="#D3766A"
      />
      <path
        d="M86.1464 156.866C88.8599 158.694 93.9654 164.434 95.6085 170.561C95.6481 170.698 95.56 170.799 95.5027 170.68C92.9369 165.727 89.7788 161.105 86.0979 156.914C86.0715 156.883 86.1156 156.844 86.1464 156.866Z"
        fill="#263238"
      />
      <path
        d="M81.3066 162.03C83.4871 163.85 88.4868 169.999 89.936 176.263C89.9669 176.404 89.8788 176.501 89.8215 176.378C87.5149 171.303 84.6422 166.504 81.2581 162.074C81.2317 162.044 81.2757 162.008 81.3066 162.03Z"
        fill="#263238"
      />
      <path
        d="M93.8364 154.103C96.7305 154.799 98.682 156.103 101.153 157.653C102.378 158.438 103.646 159.155 104.95 159.799C106.144 160.367 107.906 161.23 109.096 160.182C109.756 159.6 109.695 158.601 109.501 157.825C109.096 156.684 108.511 155.615 107.77 154.658C107.739 154.618 107.8 154.57 107.831 154.605C108.546 155.475 109.171 156.415 109.695 157.411C110.035 158.092 110.164 158.859 110.065 159.614C109.725 161.213 107.862 161.512 106.541 161.147C105.742 160.9 104.97 160.574 104.237 160.173C103.523 159.812 102.832 159.415 102.149 159.001C100.744 158.156 99.3912 157.239 97.9992 156.358C96.6449 155.545 95.2397 154.82 93.7924 154.187C93.7439 154.173 93.7792 154.09 93.8364 154.103Z"
        fill="#263238"
      />
      <path
        d="M100.321 163.84C99.5016 162.25 95.1582 155.673 93.7794 154.171C93.7719 154.163 93.7676 154.153 93.7676 154.142C93.7676 154.131 93.7719 154.121 93.7794 154.113C93.787 154.106 93.7973 154.102 93.8081 154.102C93.8188 154.102 93.8291 154.106 93.8367 154.113C96.1714 156.109 100.03 161.527 100.951 163.28C101.872 165.034 102.929 167.245 103.03 169.21C103.184 172.192 101.233 172.857 100.51 173.038C99.6496 173.238 98.7463 173.137 97.951 172.751C97.0165 172.242 96.1965 171.546 95.5415 170.707C95.5062 170.668 95.5415 170.61 95.5943 170.646C96.6868 171.769 98.4664 172.848 100.074 172.549C100.43 172.495 100.772 172.372 101.08 172.185C101.388 171.998 101.655 171.752 101.867 171.461C102.273 170.72 102.4 169.859 102.224 169.033C101.973 167.192 101.167 165.474 100.321 163.84Z"
        fill="#263238"
      />
      <path
        d="M65.2268 144.633L51.21 181.005C51.21 181.005 24.7224 184.529 18.6919 179.604C14.7273 176.362 26.5593 152.676 41.2942 149.307L65.2268 144.633Z"
        fill="#FFC727"
      />
      <path
        d="M26.2991 160.086C27.929 157.593 32.8274 151.359 41.316 149.289C41.3908 149.289 41.4349 149.386 41.36 149.412C35.1929 151.566 28.1669 157.901 26.3828 160.165C26.3432 160.222 26.2551 160.143 26.2991 160.086Z"
        fill="#263238"
      />
      <path
        d="M42.8405 179.02C44.162 174.937 45.7081 170.933 47.2455 166.929C48.7829 162.924 50.329 158.916 51.9986 154.947C52.9368 152.705 53.8575 150.458 54.8706 148.251C54.8753 148.237 54.8856 148.224 54.8992 148.217C54.9129 148.21 54.9287 148.209 54.9433 148.214C54.9579 148.218 54.9701 148.229 54.9771 148.242C54.9841 148.256 54.9854 148.272 54.9808 148.286C53.6593 152.361 52.1307 156.378 50.6066 160.387C49.0824 164.396 47.523 168.404 45.8976 172.391C44.9901 174.642 44.0386 176.88 43.0255 179.086C42.9902 179.183 42.7964 179.126 42.8405 179.02Z"
        fill="#263238"
      />
      <path
        d="M65.1329 144.266C63.4673 144.39 61.8118 144.624 60.1772 144.967C58.5297 145.266 56.8911 145.592 55.2524 145.932C51.9971 146.61 45.0239 147.672 39.3634 149.848C39.3017 149.87 39.7378 150.055 39.8039 150.024C42.4469 148.852 63.2387 145.332 64.873 144.839C64.8458 144.967 64.8296 145.096 64.8246 145.227C64.8246 145.315 64.9391 145.39 65.0008 145.302C65.1461 145.09 65.2254 144.861 65.3576 144.65C65.3834 144.611 65.3978 144.566 65.3992 144.52C65.4006 144.473 65.3889 144.428 65.3655 144.388C65.342 144.348 65.3078 144.315 65.2666 144.294C65.2255 144.272 65.1791 144.263 65.1329 144.266Z"
        fill="#263238"
      />
      <path
        d="M23.867 181.69C18.7087 180.849 16.418 178.954 15.1934 173.792C13.6296 166.92 14.5723 159.788 15.5943 152.912C17.4752 141.899 20.4883 131.151 23.889 120.539C22.6864 125.385 20.9464 131.966 19.8363 136.736C17.4885 147.309 12.2464 167.788 17.07 177.532C18.4884 180.219 21.1094 180.985 23.867 181.69Z"
        fill="#263238"
      />
      <path
        d="M22.4042 121.523C22.2104 123.228 21.9153 124.867 22.0959 126.58C21.4528 124.968 21.7655 123.092 22.4042 121.523Z"
        fill="#263238"
      />
      <path
        d="M53.2445 108.901C48.9452 109.275 42.7473 101.879 40.6901 96.3201C40.5491 95.9368 41.6372 92.2983 42.7473 87.8139C43.4124 85.0916 44.0908 82.0433 44.5093 79.2197C44.593 78.6382 61.5965 86.1312 61.5965 86.1312C61.5965 86.1312 58.9843 91.7961 58.2927 96.7297C58.2399 97.1194 58.2236 97.5132 58.2442 97.9059C58.2442 97.9588 58.2442 98.0248 58.2442 98.0997C58.2574 100.355 57.3984 108.544 53.2445 108.901Z"
        fill="#D3766A"
      />
      <path
        d="M45.584 79.4609C45.7029 84.3594 46.976 96.3367 56.4028 97.9401C57.0061 98.0498 57.6177 98.1073 58.2309 98.1119C58.2395 98.0461 58.2395 97.9795 58.2309 97.9137C58.2154 97.5211 58.2301 97.1279 58.2749 96.7375C58.9753 91.7995 61.5919 86.1346 61.5919 86.1346C61.5919 86.1346 49.3239 80.712 45.584 79.4609Z"
        fill="#263238"
      />
      <path
        d="M47.1958 57.0036C43.8568 62.7302 42.9273 81.3724 46.3897 86.3897C51.4114 93.658 61.7192 96.6623 68.4149 90.1472C74.9167 83.8348 75.6964 59.5365 71.635 54.7659C65.6573 47.7178 52.125 48.5063 47.1958 57.0036Z"
        fill="#D3766A"
      />
      <path
        d="M62.2219 72.5436C62.2219 72.4952 62.1206 72.6009 62.1206 72.6538C62.1206 73.9092 61.8739 75.3453 60.7198 75.7109C60.6845 75.7109 60.6845 75.7858 60.7198 75.7814C62.125 75.6492 62.4113 73.7418 62.2219 72.5436Z"
        fill="#263238"
      />
      <path
        d="M61.1639 71.1823C59.1508 70.9401 58.8116 74.9795 60.6749 75.2086C62.5382 75.4376 62.851 71.3894 61.1639 71.1823Z"
        fill="#263238"
      />
      <path
        d="M69.2284 73.1679C69.2284 73.1195 69.3209 73.234 69.3165 73.2869C69.1712 74.5335 69.2152 75.9872 70.3121 76.4982C70.3473 76.4982 70.3385 76.5774 70.3121 76.5686C68.9289 76.2559 68.8892 74.3265 69.2284 73.1679Z"
        fill="#263238"
      />
      <path
        d="M70.4582 71.9768C72.4845 71.9768 72.2995 76.0471 70.423 76.0294C68.5464 76.0118 68.7579 71.9415 70.4582 71.9768Z"
        fill="#263238"
      />
      <path
        d="M59.8341 68.5027C60.3861 68.4418 60.9345 68.3521 61.4772 68.234C62.1071 68.1239 62.6181 68.1018 63.1026 67.6393C63.2251 67.4952 63.2963 67.3145 63.305 67.1256C63.3138 66.9367 63.2597 66.7502 63.1511 66.5953C62.6445 65.9566 61.8296 65.8993 61.0763 66.0006C60.304 66.0767 59.5878 66.4379 59.0676 67.0138C58.5126 67.5997 59.0852 68.5512 59.8341 68.5027Z"
        fill="#263238"
      />
      <path
        d="M72.7124 69.8843C72.169 69.7664 71.6322 69.6193 71.1046 69.4438C70.4923 69.2632 69.9857 69.1839 69.5584 68.6729C69.4564 68.5146 69.4078 68.3279 69.4197 68.14C69.4316 67.9521 69.5034 67.773 69.6245 67.6289C70.1971 67.0518 71.0033 67.0827 71.7521 67.2677C72.5108 67.4304 73.1824 67.8682 73.6375 68.4967C73.7322 68.6458 73.7816 68.8191 73.7797 68.9958C73.7778 69.1724 73.7246 69.3446 73.6266 69.4916C73.5286 69.6386 73.3901 69.7539 73.2278 69.8236C73.0655 69.8933 72.8865 69.9144 72.7124 69.8843Z"
        fill="#263238"
      />
      <path
        d="M58.6441 83.5926C58.8952 83.9274 59.1331 84.3723 59.578 84.4736C60.0417 84.5339 60.5129 84.4964 60.9612 84.3635C60.9701 84.3615 60.9794 84.3623 60.9878 84.3658C60.9962 84.3694 61.0033 84.3754 61.0082 84.3831C61.013 84.3909 61.0153 84.3999 61.0148 84.409C61.0143 84.4181 61.0109 84.4269 61.0052 84.434C60.7913 84.6521 60.5224 84.8083 60.2269 84.8858C59.9314 84.9634 59.6204 84.9595 59.3269 84.8745C59.0815 84.7727 58.8725 84.5993 58.7271 84.3769C58.5817 84.1545 58.5068 83.8935 58.512 83.6279C58.5076 83.553 58.6045 83.5442 58.6441 83.5926Z"
        fill="#263238"
      />
      <path
        d="M60.5068 80.068C60.7487 80.607 61.0994 81.0903 61.5367 81.4875C61.9741 81.8847 62.4888 82.1872 63.0485 82.3763C63.5354 82.5472 64.0442 82.6481 64.5595 82.6758C64.6577 82.6823 64.7563 82.6823 64.8546 82.6758H65.1321C65.2004 82.6727 65.2658 82.6474 65.3183 82.6036C65.3708 82.5599 65.4075 82.5001 65.4229 82.4335C65.4291 82.3912 65.4291 82.3481 65.4229 82.3058V82.1956C65.5903 81.2442 65.718 79.7729 65.718 79.7729C66.0396 80.0019 67.7003 81.0724 67.7487 80.5658C68.1232 76.3942 68.5416 71.7557 68.0351 67.518C68.0298 67.4909 68.0152 67.4665 67.9939 67.449C67.9726 67.4315 67.9459 67.4219 67.9183 67.4219C67.8907 67.4219 67.864 67.4315 67.8427 67.449C67.8214 67.4665 67.8069 67.4909 67.8016 67.518C67.2818 71.5178 67.4712 75.5793 67.0131 79.6011C66.4757 79.3015 65.6079 78.7597 65.1057 78.698C64.9647 78.698 64.9119 81.5393 64.8018 81.9622C64.8047 81.9767 64.8047 81.9917 64.8018 82.0062C63.1631 81.9666 62.0266 81.3014 60.6258 79.9887C60.5597 79.905 60.4584 79.9579 60.5068 80.068Z"
        fill="#263238"
      />
      <path
        d="M64.0668 82.1128C63.4401 82.7176 62.6678 83.15 61.8247 83.3683C61.3623 83.4712 60.8795 83.4297 60.4415 83.2493C59.4856 82.8353 59.6001 81.9278 59.8997 81.1702C60.0616 80.7725 60.2701 80.3955 60.5208 80.0469C61.4535 81.0945 62.6955 81.818 64.0668 82.1128Z"
        fill="#263238"
      />
      <path
        d="M61.8247 83.3622C61.3623 83.4651 60.8795 83.4236 60.4415 83.2432C59.4856 82.8292 59.6001 81.9217 59.8997 81.1641C60.9437 81.4328 61.8907 82.3138 61.8247 83.3622Z"
        fill="#FF98B9"
      />
      <path
        d="M43.9052 70.7654C46.3984 71.8887 49.2705 68.3911 50.1868 66.8714C51.103 65.3516 51.6184 57.9291 53.2703 55.6914C54.9222 53.4536 57.8207 52.5153 59.8779 53.4888C61.935 54.4624 64.2609 57.6869 65.8599 57.7838C67.459 57.8807 68.3664 54.5196 69.4677 54.7002C71.8861 55.0703 74.1018 62.9641 74.1326 65.682C74.1326 65.682 76.4277 52.7312 63.9349 49.9868C52.7109 47.52 47.5878 53.5109 46.0813 56.775C43.1607 62.9905 43.9052 70.7654 43.9052 70.7654Z"
        fill="#263238"
      />
      <path
        d="M58.5936 78.7625C57.5854 78.5818 56.6483 78.1217 55.8887 77.4347C55.1292 76.7476 54.5777 75.8611 54.2972 74.8761C54.0166 73.8911 54.0182 72.847 54.3017 71.8629C54.5852 70.8787 55.1393 69.9939 55.9009 69.309C56.6625 68.6242 57.601 68.167 58.6097 67.9892C59.6183 67.8115 60.6566 67.9205 61.6064 68.3038C62.5562 68.6871 63.3793 69.3293 63.9821 70.1573C64.5849 70.9853 64.9432 71.9659 65.0161 72.9875C65.1179 74.4341 64.6417 75.862 63.692 76.9578C62.7423 78.0537 61.3967 78.7281 59.9503 78.833C59.4969 78.8675 59.041 78.8439 58.5936 78.7625ZM60.3996 68.7102C60.0114 68.643 59.6166 68.6223 59.2235 68.6486C58.0218 68.7356 56.8985 69.2774 56.0822 70.1636C55.266 71.0499 54.8183 72.214 54.8303 73.4187C54.8423 74.6235 55.3131 75.7784 56.1468 76.6483C56.9805 77.5181 58.1144 78.0375 59.3176 78.1005C60.5208 78.1636 61.7028 77.7657 62.6228 76.9878C63.5429 76.21 64.1319 75.1106 64.2698 73.9137C64.4077 72.7168 64.0842 71.5123 63.3652 70.5455C62.6462 69.5788 61.5856 68.9224 60.3996 68.7102Z"
        fill="#EBEBEB"
      />
      <path
        d="M71.1228 79.4654C69.0656 79.0953 67.5723 76.6461 67.7485 73.7961C67.8089 72.4337 68.2941 71.1245 69.1361 70.0518C69.4691 69.5682 69.9215 69.179 70.4494 68.9219C70.9772 68.6648 71.5626 68.5486 72.1487 68.5845C72.7347 68.6204 73.3015 68.8072 73.794 69.1268C74.2865 69.4465 74.688 69.888 74.9596 70.4086C75.6638 71.5761 75.9857 72.9345 75.8802 74.2938C75.6952 77.3069 73.7217 79.6416 71.4752 79.505C71.3571 79.4982 71.2394 79.4849 71.1228 79.4654ZM72.3782 69.3338C72.2867 69.3153 72.1939 69.3035 72.1007 69.2985C71.6339 69.2918 71.1723 69.3971 70.7546 69.6056C70.3368 69.8141 69.9752 70.1196 69.6999 70.4967C68.9561 71.4511 68.5281 72.6136 68.4753 73.8225C68.3167 76.4347 69.6779 78.646 71.5236 78.7606C73.3693 78.8751 74.9904 76.84 75.149 74.2322C75.2476 73.0264 74.9657 71.8201 74.3428 70.783C74.135 70.4174 73.8528 70.0995 73.5144 69.8498C73.1759 69.6002 72.7889 69.4244 72.3782 69.3338Z"
        fill="#EBEBEB"
      />
      <path
        d="M64.794 73.6091L64.3535 73.0188C65.2345 72.3757 67.1948 71.5563 68.4414 73.0672L67.8776 73.5298C66.7675 72.1774 64.8645 73.5518 64.794 73.6091Z"
        fill="#EBEBEB"
      />
      <path
        d="M41.6958 73.8365L41.0703 73.462C41.2421 73.1669 42.8323 70.5944 44.471 70.427C46.0084 70.2772 54.422 71.4798 54.8361 71.5458L54.7216 72.2683C51.9332 71.8278 45.7309 71.0393 44.5415 71.1582C43.4579 71.2639 42.1187 73.1273 41.6958 73.8365Z"
        fill="#EBEBEB"
      />
      <path
        d="M47.5971 72.4195C47.5971 72.4195 44.7779 65.7502 41.8353 66.6356C38.8927 67.5211 40.1482 76.5822 42.9938 78.3927C43.3427 78.6498 43.7399 78.8338 44.1617 78.9335C44.5834 79.0332 45.021 79.0467 45.4481 78.973C45.8752 78.8994 46.283 78.7402 46.647 78.5049C47.011 78.2697 47.3236 77.9633 47.5662 77.6042L47.5971 72.4195Z"
        fill="#D3766A"
      />
      <path
        d="M42.2583 69.2422C42.2099 69.2422 42.1879 69.2906 42.2275 69.3215C44.2054 70.643 44.919 72.916 45.2714 75.167C44.7957 74.2287 44.016 73.6032 42.7737 74.1406C42.7121 74.167 42.7473 74.2683 42.809 74.2683C43.216 74.2284 43.6254 74.3153 43.9811 74.5172C44.3368 74.7191 44.6213 75.0261 44.7957 75.396C45.0816 76.0166 45.2987 76.6665 45.4432 77.3343C45.5049 77.5501 45.8837 77.5237 45.8529 77.2858C45.8508 77.2668 45.8508 77.2476 45.8529 77.2285C46.4872 74.4798 45.1657 70.154 42.2583 69.2422Z"
        fill="#263238"
      />
      <path
        d="M58.2566 97.5664L52.8692 106.742L41.4469 92.875L38.1035 97.1567C38.1035 97.1567 48.103 117.142 48.2616 116.297L53.0807 108.632L58.3271 114.821L59.2521 100.69L58.2566 97.5664Z"
        fill="#FFC727"
      />
      <path
        d="M59.1944 107.859C59.2648 105.52 59.4058 103.168 59.3617 100.811C59.3617 100.727 59.2296 100.679 59.2032 100.771C58.494 102.974 58.2737 111.912 58.0534 114.039L58.027 114.013C57.8112 113.748 53.1374 108.542 53.0802 108.612C52.0802 109.806 48.5474 115.387 48.2963 115.903C46.7149 112.726 39.9267 100.159 37.9796 97.115C37.9224 97.0269 37.7858 97.0797 37.8255 97.1811C39.1734 100.502 47.9174 117.061 48.0672 117.035C48.1597 117.118 52.5427 110.251 53.1154 108.929C53.8819 110.035 58.5468 115.85 58.5953 115.506C58.9351 112.97 59.1351 110.417 59.1944 107.859Z"
        fill="#263238"
      />
      <path d="M123.052 178.805H53.1875V181.624H123.052V178.805Z" fill="#455A64" />
      <path d="M91.8462 178.805H53.1875V181.624H91.8462V178.805Z" fill="#A6A6A6" />
      <path d="M90.6758 181.611H151.906L160.562 140.023H99.3273L90.6758 181.611Z" fill="#A6A6A6" />
      <path d="M93.4492 181.611H154.684L163.335 140.023H102.101L93.4492 181.611Z" fill="#F0B409" />
      <path
        d="M124.311 161.748C124.242 162.264 124.286 162.788 124.439 163.286C124.592 163.783 124.851 164.241 125.198 164.628C125.545 165.016 125.972 165.324 126.449 165.531C126.926 165.738 127.443 165.839 127.963 165.827C129.079 165.803 130.15 165.377 130.978 164.628C131.806 163.879 132.337 162.857 132.474 161.748C132.542 161.232 132.499 160.708 132.345 160.211C132.192 159.713 131.934 159.255 131.587 158.867C131.24 158.479 130.813 158.171 130.336 157.963C129.859 157.756 129.342 157.654 128.822 157.665C127.705 157.689 126.634 158.116 125.805 158.866C124.977 159.616 124.446 160.639 124.311 161.748Z"
        fill="#EBEBEB"
      />
      <path
        d="M5.61169 44.4569C5.93883 47.6027 7.13398 50.5956 9.06368 53.1014C10.9934 55.6073 13.5817 57.5274 16.5395 58.6474C19.4973 59.7674 22.7082 60.0431 25.8137 59.4438C28.9191 58.8445 31.7969 57.3938 34.1256 55.2536L42.6626 56.5091L38.3897 49.1262C39.3679 46.7239 39.7857 44.1302 39.6112 41.5422C39.4367 38.9542 38.6745 36.44 37.3826 34.1907C36.0907 31.9415 34.3031 30.0163 32.1556 28.5615C30.0081 27.1068 27.5572 26.1607 24.9892 25.7952C22.4212 25.4298 19.8037 25.6545 17.3355 26.4524C14.8674 27.2502 12.6137 28.6002 10.7455 30.3998C8.87742 32.1993 7.4441 34.401 6.55451 36.8375C5.66493 39.2741 5.34248 41.8814 5.61169 44.4613V44.4569Z"
        fill="white"
      />
      <path
        d="M10.723 30.3637C12.5808 28.5274 14.8367 27.1438 17.3157 26.3202C19.7947 25.4967 22.4301 25.2554 25.0174 25.6151C27.6229 25.9562 30.1155 26.8906 32.3033 28.3462C35.615 30.5594 38.0605 33.8486 39.226 37.6574C40.3915 41.4662 40.2055 45.5607 38.6995 49.2481V48.953L42.9459 56.3491L43.2587 56.8953L42.642 56.8028L34.1138 55.4813L34.312 55.4196C31.9415 57.5673 29.0184 59.0106 25.872 59.5868C25.0929 59.7328 24.3033 59.8153 23.5109 59.8335C22.7209 59.8761 21.9288 59.8614 21.1409 59.7894C17.981 59.48 14.9669 58.3082 12.4277 56.4019C11.7964 55.9314 11.1947 55.4224 10.6261 54.8778C10.0774 54.3205 9.56115 53.7322 9.07991 53.1158C6.52786 49.7772 5.28796 45.6191 5.5948 41.428C5.90164 37.237 7.73394 33.3038 10.745 30.3725L10.723 30.3637ZM9.11075 53.0497C9.59779 53.6692 10.123 54.2577 10.6833 54.8117C11.2388 55.3542 11.8272 55.8617 12.4454 56.3315C13.702 57.2649 15.09 58.0071 16.5641 58.534C18.0284 59.0785 19.5614 59.4166 21.1189 59.5383C21.8962 59.5912 22.6763 59.5912 23.4536 59.5383C24.2322 59.5063 25.007 59.4121 25.7706 59.2564C28.8331 58.6401 31.663 57.1817 33.942 55.0452L34.0257 54.9659H34.1446L42.6904 56.1641L42.3821 56.6046L38.0872 49.2393L38.0079 49.0984L38.0695 48.9486C39.017 46.59 39.4263 44.0497 39.2677 41.5129C39.098 38.9909 38.3617 36.5396 37.1136 34.3415C35.8381 32.1343 34.0956 30.2324 32.0082 28.7691C29.9025 27.3145 27.4914 26.3621 24.9601 25.9851C21.6155 25.4673 18.1926 25.9675 15.1359 27.4205C12.0793 28.8735 9.53031 31.2122 7.82016 34.1327C6.11001 37.0533 5.31776 40.4207 5.54635 43.7973C5.77493 47.174 7.01378 50.4039 9.10194 53.0673L9.11075 53.0497Z"
        fill="#263238"
      />
      <path
        d="M20.4759 52.3167L13.1855 44.9383L15.6348 42.5199L19.8944 46.828L28.7398 32.9609L31.6427 34.8111L20.4759 52.3167Z"
        fill="#FFC727"
      />
    </svg>
  );
};

export default ArticleImageIcon;
