import React from 'react';

const SignWhyChooseIcon = () => {
  return (
    <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.25 19.625C1.25 19.625 12.08 1.625 6.30406 1.625C2.00656 1.625 1.96063 19.7291 11 8.39281C11 8.39281 11.7603 12.0903 13.5528 12.125C14.2766 12.1391 15.17 11.5334 16.25 9.875C16.25 9.875 16.25 12.125 20.75 12.125"
        stroke="black"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default SignWhyChooseIcon;
