import { Card, Chip } from '@mui/material';
import { width } from '@mui/system';
import ZoomInIcon from 'assets/images/eSign/ZoomInIcon';
import ZoomOutIcon from 'assets/images/eSign/ZoomOutIcon';
import classNames from 'classnames';
import Iconify from 'components/Iconify';
import useEsignCanvasZoom from 'hooks/useEsignCanvasZoom';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getEsignPdfImageScaleValue,
  selectUploadedDocsImages,
  setEsignCurrentDocSelectedPage,
  setEsignUploadedDocumentsImages,
  setSelectedActiveObject
} from 'store/esignMainSlice';
import { selectReplaceCanvasAction } from 'store/replaceCanvasReducer';

const CanvasCard2 = ({ children, canvasRdxAction }) => {
  const canvas = useSelector(canvasRdxAction);
  const [zoomIn, zoomOut, zoomLevel] = useEsignCanvasZoom(canvas);
  return (
    <Card
      sx={{
        maxWidth: 'calc(100% - 401px)',
        width: '100%',
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
      }}
      className="addsign_area"
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        {' '}
        {zoomLevel < 500 && (
          <button
            onClick={zoomIn}
            style={{
              backgroundColor: 'white',
              border: 'none',
              // width: '30px',
              fontSize: '20px',
              padding: '5px',
              borderRadius: '5px',
              margin: '5px'
            }}
          >
            <Iconify icon="cil:zoom-in" />
          </button>
        )}
        <Chip
          label={`Zoom - ${zoomLevel}%`}
          variant="outlined"
          sx={{
            backgroundColor: 'white'
          }}
        />
        {zoomLevel > 50 && (
          <button
            onClick={zoomOut}
            style={{
              backgroundColor: 'white',
              border: 'none',
              // width: '30px',
              fontSize: '20px',
              padding: '5px',
              borderRadius: '5px',
              margin: '5px'
            }}
          >
            {' '}
            <Iconify icon="cil:zoom-out" />
          </button>
        )}
        {/* <span>Zoom - {zoomLevel}%</span> */}
      </div>
      <div
        className="drag-area"
        style={{
          overflow: 'auto',
          width: '700px',
          height: 'calc(100% - 50px)'
          // height: '600px'
        }}
      >
        {children}
      </div>
    </Card>
  );
};

const CanvasCard = ({ children, canvasRdxAction, docTitle, isSignCanvas }) => {
  const canvas = useSelector(canvasRdxAction);

  const containerRef = useRef(null);
  const [zoomIn, zoomOut, zoomLevel] = useEsignCanvasZoom(canvas, containerRef);
  const pagePositions = useSelector((state) => state.esignMain.esignPdfPagesPosition);
  const currentPageNo = useSelector((state) => state.esignMain.esignCurrentDocSelectedPage);
  const isProgrammaticScroll = useSelector((state) => state.esignMain.isProgrammaticScroll);
  const uploadedDocumentAllData = useSelector((state) => state.esignMain.uploadedDocumentAllData);
  const dispatch = useDispatch();
  const [maxWidth, setMaxWidth] = useState(700);
  const activeObject = useSelector((state) => state.esignMain.selectedActiveObject);
  const scaleFactor = useSelector(getEsignPdfImageScaleValue);
  const uploadDocument = useSelector(selectUploadedDocsImages);
  const [signMarkerTop, setSignMarkerTop] = useState(130);
  const handleScroll = (e) => {
    if (isProgrammaticScroll) return;
    const container = containerRef.current;
    const scrollTop = container.scrollTop;
    const currentPage = pagePositions.findIndex(
      (pos, index) => pos <= scrollTop && (pagePositions[index + 1] > scrollTop || index === pagePositions.length - 1)
    );
    if (currentPage !== -1) {
      dispatch(setEsignCurrentDocSelectedPage(currentPage));
    }
  };

  // useEffect(() => {
  //   const container = containerRef.current;
  //   container.addEventListener('scroll', handleScroll);

  //   return () => {
  //     container.removeEventListener('scroll', handleScroll);
  //   };
  // }, [pagePositions, isProgrammaticScroll]);
  useEffect(() => {
    if (canvas !== null) {
      canvas.on('selection:created', (e) => {
        const selectedItem = e?.selected?.[0];
        dispatch(setSelectedActiveObject(selectedItem));
      });
      canvas.on('selection:updated', (e) => {
        dispatch(setSelectedActiveObject(e?.selected?.[0]));
      });
      canvas.on('selection:cleared', () => {
        dispatch(setSelectedActiveObject(null));
      });
      // canvas.on('object:moving', ({ pointer, target }) => {
      //   console.log(
      //     { x: pointer.x, y: pointer.y },
      //     { width: target?.width, height: target?.height },
      //     { width: canvas?.getObjects()?.[0]?.width, height: canvas?.getObjects()?.[0]?.height },
      //     { canvasWidth: canvas?.getWidth(), canvasHeight: canvas?.getHeight() },
      //     scaleFactor,
      //     'movingData'
      //   );
      // });
    }
    // return () => {
    //   dispatch(setEsignUploadedDocumentsImages([]));
    // };
  }, [canvas, scaleFactor]);

  console.log(
    {
      rectTop: canvas?.getActiveObject()?.top,
      rectLeft: canvas?.getActiveObject()?.left,
      rectWidth: canvas?.getActiveObject()?.width,
      rectHeight: canvas?.getActiveObject()?.height,
      rectWidth: canvas?.getActiveObject()?.width
    },
    { pdfImageWidth: canvas?.getObjects()?.[0]?.width, pdfImageHeight: canvas?.getObjects()?.[0]?.height },
    { canvasWidth: canvas?.getWidth(), canvasHeight: canvas?.getHeight() },
    canvas?.getObjects(),
    'debug90'
  );

  return (
    <>
      <div
        className={classNames('xl:flex-1 flex flex-col  items-center bg-[#F3F4F6] w-full border-t ', {
          'rounded-tr-[10px]  rounded-br-[10px]': isSignCanvas
        })}
      >
        <div
          className={classNames('bg-[#FFFFFF] w-full h-[55px] flex items-center justify-between px-4 border-b', {
            '': isSignCanvas
          })}
        >
          <div>
            <h2 className="text-[20px] font-bold capitalize">{uploadedDocumentAllData?.document_name?.split('.')?.[0]} Document</h2>
          </div>
          <div className="flex justify-end items-center gap-3  pl-4">
            <button
              onClick={zoomOut}
              disabled={zoomLevel < 100}
              className=" cursor-pointer disabled:cursor-not-allowed disabled:opacity-40"
            >
              <ZoomOutIcon />
            </button>
            <div className="text-[16px] text-[#101010] font-normal">{zoomLevel}%</div>
            <button
              onClick={zoomIn}
              disabled={zoomLevel === 250}
              className=" cursor-pointer disabled:cursor-not-allowed disabled:opacity-40"
            >
              <ZoomInIcon />
            </button>
          </div>
        </div>
        {/* <div className="bg-[white] w-[fit-content] p-[40px] flex flex-col justify-center items-center mt-[10px]"> */}
        <div
          className=" drag-area  overflow-auto align-middle mt-[10px] pb-[10px] pdfScrollCustom flex flex-col justify-start items-start"
          style={{
            // height: `calc(100vh - ${isSignCanvas ? 140 : 290}px)`,
            maxWidth: `${maxWidth}px`,
            width: 'calc(100% + 15px)',
            height: `calc(100vh - ${isSignCanvas ? 130 : 185}px)`
          }}
          ref={containerRef}
          id="esign_document_canvas_container"
        >
          {children}
          {/* {uploadDocument.length > 0 ? children : <div className="text-[18px] text-white font-bold">Loading...</div>} */}
        </div>
      </div>
      {/* </div> */}
    </>
  );
};
export default CanvasCard;
