import { Navigate, useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import { Routes, Route } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
const PublicRoute = ({ element }) => {
  const token = localStorage.getItem('token');
  if (token) {
    return <Navigate to="/dashboard/home" />;
  }
  console.log(element, token, 'elementData');
  return element; // Render the element directly (no restrictions)
};

// ==============================|| ROUTING RENDER ||============================== //

export default function RoutesComp() {
  return useRoutes([MainRoutes, ...AuthenticationRoutes]);
  //   return (
  //     <Routes>
  //       {[...AuthenticationRoutes, ...MainRoutes].map((route, index) => {
  //         if (route?.type === 'public') {
  //           return <Route key={index} path={route.path} element={<PublicRoute element={route.element} />} />;
  //         }
  //         // For private routes, wrap them in the PrivateRoute component
  //         return <Route key={index} path={route.path} element={<PrivateRoute element={route.element} />} />;
  //       })}
  //     </Routes>
  //   );
}
