import styled from "@emotion/styled";
import { Button, FormControl, InputLabel, Paper, Stack, TextField } from "@mui/material";
import { Form } from "formik";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";



const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary
}));


const AddRecipient = ({ docId, recipientDetails, setRecipientDetails }) => {
  debugger
  const navigate = useNavigate();
  // const { docId } = useSelector((state) => state.main);
  // const [recipientDetails, setRecipientDetails] = useState({});s

  const handleChange = (e, key) => {
    const { value, name } = e.target;
    setRecipientDetails({
      ...recipientDetails,
      [key]: { ...recipientDetails[key], [name]: value },
    });
  };

  return (
    <div className="add-recipient">
      <h1>Add Recipient</h1>
      <form className="mt-5 ">
        <Item>
          <FormControl fullWidth>
            <TextField
              size="small"
              fullWidth
              type="text"
              label="name"
              onChange={(e) => handleChange(e, "one")}
              name="name"
            />
          </FormControl>
        </Item>
        <Item>
          <FormControl fullWidth>
            <TextField
              size="small"
              fullWidth
              type="email"
              name="email"
              label="email"
              onChange={(e) => handleChange(e, "one")}
            />
          </FormControl>
        </Item>

        <hr />


        <Item>
          <FormControl fullWidth>
            <TextField
              size="small"
              fullWidth
              type="text"
              label="name"
              onChange={(e) => handleChange(e, "two")}
              name="name"
            />
          </FormControl>
        </Item>
        <Item>
          <FormControl fullWidth>
            <TextField
              size="small"
              fullWidth
              type="email"
              name="email"
              label="email"
              onChange={(e) => handleChange(e, "two")}
            />
          </FormControl>
        </Item>


        {/* <Stack direction="horizontal">
          <div className="p-2 ms-auto">
            {" "}
            <Button
              variant="primary"
              onClick={() => {
                localStorage.setItem(
                  "recipientDetails",
                  JSON.stringify(recipientDetails)
                );
                navigate(`/dashboard/docusign-container/${docId}`);
              }}
            >
              Next
            </Button>
          </div>
        </Stack> */}
      </form>
    </div>
  );
};

export default AddRecipient;
