import React from 'react';

const ShieldWhyChooseEsignIcon = () => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_6210_14456)">
        <path
          d="M20.25 11.125V5.875C20.25 5.67609 20.171 5.48532 20.0303 5.34467C19.8897 5.20402 19.6989 5.125 19.5 5.125H4.5C4.30109 5.125 4.11032 5.20402 3.96967 5.34467C3.82902 5.48532 3.75 5.67609 3.75 5.875V11.125C3.75 20.125 12 22.375 12 22.375C12 22.375 20.25 20.125 20.25 11.125Z"
          stroke="black"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path d="M8.25 13.375L10.5 15.625L15.75 10.375" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_6210_14456">
          <rect width="24" height="24" fill="white" transform="translate(0 0.625)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ShieldWhyChooseEsignIcon;
