import React from 'react';

const PathWhyChooseEsignIcon = () => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_6210_14462)">
        <path
          d="M18.75 21.625C19.9926 21.625 21 20.6176 21 19.375C21 18.1324 19.9926 17.125 18.75 17.125C17.5074 17.125 16.5 18.1324 16.5 19.375C16.5 20.6176 17.5074 21.625 18.75 21.625Z"
          stroke="black"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M6.75 5.875H15.75C16.5456 5.875 17.3087 6.19107 17.8713 6.75368C18.4339 7.31629 18.75 8.07935 18.75 8.875C18.75 9.67065 18.4339 10.4337 17.8713 10.9963C17.3087 11.5589 16.5456 11.875 15.75 11.875H6.75C5.75544 11.875 4.80161 12.2701 4.09835 12.9733C3.39509 13.6766 3 14.6304 3 15.625C3 16.6196 3.39509 17.5734 4.09835 18.2767C4.80161 18.9799 5.75544 19.375 6.75 19.375H16.5"
          stroke="black"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_6210_14462">
          <rect width="24" height="24" fill="white" transform="translate(0 0.625)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PathWhyChooseEsignIcon;
