import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
// import CreateNewCanvasWithPdfLoad from './CreateNewCanvasWithPdfLoad';
import CanvasCard from '../components/canvas/CanvasCard';

import ReplaceFabricCanvas from 'views/eSign_fabric/AddSignatureFabric/ReplaceFabricCanvas';
import { clearCanvas, initializeCanvas, selectReplaceCanvasAction } from 'store/replaceCanvasReducer';
import { AllCanvasTypes } from 'utils';
import CreateNewCanvasWithPdfLoad from './CreateNewCanvasWithPdfLoad';
import { useSelector } from 'react-redux';
import ForwardIcon from 'app/assets/icons/ForwardIcon';
import BackwardIcon from 'app/assets/icons/BackwardIcon';
import SignMarker from './SignMarker';

const ReplacePlaceholderWithValueCanvas = ({ isSignCanvas }) => {
  return (
    <CanvasCard canvasRdxAction={selectReplaceCanvasAction} isSignCanvas={isSignCanvas}>
      <SignMarker />
      <CreateNewCanvasWithPdfLoad
        canvasRdxAction={selectReplaceCanvasAction}
        id={AllCanvasTypes.addSignCanvas}
        initializeCanvasRdx={initializeCanvas}
        clearCanvasRdx={clearCanvas}
      />
      {/* <ReplaceFabricCanvas uploadDocument={uploadDocument} /> */}
    </CanvasCard>
  );
};

export default ReplacePlaceholderWithValueCanvas;
