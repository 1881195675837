// fabricSlice.js
import { createSlice } from '@reduxjs/toolkit';
import { AllCanvasTypes } from 'utils';

const initialState = {
  canvas: null
};

const fabricSlice = createSlice({
  name: AllCanvasTypes.placeHolderCanvas,
  initialState,
  reducers: {
    initializeCanvas(state, action) {
      state.canvas = action.payload.canvas;
    },
    clearCanvas(state) {
      if (state.canvas) {
        state.canvas.clear();
        state.canvas.dispose();
        state.canvas = null;
      }
    }
    // Add other Fabric.js canvas manipulation methods here
  }
});

export const { initializeCanvas, clearCanvas } = fabricSlice.actions;

export const selectCanvasAction = (state) => state[AllCanvasTypes.placeHolderCanvas].canvas;

export default fabricSlice.reducer;
