import React from 'react';

const HardDriveSecureSignatureIcon = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_6307_39867)">
        <path
          d="M26 18H6C5.44772 18 5 18.4477 5 19V25C5 25.5523 5.44772 26 6 26H26C26.5523 26 27 25.5523 27 25V19C27 18.4477 26.5523 18 26 18Z"
          stroke="black"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M26 6H6C5.44772 6 5 6.44772 5 7V13C5 13.5523 5.44772 14 6 14H26C26.5523 14 27 13.5523 27 13V7C27 6.44772 26.5523 6 26 6Z"
          stroke="black"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M22.5 11.5C23.3284 11.5 24 10.8284 24 10C24 9.17157 23.3284 8.5 22.5 8.5C21.6716 8.5 21 9.17157 21 10C21 10.8284 21.6716 11.5 22.5 11.5Z"
          fill="black"
        />
        <path
          d="M22.5 23.5C23.3284 23.5 24 22.8284 24 22C24 21.1716 23.3284 20.5 22.5 20.5C21.6716 20.5 21 21.1716 21 22C21 22.8284 21.6716 23.5 22.5 23.5Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_6307_39867">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default HardDriveSecureSignatureIcon;
