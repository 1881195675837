import UserStatusArrowIcon from 'app/assets/icons/UserStatusArrowIcon';
import classNames from 'classnames';
import React from 'react';

const EsignAvatarWithDescr = ({ initials, bgColor, textColor, title, description, showArrow, date, isUnread }) => {
  const isActive = true;
  return (
    <div className="flex gap-[10px] w-full items-start">
      <div className="flex flex-col items-center justify-center w-[40px] gap-[10px]">
        <div
          className="w-[40px] h-[40px] rounded-full  flex items-center justify-center text-[16px] font-[600] uppercase"
          style={{
            backgroundColor: bgColor,
            color: textColor
          }}
        >
          {initials}
        </div>
        <div>{showArrow && <UserStatusArrowIcon />}</div>
      </div>

      <div className="flex justify-between h-[100%] items-start w-full">
        <div className="">
          <h2 className="text-[14px] font-[500] text-[#101010] mb-[4px]">{title}</h2>
          <span className={classNames('text-[14px] font-[600] ', { 'text-[#444444]': !isActive }, { 'text-[#3B82F6]': isActive })}>
            {description}
          </span>
        </div>
      </div>
      <div className="flex items-center gap-[10px]">
        <h2
          className="text-[#A0A0A0] text-[14px] font-[500]"
          style={{
            inlineSize: 'max-content'
          }}
        >
          {date}
        </h2>
        {isUnread && <div className="w-[8px] h-[8px] bg-[#3B82F6] rounded-full"></div>}
      </div>
    </div>
  );
};

export default EsignAvatarWithDescr;
