import React, { useEffect } from 'react';
import classnames from 'classnames';
import { convertObjectIdToDate } from 'utils/esign.helper';
import classNames from 'classnames';
import { useAccount } from 'context/AccountProvider';
import UploadIcon from 'assets/images/eSign/UploadIcon';
import DropdownIcon from 'assets/images/eSign/DropdownIcon';
import { useNavigate } from 'react-router';
import EsignTableActions from './EsignList/EsignTableActions';
import { downloadEsignDoc } from 'services/eSignService';
import { EsignTable } from './EsignList/EsignTable';

const StatusConst = {
  0: { title: 'Needs to Sign', color: '#216DDE' },
  1: { title: 'Completed', color: 'green' },
  2: { title: 'Waiting for others', color: '#216DDE' }
};

const ToggleButton = ({ onClick, title, isActive }) => {
  return (
    <button
      className={classnames(
        ' text-[13px] w-[130px] uppercase h-full font-medium',
        {
          'border-1 border-[#216DDE] text-[#216DDE] rounded bg-[#E9F0FC]': isActive
        },
        { 'text-[#A0A0A0]': !isActive }
      )}
      onClick={onClick}
    >
      {title}
    </button>
  );
};

const people = Array(20).fill({
  name: 'Lindsay Walton',
  title: 'Front-end Developer',
  email: 'lindsay.walton@example.com',
  role: 'Member'
});

const EsignTableStatusColumn = () => {
  return (
    <>
      <div className="w-full flex justify-end items-center">
        <EsignTableActions />
      </div>
    </>
  );
};

export function Table({ list, setShowCreateModal }) {
  const {
    userDetails: { email }
  } = useAccount();
  const navigate = useNavigate();
  if (!email) return;

  const handleDoSign = (e, docId) => {
    e.stopPropagation();
    window.location.href = `/dashboard/add-digi-esign/${docId}`;
    // navigate(`/dashboard/add-digi-esign/${docId}`);
  };
  const handleDropdownClick = (e) => {
    e.stopPropagation();
  };

  const handleDownload = async (e, docId) => {
    e.stopPropagation();
    try {
      // Fetch the document data
      const response = await downloadEsignDoc(docId);

      // If response.data is a Blob, use it directly
      const blob = new Blob([response], { type: 'application/pdf' });

      // Create a link element
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'downloaded-file.pdf'; // Specify the filename for the download
      link.click();

      // Clean up the URL object
      URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error('Download failed:', error);
    }
  };
  return (
    <div>
      <div className="sm:flex sm:items-center justify-end">
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <button
            type="button"
            className="  bg-[#216DDE] px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:opacity-90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 border-b-[4px] border-[#184EA0] w-[140px] flex items-center gap-3 justify-center text-[15px] rounded-xl"
            onClick={() => setShowCreateModal(true)}
          >
            <UploadIcon fill={'white'} />
            Upload
          </button>
        </div>
      </div>
      <div className="mt-2 flow-root ">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div
              className=" shadow ring-1 ring-black ring-opacity-5 rounded-[10px] esignMainListContainer"
              style={{
                height: 'calc(100vh - 240px)',
                overflow: 'auto'
              }}
            >
              <table className="min-w-full divide-y divide-gray-300">
                <colgroup>
                  <col className="w-3/6" />
                  {/* <col className="sm:w-1/6" /> */}
                  <col className="sm:w-1/6" />
                  <col className="sm:w-1/6" />
                </colgroup>
                <thead className="bg-[#E2E2E2]">
                  <tr className="">
                    <th
                      scope="col"
                      className="sticky border-r top-0 z-10 border-b border-gray-300 bg-white  py-3.5 pl-4 pr-3 text-[16px] font-[700] text-[#303030]   backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                    >
                      Name
                    </th>

                    <th
                      scope="col"
                      className="sticky border-r top-0 z-10 hidden border-b border-gray-300 bg-white  px-3 py-3.5 text-[16px] font-[700] text-[#303030]   backdrop-blur backdrop-filter lg:table-cell"
                    >
                      Last Updated
                    </th>
                    <th
                      scope="col"
                      className="sticky border-r top-0 z-10 hidden border-b border-gray-300 bg-white  px-3 py-3.5 text-[16px] font-[700] text-[#303030]   backdrop-blur backdrop-filter sm:table-cell"
                    >
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {list.map((person) => {
                    const status = person?.sign_configs?.[email] || [];
                    const status2 = person?.signs?.[email] || [];
                    let statusIndex = 2;
                    if (status?.length > 0 && status?.length === status2?.length) {
                      statusIndex = 1;
                    } else if (status?.length > 0 && status?.length >= status2?.length) {
                      statusIndex = 0;
                    }

                    return (
                      <tr
                        key={person?._id?.$oid}
                        className="cursor-pointer hover:bg-[#F8F9FA]"
                        onClick={(e) => handleDoSign(e, person?._id?.$oid)}
                      >
                        <td className="whitespace-nowrap py-3 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          <h4 className="text-[16px]">{person?.document_name?.split('.')[0]}</h4>
                          {/* <h4 className="text-[14px] mb-1">{'Social Media Agreement '}</h4> */}
                          <h5 className="text-[12px] font-[400] text-[#606060]">To: Vipul Saklani, Joseph Pokkat</h5>
                        </td>
                        {/* <td
                          className={classNames('whitespace-nowrap px-3 py-3 text-sm text-gray-500 border-l font-[400] font-custom')}
                          style={{
                            color: StatusConst[statusIndex]?.color
                          }}
                        >
                          {StatusConst[statusIndex]?.title}
                        </td> */}
                        <td className="whitespace-nowrap px-3 py-3 text-sm text-gray-500 border-l">
                          <h4 className="text-[14px] mb-1 text-gray-900 font-medium">
                            {person.updated_at?.$date ? convertObjectIdToDate(person.updated_at.$date, true)?.date : '-'}
                          </h4>
                          <h5 className="text-[12px] font-[400] text-[#606060]">
                            {person.updated_at?.$date ? convertObjectIdToDate(person.updated_at.$date, true)?.time : '-'}
                          </h5>
                        </td>
                        <td className="whitespace-nowrap px-3 py-3 text-sm text-gray-500 border-l flex justify-center">
                          {statusIndex === 0 ? (
                            <div className="flex justify-between items-center">
                              <button
                                type="button"
                                className="  bg-[white] px-3 h-[40px] text-center text-sm font-semibold text-[#216DDE] shadow-sm hover:opacity-90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 border-1  border-[#216DDE] w-[140px] flex items-center gap-3 justify-center text-[16px] rounded-[10px]"
                                onClick={(e) => handleDoSign(e, person?._id?.$oid)}
                              >
                                Sign
                              </button>
                              {/* <button
                                className=" bg-[white] px-3 h-[40px] text-center text-sm font-semibold text-[#216DDE] shadow-sm hover:opacity-90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 border-1  border-[#216DDE] flex items-center gap-3 justify-center text-[16px] rounded-lg rounded-tl-none rounded-bl-none"
                                onClick={handleDropdownClick}
                              >
                                <DropdownIcon fill="#216DDE" />
                              </button> */}
                            </div>
                          ) : (
                            <div className="flex justify-between items-center">
                              {/* <button
                                type="button"
                                className="  bg-[white] px-3 h-[40px] text-center text-sm font-medium text-[#404040] shadow-sm hover:opacity-90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 border-1  border-[#404040] w-[140px] flex items-center gap-3 justify-center text-[16px] rounded-[10px]"
                                onClick={(e) => handleDoSign(e, person?._id?.$oid)}
                              >
                                View
                              </button> */}
                              <button
                                type="button"
                                className="  bg-[white] px-3 h-[40px] text-center text-sm font-medium text-[#404040] shadow-sm hover:opacity-90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 border-1  border-[#404040] w-[140px] flex items-center gap-3 justify-center text-[16px] rounded-[10px]"
                                onClick={(e) => handleDownload(e, person?._id?.$oid)}
                              >
                                Download
                              </button>
                              {/* <button
                                className=" bg-[white] px-3 h-[40px] text-center text-sm font-medium text-[#404040] shadow-sm hover:opacity-90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 border-1  border-[#404040] flex items-center gap-3 justify-center text-[16px] rounded-lg rounded-tl-none rounded-bl-none"
                                onClick={handleDropdownClick}
                              >
                                <DropdownIcon />
                              </button> */}
                            </div>
                          )}
                        </td>
                        {/* <td className="whitespace-nowrap px-3 py-3 text-sm text-gray-500 border-l flex justify-center">
                          <EsignTableStatusColumn />
                        </td> */}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const AppEsignList = ({ list, setShowCreateModal }) => {
  return (
    <>
      <div className="border-1 border-defaultBorderColor rounded-xl  h-[40px] bg-white flex items-center w-fit py-1 px-2">
        <ToggleButton title={'Home'} />
        <ToggleButton title={'Templates'} />
        <ToggleButton title={'Documents'} isActive={true} />
      </div>
      <div>
        <Table list={list} setShowCreateModal={setShowCreateModal} />
        {/* <EsignTable list={list} setShowCreateModal={setShowCreateModal} /> */}
        {/* <Example /> */}
      </div>
    </>
  );
};

export default AppEsignList;
