import React from 'react';

const CrossIcon = ({ width = 24, height = 24, stroke = '#9CA3AF' }) => {
  return (
    <svg width={width} height={width} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_3700_40180)">
        <path d="M18 6L6 18" stroke={stroke} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M6 6L18 18" stroke={stroke} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_3700_40180">
          <rect width={width} height={width} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CrossIcon;
