import React, { useState } from 'react';
import Joyride from 'react-joyride';
import CustomJoyrideTooltip from './CustomJoyrideTooltip';

const withTour =
  (steps, options = {}) =>
  (WrappedComponent) => {
    return function WithTour(props) {
      const [runTour, setRunTour] = useState(false);

      const handleJoyrideCallback = (data) => {
        const { status } = data;
        const finishedStatuses = ['finished', 'skipped'];

        if (finishedStatuses.includes(status)) {
          localStorage.setItem('placeholderTourSeen', 'true');
          setRunTour(false);
        }

        if (props.onTourComplete) {
          props.onTourComplete(data);
        }
      };

      const startTour = () => {
        setRunTour(true);
      };

      return (
        <>
          <Joyride
            steps={steps}
            run={runTour}
            continuous={true}
            showProgress={true}
            showSkipButton={true}
            callback={handleJoyrideCallback}
            tooltipComponent={CustomJoyrideTooltip}
            {...options}
            locale={{
              last: 'Done'
            }}
          />
          <WrappedComponent {...props} startTour={startTour} />
        </>
      );
    };
  };

export default withTour;
