import React from 'react';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import ArrowDownIcon from 'app/assets/icons/ArrowDownIcon';

export default function CommonDropdown({ items, buttonLabel, className = '', itemClassName = '', onSelect, dropTopTitle }) {
  return (
    <>
      <Menu as="div" className={`relative inline-block text-left  ${className}`}>
        {dropTopTitle ? <button className="z-1 dropdownTitle">{dropTopTitle}</button> : null}

        <MenuButton className=" flex items-center justify-between h-[56px] bg-[white] border-1 border-[#D4D4D8] rounded-[6px] px-[12px] py-[6px] w-[150px] esignCommonDropdownBtn">
          <h4 className="text-[#444444] text-[14px] font-[500]">{buttonLabel}</h4>
          <ArrowDownIcon />
        </MenuButton>

        <MenuItems
          transition
          className={`z-20 absolute right-0 mt-2 w-[150px] origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in border-1 shadow-none commonDropdownItemContainerDefault ${itemClassName}`}
          style={{
            zIndex: 999
          }}
        >
          <div>
            {items.map((item) => (
              <MenuItem key={item.id} onClick={() => onSelect(item)}>
                <div
                  className={`flex items-center justify-between px-[16px] py-2 text-sm text-gray-700 data-[focus]:bg-[#3B82F6] data-[focus]:text-[white] cursor-pointer commonDropdownItemDefault`}
                >
                  {item.title}
                  {item?.img && <img src={item?.img} width={49} height={20} />}
                </div>
              </MenuItem>
            ))}
          </div>
        </MenuItems>
      </Menu>
    </>
  );
}
