import React from 'react';

const DigiEsignIntegrationIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_6210_15437)">
        <path d="M13.5 13.5L11.25 15.75" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M10.5 10.5L8.25 12.75" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M6 10.5L13.5 18" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M5.44313 18.5547L2.25 21.7478" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path
          d="M12.3763 16.875L9.65755 19.5938C9.2356 20.0157 8.66333 20.2527 8.06661 20.2527C7.4699 20.2527 6.89763 20.0157 6.47568 19.5938L4.40755 17.5228C3.98649 17.101 3.75 16.5293 3.75 15.9333C3.75 15.3373 3.98649 14.7656 4.40755 14.3438L7.1263 11.625"
          stroke="black"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path d="M10.5 6L18 13.5" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M18.5566 5.44313L21.7498 2.25" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path
          d="M16.875 12.3763L19.5938 9.65755C20.0157 9.2356 20.2527 8.66333 20.2527 8.06661C20.2527 7.4699 20.0157 6.89763 19.5938 6.47568L17.5228 4.40755C17.101 3.98649 16.5293 3.75 15.9333 3.75C15.3373 3.75 14.7656 3.98649 14.3438 4.40755L11.625 7.1263"
          stroke="black"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_6210_15437">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DigiEsignIntegrationIcon;
