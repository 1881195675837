import React from 'react';

const SuccessSignedDocIcon = () => {
  return (
    <svg width="304" height="281" viewBox="0 0 304 281" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_3700_37678)">
        <path
          d="M139.579 154.225C139.525 154.225 139.49 154.223 139.479 154.223L132.979 154.223V153.265H139.502C139.653 153.27 142.734 153.357 145.573 149.912C149.804 144.778 153.279 132.068 145.804 100.675C135.754 58.463 144.136 21.1707 148.207 7.09661C148.407 6.40031 148.443 5.66691 148.311 4.95435C148.18 4.2418 147.884 3.56962 147.448 2.99093C147.012 2.41223 146.447 1.94287 145.799 1.61994C145.15 1.29701 144.435 1.12935 143.711 1.13021H46.6436C45.9056 1.1312 45.1783 1.30618 44.5206 1.64094C43.8629 1.97571 43.2934 2.46084 42.8583 3.05694C41.134 5.4289 38.7677 9.98566 37.2869 18.225L36.3438 18.0556C37.8566 9.63727 40.2999 4.94646 42.0832 2.49354C42.6073 1.77527 43.2934 1.19071 44.0857 0.787314C44.8781 0.383922 45.7544 0.173069 46.6436 0.171878H143.711C144.584 0.171037 145.445 0.373264 146.227 0.762579C147.008 1.15189 147.689 1.71763 148.214 2.4151C148.74 3.11256 149.096 3.92265 149.254 4.78134C149.413 5.64004 149.369 6.52382 149.127 7.36287C145.078 21.3639 136.739 58.4627 146.737 100.454C154.316 132.288 150.672 145.273 146.281 150.561C143.385 154.048 140.193 154.225 139.579 154.225Z"
          fill="#E6E6E6"
        />
        <path
          d="M122.089 171.717C122.034 171.717 122 171.715 121.988 171.715L115.489 171.715V170.757H122.012C122.164 170.762 125.244 170.85 128.083 167.405C132.314 162.27 135.789 149.561 128.314 118.168C118.263 75.9552 126.646 38.6629 130.716 24.5888C130.917 23.8925 130.953 23.1591 130.821 22.4465C130.69 21.734 130.394 21.0618 129.958 20.4831C129.522 19.9044 128.957 19.4351 128.309 19.1121C127.66 18.7892 126.945 18.6215 126.22 18.6224H29.1534C28.4154 18.6234 27.6881 18.7984 27.0304 19.1331C26.3727 19.4679 25.8032 19.953 25.3682 20.5491C23.6438 22.9211 21.2775 27.4778 19.7968 35.7172L18.8535 35.5478C20.3664 27.1295 22.8097 22.4386 24.593 19.9857C25.1171 19.2675 25.8032 18.6829 26.5956 18.2795C27.3879 17.8761 28.2643 17.6653 29.1534 17.6641H126.22C127.094 17.6632 127.955 17.8655 128.737 18.2548C129.518 18.6441 130.199 19.2098 130.724 19.9073C131.249 20.6047 131.605 21.4148 131.764 22.2735C131.922 23.1322 131.879 24.016 131.637 24.8551C127.588 38.8561 119.249 75.9549 129.246 117.946C136.826 149.78 133.182 162.765 128.791 168.053C125.894 171.54 122.703 171.717 122.089 171.717Z"
          fill="#CCCCCC"
        />
        <path
          d="M104.599 189.209C104.545 189.209 104.51 189.208 104.499 189.207L8.92759 189.208C8.00745 189.209 7.10108 188.984 6.28778 188.554C5.47447 188.124 4.77903 187.5 4.26231 186.739C3.74559 185.978 3.42334 185.101 3.32376 184.187C3.22418 183.272 3.35031 182.347 3.6911 181.492C7.15874 172.828 11.8277 152.043 4.13746 110.173C-4.9434 60.7331 3.18638 42.8652 7.10317 37.4779C7.62727 36.7596 8.31336 36.1751 9.10575 35.7717C9.89814 35.3683 10.7745 35.1574 11.6637 35.1563H108.731C109.604 35.1554 110.465 35.3576 111.247 35.747C112.029 36.1363 112.709 36.702 113.234 37.3995C113.76 38.0969 114.116 38.907 114.274 39.7657C114.433 40.6244 114.389 41.5082 114.147 42.3472C110.098 56.3483 101.759 93.4471 111.757 135.438C119.336 167.272 115.693 180.258 111.301 185.545C108.405 189.032 105.213 189.209 104.599 189.209ZM11.6637 36.1146C10.9257 36.1156 10.1983 36.2905 9.5406 36.6253C8.88289 36.9601 8.31338 37.4452 7.8783 38.0413C4.03416 43.3287 -3.93383 60.9249 5.07998 110C12.4265 149.998 8.86347 171.148 4.58075 181.848C4.29211 182.554 4.18378 183.321 4.26551 184.079C4.34724 184.837 4.61648 185.563 5.04892 186.192C5.47771 186.826 6.05558 187.345 6.73183 187.704C7.40808 188.062 8.16204 188.25 8.92757 188.249H104.522C104.673 188.257 107.754 188.342 110.594 184.897C114.824 179.763 118.299 167.053 110.824 135.66C100.774 93.4473 109.156 56.1551 113.227 42.081C113.427 41.3847 113.463 40.6513 113.331 39.9387C113.2 39.2262 112.904 38.554 112.468 37.9753C112.032 37.3966 111.467 36.9272 110.819 36.6043C110.17 36.2814 109.455 36.1137 108.731 36.1146H11.6637Z"
          fill="#3F3D56"
        />
        <path
          d="M92.2217 78.276H16.0345C15.0178 78.276 14.0428 77.8722 13.3239 77.1533C12.605 76.4344 12.2012 75.4594 12.2012 74.4427C12.2012 73.426 12.605 72.451 13.3239 71.7321C14.0428 71.0132 15.0178 70.6094 16.0345 70.6094H92.2217C93.2384 70.6094 94.2134 71.0132 94.9323 71.7321C95.6512 72.451 96.0551 73.426 96.0551 74.4427C96.0551 75.4594 95.6512 76.4344 94.9323 77.1533C94.2134 77.8722 93.2384 78.276 92.2217 78.276Z"
          fill="#3B82F6"
        />
        <path
          d="M92.2217 94.5729H16.0345C15.0178 94.5729 14.0428 94.169 13.3239 93.4501C12.605 92.7313 12.2012 91.7562 12.2012 90.7396C12.2012 89.7229 12.605 88.7479 13.3239 88.029C14.0428 87.3101 15.0178 86.9062 16.0345 86.9062H92.2217C93.2384 86.9062 94.2134 87.3101 94.9323 88.029C95.6512 88.7479 96.0551 89.7229 96.0551 90.7396C96.0551 91.7562 95.6512 92.7313 94.9323 93.4501C94.2134 94.169 93.2384 94.5729 92.2217 94.5729Z"
          fill="#CCCCCC"
        />
        <path
          d="M92.2217 110.862H16.0345C15.0178 110.862 14.0428 110.458 13.3239 109.739C12.605 109.02 12.2012 108.045 12.2012 107.029C12.2012 106.012 12.605 105.037 13.3239 104.318C14.0428 103.599 15.0178 103.195 16.0345 103.195H92.2217C93.2384 103.195 94.2134 103.599 94.9323 104.318C95.6512 105.037 96.0551 106.012 96.0551 107.029C96.0551 108.045 95.6512 109.02 94.9323 109.739C94.2134 110.458 93.2384 110.862 92.2217 110.862Z"
          fill="#CCCCCC"
        />
        <path
          d="M97.9717 127.151H21.7845C20.7678 127.151 19.7928 126.747 19.0739 126.028C18.355 125.309 17.9512 124.334 17.9512 123.318C17.9512 122.301 18.355 121.326 19.0739 120.607C19.7928 119.888 20.7678 119.484 21.7845 119.484H97.9717C98.9884 119.484 99.9634 119.888 100.682 120.607C101.401 121.326 101.805 122.301 101.805 123.318C101.805 124.334 101.401 125.309 100.682 126.028C99.9634 126.747 98.9884 127.151 97.9717 127.151Z"
          fill="#CCCCCC"
        />
        <path
          d="M97.9717 143.448H21.7845C20.7678 143.448 19.7928 143.044 19.0739 142.325C18.355 141.606 17.9512 140.631 17.9512 139.615C17.9512 138.598 18.355 137.623 19.0739 136.904C19.7928 136.185 20.7678 135.781 21.7845 135.781H97.9717C98.9884 135.781 99.9634 136.185 100.682 136.904C101.401 137.623 101.805 138.598 101.805 139.615C101.805 140.631 101.401 141.606 100.682 142.325C99.9634 143.044 98.9884 143.448 97.9717 143.448Z"
          fill="#CCCCCC"
        />
        <path
          d="M51.9719 61.987H16.0345C15.0178 61.987 14.0428 61.5831 13.3239 60.8642C12.605 60.1453 12.2012 59.1703 12.2012 58.1536C12.2012 57.137 12.605 56.162 13.3239 55.4431C14.0428 54.7242 15.0178 54.3203 16.0345 54.3203H51.9719C52.9885 54.3203 53.9636 54.7242 54.6824 55.4431C55.4013 56.162 55.8052 57.137 55.8052 58.1536C55.8052 59.1703 55.4013 60.1453 54.6824 60.8642C53.9636 61.5831 52.9885 61.987 51.9719 61.987Z"
          fill="#3B82F6"
        />
        <path
          d="M47.3301 169.718C48.1035 166.443 51.7374 165.369 54.7084 165.489C56.5855 165.616 58.4475 165.91 60.2719 166.369C62.181 166.797 64.0715 167.302 65.9524 167.839C69.4017 168.822 72.8154 170.012 76.3214 170.781C79.2402 171.421 82.4122 171.797 85.323 170.913C88.121 170.063 90.4948 167.977 91.2561 165.094C91.9735 162.376 90.9885 159.405 88.3551 158.163C85.9537 157.03 82.9202 157.606 81.73 160.167C80.3879 163.055 81.7288 166.204 83.8538 168.294C85.0616 169.428 86.3846 170.433 87.8011 171.292C89.2389 172.272 90.8391 172.989 92.5275 173.409C95.2994 174.001 98.6015 173.129 100.031 170.481C100.349 169.881 100.54 169.223 100.592 168.546C100.677 167.625 99.2391 167.631 99.155 168.546C98.9126 171.183 95.8244 172.418 93.515 172.129C91.9561 171.871 90.4675 171.293 89.1425 170.432C87.8637 169.686 86.652 168.831 85.5209 167.877C83.5621 166.181 81.726 163.392 83.0415 160.747C84.1127 158.593 86.9052 158.615 88.6036 160.004C90.6604 161.686 90.2936 164.739 88.937 166.737C87.4349 168.949 84.7124 169.876 82.1454 169.976C79.3561 170.085 76.5902 169.444 73.9245 168.687C70.6122 167.747 67.3329 166.694 64.0047 165.809C62.1153 165.306 60.2112 164.852 58.2877 164.499C56.4689 164.098 54.6007 163.969 52.744 164.116C49.7444 164.462 46.6825 166.208 45.9439 169.336C45.7315 170.235 47.1172 170.619 47.3301 169.718Z"
          fill="#3F3D56"
        />
        <path
          d="M194.196 250.985C194.278 260.42 194.969 269.84 196.265 279.187C196.31 279.515 196.359 279.843 196.405 280.171L284.764 279.677C285.023 279.351 285.279 279.022 285.526 278.688C286.351 277.583 287.124 276.447 287.834 275.271C291.245 269.609 292.807 262.98 291.242 258.406L291.214 258.334C290.876 257.333 290.344 256.408 289.648 255.613C286.079 251.596 278.865 252.265 272.317 255.508C278.19 249.08 282.866 240.968 283.221 233.728C283.569 226.666 279.877 221.621 275.872 217.334C275.74 217.192 275.609 217.054 275.477 216.917C275.414 216.846 275.346 216.779 275.282 216.708C272.158 213.441 268.514 210.22 263.241 210.61C257.458 211.038 251.046 215.882 246.55 221.526C242.055 227.167 239.067 233.575 236.039 239.829C233.007 246.079 229.726 252.489 224.861 257.831C231.451 249.495 236.764 240.011 238.727 230.787C240.69 221.563 238.962 212.704 233.089 208.36C231.306 207.082 229.243 206.249 227.072 205.932C226.818 205.89 226.561 205.856 226.3 205.826C220.862 205.207 214.371 207.087 208.848 211.582C202.765 216.531 198.548 223.868 196.45 230.833C194.351 237.798 194.128 244.503 194.196 250.985Z"
          fill="#F2F2F2"
        />
        <path
          d="M252.004 279.862L253.694 279.852C254.23 279.517 254.769 279.185 255.311 278.858C255.646 278.646 255.985 278.443 256.324 278.235C261.929 274.812 267.568 271.529 273.243 268.385C278.912 265.239 284.61 262.238 290.336 259.382C290.488 259.315 290.612 259.198 290.686 259.05C290.76 258.902 290.781 258.733 290.743 258.572C290.737 258.551 290.728 258.531 290.715 258.513C290.635 258.363 290.457 258.3 290.196 258.429C289.494 258.781 288.787 259.133 288.085 259.493C282.343 262.398 276.626 265.448 270.933 268.644C265.244 271.837 259.588 275.172 253.964 278.648C253.846 278.72 253.727 278.796 253.609 278.867C253.073 279.199 252.54 279.53 252.004 279.862Z"
          fill="white"
        />
        <path
          d="M230.219 279.991L231.264 279.985C231.499 279.655 231.731 279.325 231.966 278.996C237.503 271.232 243.038 263.468 248.572 255.704C257.635 242.994 266.695 230.283 275.753 217.573C275.806 217.504 275.846 217.426 275.87 217.342C275.948 217.061 275.741 216.896 275.476 216.925C275.364 216.941 275.257 216.981 275.162 217.041C275.067 217.102 274.986 217.182 274.924 217.277C269.843 224.405 264.763 231.53 259.685 238.653C250.669 251.3 241.655 263.945 232.641 276.59C232.068 277.392 231.495 278.199 230.922 279.001C230.686 279.331 230.45 279.661 230.219 279.991Z"
          fill="white"
        />
        <path
          d="M206.944 273.92C206.997 275.68 207.113 277.413 207.266 279.13C207.295 279.458 207.325 279.786 207.354 280.115L208.45 280.109C208.417 279.78 208.383 279.452 208.354 279.124C208.122 276.644 207.958 274.136 207.936 271.559C207.906 264.417 208.603 257.29 210.013 250.288C211.512 242.749 213.665 235.355 216.447 228.19C219.367 220.632 222.887 213.321 226.974 206.325C227.051 206.211 227.086 206.074 227.071 205.937C227.026 205.624 226.61 205.532 226.299 205.83C226.238 205.889 226.185 205.956 226.142 206.029C225.636 206.899 225.14 207.771 224.653 208.645C220.716 215.68 217.347 223.017 214.577 230.586C211.938 237.775 209.931 245.181 208.578 252.718C207.311 259.711 206.763 266.815 206.944 273.92Z"
          fill="white"
        />
        <path
          d="M100.148 170.056C99.9664 169.905 99.8335 169.703 99.7663 169.476C99.699 169.25 99.7004 169.008 99.7702 168.782C99.8399 168.556 99.9751 168.356 100.158 168.207C100.342 168.057 100.565 167.966 100.801 167.943C103.414 167.691 106.052 167.961 108.56 168.738L134.107 176.628C134.437 176.73 134.745 176.897 135.011 177.117C135.278 177.338 135.498 177.61 135.659 177.916C135.821 178.222 135.921 178.557 135.953 178.901C135.985 179.246 135.949 179.593 135.847 179.924C135.745 180.255 135.579 180.562 135.358 180.828C135.137 181.095 134.866 181.315 134.56 181.477C134.254 181.638 133.919 181.738 133.574 181.77C133.23 181.802 132.882 181.766 132.551 181.664L107.004 173.775C104.495 173.002 102.164 171.738 100.148 170.056Z"
          fill="#3F3D56"
        />
        <path
          d="M204.868 202.826C204.174 202.535 203.552 202.096 203.046 201.538C202.541 200.981 202.163 200.32 201.941 199.601C201.719 198.882 201.657 198.123 201.759 197.377C201.862 196.632 202.127 195.918 202.535 195.286L198.156 172.386L209.317 171.516L209.99 193.915C211.007 194.672 211.71 195.775 211.968 197.015C212.225 198.256 212.019 199.548 211.387 200.646C210.756 201.745 209.743 202.573 208.542 202.975C207.34 203.377 206.033 203.324 204.868 202.826Z"
          fill="#FFB8B8"
        />
        <path
          d="M197.468 191.007L186.101 138.067C185.634 135.893 186.05 133.622 187.258 131.755C188.465 129.887 190.365 128.576 192.539 128.109C194.714 127.642 196.984 128.058 198.852 129.265C200.72 130.473 202.031 132.373 202.498 134.547L213.865 187.486C213.984 188.045 213.877 188.629 213.567 189.109C213.257 189.589 212.768 189.926 212.21 190.047L200.029 192.662C199.752 192.722 199.466 192.726 199.188 192.675C198.909 192.624 198.643 192.519 198.405 192.365C198.168 192.211 197.962 192.012 197.802 191.779C197.641 191.546 197.527 191.284 197.468 191.007Z"
          fill="#3B82F6"
        />
        <path d="M178.81 274.284H172.936L170.141 251.625L178.811 251.625L178.81 274.284Z" fill="#FFB8B8" />
        <path
          d="M168.741 272.609H180.07V279.743H161.607C161.607 278.806 161.792 277.878 162.15 277.013C162.509 276.147 163.034 275.361 163.697 274.699C164.359 274.036 165.145 273.511 166.011 273.152C166.876 272.794 167.804 272.609 168.741 272.609Z"
          fill="#2F2E41"
        />
        <path d="M198.456 273.807H192.582L189.787 251.148L198.458 251.149L198.456 273.807Z" fill="#FFB8B8" />
        <path
          d="M188.387 272.133H199.716V279.266H181.254C181.254 278.329 181.438 277.402 181.797 276.536C182.155 275.671 182.681 274.884 183.343 274.222C184.006 273.56 184.792 273.034 185.657 272.676C186.523 272.317 187.45 272.133 188.387 272.133Z"
          fill="#2F2E41"
        />
        <path
          d="M171.773 270.062C171.553 269.882 171.371 269.659 171.238 269.407C171.105 269.155 171.023 268.879 170.998 268.595L164.169 193.472C164.143 193.18 164.176 192.886 164.267 192.608C164.357 192.33 164.504 192.073 164.697 191.853C164.891 191.633 165.127 191.455 165.391 191.329C165.656 191.203 165.943 191.133 166.236 191.122L200.486 189.837C200.783 189.826 201.078 189.877 201.354 189.985C201.63 190.093 201.881 190.257 202.091 190.467C202.301 190.677 202.465 190.927 202.574 191.203C202.682 191.479 202.733 191.775 202.722 192.071L199.984 266.906C199.964 267.43 199.754 267.928 199.393 268.309C199.033 268.689 198.546 268.926 198.024 268.974L191.118 269.602C190.832 269.628 190.543 269.597 190.269 269.509C189.995 269.422 189.741 269.281 189.523 269.094C189.304 268.908 189.125 268.679 188.996 268.422C188.867 268.165 188.791 267.885 188.772 267.598L185.602 220.057C185.6 219.965 185.58 219.875 185.542 219.792C185.505 219.709 185.451 219.633 185.384 219.571C185.318 219.509 185.239 219.46 185.154 219.428C185.068 219.397 184.977 219.382 184.886 219.386H184.883C184.791 219.383 184.7 219.397 184.614 219.429C184.528 219.462 184.449 219.511 184.383 219.574C184.316 219.637 184.263 219.712 184.226 219.796C184.189 219.88 184.169 219.971 184.167 220.062L181.331 267.924C181.303 268.436 181.092 268.922 180.736 269.292C180.381 269.662 179.904 269.893 179.393 269.942L173.36 270.545C173.077 270.575 172.79 270.547 172.518 270.464C172.245 270.381 171.992 270.245 171.773 270.062Z"
          fill="#2F2E41"
        />
        <path
          d="M184.947 116.858C191.446 116.858 196.715 111.589 196.715 105.089C196.715 98.5894 191.446 93.3203 184.947 93.3203C178.447 93.3203 173.178 98.5894 173.178 105.089C173.178 111.589 178.447 116.858 184.947 116.858Z"
          fill="#FFB8B8"
        />
        <path
          d="M162.283 193.978C162.073 193.74 161.919 193.459 161.832 193.154C161.745 192.849 161.727 192.528 161.779 192.216C163.951 179.009 162.727 159.597 161.317 145.637C160.949 141.934 161.563 138.2 163.097 134.81C164.632 131.419 167.032 128.493 170.057 126.326L176.203 121.058C176.612 120.712 177.133 120.528 177.668 120.54L192.938 120.976C193.488 120.991 194.012 121.217 194.401 121.607L196.76 126.832L196.784 126.85C199.596 128.916 201.852 131.648 203.35 134.799C204.848 137.951 205.541 141.424 205.369 144.91L203.121 191.358C203.092 191.898 202.863 192.408 202.477 192.788C202.092 193.167 201.579 193.389 201.038 193.409L163.976 194.711C163.952 194.712 163.928 194.712 163.905 194.712C163.597 194.713 163.294 194.648 163.014 194.521C162.735 194.394 162.485 194.209 162.283 193.978Z"
          fill="#3B82F6"
        />
        <path
          d="M117.83 174.598C117.811 173.846 117.958 173.099 118.259 172.409C118.561 171.72 119.01 171.105 119.575 170.608C120.14 170.11 120.807 169.743 121.529 169.532C122.251 169.32 123.011 169.27 123.755 169.384L142.857 156.016L148.219 165.842L128.057 175.625C127.783 176.862 127.064 177.955 126.038 178.698C125.011 179.44 123.748 179.781 122.487 179.654C121.227 179.528 120.056 178.943 119.198 178.011C118.339 177.079 117.853 175.865 117.83 174.598Z"
          fill="#FFB8B8"
        />
        <path
          d="M133.905 176.921C133.625 176.879 133.356 176.781 133.114 176.635C132.872 176.488 132.661 176.294 132.494 176.066L125.122 166.022C124.784 165.561 124.643 164.985 124.73 164.419C124.817 163.854 125.124 163.347 125.584 163.008L169.234 130.969C171.027 129.653 173.269 129.104 175.467 129.441C176.556 129.608 177.6 129.987 178.542 130.558C179.484 131.129 180.304 131.88 180.956 132.768C181.607 133.655 182.077 134.663 182.34 135.732C182.602 136.802 182.651 137.912 182.484 139.001C182.317 140.089 181.937 141.134 181.367 142.076C180.796 143.018 180.045 143.838 179.157 144.489L135.508 176.528C135.14 176.8 134.694 176.946 134.237 176.946C134.126 176.946 134.015 176.938 133.905 176.921Z"
          fill="#3B82F6"
        />
        <path
          d="M185.55 115.571C187.111 113.657 186.94 110.742 185.687 108.614C184.435 106.486 182.308 105.02 180.12 103.876C177.932 102.731 175.608 101.831 173.549 100.467C171.49 99.1044 171.58 93.3339 171.031 90.9262L172.339 91.3617C171.836 90.7188 171.675 88.8189 171.173 88.176C172.65 88.322 173.786 89.7249 175.264 89.8708L174.411 87.5821C179.478 87.2698 182.628 90.7907 187.695 90.4783C190.206 90.3235 192.932 90.2256 194.996 91.6633C196.789 92.9127 197.697 95.0751 198.293 97.1781C199.572 101.693 199.77 106.751 197.561 110.892C195.352 115.032 190.261 117.822 185.812 116.327L185.55 115.571Z"
          fill="#2F2E41"
        />
        <path
          d="M120.936 280.005H303.498C303.625 280.005 303.747 279.955 303.837 279.865C303.927 279.775 303.977 279.653 303.977 279.526C303.977 279.399 303.927 279.277 303.837 279.187C303.747 279.097 303.625 279.047 303.498 279.047H120.936C120.809 279.047 120.687 279.097 120.597 279.187C120.508 279.277 120.457 279.399 120.457 279.526C120.457 279.653 120.508 279.775 120.597 279.865C120.687 279.955 120.809 280.005 120.936 280.005Z"
          fill="#3F3D56"
        />
      </g>
      <defs>
        <clipPath id="clip0_3700_37678">
          <rect width="303.976" height="280" fill="white" transform="translate(0 0.171875)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SuccessSignedDocIcon;
