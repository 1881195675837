import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectReplaceCanvasAction } from 'store/replaceCanvasReducer';
import { PlaceHolderTypes, replacePlaceholderwithImage } from 'utils/esign.helper';
import SignaturePad from 'react-signature-canvas';
import { width } from '@mui/system';
import { setPlaceHolderValue } from 'store/mainSlice';
import { setEsignSelectedSignType } from 'store/esignMainSlice';
import CloseIcon from '@mui/icons-material/Close';
import SignConsentPopup from 'app/components/Consent/SignConsentPopup';
import classNames from 'classnames';
import CustomSignTemplate from 'app/pages/RoutesPages/AddSignOnCanvas/RightSideSignNPlaceholderValueAdd/SignActionsList/CustomSignTemplate';
const DrawSignPopup = () => {
  const sigCanvas = useRef(null);
  const canvas = useSelector(selectReplaceCanvasAction);
  const dispatch = useDispatch();
  const [showConsentPopup, setShowConsentPopup] = useState(false);
  const [imgPreview, setImgPreview] = useState('');
  const handleSignDropdownType = () => {
    dispatch(setEsignSelectedSignType({}));
  };

  const increaseResolution = (scaleFactor) => {
    const canvas = sigCanvas?.current?.getTrimmedCanvas();

    if (canvas) {
      // Save the original dimensions
      const originalWidth = canvas.width;
      const originalHeight = canvas.height;

      // Create an offscreen canvas to hold the higher resolution image
      const scaledCanvas = document.createElement('canvas');
      scaledCanvas.width = originalWidth * scaleFactor;
      scaledCanvas.height = originalHeight * scaleFactor;

      // Draw the original canvas onto the scaled canvas
      const ctx = scaledCanvas.getContext('2d');
      ctx.scale(scaleFactor, scaleFactor);
      ctx.drawImage(canvas, 0, 0);

      // Convert scaled canvas to image data URL
      const imgData = scaledCanvas.toDataURL('image/png');

      return imgData;
    }

    return null;
  };
  const create = () => {
    // const URL = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
    const URL = increaseResolution(4);
    setImgPreview(URL);
  };

  const handleClear = () => {
    sigCanvas.current.clear();
  };
  const placeholderWidth = canvas?.getActiveObject()?.width;
  const placeholderHeight = canvas?.getActiveObject()?.height;
  return (
    <>
      {showConsentPopup ? (
        <SignConsentPopup
          onSignClick={create}
          onCancel={() => {
            handleSignDropdownType();
            setShowConsentPopup(false);
          }}
        />
      ) : null}
      <div className="w-full h-[140px] border-[3px] border-dashed rounded-[10px] flex flex-col items-center justify-center gap-[12px] cursor-pointer">
        <CustomSignTemplate
          width={placeholderWidth}
          height={placeholderHeight}
          imageUrl={imgPreview}
          cb={(imageurl) => {
            if (imgPreview) {
              console.log(imageurl, 'imageUrl');
              replacePlaceholderwithImage(imageurl, canvas, (img, enhancedImageResolution) => {
                dispatch(
                  setPlaceHolderValue({ value: { id: img?.id, addedValue: enhancedImageResolution, actionType: PlaceHolderTypes.sign } })
                );
              });
              handleSignDropdownType();
            }

            // setPreviewImageWithContainer(imageurl);
          }}
        />
      </div>

      <div class={classNames('relative z-20', { hidden: showConsentPopup })} aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

        <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <div class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
              <CloseIcon
                color="red"
                className="absolute top-[10px] right-[10px] text-[grey] cursor-pointer"
                onClick={handleSignDropdownType}
              />
              <div class="bg-white px-4 pb-4 sm:p-6 sm:pb-4">
                <div class="sm:flex sm:items-start">
                  <div class="text-left sm:ml-4 sm:mt-0 sm:text-left w-full h-full">
                    <h3 class="text-base font-semibold leading-6 text-gray-900" id="modal-title">
                      Draw your sign
                    </h3>
                    <div class="mt-2">
                      <div className="w-[430px] h-[250]  rounded-xl border-1 border-[#D1D9E0]">
                        <SignaturePad
                          penColor={'black'}
                          canvasProps={{ className: 'sigCanvas relative', height: 250, width: 430 }}
                          ref={sigCanvas}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="bg-gray-50 px-4 py-2 sm:flex sm:flex-row-reverse sm:px-6">
                <button
                  type="button"
                  class="mt-3 inline-flex w-full justify-center rounded-md bg-[#216DDE] px-3 py-2 text-sm font-semibold  shadow-sm ring-1 ring-inset  sm:mt-0 sm:w-auto text-white ml-2"
                  onClick={() => {
                    setShowConsentPopup(true);
                  }}
                >
                  Adopt & Sign
                </button>
                <button
                  type="button"
                  class="mt-3 inline-flex w-full justify-center rounded-md bg-[red] px-3 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300 sm:mt-0 sm:w-auto text-white"
                  onClick={handleClear}
                >
                  Clear
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DrawSignPopup;
