import React from 'react';

const OverlayContainer = ({ children }) => {
  return (
    <>
      <div
        className=" w-[100vw] bg-[black] opacity-50 absolute z-20 top-[-65px] left-[0px] flex flex-col items-center justify-center"
        style={{
          height: ' calc(100vh + 65px)'
        }}
      ></div>
      {children}
    </>
  );
};

export default OverlayContainer;
