import React, { useEffect, useState } from 'react';
import DocumentThumbnail from '../../DashboardCreateDocument/PlaceFields/DocumentThumbnail';
import ReplacePlaceholderWithValueCanvas from 'views/digiEsign/canvas/ReplacePlaceholderWithValueCanvas';
import RightSideSignNPlaceholderValueAdd from '../RightSideSignNPlaceholderValueAdd';
import useRecipientAddSignForUnAuthUser from 'app/hooks/useRecipientAddSignForUnAuthUser';
import useLoadTailwindCss from 'hooks/useLoadTailwindCss';
import ReviewDocumentForRecipient from 'app/components/Consent/ReviewDocumentForRecipient';
import { useSelector } from 'react-redux';
import { divideTextBySpace } from 'utils/esign.helper';
import EsignReciepientFooterFinish from '../FinishButtons';
import DocumentSignedSuccessScreen from 'app/components/EsignSuccessScreen/DocumentSignedSuccessScree';
import { useNavigate } from 'react-router';
import { selectReplaceCanvasAction } from 'store/replaceCanvasReducer';
import { baseApiUrl } from 'services/eSignService';

const AddSignOnCanvasForUnAuthUser = () => {
  const [showConsent, setShowConsent] = useState(true);
  const [setIsSignedSubmitted, setSetIsSignedSubmitted] = useState(false);
  const publicRecipientDetails = useSelector((state) => state.main.publicRecipientDetails);
  const navigate = useNavigate();
  useRecipientAddSignForUnAuthUser();
  useLoadTailwindCss();

  const userEmail = publicRecipientDetails?.email;
  const userDetails = publicRecipientDetails?.sign_configs?.[userEmail]?.[0];
  const getUserFullNameInitials = () => {
    const firstName = divideTextBySpace(userDetails?.userFullName)?.[0];
    const lastName = divideTextBySpace(userDetails?.userFullName)?.[1];
    return `${firstName?.[0] || ''}${lastName?.[0] || ''}`;
  };
  return (
    <>
      {setIsSignedSubmitted ? (
        <DocumentSignedSuccessScreen
          heading="You have successfully signed the document"
          btnTitle={'Create Account'}
          btnOnClick={() => {
            window.location.href = `${baseApiUrl}register`;
          }}
        />
      ) : (
        <>
          {showConsent ? (
            <ReviewDocumentForRecipient
              fullName={userDetails?.userFullName}
              userColor={userDetails?.color}
              userInitials={getUserFullNameInitials()}
              onClose={() => setShowConsent(false)}
            />
          ) : null}

          <div className="h-full w-full flex flex-col items-center justify-start esignAddPlaceFields">
            <div className="w-full h-full flex">
              <div
                className="bg-[#D1D5DB]  p-[14px] overflow-auto min-h-screen "
                style={{
                  maxHeight: 'calc(100vh - 160px)'
                }}
              >
                <DocumentThumbnail canvasRdxFunction={selectReplaceCanvasAction} />
              </div>
              <div className="h-full w-[100%]">
                <ReplacePlaceholderWithValueCanvas isSignCanvas={true} />
              </div>
              <div className="h-screen w-[100%] max-w-[250px] p-[10px]  overflow-auto border-l finishBtnContainer" style={{}}>
                <RightSideSignNPlaceholderValueAdd />
                <EsignReciepientFooterFinish setIsSignedSubmitted={setSetIsSignedSubmitted} />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AddSignOnCanvasForUnAuthUser;
