import React from 'react';

const DropdownIcon = ({ fill = '#404040' }) => {
  return (
    <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.11738 5.598L0.0703811 0.41C0.0703811 0.41 0.0350705 0.25789 0.0703811 0.171852C0.106537 0.0837536 0.243381 0 0.243381 0L11.7574 0.0010004C11.9024 0.0010004 11.9614 0.104 11.9804 0.148C11.9984 0.193 12.0324 0.307 11.9304 0.411L6.86938 5.613C6.42038 6.113 5.59438 6.127 5.11738 5.598Z"
        fill={fill}
      />
    </svg>
  );
};

export default DropdownIcon;
