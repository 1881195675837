import React from 'react';

const FAQImageIcon = () => {
  return (
    <svg width="251" height="157" viewBox="0 0 251 157" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M171.869 149.406H250.114" stroke="#263238" stroke-miterlimit="10" />
      <path d="M22.5586 149.406H168.465" stroke="#263238" stroke-miterlimit="10" />
      <path d="M15.9258 149.406H19.1576" stroke="#263238" stroke-miterlimit="10" />
      <path d="M228.291 82.6562L233.553 133.092" stroke="#263238" stroke-width="2" stroke-miterlimit="10" />
      <path d="M232.707 123.439L230.318 120.922" stroke="#263238" stroke-width="2" stroke-miterlimit="10" />
      <path
        d="M231.044 121.673C231.039 121.288 230.952 120.908 230.789 120.559C230.625 120.211 230.389 119.901 230.095 119.651C229.483 119.103 226.259 117.761 225.937 118.895C225.62 120.12 228.92 122.543 231.044 121.673Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path d="M231.044 121.667L227.975 119.758" stroke="#263238" stroke-width="0.5" stroke-miterlimit="10" />
      <path d="M233.305 129.177L230.92 126.656" stroke="#263238" stroke-width="2" stroke-miterlimit="10" />
      <path
        d="M231.641 127.411C231.638 127.025 231.551 126.644 231.387 126.295C231.224 125.945 230.987 125.635 230.692 125.385C230.08 124.841 226.856 123.495 226.535 124.629C226.221 125.854 229.517 128.277 231.641 127.411Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path d="M231.64 127.413L228.574 125.5" stroke="#263238" stroke-width="0.5" stroke-miterlimit="10" />
      <path d="M231.243 109.443L228.857 106.922" stroke="#263238" stroke-width="2" stroke-miterlimit="10" />
      <path
        d="M229.586 107.677C229.581 107.29 229.493 106.91 229.329 106.56C229.164 106.211 228.927 105.901 228.633 105.65C228.021 105.106 224.8 103.761 224.494 104.894C224.161 106.119 227.461 108.542 229.586 107.677Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path d="M229.585 107.686L226.516 105.773" stroke="#263238" stroke-width="0.5" stroke-miterlimit="10" />
      <path d="M231.846 115.177L229.457 112.656" stroke="#263238" stroke-width="2" stroke-miterlimit="10" />
      <path
        d="M230.181 113.416C230.176 113.03 230.089 112.651 229.925 112.302C229.762 111.953 229.525 111.643 229.232 111.393C228.62 110.845 225.395 109.503 225.074 110.637C224.757 111.858 228.056 114.285 230.181 113.416Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path d="M230.181 113.42L227.111 111.508" stroke="#263238" stroke-width="0.5" stroke-miterlimit="10" />
      <path d="M230.272 100.123L227.887 97.6016" stroke="#263238" stroke-width="2" stroke-miterlimit="10" />
      <path
        d="M228.61 98.364C228.606 97.978 228.52 97.5973 228.356 97.2478C228.192 96.8982 227.955 96.5879 227.661 96.338C227.049 95.7937 223.825 94.448 223.503 95.582C223.19 96.8067 226.486 99.2296 228.61 98.364Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path d="M228.609 98.3658L225.543 96.4531" stroke="#263238" stroke-width="0.5" stroke-miterlimit="10" />
      <path d="M229.297 90.7712L226.908 88.25" stroke="#263238" stroke-width="2" stroke-miterlimit="10" />
      <path
        d="M227.634 89.0093C227.629 88.6241 227.542 88.2442 227.379 87.8954C227.215 87.5466 226.978 87.2369 226.685 86.9871C226.073 86.439 222.848 85.0971 222.527 86.2311C222.21 87.452 225.51 89.8787 227.634 89.0093Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path d="M227.634 89.0064L224.564 87.0938" stroke="#263238" stroke-width="0.5" stroke-miterlimit="10" />
      <path d="M232.303 120.046L234.117 117.086" stroke="#263238" stroke-width="2" stroke-miterlimit="10" />
      <path
        d="M233.566 117.978C233.49 117.599 233.497 117.209 233.585 116.834C233.673 116.458 233.84 116.106 234.076 115.801C234.564 115.139 237.437 113.155 237.969 114.194C238.552 115.336 235.822 118.39 233.566 117.978Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path d="M233.564 117.97L236.328 115.328" stroke="#263238" stroke-width="0.5" stroke-miterlimit="10" />
      <path d="M230.73 104.948L232.545 101.992" stroke="#263238" stroke-width="2" stroke-miterlimit="10" />
      <path
        d="M231.99 102.884C231.916 102.505 231.923 102.115 232.012 101.74C232.101 101.364 232.268 101.012 232.504 100.707C232.988 100.045 235.865 98.0608 236.398 99.1003C236.976 100.242 234.239 103.292 231.99 102.884Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path d="M231.986 102.884L234.749 100.242" stroke="#263238" stroke-width="0.5" stroke-miterlimit="10" />
      <path d="M230.037 98.3191L231.851 95.3594" stroke="#263238" stroke-width="2" stroke-miterlimit="10" />
      <path
        d="M231.297 96.259C231.223 95.8807 231.23 95.4908 231.318 95.1154C231.406 94.74 231.573 94.3876 231.808 94.0818C232.295 93.4203 235.168 91.4358 235.705 92.4753C236.283 93.6131 233.558 96.6597 231.297 96.259Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path d="M231.297 96.2437L234.06 93.6016" stroke="#263238" stroke-width="0.5" stroke-miterlimit="10" />
      <path d="M229.02 88.6003L230.834 85.6406" stroke="#263238" stroke-width="2" stroke-miterlimit="10" />
      <path
        d="M230.283 86.5325C230.207 86.1542 230.214 85.7641 230.302 85.3885C230.39 85.0129 230.557 84.6606 230.793 84.3552C231.281 83.6937 234.153 81.7092 234.686 82.7487C235.268 83.8865 232.539 86.9407 230.283 86.5325Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path d="M230.281 86.5328L233.044 83.8906" stroke="#263238" stroke-width="0.5" stroke-miterlimit="10" />
      <path
        d="M228.247 82.3724C228.172 81.9947 228.179 81.6052 228.267 81.2303C228.355 80.8553 228.522 80.5036 228.758 80.199C229.245 79.5375 232.118 77.553 232.651 78.5925C233.233 79.7302 230.504 82.7844 228.247 82.3724Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path d="M228.246 82.3806L231.009 79.7422" stroke="#263238" stroke-width="0.5" stroke-miterlimit="10" />
      <path
        d="M228.405 82.4929C228.022 82.5288 227.635 82.4818 227.271 82.3553C226.907 82.2288 226.575 82.0257 226.296 81.7596C225.687 81.2039 224.028 78.1384 225.105 77.7188C226.3 77.2577 229.052 80.293 228.405 82.4929Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path d="M228.404 82.4927L226.068 79.4688" stroke="#263238" stroke-width="0.5" stroke-miterlimit="10" />
      <path d="M242.171 89.6016L236.939 140.038" stroke="#263238" stroke-width="2" stroke-miterlimit="10" />
      <path d="M238.097 130.417L236.279 127.461" stroke="#263238" stroke-width="2" stroke-miterlimit="10" />
      <path
        d="M236.835 128.351C236.909 127.972 236.901 127.582 236.813 127.207C236.724 126.831 236.556 126.479 236.321 126.174C235.833 125.512 232.96 123.528 232.427 124.571C231.845 125.709 234.574 128.767 236.835 128.351Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path d="M236.835 128.354L234.227 125.852" stroke="#263238" stroke-width="0.5" stroke-miterlimit="10" />
      <path d="M239.549 116.417L237.734 113.461" stroke="#263238" stroke-width="2" stroke-miterlimit="10" />
      <path
        d="M238.287 114.359C238.361 113.98 238.354 113.59 238.265 113.215C238.176 112.839 238.008 112.487 237.773 112.181C237.289 111.52 234.412 109.535 233.879 110.579C233.301 111.716 236.03 114.786 238.287 114.359Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path d="M238.286 114.354L235.678 111.852" stroke="#263238" stroke-width="0.5" stroke-miterlimit="10" />
      <path d="M238.951 122.163L237.137 119.203" stroke="#263238" stroke-width="2" stroke-miterlimit="10" />
      <path
        d="M237.692 120.076C237.766 119.698 237.759 119.308 237.67 118.933C237.582 118.558 237.414 118.207 237.178 117.902C236.69 117.241 233.818 115.256 233.285 116.296C232.702 117.452 235.432 120.503 237.692 120.076Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path d="M237.692 120.088L235.08 117.586" stroke="#263238" stroke-width="0.5" stroke-miterlimit="10" />
      <path d="M240.515 107.112L238.697 104.156" stroke="#263238" stroke-width="2" stroke-miterlimit="10" />
      <path
        d="M239.255 105.046C239.329 104.668 239.321 104.277 239.233 103.902C239.144 103.527 238.976 103.174 238.74 102.869C238.253 102.207 235.38 100.223 234.847 101.266C234.265 102.404 236.994 105.458 239.255 105.046Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path d="M239.255 105.045L236.646 102.539" stroke="#263238" stroke-width="0.5" stroke-miterlimit="10" />
      <path d="M241.488 97.7528L239.67 94.7969" stroke="#263238" stroke-width="2" stroke-miterlimit="10" />
      <path
        d="M240.225 95.679C240.299 95.3004 240.292 94.9103 240.203 94.5348C240.115 94.1594 239.947 93.8071 239.711 93.5017C239.224 92.8402 236.351 90.8557 235.818 91.899C235.236 93.0368 237.965 96.091 240.225 95.679Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path d="M240.225 95.682L237.613 93.1797" stroke="#263238" stroke-width="0.5" stroke-miterlimit="10" />
      <path d="M238.402 127.013L240.787 124.492" stroke="#263238" stroke-width="2" stroke-miterlimit="10" />
      <path
        d="M240.061 125.248C240.065 124.862 240.153 124.481 240.316 124.132C240.48 123.783 240.716 123.472 241.009 123.221C241.622 122.673 244.842 121.331 245.167 122.465C245.485 123.686 242.174 126.117 240.061 125.248Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path d="M240.061 125.253L243.308 123.234" stroke="#263238" stroke-width="0.5" stroke-miterlimit="10" />
      <path d="M239.967 111.927L242.352 109.406" stroke="#263238" stroke-width="2" stroke-miterlimit="10" />
      <path
        d="M241.629 110.15C241.634 109.765 241.721 109.385 241.884 109.036C242.048 108.687 242.284 108.378 242.578 108.128C243.19 107.58 246.414 106.238 246.736 107.372C247.053 108.589 243.757 111.016 241.629 110.15Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path d="M241.629 110.155L244.876 108.141" stroke="#263238" stroke-width="0.5" stroke-miterlimit="10" />
      <path d="M240.656 105.291L243.041 102.766" stroke="#263238" stroke-width="2" stroke-miterlimit="10" />
      <path
        d="M242.316 103.517C242.321 103.132 242.408 102.752 242.572 102.403C242.735 102.054 242.972 101.745 243.265 101.495C243.878 100.947 247.102 99.6049 247.423 100.739C247.741 101.96 244.445 104.387 242.316 103.517Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path d="M242.316 103.523L245.567 101.508" stroke="#263238" stroke-width="0.5" stroke-miterlimit="10" />
      <path d="M241.664 95.5603L244.049 93.0391" stroke="#263238" stroke-width="2" stroke-miterlimit="10" />
      <path
        d="M243.309 93.7944C243.313 93.4086 243.401 93.0283 243.564 92.6789C243.728 92.3295 243.964 92.019 244.257 91.7683C244.87 91.2202 248.094 89.8783 248.415 91.0123C248.748 92.2332 245.452 94.66 243.309 93.7944Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path d="M243.324 93.7997L246.575 91.7812" stroke="#263238" stroke-width="0.5" stroke-miterlimit="10" />
      <path
        d="M242.176 89.3172C242.181 88.9314 242.268 88.5511 242.431 88.2017C242.595 87.8523 242.831 87.5418 243.125 87.2911C243.737 86.7468 246.957 85.4011 247.283 86.5351C247.611 87.756 244.311 90.1828 242.176 89.3172Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path d="M242.188 89.3194L245.434 87.3047" stroke="#263238" stroke-width="0.5" stroke-miterlimit="10" />
      <path
        d="M242.319 89.4619C241.936 89.4166 241.568 89.29 241.238 89.0905C240.908 88.891 240.624 88.6232 240.407 88.3052C239.927 87.6399 238.895 84.2946 240.074 84.1094C241.336 83.9129 243.404 87.4471 242.319 89.4619Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path d="M242.319 89.471L240.656 86.0312" stroke="#263238" stroke-width="0.5" stroke-miterlimit="10" />
      <path d="M236.172 69.0625V132.842" stroke="#263238" stroke-width="2" stroke-miterlimit="10" />
      <path d="M236.226 123.263L234.113 120.508" stroke="#263238" stroke-width="2" stroke-miterlimit="10" />
      <path
        d="M234.756 121.326C234.792 120.942 234.745 120.555 234.618 120.19C234.492 119.826 234.289 119.493 234.023 119.213C233.471 118.605 230.405 116.923 229.982 118.019C229.524 119.213 232.552 121.961 234.756 121.326Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path d="M234.756 121.332L231.902 119.109" stroke="#263238" stroke-width="0.5" stroke-miterlimit="10" />
      <path d="M236.226 111.271L234.113 108.516" stroke="#263238" stroke-width="2" stroke-miterlimit="10" />
      <path
        d="M234.756 109.342C234.792 108.958 234.745 108.57 234.618 108.206C234.492 107.841 234.289 107.508 234.023 107.229C233.471 106.62 230.405 104.938 229.982 106.034C229.524 107.221 232.552 109.984 234.756 109.342Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path d="M234.756 109.34L231.902 107.117" stroke="#263238" stroke-width="0.5" stroke-miterlimit="10" />
      <path d="M236.226 97.2009L234.113 94.4453" stroke="#263238" stroke-width="2" stroke-miterlimit="10" />
      <path
        d="M234.756 95.2772C234.792 94.893 234.745 94.5056 234.618 94.1411C234.492 93.7766 234.289 93.4435 234.023 93.1642C233.471 92.5594 230.405 90.8735 229.982 91.9735C229.524 93.1642 232.552 95.9198 234.756 95.2772Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path d="M234.756 95.2773L231.902 93.0547" stroke="#263238" stroke-width="0.5" stroke-miterlimit="10" />
      <path d="M236.226 87.8337L234.113 85.0781" stroke="#263238" stroke-width="2" stroke-miterlimit="10" />
      <path
        d="M234.756 85.9044C234.792 85.5202 234.745 85.1328 234.618 84.7683C234.492 84.4038 234.289 84.0707 234.023 83.7914C233.471 83.1828 230.405 81.5007 229.982 82.5969C229.524 83.7876 232.552 86.5469 234.756 85.9044Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path d="M234.756 85.9101L231.902 83.6875" stroke="#263238" stroke-width="0.5" stroke-miterlimit="10" />
      <path d="M236.226 78.4196L234.113 75.6641" stroke="#263238" stroke-width="2" stroke-miterlimit="10" />
      <path
        d="M234.756 76.4943C234.792 76.1107 234.745 75.7239 234.618 75.3601C234.492 74.9962 234.289 74.6637 234.023 74.3851C233.471 73.7765 230.405 72.0945 229.982 73.1906C229.524 74.3851 232.552 77.1369 234.756 76.4943Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path d="M234.756 76.4845L231.902 74.2656" stroke="#263238" stroke-width="0.5" stroke-miterlimit="10" />
      <path d="M236.18 126.92L238.289 124.164" stroke="#263238" stroke-width="2" stroke-miterlimit="10" />
      <path
        d="M237.638 124.988C237.606 124.602 237.657 124.214 237.788 123.85C237.918 123.485 238.125 123.153 238.394 122.875C238.946 122.27 242.012 120.584 242.431 121.684C242.881 122.875 239.849 125.631 237.638 124.988Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path d="M237.646 124.996L240.67 122.656" stroke="#263238" stroke-width="0.5" stroke-miterlimit="10" />
      <path d="M236.18 107.857L238.289 105.102" stroke="#263238" stroke-width="2" stroke-miterlimit="10" />
      <path
        d="M237.638 105.928C237.606 105.542 237.657 105.154 237.787 104.789C237.918 104.425 238.125 104.093 238.394 103.815C238.946 103.206 242.012 101.524 242.431 102.62C242.881 103.822 239.85 106.57 237.638 105.928Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path d="M237.646 105.934L240.67 103.594" stroke="#263238" stroke-width="0.5" stroke-miterlimit="10" />
      <path d="M236.18 98.4431L238.289 95.6875" stroke="#263238" stroke-width="2" stroke-miterlimit="10" />
      <path
        d="M237.638 96.5215C237.606 96.1358 237.657 95.7476 237.787 95.3831C237.918 95.0186 238.125 94.6863 238.394 94.4085C238.946 93.8 242.012 92.1179 242.431 93.2141C242.881 94.4085 239.85 97.1641 237.638 96.5215Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path d="M237.646 96.5117L240.67 94.1719" stroke="#263238" stroke-width="0.5" stroke-miterlimit="10" />
      <path d="M236.18 86.0134L238.289 83.2578" stroke="#263238" stroke-width="2" stroke-miterlimit="10" />
      <path
        d="M237.638 84.0762C237.606 83.6905 237.657 83.3023 237.787 82.9378C237.918 82.5733 238.125 82.241 238.394 81.9632C238.946 81.3547 242.012 79.6726 242.431 80.7688C242.881 81.9632 239.85 84.7188 237.638 84.0762Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path d="M237.646 84.0742L240.67 81.7344" stroke="#263238" stroke-width="0.5" stroke-miterlimit="10" />
      <path d="M236.18 76.2284L238.289 73.4766" stroke="#263238" stroke-width="2" stroke-miterlimit="10" />
      <path
        d="M237.638 74.3006C237.606 73.9154 237.657 73.5278 237.787 73.1639C237.918 72.8001 238.125 72.4684 238.394 72.1914C238.946 71.5828 242.012 69.8969 242.431 70.9969C242.881 72.1914 239.85 74.9432 237.638 74.3006Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path d="M237.646 74.3007L240.67 71.9609" stroke="#263238" stroke-width="0.5" stroke-miterlimit="10" />
      <path
        d="M236.052 69.9647C236.017 69.5806 236.065 69.1936 236.191 68.8293C236.318 68.4649 236.52 68.1317 236.785 67.8517C237.341 67.2469 240.403 65.561 240.826 66.661C241.287 67.8517 238.256 70.6073 236.052 69.9647Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path d="M236.051 69.9726L239.075 67.6328" stroke="#263238" stroke-width="0.5" stroke-miterlimit="10" />
      <path
        d="M236.199 70.0968C235.814 70.0921 235.434 70.0051 235.085 69.8414C234.736 69.6778 234.426 69.4413 234.177 69.148C233.628 68.5319 232.287 65.3076 233.421 64.9901C234.649 64.6725 237.068 67.9762 236.199 70.0968Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path d="M236.2 70.1023L234.189 66.8516" stroke="#263238" stroke-width="0.5" stroke-miterlimit="10" />
      <path d="M238.799 149.395H232.044L231.197 132.336H239.642L238.799 149.395Z" fill="#263238" stroke="#263238" stroke-miterlimit="10" />
      <path d="M95.8295 15.9688H92.2461V17.6811H95.8295V15.9688Z" stroke="#263238" stroke-miterlimit="10" />
      <path d="M100.037 15.9688H96.4531V17.6811H100.037V15.9688Z" stroke="#263238" stroke-miterlimit="10" />
      <path d="M104.244 15.9688H100.66V17.6811H104.244V15.9688Z" stroke="#263238" stroke-miterlimit="10" />
      <path d="M108.453 15.9688H104.869V17.6811H108.453V15.9688Z" stroke="#263238" stroke-miterlimit="10" />
      <path d="M112.654 15.9688H109.07V17.6811H112.654V15.9688Z" stroke="#263238" stroke-miterlimit="10" />
      <path d="M116.861 15.9688H113.277V17.6811H116.861V15.9688Z" stroke="#263238" stroke-miterlimit="10" />
      <path d="M121.068 15.9688H117.484V17.6811H121.068V15.9688Z" stroke="#263238" stroke-miterlimit="10" />
      <path d="M125.271 15.9688H121.688V17.6811H125.271V15.9688Z" stroke="#263238" stroke-miterlimit="10" />
      <path d="M95.8295 18.7734H92.2461V20.4858H95.8295V18.7734Z" stroke="#263238" stroke-miterlimit="10" />
      <path d="M100.037 18.7734H96.4531V20.4858H100.037V18.7734Z" stroke="#263238" stroke-miterlimit="10" />
      <path d="M104.244 18.7734H100.66V20.4858H104.244V18.7734Z" stroke="#263238" stroke-miterlimit="10" />
      <path d="M108.453 18.7734H104.869V20.4858H108.453V18.7734Z" stroke="#263238" stroke-miterlimit="10" />
      <path d="M112.654 18.7734H109.07V20.4858H112.654V18.7734Z" stroke="#263238" stroke-miterlimit="10" />
      <path d="M116.861 18.7734H113.277V20.4858H116.861V18.7734Z" stroke="#263238" stroke-miterlimit="10" />
      <path d="M121.068 18.7734H117.484V20.4858H121.068V18.7734Z" stroke="#263238" stroke-miterlimit="10" />
      <path d="M125.271 18.7734H121.688V20.4858H125.271V18.7734Z" stroke="#263238" stroke-miterlimit="10" />
      <path d="M95.8295 21.5781H92.2461V23.2904H95.8295V21.5781Z" stroke="#263238" stroke-miterlimit="10" />
      <path d="M100.037 21.5781H96.4531V23.2904H100.037V21.5781Z" stroke="#263238" stroke-miterlimit="10" />
      <path d="M104.244 21.5781H100.66V23.2904H104.244V21.5781Z" stroke="#263238" stroke-miterlimit="10" />
      <path d="M108.453 21.5781H104.869V23.2904H108.453V21.5781Z" stroke="#263238" stroke-miterlimit="10" />
      <path d="M112.654 21.5781H109.07V23.2904H112.654V21.5781Z" stroke="#263238" stroke-miterlimit="10" />
      <path d="M116.861 21.5781H113.277V23.2904H116.861V21.5781Z" stroke="#263238" stroke-miterlimit="10" />
      <path d="M121.068 21.5781H117.484V23.2904H121.068V21.5781Z" stroke="#263238" stroke-miterlimit="10" />
      <path d="M125.271 21.5781H121.688V23.2904H125.271V21.5781Z" stroke="#263238" stroke-miterlimit="10" />
      <path d="M95.8295 24.3828H92.2461V26.0951H95.8295V24.3828Z" stroke="#263238" stroke-miterlimit="10" />
      <path d="M100.037 24.3828H96.4531V26.0951H100.037V24.3828Z" stroke="#263238" stroke-miterlimit="10" />
      <path d="M112.344 24.3828H100.66V26.0951H112.344V24.3828Z" stroke="#263238" stroke-miterlimit="10" />
      <path d="M116.861 24.3828H113.277V26.0951H116.861V24.3828Z" stroke="#263238" stroke-miterlimit="10" />
      <path d="M121.068 24.3828H117.484V26.0951H121.068V24.3828Z" stroke="#263238" stroke-miterlimit="10" />
      <path d="M125.271 24.3828H121.688V26.0951H125.271V24.3828Z" stroke="#263238" stroke-miterlimit="10" />
      <path d="M129.316 45.0428C129.316 45.0428 139.042 31.4349 153.886 23.6406" stroke="#263238" stroke-miterlimit="10" />
      <path d="M143.641 30.3621L144.347 19.5703" stroke="#263238" stroke-miterlimit="10" />
      <path d="M140.107 33.4922L150.396 34.4296" stroke="#263238" stroke-miterlimit="10" />
      <path
        d="M143.85 24.8398C143.85 24.8398 141.393 23.5547 141.51 19.5781C141.628 17.1298 142.624 14.8059 144.315 13.0312C145.59 14.9077 146.357 17.0826 146.541 19.3438C146.772 22.9688 143.85 24.8398 143.85 24.8398Z"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M135.666 32.793C135.666 32.793 133.213 31.5078 133.326 27.5313C133.445 25.0825 134.442 22.7585 136.135 20.9844C137.406 22.862 138.171 25.0366 138.353 27.2969C138.603 30.9219 135.666 32.793 135.666 32.793Z"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M145.951 33.9574C146.504 33.3241 147.188 32.8184 147.955 32.4751C148.723 32.1318 149.556 31.9592 150.396 31.9692C153.318 31.9692 156.24 35.9457 156.24 35.9457C154.229 36.6909 152.084 37.0089 149.943 36.8793C146.42 36.645 145.951 33.9574 145.951 33.9574Z"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M149.46 26.4774C149.46 26.4774 148.292 22.8525 152.03 21.3329C154.191 20.4209 156.62 20.379 158.811 21.2157C158.811 21.2157 158.811 25.0751 155.41 26.5946C152.008 28.1142 149.46 26.4774 149.46 26.4774Z"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path d="M136.018 37.2266L135.783 28.2266" stroke="#263238" stroke-miterlimit="10" />
      <path
        d="M217.563 21.0156H199.469V32.416H209.72L209.384 37.621L213.405 32.416H217.563V21.0156Z"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M227.261 34.1484H214.84V41.9767H221.874L221.644 45.5488L224.407 41.9767H227.261V34.1484Z"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M205.785 26.7051C205.785 26.9017 205.727 27.0939 205.618 27.2574C205.508 27.4209 205.353 27.5483 205.171 27.6235C204.99 27.6988 204.79 27.7185 204.597 27.6801C204.404 27.6417 204.227 27.5471 204.088 27.408C203.949 27.269 203.854 27.0919 203.816 26.899C203.778 26.7062 203.797 26.5063 203.873 26.3246C203.948 26.143 204.075 25.9877 204.239 25.8785C204.402 25.7692 204.594 25.7109 204.791 25.7109C204.922 25.7104 205.051 25.7358 205.172 25.7856C205.293 25.8354 205.403 25.9086 205.495 26.001C205.587 26.0934 205.661 26.2032 205.71 26.324C205.76 26.4449 205.786 26.5744 205.785 26.7051Z"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M209.994 26.7051C209.994 26.9008 209.935 27.0919 209.826 27.2542C209.716 27.4166 209.561 27.543 209.38 27.6173C209.199 27.6917 209 27.7108 208.809 27.6721C208.617 27.6335 208.441 27.5389 208.302 27.4002C208.164 27.2616 208.07 27.0851 208.032 26.8931C207.995 26.7011 208.014 26.5022 208.089 26.3214C208.164 26.1407 208.291 25.9862 208.454 25.8776C208.617 25.7689 208.808 25.7109 209.004 25.7109C209.134 25.7109 209.263 25.7367 209.384 25.7867C209.504 25.8367 209.614 25.91 209.706 26.0023C209.798 26.0947 209.871 26.2043 209.92 26.3249C209.97 26.4455 209.995 26.5747 209.994 26.7051Z"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M214.201 26.7051C214.201 26.9008 214.142 27.0919 214.033 27.2542C213.923 27.4166 213.768 27.543 213.587 27.6173C213.406 27.6917 213.207 27.7108 213.016 27.6721C212.824 27.6335 212.648 27.5389 212.509 27.4002C212.371 27.2616 212.277 27.0851 212.239 26.8931C212.202 26.7011 212.221 26.5022 212.296 26.3214C212.372 26.1407 212.498 25.9862 212.661 25.8776C212.824 25.7689 213.015 25.7109 213.211 25.7109C213.341 25.7109 213.471 25.7367 213.591 25.7867C213.711 25.8367 213.821 25.91 213.913 26.0023C214.005 26.0947 214.078 26.2043 214.127 26.3249C214.177 26.4455 214.202 26.5747 214.201 26.7051Z"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M18.6191 54.2656H32.3177V62.899H24.5575L24.8107 66.8378L21.7678 62.899H18.6191V54.2656Z"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M11.2793 64.2188H20.6838V70.1457H15.3579L15.5318 72.8522L13.4414 70.1457H11.2793V64.2188Z"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M28.2893 59.3404C28.7048 59.3404 29.0415 59.0036 29.0415 58.5882C29.0415 58.1727 28.7048 57.8359 28.2893 57.8359C27.8739 57.8359 27.5371 58.1727 27.5371 58.5882C27.5371 59.0036 27.8739 59.3404 28.2893 59.3404Z"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M25.0999 59.3404C25.5153 59.3404 25.8521 59.0036 25.8521 58.5882C25.8521 58.1727 25.5153 57.8359 25.0999 57.8359C24.6844 57.8359 24.3477 58.1727 24.3477 58.5882C24.3477 59.0036 24.6844 59.3404 25.0999 59.3404Z"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M21.9085 59.3404C22.3239 59.3404 22.6607 59.0036 22.6607 58.5882C22.6607 58.1727 22.3239 57.8359 21.9085 57.8359C21.493 57.8359 21.1562 58.1727 21.1562 58.5882C21.1562 59.0036 21.493 59.3404 21.9085 59.3404Z"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M35.6457 28.7525C35.5845 28.5647 35.5564 28.3677 35.5625 28.1703C35.5625 26.0876 37.1955 25.9024 37.1955 23.5286C37.1955 22.8482 36.9233 22.5873 36.4395 22.5873C35.9556 22.5873 35.6835 22.8482 35.6835 23.5286V24.5605H34.2773V23.6231C34.2773 22.1111 35.0333 21.2266 36.4848 21.2266C37.9363 21.2266 38.6886 22.0997 38.6886 23.6231C38.6886 26.0989 36.9195 26.4807 36.9195 28.2233C36.9182 28.401 36.936 28.5785 36.9725 28.7525H35.6457ZM37.0178 29.4215V30.8692H35.5777V29.4253L37.0178 29.4215Z"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M84.9286 130.167C84.8673 129.977 84.8391 129.779 84.8455 129.581C84.8455 127.498 86.4784 127.313 86.4784 124.939C86.4784 124.258 86.2063 124.001 85.7224 124.001C85.2386 124.001 84.9664 124.258 84.9664 124.939V125.975H83.5527V125.037C83.5527 123.525 84.3087 122.641 85.7565 122.641C87.2042 122.641 87.9602 123.51 87.9602 125.037C87.9602 127.513 86.1911 127.895 86.1911 129.637C86.1894 129.815 86.2084 129.993 86.2478 130.167H84.9286ZM86.3045 130.836V132.276H84.8606V130.836H86.3045Z"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M69.1687 88.5974H91.323V104.216H69.1687V149.292H52.0039V40.0703H97.4012V55.674H69.1687V88.5974Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path d="M95.5174 44.2969V53.7846H67.2773V90.488H80.8852" stroke="#263238" stroke-miterlimit="10" />
      <path d="M53.8926 58.7326V41.9609H95.5175V43.488" stroke="#263238" stroke-miterlimit="10" />
      <path d="M53.8926 139.172V60.8359" stroke="#263238" stroke-miterlimit="10" />
      <path d="M67.2774 113.555V147.404H53.8926V141.451" stroke="#263238" stroke-miterlimit="10" />
      <path d="M67.2773 107.867V111.11" stroke="#263238" stroke-miterlimit="10" />
      <path d="M83.157 90.4844H89.4317V102.308H67.2773V106.413" stroke="#263238" stroke-miterlimit="10" />
      <path
        d="M159.2 149.292H141.88L138.917 129.478H117.851L114.888 149.292H99.1289L116.604 40.0703H141.725L159.2 149.292ZM120.036 114.653H136.577L128.306 59.4199L120.036 114.653Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M103.028 136.868L112.054 80.4447M142.086 137.919L140.544 127.588H116.22L113.275 147.403H101.342L102.691 139.026M155.063 135.356L156.99 147.403H143.515L142.335 139.529M145.11 73.1683L154.73 133.323M112.493 77.7042L118.212 41.9609H140.113L144.566 69.7701M137.006 104.493L130.202 57.9199H126.463L117.845 116.543H138.771L137.414 107.263"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M165.6 66.2817C165.6 48.807 174.804 38.8203 191.655 38.8203C208.506 38.8203 217.714 48.807 217.714 66.2817V123.076C217.714 129.161 216.621 134.31 214.437 138.369C215.215 140.395 216.467 140.709 219.275 140.709H220.832V155.999H218.493C210.846 155.999 206.019 153.191 203.671 148.511C199.814 149.879 195.748 150.565 191.655 150.537C174.804 150.537 165.6 140.554 165.6 123.076V66.2817ZM182.761 124.168C182.761 131.97 186.197 134.933 191.655 134.933C197.113 134.933 200.549 131.97 200.549 124.168V65.2006C200.549 57.3988 197.117 54.4353 191.655 54.4353C186.193 54.4353 182.761 57.3988 182.761 65.2006V124.168Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M209.778 152.239C207.868 151.216 206.324 149.624 205.36 147.684L204.604 146.172L203.02 146.75C199.371 148.046 195.522 148.694 191.65 148.663C183.84 148.663 177.792 146.448 173.68 142.082C173.065 141.426 172.495 140.729 171.975 139.995M214.579 133.021C214.138 134.567 213.532 136.061 212.772 137.478L212.36 138.234L212.67 139.043C213.883 142.191 216.31 142.569 218.941 142.592V154.11H218.491C216.281 154.149 214.079 153.837 211.967 153.184M215.823 72.353V123.076C215.843 125.698 215.596 128.314 215.086 130.886M167.651 62.4306C168.214 56.0349 170.236 50.9546 173.699 47.3107C177.796 42.926 183.844 40.7109 191.654 40.7109C199.463 40.7109 205.515 42.926 209.627 47.2956C213.74 51.6653 215.823 58.0496 215.823 66.2824V69.9225M170.425 137.387C168.471 133.528 167.488 128.739 167.488 123.076V66.2824C167.488 65.7569 167.488 65.2391 167.515 64.725M180.869 123.28V124.169C180.869 134.632 186.736 136.824 191.654 136.824C196.571 136.824 202.438 134.632 202.438 124.169V71.7066M180.869 81.4362V121.171M202.438 69.1098V65.1899C202.438 54.7308 196.575 52.5346 191.654 52.5346C186.732 52.5346 180.869 54.7308 180.869 65.1899V78.5105"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M195.5 71.3281C195.5 71.3281 198.403 71.9102 200.141 73.3202C201.88 74.7301 203.041 78.1245 202.213 80.2791C201.385 82.4336 201.162 82.2938 202.156 83.5374C203.15 84.781 202.322 86.9393 202.322 86.9393L195.942 84.9511C195.986 83.4171 196.112 81.8865 196.32 80.366C196.649 78.1283 196.482 73.9854 195.488 72.825L195.5 71.3281Z"
        fill="#263238"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M186.495 124.625L187.572 130.427C187.452 132.797 187.675 135.171 188.234 137.477C189.145 140.879 189.81 148.499 189.81 148.499C189.81 148.499 188.4 149.656 186.907 150.65C185.414 151.644 184.507 152.559 186.578 152.559C188.65 152.559 189.727 151.977 190.967 151.482C192.207 150.986 193.204 150.726 193.204 150.072C193.204 149.418 192.543 146.67 192.543 146.67C192.543 146.67 192.792 136.978 192.792 133.818C192.806 132.006 192.611 130.199 192.21 128.431V125.305L186.495 124.625Z"
        fill="white"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M190.967 151.49C192.211 150.991 193.205 150.734 193.205 150.08C193.183 149.746 193.138 149.414 193.069 149.086C192.483 149.377 191.814 149.725 191.383 149.997C190.472 150.579 189.228 151.157 188.484 151.157C187.93 151.099 187.393 150.932 186.904 150.666C185.414 151.656 184.507 152.556 186.578 152.556C188.65 152.556 189.727 151.985 190.967 151.49Z"
        fill="#263238"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M191.299 126.455L191.548 130.348C191.548 130.348 191.049 135.069 191.548 138.286C192.047 141.503 192.043 147.649 192.043 148.643C192.043 149.637 191.287 150.715 189.972 151.875C189.972 151.875 188.895 153.119 189.394 153.863C189.893 154.608 193.453 153.202 193.552 152.374C193.65 151.546 194.463 149.728 194.463 149.142V146.496C194.463 145.918 196.783 138.131 196.534 135.311C196.284 132.491 196.284 128.352 196.284 128.352L196.368 125.453C196.368 125.453 192.709 126.621 191.299 126.455Z"
        fill="white"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M192.459 152.313C191.246 152.884 190.569 151.795 190.426 151.481C190.286 151.621 190.135 151.757 189.972 151.897C189.972 151.897 188.895 153.141 189.394 153.885C189.893 154.63 193.453 153.224 193.552 152.396C193.65 151.568 194.463 149.75 194.463 149.164C194.062 150.264 193.363 151.886 192.459 152.313Z"
        fill="#263238"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M185.996 100.862C185.996 100.862 182.847 107.825 182.594 111.552C182.341 115.279 184.586 125.141 184.586 125.141C184.586 125.141 188.646 127.03 192.04 126.879C194.057 126.791 196.048 126.4 197.948 125.719C197.948 125.719 200.931 117.025 200.02 111.884C199.109 106.743 196.871 101.444 196.871 101.444L186.23 100.117L185.996 100.862Z"
        fill="#263238"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M190.887 83.2966C190.887 83.2966 192.293 82.4688 193.37 82.4688H195.029C195.029 82.4688 195.785 85.4511 195.785 85.7838C195.785 86.1164 192.637 101.361 192.637 101.361L186.672 100.779C186.672 100.779 186.672 91.1702 187.5 88.2672C188.327 85.3642 190.887 83.2966 190.887 83.2966Z"
        fill="white"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M186.229 71.4141C186.229 71.4141 185.651 74.8954 186.396 77.2995C187.14 79.7035 189.212 82.2701 190.043 82.3533C190.658 82.355 191.261 82.182 191.782 81.8543L191.476 82.9618L190.814 86.8552L193.797 87.1047C193.797 87.1047 195.04 82.7124 195.04 82.4629C195.04 82.2134 195.014 81.6086 195.014 81.6086L194.681 78.2936C195.138 77.5433 195.553 76.7685 195.925 75.9727C196.174 75.3112 195.592 74.2339 195.592 74.2339C195.592 74.2339 196.189 70.6505 194.186 68.8475C191.699 66.6097 187.382 68.598 186.312 69.6753C185.243 70.7526 186.229 71.4141 186.229 71.4141Z"
        fill="white"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M191.773 81.8561L192.91 81.5234L191.277 84.4L191.773 81.8561Z"
        fill="#263238"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M194.169 68.8475C191.523 66.6097 187.365 68.598 186.295 69.6753C185.225 70.7526 186.212 71.4141 186.212 71.4141C186.212 71.4141 186.794 70.8357 188.283 71.4141C189.772 71.9924 190.929 74.9786 192.014 75.3112C192.694 75.5161 193.386 75.6815 194.085 75.8064C194.085 75.8064 194.085 74.5628 194.664 74.3965C194.96 74.3052 195.265 74.2506 195.575 74.2339C195.575 74.2339 196.236 70.5863 194.169 68.8475Z"
        fill="#263238"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M188.125 75.1406L188.79 76.1348L188.458 78.7845L189.535 78.9508"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M189.201 79.8651C189.426 79.9077 189.659 79.8923 189.876 79.8202C190.094 79.7481 190.29 79.6218 190.445 79.4531"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M186.883 75.6328L187.129 76.7933C187.55 76.8412 187.978 76.7841 188.372 76.6269"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M190.279 75.1406L190.446 76.3842C190.446 76.3842 191.606 76.7131 192.022 76.4636"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M186.551 74.8148C186.964 74.6201 187.429 74.5618 187.878 74.6485"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M189.633 74.3145C189.633 74.3145 189.965 73.9818 190.876 74.1481"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M191.465 82.9609C191.465 82.9609 187.817 84.6997 186.989 84.8509C186.161 85.0021 184.668 85.6787 184.751 86.2571C184.835 86.8354 184.422 95.7863 184.668 96.3685C184.914 96.9506 184.668 101.589 184.506 102.167C184.343 102.745 181.693 110.301 181.693 110.301C181.693 110.301 182.275 110.967 183.519 110.468C184.763 109.969 186.501 104.669 186.83 102.598C187.159 100.526 189.401 91.4091 189.979 89.3377C190.557 87.2663 191.465 82.9609 191.465 82.9609Z"
        fill="white"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M195.031 82.4688C195.031 82.4688 193.372 88.8493 192.544 91.4159C191.716 93.9825 190.888 95.4794 191.032 97.4639C191.176 99.4483 190.949 109.477 192.442 110.886C193.935 112.296 195.844 113.204 197.734 113.204C199.05 113.17 200.358 112.976 201.627 112.625C201.627 112.625 199.306 99.9473 199.306 99.6184C199.306 99.2896 202.954 87.6019 202.871 86.6078C202.788 85.6137 195.031 82.4688 195.031 82.4688Z"
        fill="white"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M188.234 90.3394C188.245 91.8572 188.358 93.3726 188.571 94.8754C189.157 92.4713 189.705 90.2411 189.973 89.3377C190.555 87.2663 191.485 82.9609 191.485 82.9609C191.485 82.9609 190.574 83.3956 189.557 83.8492C189.089 85.2629 188.234 88.2037 188.234 90.3394Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M195.03 82.4688C195.03 82.4688 193.37 88.8493 192.543 91.4159C192.097 92.7918 191.654 93.8615 191.363 94.8595C192.566 93.9135 193.608 92.7786 194.448 91.4991C195.809 89.4617 196.667 84.8577 196.927 83.2701C195.835 82.7938 195.03 82.4688 195.03 82.4688Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M180.183 100.189L179.355 102.593L178.523 99.5271C178.834 99.4613 179.158 99.4889 179.453 99.6067C179.749 99.7244 180.002 99.9269 180.183 100.189Z"
        fill="#263238"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M174.305 90.8125L179.026 99.0982L185.157 99.4308L181.097 90.8125H174.305Z"
        fill="#263238"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M178.538 99.5368C178.538 99.5368 176.22 96.1348 175.892 95.3108C175.603 94.6264 175.434 93.8976 175.393 93.1562C175.393 92.6572 175.559 92.4909 175.808 93.073C176.058 93.6551 176.564 94.895 176.564 94.895L176.98 92.4078C176.98 92.4078 177.23 91.9957 177.475 92.4909C177.721 92.9861 177.475 94.895 177.475 94.895L178.141 91.9957C178.141 91.9957 178.469 91.4136 178.719 91.8294C178.968 92.2452 178.553 94.5623 178.553 94.5623L179.309 92.8236C179.426 92.8257 179.54 92.8682 179.63 92.9439C179.72 93.0195 179.781 93.1238 179.804 93.2393C179.887 93.6514 179.225 95.8853 179.225 95.8853L180.303 94.808C180.303 94.808 180.715 94.6417 180.548 95.1407C180.245 95.7522 179.886 96.3343 179.475 96.8795C179.678 97.3639 179.843 97.863 179.97 98.3725C179.901 98.6897 179.789 98.9961 179.637 99.2835L180.22 100.194L179.887 100.856L178.538 99.5368Z"
        fill="white"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M184.508 102.18C184.639 101.289 184.714 100.392 184.734 99.4922L180.115 99.8702L179.359 102.607L184.273 102.815C184.409 102.486 184.481 102.263 184.508 102.18Z"
        fill="white"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M191.548 97.1277C191.548 97.1277 188.868 95.0185 188.328 94.5536C187.598 93.9299 186.328 94.1453 186.328 94.1453C186.328 94.1453 184.34 95.4721 184.007 95.801C183.675 96.1298 183.675 96.4662 184.007 96.3831C184.61 96.0931 185.191 95.7609 185.746 95.389L185.829 96.6288L187.572 97.1277L188.067 96.6288C188.312 96.8213 188.594 96.9627 188.895 97.0446C189.175 97.0883 189.461 97.0596 189.726 96.9614L191.299 98.3713L191.548 97.1277Z"
        fill="white"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M200.084 85.1094C200.084 85.1094 203.316 85.7746 203.728 86.8519C204.14 87.9292 203.811 104.75 203.479 105.245C203.146 105.74 190.389 99.2918 190.389 99.2918L191.466 96.8046L198.841 98.7928L198.327 90.6621"
        fill="white"
      />
      <path
        d="M200.084 85.1094C200.084 85.1094 203.316 85.7746 203.728 86.8519C204.14 87.9292 203.811 104.75 203.479 105.245C203.146 105.74 190.389 99.2918 190.389 99.2918L191.466 96.8046L198.841 98.7928L198.327 90.6621"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M186.348 96.707C186.348 96.707 186.453 94.7263 186.522 94.4844"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M187.01 96.8186C187.01 96.8186 187.184 94.9778 187.252 94.6641"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M188.069 96.6303L188.054 96.154L187.635 96.0859" stroke="#263238" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M117.165 150.005C117.165 150.005 116.67 151.608 117.055 152.379C117.441 153.15 118.272 153.32 119.429 153.21C120.586 153.101 125.065 152.103 125.673 151.993C126.124 151.902 126.566 151.776 126.996 151.615C125.532 151.133 124.039 150.745 122.525 150.455C120.536 150.122 117.554 149.57 117.554 149.57L117.165 150.005Z"
        fill="white"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M117.553 149.555L117.175 149.997C117.175 149.997 116.679 151.6 117.065 152.371C117.356 152.953 117.893 153.191 118.637 153.225C118.26 152.873 117.985 152.427 117.84 151.932C117.733 151.55 117.716 151.148 117.79 150.758C117.864 150.368 118.027 150 118.267 149.683L117.553 149.555Z"
        fill="white"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M121.218 137.248L119.229 143.436L112.766 142.941C112.766 142.941 116.689 146.642 119.57 148.354C122.45 150.066 127.632 151.665 130.891 150.894C134.149 150.123 142.007 142.828 142.007 142.828L135.105 142.941L133.691 137.086L121.218 137.248Z"
        fill="#263238"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M126.997 107.055C126.997 107.055 122.937 107.811 122.442 109.043C121.947 110.275 122.359 113.201 122.692 115.091C123.024 116.981 123.848 117.245 122.442 118.655C121.036 120.065 122.359 121.555 123.686 122.299C125.013 123.044 126.834 122.054 127.247 121.222"
        fill="#263238"
      />
      <path
        d="M126.997 107.055C126.997 107.055 122.937 107.811 122.442 109.043C121.947 110.275 122.359 113.201 122.692 115.091C123.024 116.981 123.848 117.245 122.442 118.655C121.036 120.065 122.359 121.555 123.686 122.299C125.013 123.044 126.834 122.054 127.247 121.222"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M116.392 130.508C116.392 130.508 114.238 136.476 114.457 136.972C114.676 137.467 120.146 143.326 120.312 143.326C120.479 143.326 121.363 143.878 121.639 143.878C121.806 143.894 121.975 143.875 122.134 143.821C122.446 143.767 122.76 143.731 123.075 143.711L123.684 144.263L125.672 144.044C125.672 144.044 124.402 142.332 123.846 142.109C123.291 141.885 120.921 141.613 120.921 141.613L117.897 135.977L119.443 132.443L116.392 130.508Z"
        fill="white"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M123.685 122.605C123.685 122.605 119.319 122.934 118.877 123.047C118.707 123.073 118.545 123.136 118.402 123.231C118.259 123.327 118.139 123.452 118.049 123.599C117.883 123.875 115.025 130.173 115.025 130.173L119.705 132.989L120.314 131.443L121.217 137.241H133.755L134.088 131.609L135.305 133.321L140.385 129.674C140.385 129.674 135.471 122.771 134.088 122.605C132.704 122.439 123.685 122.605 123.685 122.605Z"
        fill="white"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M125.451 119.781C125.451 119.781 125.175 121.165 125.009 121.671C124.842 122.178 123.686 122.612 123.686 122.612C123.686 122.612 124.68 125.319 127.329 125.927C129.272 126.37 130.701 124.544 130.977 124.158C131.253 123.773 131.529 122.389 131.529 122.389C131.529 122.389 130.312 121.342 130.259 121.119C130.206 120.896 130.089 119.985 130.089 119.985C130.089 119.985 127.11 120.556 125.451 119.781Z"
        fill="white"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M126.668 110.043L123.436 114.436C123.436 114.436 122.109 112.614 122.109 114.353C122.109 116.091 122.865 116.507 123.104 116.59C123.342 116.673 123.686 116.757 123.686 116.757C124.032 117.962 124.656 119.069 125.508 119.988C126.834 121.311 128.154 121.561 129.318 120.899C130.482 120.238 131.306 118.163 131.306 118.163C131.306 118.163 132.138 115.43 131.971 114.103C131.805 112.776 127.991 109.548 126.668 110.043Z"
        fill="white"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M126.355 115.762C126.397 116.026 126.283 116.264 126.102 116.291C125.921 116.317 125.724 116.128 125.698 115.86C125.671 115.591 125.773 115.357 125.955 115.331C126.136 115.304 126.317 115.493 126.355 115.762Z"
        fill="#263238"
      />
      <path
        d="M128.849 115.762C128.811 116.026 128.925 116.264 129.106 116.291C129.288 116.317 129.484 116.128 129.511 115.86C129.537 115.591 129.435 115.357 129.254 115.331C129.072 115.304 128.891 115.493 128.849 115.762Z"
        fill="#263238"
      />
      <path
        d="M127.146 118.225C127.163 118.285 127.192 118.342 127.231 118.391C127.27 118.439 127.319 118.48 127.374 118.51C127.428 118.54 127.489 118.559 127.551 118.565C127.613 118.571 127.676 118.565 127.736 118.546C127.863 118.529 127.982 118.473 128.077 118.387C128.172 118.3 128.239 118.187 128.269 118.062"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M126.721 119.028C126.721 119.028 127.896 119.561 128.645 118.922"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M125.062 114.965C125.062 114.965 125.274 114.27 126.612 114.538"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M128.27 114.591C128.428 114.516 128.602 114.477 128.778 114.477C128.954 114.477 129.127 114.516 129.286 114.591"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M126.585 109.706C126.585 109.706 130.743 117.081 130.978 118.158C131.212 119.235 129.651 120.562 130.479 121.56C131.306 122.558 132.55 123.798 132.55 123.798C132.55 123.798 130.66 124.554 131.306 125.125C131.953 125.695 135.366 124.792 136.36 123.798C137.354 122.804 135.948 121.311 135.604 120.396C135.26 119.481 136.432 117.413 136.103 114.681C135.774 111.948 132.206 107.552 130.135 106.807C128.063 106.062 126.408 106.973 125.746 107.801"
        fill="#263238"
      />
      <path
        d="M126.585 109.706C126.585 109.706 130.743 117.081 130.978 118.158C131.212 119.235 129.651 120.562 130.479 121.56C131.306 122.558 132.55 123.798 132.55 123.798C132.55 123.798 130.66 124.554 131.306 125.125C131.953 125.695 135.366 124.792 136.36 123.798C137.354 122.804 135.948 121.311 135.604 120.396C135.26 119.481 136.432 117.413 136.103 114.681C135.774 111.948 132.206 107.552 130.135 106.807C128.063 106.062 126.408 106.973 125.746 107.801"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M126.024 120.622C126.024 120.622 125.438 121.48 125.865 121.756C126.292 122.032 126.772 122.346 126.772 122.346C126.772 122.346 126.613 123.858 126.935 124.322C127.256 124.787 128.643 125.456 128.643 125.456C128.643 125.456 131.157 128.719 132.922 130.537C134.687 132.355 138.161 135.938 139.019 135.938C139.877 135.938 141.049 134.12 140.943 132.676C140.837 131.232 136.611 129.361 135.81 128.719C135.009 128.076 130.087 124.939 130.087 124.939C130.087 124.939 129.875 122.425 129.928 121.892C129.981 121.359 129.875 120.38 130.087 120.002C130.299 119.624 129.66 119.843 129.331 120.38C129.155 120.635 129.045 120.93 129.01 121.238L126.024 120.622Z"
        fill="white"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M135.89 136.031H118.934V146.252H135.89V136.031Z"
        fill="#B0B0B0"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M127.552 149.283C127.552 149.283 135.725 145.915 137.936 144.202C140.147 142.49 142.411 141.658 143.734 142.048C145.057 142.437 145.836 144.973 145.227 146.633C144.619 148.292 139.867 149.063 137.826 149.781C135.785 150.5 129.533 151.547 129.533 151.547L126.66 149.944L127.552 149.283Z"
        fill="#263238"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M128.103 149.398C128.103 149.398 130.258 149.897 132.189 150.449C134.121 151.001 134.457 151.058 134.457 151.058C134.457 151.058 139.099 150.117 139.817 149.95C140.536 149.784 142.025 150.393 142.191 151.443C142.357 152.494 141.972 153.156 141.435 153.432C140.898 153.708 139.942 153.708 139.281 153.708C138.619 153.708 134.915 153.375 134.367 153.33C133.819 153.284 127.627 152.112 127.627 152.112L128.103 149.398Z"
        fill="white"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M134.307 153.335H134.344C134.896 153.388 138.597 153.713 139.258 153.713C139.92 153.713 140.857 153.713 141.413 153.437C141.969 153.161 142.35 152.5 142.169 151.449C141.988 150.398 140.513 149.793 139.795 149.956C139.077 150.118 134.446 151.067 134.446 151.067C134.446 151.067 134.911 152.473 134.307 153.335Z"
        fill="white"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M127.552 149.289C125.01 148.441 122.58 147.292 120.313 145.864C116.669 143.544 111.861 141.706 111.2 141.665C110.538 141.623 108.826 143.215 109.264 145.09C109.703 146.964 113.422 149.07 114.125 149.346C114.828 149.622 124.732 152.271 126.72 152.271L127.552 149.289Z"
        fill="#263238"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M126.225 148.675C126.225 148.675 125.341 151.657 125.284 151.88C125.227 152.103 125.122 152.376 125.56 152.542C126.118 152.706 126.695 152.799 127.276 152.818C127.605 152.818 127.828 152.062 127.938 151.771C128.177 150.996 128.343 150.2 128.433 149.393C128.433 148.898 127.495 148.732 127.163 148.637C126.851 148.579 126.531 148.592 126.225 148.675Z"
        fill="#D9D9D9"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M90.1706 36.8508C90.1706 36.8508 90.3633 37.0436 91.1949 37.1078C92.0265 37.1721 92.9261 37.4291 93.8863 37.1721C94.8464 36.915 95.4209 36.7941 95.1034 37.2363C94.7859 37.6786 92.5406 38.8995 92.2193 39.028C91.898 39.1566 90.8774 39.2208 90.6204 39.4779C90.3633 39.7349 90.1705 40.438 89.5317 40.5022C88.8929 40.5665 87.6758 38.2607 87.6758 38.2607L89.6603 36.5938L90.1706 36.8508Z"
        fill="#B0B0B0"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M70.2576 33.8341C70.2576 33.8341 75.5722 29.2981 78.9024 27.3023C82.2325 25.3065 82.9356 24.7433 83.3854 24.868C83.8352 24.9927 90.9453 36.5254 90.9453 36.5254L87.2296 39.5494L82.4933 30.3263C82.4933 30.3263 75.7688 36.7938 74.0413 37.9467C72.3139 39.0996 68.8515 35.7694 68.6625 35.2591C68.4735 34.7488 70.2576 33.8341 70.2576 33.8341Z"
        fill="#263238"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M79.7059 40.8116L88.8156 40.8569L86.6384 38.9103L78.5039 38.6797L79.7059 40.8116Z"
        fill="white"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M88.8159 40.8633L94.2402 33.7872L91.031 32.7969L86.6387 38.9166L88.8159 40.8633Z"
        fill="white"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M74.2269 23.7266L77.1753 30.8329L81.783 37.1076C81.783 37.1076 83.45 37.1719 83.7675 37.2361C84.085 37.3004 84.9847 37.3647 85.3702 37.8107C85.687 38.3048 85.9242 38.8457 86.0733 39.4134L85.6953 39.4777L84.9393 38.6461L83.5332 38.3248C83.5332 38.3248 81.8662 38.1962 81.4844 38.0035C81.1027 37.8107 79.2429 36.4045 79.2429 36.4045L73.0929 28.7199L72.1328 26.0286L74.2269 23.7266Z"
        fill="#B0B0B0"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M81.4605 36.2899L80.0544 37.4239L73.0501 30.1437L72.3281 26.3448L74.7397 24.0391L77.2118 29.9623L81.4605 36.2899Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M71.348 59.3125C71.348 59.3125 68.8494 63.1529 68.7889 63.667C68.756 63.9083 68.8081 64.1535 68.9365 64.3605C69.0648 64.5675 69.2612 64.7232 69.492 64.801C70.0023 64.9938 71.0267 65.3756 71.6693 65.6968C72.3119 66.0181 73.718 66.7855 74.7424 66.7855C75.7667 66.7855 76.0238 66.4642 75.7025 66.1467C75.3812 65.8291 73.461 64.1584 73.2682 63.7766C72.9785 63.1361 72.7433 62.4722 72.5651 61.7922C72.5651 61.5994 73.1397 59.8077 73.1397 59.8077L71.348 59.3125Z"
        fill="#B0B0B0"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M61.9985 32.7656C60.9227 34.1962 60.4199 35.977 60.5886 37.759C60.9666 41.0248 63.6617 41.8602 66.096 42.5633C68.5303 43.2663 77.7496 45.7006 77.7496 45.7006C77.7496 45.7006 75.1263 48.4525 74.1625 50.7582C73.1986 53.064 70.6433 59.7243 70.6433 59.7243L75.3153 61.8713L81.3368 49.5864C81.3368 49.5864 82.8072 46.9631 83.0643 45.6175C83.3213 44.2718 82.9357 43.4402 82.8072 43.2474C82.6787 43.0547 74.7408 37.6796 73.2666 36.7157C72.4213 36.1562 71.5207 35.685 70.5791 35.3095L61.9985 32.7656Z"
        fill="#263238"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M73.9639 20.1295C73.9639 20.1295 72.9395 16.6708 71.3179 16.2248C69.6963 15.7787 67.8592 17.3588 66.4039 19.1051C64.9487 20.8514 65.1868 24.93 65.1868 26.0829C65.1868 27.2358 61.9209 32.2972 61.5996 33.382C61.5996 33.382 62.9453 34.3459 65.5723 34.9205C67.3208 35.2733 69.1041 35.4242 70.887 35.3703C70.887 35.3703 71.3973 31.7188 71.5258 30.5659C71.6543 29.413 72.5501 27.7499 73.2532 27.3001C73.9563 26.8502 74.6631 25.5726 74.7274 24.0342C74.7917 22.4957 73.9639 20.1295 73.9639 20.1295Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M79.2239 37.0391L80.7586 37.7421C80.7586 37.7421 83.9602 37.9349 84.2172 37.9349C84.4743 37.9349 85.0488 39.7909 85.0488 39.7909C85.0488 39.7909 84.8561 39.9836 84.6028 39.7304C84.3495 39.4771 84.0887 39.1521 84.0887 39.1521C84.0887 39.1521 82.4898 39.2806 81.8472 39.2806C81.2046 39.2806 80.0555 38.5246 80.0555 38.5246L78.502 37.8707L79.2239 37.0391Z"
        fill="#B0B0B0"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M67.7598 20.646C67.7598 20.646 69.748 27.499 70.4511 29.2265C71.1542 30.9539 72.9496 33.0707 73.5885 33.9023C74.2273 34.7339 79.0316 38.4382 79.0316 38.4382L80.056 37.4139L74.953 29.7708C74.953 29.7708 72.3713 19.5649 72.4356 19.2285C72.4998 18.8921 71.6796 18.0113 71.0899 17.8828"
        fill="#92E3A9"
      />
      <path
        d="M67.7598 20.646C67.7598 20.646 69.748 27.499 70.4511 29.2265C71.1542 30.9539 72.9496 33.0707 73.5885 33.9023C74.2273 34.7339 79.0316 38.4382 79.0316 38.4382L80.056 37.4139L74.953 29.7708C74.953 29.7708 72.3713 19.5649 72.4356 19.2285C72.4998 18.8921 71.6796 18.0113 71.0899 17.8828"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M83.0648 9.44177C83.0648 9.44177 83.2576 11.7475 82.9363 12.5149C82.615 13.2822 81.1484 13.732 81.1484 14.3708C81.1484 15.0096 81.2126 15.6522 80.8913 15.9093C80.57 16.1663 79.9955 15.9093 79.9955 15.9093C79.8818 16.2675 79.6583 16.5809 79.3566 16.8051C78.8426 17.1831 78.9333 17.1831 78.9333 17.1831C78.5068 17.5897 77.9553 17.8402 77.3684 17.8938C76.5368 17.8938 75.898 16.7409 75.7052 16.6123C75.5124 16.4838 74.9908 16.8467 74.4881 17.1907C73.8266 17.6405 73.3352 20.071 73.3352 20.071C73.3352 20.071 71.4792 16.5481 71.2864 16.4838C71.0937 16.4196 69.9219 16.3402 69.9219 16.3402C69.9219 16.3402 71.8119 15.3952 72.0538 14.6279C72.2957 13.8605 72.0538 12.6434 72.0538 12.3864C72.0538 12.1293 72.1823 9.50603 73.2709 8.16036C74.3595 6.81469 78.9673 6.43292 80.6948 6.30818C82.4222 6.18344 83.0648 9.44177 83.0648 9.44177Z"
        fill="#B0B0B0"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M83.0641 9.44086C83.0641 9.44086 82.4253 9.06287 82.3081 10.1439C82.1909 11.225 82.3723 11.2968 81.6012 11.3611C80.8301 11.4253 80.5126 11.6181 80.5126 12.0679C80.5126 12.5177 79.938 12.7067 79.4882 12.7067C79.0384 12.7067 78.6566 12.0679 78.3996 12.7067C78.1426 13.3456 77.2656 13.6669 76.6722 13.6669C76.0787 13.6669 76.0333 14.0449 75.009 14.2452C73.9846 14.4455 73.7918 13.3456 73.7918 13.3456C73.2412 13.4611 72.6847 13.5469 72.1249 13.6026C71.7469 13.6026 71.8074 12.0037 71.8074 12.0037C71.3215 12.0951 70.8275 12.1369 70.3332 12.1284C69.9552 12.0679 69.6301 11.1683 69.6301 10.8508C69.6301 10.5333 68.9233 10.0154 68.9233 9.56938V8.81339C68.9233 8.81339 68.7343 7.72476 68.859 7.46772C68.9837 7.21069 69.8078 6.68527 69.8078 6.36776C69.8078 6.05024 69.5507 4.57606 69.9968 3.99772C70.4428 3.41939 70.8964 2.86373 71.3424 2.86373C71.7885 2.86373 71.9208 2.03214 72.6238 1.90362C73.1087 1.80476 73.6033 1.76164 74.098 1.77511C74.6083 1.77511 74.9296 1.13629 75.5042 1.01911C76.2609 0.957594 77.0225 1.04492 77.7457 1.27615C78.0027 1.40467 78.1955 1.40467 78.7058 1.21189C79.0389 1.13995 79.3839 1.14263 79.7159 1.21974C80.0479 1.29686 80.3587 1.4465 80.626 1.65793C81.0162 2.0159 81.5098 2.24089 82.0359 2.30052C82.3572 2.30052 82.6747 2.10774 83.1246 2.93933C83.5744 3.77092 84.3417 3.89944 84.7877 4.285C84.9747 4.44976 85.1147 4.661 85.1937 4.89731C85.2727 5.13363 85.2878 5.38662 85.2376 5.63066C85.1733 6.0767 85.4946 6.46225 85.7516 6.97255C85.8691 7.18003 85.9254 7.41653 85.914 7.65467C85.9026 7.89281 85.824 8.12287 85.6874 8.31821C85.4303 8.63951 85.6231 9.27833 85.6874 9.53536C85.7516 9.7924 85.0448 10.2914 84.4702 10.4955C83.8957 10.6996 83.3211 10.9793 83.0641 9.44086Z"
        fill="#263238"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M80.9642 13.9044C80.8243 14.1539 80.624 14.3089 80.5144 14.2484C80.4048 14.1879 80.4237 13.9347 80.5597 13.6852C80.6958 13.4357 80.8962 13.2807 81.0096 13.3412C81.123 13.4017 81.1003 13.6549 80.9642 13.9044Z"
        fill="#263238"
      />
      <path
        d="M80.6484 12.5316C80.6484 12.5316 81.3364 12.1876 81.7371 13.2196"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M30.8748 86.9062C30.8748 86.9062 32.0314 88.7206 32.3603 89.2725C32.6891 89.8244 32.6362 91.0415 32.6362 91.0415L33.963 92.3645L32.3867 93.5816C32.3867 93.5816 30.7273 92.4779 30.3985 92.3116C30.066 92.1617 29.7108 92.0684 29.3476 92.0356C29.1813 92.0356 27.6353 90.1003 27.6353 90.1003L26.7168 89.4766L27.2611 87.9646L29.6916 88.6828L30.8748 86.9062Z"
        fill="#CFCFCF"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M25.2148 87.1448L30.6844 92.3649L32.6727 90.6262L27.7852 85.9844L25.2148 87.1448Z"
        fill="#263238"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M30.0756 86.6816C29.9845 86.6868 29.8978 86.7224 29.8293 86.7827C29.7607 86.843 29.7144 86.9246 29.6977 87.0143C29.5843 87.6191 29.3953 88.8627 29.7619 88.9685C29.8434 89.002 29.9317 89.0153 30.0194 89.0075C30.1071 88.9996 30.1916 88.9707 30.2657 88.9232C30.3399 88.8758 30.4016 88.8111 30.4454 88.7348C30.4893 88.6584 30.5142 88.5727 30.5179 88.4847C30.5859 88.1369 31.0017 87.173 30.8619 86.8971C30.7447 86.6779 30.465 86.6741 30.0756 86.6816Z"
        fill="#CFCFCF"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M26.3757 87.8901C26.3757 87.8901 25.6197 89.2963 26.2093 89.7121C26.799 90.1279 26.7915 89.2963 26.9653 88.8011C27.1392 88.3059 27.1203 87.6406 26.3757 87.8901Z"
        fill="#CFCFCF"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M27.7843 89.2914C27.7843 89.2914 27.119 90.6181 27.3685 91.0339C27.618 91.4497 27.9506 91.0339 28.1245 90.6181C28.2682 90.3642 28.3534 90.0814 28.3739 89.7903C28.3777 89.0646 27.7843 89.2914 27.7843 89.2914Z"
        fill="#CFCFCF"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M28.4725 89.8864C28.4725 89.8864 27.811 91.2094 28.0567 91.6252C28.3024 92.0409 28.6388 91.6252 28.8127 91.2094C28.9573 90.9561 29.0414 90.6727 29.0584 90.3815C29.0697 89.6558 28.4725 89.8864 28.4725 89.8864Z"
        fill="#CFCFCF"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M29.2593 90.5414C29.2593 90.5414 28.5978 91.8681 28.8472 92.2839C29.0967 92.6997 29.4256 92.2839 29.6032 91.8681C29.7469 91.6142 29.8322 91.3314 29.8527 91.0403C29.8565 90.3146 29.2593 90.5414 29.2593 90.5414Z"
        fill="#CFCFCF"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M33.8328 91.5859L31.459 94.0164L37.7526 99.1534L38.3612 94.901L33.8328 91.5859Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M36.8982 102.094C36.8982 102.094 35.7038 106.49 35.7642 107.163C35.8095 107.558 35.8347 107.956 35.8398 108.354C35.8398 108.354 32.4832 112.455 30.7709 116.106C29.0586 119.758 28.9074 121.848 29.429 122.219C29.9507 122.589 37.0305 125.723 37.7752 125.874L38.5312 126.021L44.5791 128.108L45.1763 128.781L46.367 128.403C46.8887 128.104 47.1873 126.093 47.3385 125.273C47.4897 124.453 47.8601 124.078 47.4859 124.007C47.1393 123.966 46.7892 123.966 46.4426 124.007C46.4426 124.007 46.6656 123.557 46.2952 123.557H45.2368C45.2368 123.557 40.3229 121.546 39.4988 121.1C38.6748 120.654 36.5921 119.535 36.5921 119.535L41.4569 116.035C41.4569 116.035 47.346 112.606 48.1663 111.189C48.9865 109.771 49.4326 107.088 49.4326 105.969C49.4326 104.85 41.3057 101.055 39.892 101.055C38.4783 101.055 36.97 101.35 36.8982 102.094Z"
        fill="#263238"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M47.4824 124.016C47.4824 124.016 51.6404 124.163 52.2527 124.462C52.8651 124.76 52.8499 125.804 52.8499 126.548C52.8499 127.293 52.2527 129.009 52.4039 129.829C52.5551 130.649 52.5816 131.625 52.6987 132.812C52.9255 135.046 52.2716 135.28 51.8823 135.197C50.4648 134.898 50.3136 133.182 49.6445 131.545C48.9755 129.909 48.6013 129.009 48.6013 129.009L46.3711 128.404C46.3711 128.404 46.8133 125.134 47.4824 124.016Z"
        fill="white"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M52.2537 124.462C51.9107 124.35 51.5552 124.282 51.1953 124.258C51.4943 125.396 51.6492 126.567 51.6565 127.743C51.6565 130.052 52.1063 132.736 51.8833 134.452C51.8455 134.728 51.8077 134.959 51.7699 135.163C51.807 135.177 51.8448 135.188 51.8833 135.197C52.2613 135.28 52.9265 135.046 52.6997 132.812C52.5826 131.625 52.5523 130.65 52.4049 129.829C52.2575 129.009 52.8509 127.293 52.8509 126.548C52.8509 125.804 52.8509 124.761 52.2537 124.462Z"
        fill="white"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M37.8101 108.368C37.8101 108.368 38.721 114.004 38.3884 117.315C38.0558 120.626 38.309 128.005 38.6379 130.076C38.9667 132.147 38.721 138.362 38.721 139.439C38.7317 139.966 38.7039 140.492 38.6379 141.015C38.6379 141.015 37.7269 140.932 37.8101 141.427C37.8618 141.756 37.8895 142.088 37.8932 142.421C38.4717 142.883 39.1543 143.195 39.8815 143.332C41.1251 143.582 42.4518 143.831 42.6975 143.499C42.9432 143.166 42.8639 142.092 42.8639 142.092C42.9996 141.966 43.1069 141.811 43.1786 141.64C43.2502 141.469 43.2847 141.284 43.2797 141.098V140.081C43.2797 140.081 44.4401 135.277 44.3569 132.378C44.2738 129.479 43.6917 127.819 43.858 127.086C44.0243 126.353 47.7513 114.99 47.7513 114.99C47.7513 114.99 48.9118 112.503 49.1613 110.93C49.3372 109.562 49.0181 108.176 48.2616 107.022C47.7627 106.36 40.1423 104.289 40.1423 104.289"
        fill="#263238"
      />
      <path
        d="M37.8101 108.368C37.8101 108.368 38.721 114.004 38.3884 117.315C38.0558 120.626 38.309 128.005 38.6379 130.076C38.9667 132.147 38.721 138.362 38.721 139.439C38.7317 139.966 38.7039 140.492 38.6379 141.015C38.6379 141.015 37.7269 140.932 37.8101 141.427C37.8618 141.756 37.8895 142.088 37.8932 142.421C38.4717 142.883 39.1543 143.195 39.8815 143.332C41.1251 143.582 42.4518 143.831 42.6975 143.499C42.9432 143.166 42.8639 142.092 42.8639 142.092C42.9996 141.966 43.1069 141.811 43.1786 141.64C43.2502 141.469 43.2847 141.284 43.2797 141.098V140.081C43.2797 140.081 44.4401 135.277 44.3569 132.378C44.2738 129.479 43.6917 127.819 43.858 127.086C44.0243 126.353 47.7513 114.99 47.7513 114.99C47.7513 114.99 48.9118 112.503 49.1613 110.93C49.3372 109.562 49.0181 108.176 48.2616 107.022C47.7627 106.36 40.1423 104.289 40.1423 104.289"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M39.913 82.5659C39.913 82.5659 37.8832 86.807 37.8832 89.453C37.8832 92.0989 37.0856 96.5857 37.0856 97.8142C37.0856 99.0427 35.9781 100.891 36.4695 101.949C36.9609 103.008 39.7883 102.993 40.034 102.993C40.2797 102.993 41.8824 103.053 42.1886 102.932C42.4947 102.811 43.0504 92.6621 43.0504 90.3261C43.0504 87.9901 43.1713 82.0102 43.1109 81.2845C43.0504 80.5587 40.6501 81.2126 39.913 82.5659Z"
        fill="white"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M43.2796 78.3589C41.625 81.1695 40.6069 84.3088 40.2972 87.5555C39.7151 93.1083 38.1427 102.966 39.3031 104.044C40.4636 105.121 43.3628 105.866 44.6858 106.531C46.0088 107.196 48.1671 108.27 48.9118 108.686C49.6564 109.101 50.9 108.602 50.9 108.602C50.9 108.602 51.8942 99.8177 51.5653 95.9244C51.2364 92.031 50.7375 85.4841 49.1612 82.3354C47.585 79.1867 43.6916 77.9431 43.2796 78.3589Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M43.4453 106.531L42.4512 107.609L44.1068 109.842L45.1009 107.937L43.4453 106.531Z"
        fill="#CFCFCF"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M43.4449 83.9935C43.4449 83.9935 42.0349 87.6412 42.7796 90.6235C43.5242 93.6059 45.5163 99.8202 45.5163 99.8202L43.0291 106.533L45.5163 108.771C45.5163 108.771 50.7364 100.153 50.7364 98.9924C50.7364 97.8319 49.576 85.9818 49.077 84.4093C48.5781 82.8368 47.7502 81.3438 46.5104 81.3438"
        fill="#92E3A9"
      />
      <path
        d="M43.4449 83.9935C43.4449 83.9935 42.0349 87.6412 42.7796 90.6235C43.5242 93.6059 45.5163 99.8202 45.5163 99.8202L43.0291 106.533L45.5163 108.771C45.5163 108.771 50.7364 100.153 50.7364 98.9924C50.7364 97.8319 49.576 85.9818 49.077 84.4093C48.5781 82.8368 47.7502 81.3438 46.5104 81.3438"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M38.2251 142.656C38.2251 142.656 37.643 142.906 37.643 143.318C37.643 143.73 37.1478 145.964 37.1478 145.964C37.1478 145.964 33.0881 147.37 32.3397 147.536C31.5913 147.702 31.3456 149.029 31.5837 149.426C31.8219 149.823 34.5661 150.182 36.9702 150.258C39.3742 150.333 42.1903 150.341 42.4398 149.925C42.6892 149.509 42.6023 145.45 42.6023 145.45L42.6855 143.461C41.1768 143.333 39.6834 143.064 38.2251 142.656Z"
        fill="white"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M31.5195 149.219C31.5303 149.3 31.5559 149.378 31.5951 149.449C31.8446 149.865 34.5775 150.205 36.9816 150.281C39.3856 150.357 42.2017 150.364 42.4512 149.948C42.5069 149.799 42.5388 149.642 42.5457 149.483C38.9887 150.092 33.8593 149.536 31.5195 149.219Z"
        fill="white"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M31.4294 68.1668C31.4294 68.1668 29.6906 69.7393 29.8569 71.977C30.0232 74.2147 30.3521 75.0426 30.6129 75.292C30.9244 75.5411 31.257 75.7624 31.607 75.9535C31.607 75.9535 31.4407 78.3576 31.9397 78.5239C32.4014 78.623 32.8756 78.651 33.3458 78.6071C33.3458 78.6071 34.6726 81.0073 35.4172 81.0905C36.1619 81.1736 37.655 79.6843 37.655 79.6843L40.1422 81.9183L42.9583 77.6091L41.3858 75.8704C41.3858 75.8704 42.1418 71.2324 41.0532 68.7451C39.9645 66.2579 33.5197 65.0181 31.4294 68.1668Z"
        fill="#CFCFCF"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M37.6426 79.6836L38.7992 78.5156L38.6178 80.2317L37.6426 79.6836Z"
        fill="#263238"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M38.8117 75.5377C38.8117 75.5377 40.2896 75.6511 41.4198 75.602C41.5559 74.5776 41.9717 70.8733 41.0418 68.7451C39.9645 66.2579 33.4819 65.0181 31.4294 68.1668C30.8201 68.7667 30.3571 69.4987 30.0762 70.3063C30.1934 70.499 30.3597 70.6087 30.6016 70.4008C31.1799 69.9056 32.9225 69.7393 33.0888 70.6502C33.2551 71.5612 33.0888 72.3928 34.0829 72.1433C34.5998 71.9789 35.1588 72.0085 35.6554 72.2265C35.6554 72.2265 36.3169 73.3869 36.7327 73.1375C37.1485 72.888 36.8158 72.3815 37.3148 71.8107C37.8137 71.2399 39.7151 72.1433 39.7982 73.3869C39.8814 74.6305 38.8117 75.5377 38.8117 75.5377Z"
        fill="#263238"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M33.1416 75.2357C33.323 75.455 33.3684 75.7158 33.2399 75.8216C33.1113 75.9275 32.8619 75.8216 32.6842 75.6137C32.5065 75.4058 32.4574 75.1337 32.5859 75.0278C32.7144 74.922 32.9412 75.0165 33.1416 75.2357Z"
        fill="#263238"
      />
      <path
        d="M31.8905 74.9513C31.8905 74.9513 31.6902 74.1953 32.6465 74.1953"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M42.7383 76.8359L39.1738 82.2451L40.7728 84.3354L44.3373 78.0039L42.7383 76.8359Z"
        fill="white"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default FAQImageIcon;
