import React from 'react';

const NeedToSignIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_140_22537)">
        <path
          d="M24 23.5C24 23.78 23.78 24 23.5 24C22.23 24 21.6 23.04 21.08 22.28C20.55 21.49 20.19 21 19.5 21C18.73 21 18.21 21.61 17.61 22.32C16.94 23.11 16.19 24 14.96 24C13.73 24 12.99 23.11 12.34 22.32C11.76 21.61 11.26 21 10.5 21C9.79 21 9.17 21.53 8.46 22.15C7.51 22.97 6.33 24 4.5 24C2.02 24 0 21.98 0 19.5C0 17.02 2.02 15 4.5 15H5.5C5.78 15 6 15.22 6 15.5C6 15.78 5.78 16 5.5 16H4.5C2.57 16 1 17.57 1 19.5C1 21.43 2.57 23 4.5 23C5.96 23 6.9 22.18 7.81 21.4C8.6 20.71 9.42 20 10.5 20C11.74 20 12.47 20.89 13.12 21.68C13.7 22.39 14.2 23 14.96 23C15.72 23 16.25 22.39 16.85 21.68C17.52 20.89 18.27 20 19.5 20C20.73 20 21.4 20.96 21.92 21.72C22.45 22.51 22.81 23 23.5 23C23.78 23 24 23.22 24 23.5Z"
          fill="#606060"
        />
        <path
          d="M23.23 0.770059C22.2 -0.259941 20.52 -0.259941 19.49 0.770059L9.03 11.2401C8.37 11.9001 8 12.7801 8 13.7201V15.5101C8 15.7901 8.22 16.0101 8.5 16.0101H10.29C11.23 16.0101 12.1 15.6501 12.77 14.9801L23.23 4.51006C24.26 3.48006 24.26 1.80006 23.23 0.770059ZM12.06 14.2701C11.59 14.7401 10.96 15.0001 10.29 15.0001H9V13.7101C9 13.0401 9.26 12.4101 9.73 11.9401L17.98 3.69006L20.3 6.01006L12.06 14.2701ZM22.52 3.80006L21.02 5.30006L18.7 2.98006L20.2 1.48006C20.84 0.840059 21.88 0.840059 22.52 1.48006C23.16 2.12006 23.16 3.16006 22.52 3.80006Z"
          fill="#606060"
        />
      </g>
      <defs>
        <clipPath id="clip0_140_22537">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default NeedToSignIcon;
