import React from 'react';

const EsignAvatar = ({ initials, fullName, bgColor, textColor }) => {
  return (
    <div className="flex items-center gap-[10px]">
      <div
        className="w-[32px] h-[32px] rounded-full  flex items-center justify-center"
        style={{
          backgroundColor: bgColor,
          color: textColor ? textColor : 'white'
        }}
      >
        {initials}
      </div>
      <h2 className="text-[16px] font-[600] text-[#404040]">{fullName}</h2>
    </div>
  );
};

export default EsignAvatar;
