import React from 'react';

const ZoomOutIcon = () => {
  return (
    <svg width="20" height="6" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.0833 0.666016H0.916667C0.686667 0.666016 0.5 0.852682 0.5 1.08268C0.5 1.31268 0.686667 1.49935 0.916667 1.49935H15.0833C15.3133 1.49935 15.5 1.31268 15.5 1.08268C15.5 0.852682 15.3133 0.666016 15.0833 0.666016Z"
        fill="#303030"
      />
    </svg>
  );
};

export default ZoomOutIcon;
