import React from 'react';

const BuildingWhyChooseEsignIcon = () => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_6210_14437)">
        <path
          d="M12.75 20.8745V3.62455C12.7499 3.48882 12.713 3.35565 12.6432 3.23924C12.5734 3.12284 12.4733 3.02756 12.3536 2.96357C12.2339 2.89958 12.099 2.86928 11.9635 2.87589C11.8279 2.8825 11.6967 2.92578 11.5838 3.00111L4.08375 8.0008C3.98088 8.06943 3.89658 8.16244 3.83835 8.27154C3.78012 8.38064 3.74977 8.50245 3.75 8.62611V20.8745"
          stroke="black"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12.75 8.875H19.5C19.6989 8.875 19.8897 8.95402 20.0303 9.09467C20.171 9.23532 20.25 9.42609 20.25 9.625V20.875"
          stroke="black"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path d="M1.5 20.875H22.5" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M9.75 11.125V12.625" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M6.75 11.125V12.625" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M6.75 16.375V17.875" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M9.75 16.375V17.875" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_6210_14437">
          <rect width="24" height="24" fill="white" transform="translate(0 0.625)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BuildingWhyChooseEsignIcon;
