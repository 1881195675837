import React from 'react';

const FileWhyChooseEsignIcon = () => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_6210_14415)">
        <path d="M9 14.875H15" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M9 11.875H15" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path
          d="M15 4.375H18.75C18.9489 4.375 19.1397 4.45402 19.2803 4.59467C19.421 4.73532 19.5 4.92609 19.5 5.125V20.875C19.5 21.0739 19.421 21.2647 19.2803 21.4053C19.1397 21.546 18.9489 21.625 18.75 21.625H5.25C5.05109 21.625 4.86032 21.546 4.71967 21.4053C4.57902 21.2647 4.5 21.0739 4.5 20.875V5.125C4.5 4.92609 4.57902 4.73532 4.71967 4.59467C4.86032 4.45402 5.05109 4.375 5.25 4.375H9"
          stroke="black"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8.25 7.375V6.625C8.25 5.63044 8.64509 4.67661 9.34835 3.97335C10.0516 3.27009 11.0054 2.875 12 2.875C12.9946 2.875 13.9484 3.27009 14.6517 3.97335C15.3549 4.67661 15.75 5.63044 15.75 6.625V7.375H8.25Z"
          stroke="black"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_6210_14415">
          <rect width="24" height="24" fill="white" transform="translate(0 0.625)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FileWhyChooseEsignIcon;
