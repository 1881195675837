import React from 'react';

const MarkAsInvalidIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.3641 16.3641C18.0519 14.6762 19.0001 12.387 19.0001 10.0001C19.0001 7.61309 18.0519 5.32389 16.3641 3.63606C14.6762 1.94822 12.387 1 10.0001 1C7.61309 1 5.32389 1.94822 3.63606 3.63606M16.3641 16.3641C14.6762 18.0519 12.387 19.0001 10.0001 19.0001C7.61309 19.0001 5.32389 18.0519 3.63606 16.3641C1.94822 14.6762 1 12.387 1 10.0001C1 7.61309 1.94822 5.32389 3.63606 3.63606M16.3641 16.3641L3.63606 3.63606"
        stroke="#EA001E"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default MarkAsInvalidIcon;
