import SignedDocumentIcon from 'assets/images/eSign/SignedDocumentIcon';
import { useAccount } from 'context/AccountProvider';
import SecondaryButton from 'layout/MainLayoutNewUI/Form/Button/SecondaryButton';
import React from 'react';
import { baseApiUrl } from 'services/eSignService';

const SuccuessFullySigned = () => {
  const { userDetails } = useAccount();
  const redirectToCreateAccount = () => {
    window.location.href = `${baseApiUrl}register`;
  };

  const redirectToInbox = () => {
    window.location.href = `/dashboard/documents/inbox`;
  };
  return (
    <div className="success-screens-container flex flex-col  items-center justify-center  bg-[white] w-full rounded-[10px] mb-[10px]">
      <div className="w-[70%] flex flex-col items-center justify-center max-w-[440px]">
        <SignedDocumentIcon />
        <h2>You have successfully signed the document!</h2>
        <div className="flex gap-2 mt-[30px] w-full items-center justify-center">
          {userDetails?.email ? (
            <SecondaryButton title={'Go to Inbox'} className={'w-[70%]'} onClick={redirectToInbox} />
          ) : (
            <SecondaryButton title={'Create your account'} className={'w-[70%]'} onClick={redirectToCreateAccount} />
          )}
        </div>
      </div>
    </div>
  );
};

export default SuccuessFullySigned;
